import { useState } from 'react';
import { Form, message } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/storeContext';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../../constants/routePath';
import { BaseInput } from '../../../../components/Input';
import BaseTypography from '../../../../components/BaseTypography';
import { COLORS } from '../../../../constants/colors';
import {
  StyledFlex,
  StyledLink,
  StyledRegister,
  StyledForm,
} from '../../styled';
import { BaseButton } from '../../../../components/Button';
import { isMobile } from 'react-device-detect';

const Login = observer(() => {
  const { authStore } = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onLogin = async (values) => {
    try {
      const result = await authStore.onLogin(values);
      
      if (result && result.profile) {
        if (result.profile.isApproved) {
          navigate(ROUTE_PATH.DASHBOARD);
        } else {
          navigate(ROUTE_PATH.PENDING_APPROVAL);
        }
      } else {
        message.error('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <StyledFlex vertical>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        type="title"
        color={COLORS.$black}
        fontWeight={700}
        level={3}
      >
        Welcome back
      </BaseTypography>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        marginBottom="32px"
        color={COLORS.$blackOpacity50}
      >
        Welcome back! Please enter your details.
      </BaseTypography>
      <StyledForm form={form} onFinish={onLogin}>
        <Form.Item name="email">
          <BaseInput label="Email" placeholder="Email" />
        </Form.Item>
        <Form.Item name="password">
          <BaseInput.Password
            type="password"
            label="Password"
            placeholder="Enter password"
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />
        </Form.Item>
        <StyledLink to={ROUTE_PATH.FORGOT_PASSWORD}>
          Forgot password?
        </StyledLink>
        <Form.Item>
          <BaseButton type="primary" htmlType="submit" block>
            Log in
          </BaseButton>
        </Form.Item>
        <StyledRegister>
          Don’t have an account?{' '}
          <Link to={ROUTE_PATH.REGISTER}>Sign up for free</Link>
        </StyledRegister>
      </StyledForm>
    </StyledFlex>
  );
});

export default Login;
