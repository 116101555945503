import styled from 'styled-components';
import { Card, Select, Button } from 'antd';

export const Container = styled.div`
  padding: 0 16px;
`;

export const Header = styled.header`
  padding: 16px 0;
`;

export const MainContent = styled.div`
  padding: 32px 0;
  max-width: 1440px;
  margin: auto;
`;

export const LocationSearch = styled.div`
  margin: 16px 0;
`;

export const PropertyList = styled.div`
  margin: 32px;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 75px;

  .ant-card-cover img {
    width: 100%;
    height: auto;
  }

  .ant-card-meta-title {
    margin-top: 16px;
  }

  .ant-card-meta-description {
    margin-top: 4px;
  }
`;


export const LinkToMore = styled.a`
  text-decoration: underline;
  margin-bottom: 20px;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Footer = styled.footer`
  padding: 32px 0;
  text-align: center;
`;

export const StyledCard = styled(Card)`
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.0);
  }
`;

export const HouseStatus = styled.p`
  position: relative;
  padding-left: 15px;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: #4CAF50;  // Green color
    border-radius: 50%;
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 48px !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-item {
    line-height: 48px !important;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  width: 270px;
  margin: 20px 0;
  background-color: black;
  color: white;
  border: none;
  height: 48px;
  &:hover {
    background-color: #333;
    color: white;
  }
`;

export const SectionHighlight = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #000;
  text-align: left; // Align text to the left

  span {
    color: #007bff;
  }

  @media (max-width: 768px) {
    font-size: 27px;
  }
`;

export const SectionSubheading = styled.h3`
  font-size: 16px;
  margin-bottom: 20px;
  color: #b0b0b0;
  text-align: left; // Align text to the left

   @media (max-width: 768px) {
    font-size: 16px!important;
  }
`;