// components/DesktopFilters/styled.js
import styled from 'styled-components';
import { Button as AntButton, Flex as AntFlex, Button, Input as AntInput, Input } from 'antd';

export const SearchContainer = styled.div`
  margin-bottom: 15px;
`;

export const SelectsWrapper = styled(AntFlex)`
  display: flex;
  gap: 8px;
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FilterButton = styled(Button)`
  height: 48px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  .filter-text {
    flex: 1;
    text-align: left;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0; // This is important for text-overflow to work
  }

  .anticon {
    flex-shrink: 0;
    margin-left: 4px;
  }
  
  &:hover {
    border-color: #000;
    color: #000;
  }
`;

export const FiltersButton = styled(AntButton)`
  background-color: #005eb8;
  color: #ffffff;
  height: 48px;
  width: 100%;
`;

// Update the styled components
export const StyledButton = styled(Button)`
  height: 48px;
  min-width: 120px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  
  &:hover {
    border-color: #000;
    color: #000;
  }
`;

export const PopupDoneButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    opacity: 0.8;
  }
`;


// Popup Styled Components
export const PopupContainer = styled.div`
  width: 320px;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
`;

export const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`;

export const SliderContainer = styled.div`
  padding: 24px 0;

  .ant-slider {
    margin: 10px 0 30px;

    .ant-slider-track {
      background-color: #000;
    }

    .ant-slider-handle {
      border-color: #000;
      
      &:hover, &:active {
        border-color: #000;
      }
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const StyledInput = styled(AntInput)`
  height: 44px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  font-size: 14px;
  
  &::placeholder {
    color: #999;
  }
`;

export const DoneButton = styled(AntButton)`
  width: 100%;
  height: 44px;
  background: #000;
  border-color: #000;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  
  &:hover {
    background: #333;
    border-color: #333;
  }
`;

export const SearchInput = styled(Input)`
  height: 48px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  font-size: 14px;
  flex: 1.5;
  width: 100%;
  
  .ant-input {
    font-size: 14px;
  }

  .ant-input-prefix {
    color: #666;
    margin-right: 8px;
  }

  &:hover, &:focus {
    border-color: #000;
  }

  &.ant-input-affix-wrapper:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: #000;
    box-shadow: none;
  }
`;