import React, { useState } from 'react';
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks/storeContext";
import BaseTypography from "../../../../components/BaseTypography";
import { COLORS } from "../../../../constants/colors";
import { StyledContainer, StyledFlex, StyledInputGroup, StyledLabel, StyledRegister, StyledSelect } from "./styled";
import { BaseButton } from "../../../../components/Button";
import { Slider } from 'antd';

const PROPERTY_TYPE_OPTIONS = [
  { value: 'SFR', label: 'Single Family Residence' },
  { value: 'MFR', label: 'Multi-Family Residence' },
  { value: 'LAND', label: 'Land' },
  { value: 'CONDO', label: 'Condo' },
  { value: 'MOBILE', label: 'Mobile' },
  { value: 'OTHER', label: 'Other' },
];

const Onboarding1 = observer(({ setStep }) => {
    const [preferences, setPreferences] = useState({
        propertyType: 'SFR',
        minLoanTerm: 15,
        maxLoanTerm: 30,
        returnRateExpectation: 8,
    });
    
    const { authStore } = useStore();

    const handleChange = (name, value) => {
        setPreferences(prev => ({ ...prev, [name]: value }));
    };

    const onSubmit = async () => {
        try {
            await authStore.saveInvestmentPreferences(preferences);
            console.log('Investment preferences saved successfully');
            setStep(2);
        } catch (error) {
            console.error('Error saving investment preferences:', error);
        }
    };

    const onSkip = () => {
        setStep(2);
    };

    return (
        <StyledContainer>
            <StyledFlex vertical>
                <BaseTypography align="left" type="paragraph" color={COLORS.$greyScale500} level={0}>
                    Step 1/4
                </BaseTypography>
                <BaseTypography align="left" type="title" color={COLORS.$black} level={3} style={{ marginBottom: '20px' }}>
                    Investment Preferences
                </BaseTypography>

                <StyledInputGroup>
                    <StyledLabel>Property Type</StyledLabel>
                    <StyledSelect
                        placeholder="Select property type"
                        options={PROPERTY_TYPE_OPTIONS}
                        style={{ width: '100%' }}
                        onChange={(value) => handleChange('propertyType', value)}
                        value={preferences.propertyType}
                    />
                </StyledInputGroup>

                <StyledInputGroup>
                    <StyledLabel>Preferred Loan Terms Range (in years)</StyledLabel>
                    <StyledFlex>
                        <Slider
                            range
                            min={1}
                            max={30}
                            step={1}
                            value={[preferences.minLoanTerm, preferences.maxLoanTerm]}
                            onChange={(value) => {
                                handleChange('minLoanTerm', value[0]);
                                handleChange('maxLoanTerm', value[1]);
                            }}
                            style={{ flex: 1 }}
                        />
                        <BaseTypography style={{ marginLeft: '10px', minWidth: '100px' }}>
                            {preferences.minLoanTerm} - {preferences.maxLoanTerm} years
                        </BaseTypography>
                    </StyledFlex>
                </StyledInputGroup>

                <StyledInputGroup>
                    <StyledLabel>Rate of Return Expectation: {preferences.returnRateExpectation}%</StyledLabel>
                    <Slider
                        min={0}
                        max={20}
                        step={0.5}
                        value={preferences.returnRateExpectation}
                        onChange={(value) => handleChange('returnRateExpectation', value)}
                    />
                </StyledInputGroup>

                <BaseButton type="primary" onClick={onSubmit} block>
                    Next
                </BaseButton>
                <StyledRegister>
                    <button onClick={onSkip} style={{ background: 'none', border: 'none', color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
                        Skip For Now
                    </button>
                </StyledRegister>
            </StyledFlex>
        </StyledContainer>
    );
});

export default Onboarding1;