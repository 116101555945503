import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const mainBlue = '#005EB8';  // Replace with your main blue color

export const Wrapper = styled.div`
  padding: 20px;
`;

export const LinkStyle = styled(Link)`
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  gap: 16px;
  opacity: 0.7;
  cursor: pointer;
`;

export const TitlePage = styled.h4`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding: 10px 0;

  &::-webkit-scrollbar {
    display: none;  // Hide the scrollbar in WebKit browsers
  }
`;

export const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;  // Prevent text from wrapping
  flex-shrink: 0;  // Prevent flex items from shrinking
  font-size: 16px;
  margin-right: 20px;  // Add space between tabs

  &.active {
    font-weight: bold;
    border-bottom: 2px solid ${mainBlue};  // Underline for active tab with main blue color
  }
`;

export const IconStyle = styled(ArrowLeftOutlined)`
  margin-right: 8px;
`;

export const ActiveTab = styled(Tab)`
  font-weight: bold;
  border-bottom: 2px solid ${mainBlue};
`;

export const InactiveTab = styled(Tab)`
  color: #888;
`;
