import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { GlobalStyles } from './GlobalStyles';
import { ROUTES } from './constants/routes';
import { StoreContext } from './hooks/storeContext';
import store from './_store/index';
import './styles/global.scss';
import { getTheme } from './theme';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

// Providers
import { APIProvider } from '@vis.gl/react-google-maps';
import { ConfigProvider } from 'antd';

const router = createBrowserRouter(ROUTES);
const themeObject = getTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreContext.Provider value={store}>
    <ConfigProvider theme={themeObject}>
      <ThemeProvider theme={theme}>
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <RouterProvider router={router} />
          <GlobalStyles />
        </APIProvider>
      </ThemeProvider>
    </ConfigProvider>
  </StoreContext.Provider>
);
