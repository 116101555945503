import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import styled from 'styled-components';
import { useStore } from '../../../../hooks/storeContext';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 24px;
  }
`;

const StyledButton = styled(Button)`
  height: 52px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #414141;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  height: 48px !important;
  padding: 0 11px;
  font-size: 16px;

  &.ant-input {
    height: 48px !important;
    line-height: 48px !important;
  }
`;

const StyledPassword = styled(Input.Password)`
  .ant-input {
    height: 48px !important;
    line-height: 48px !important;
  }

  .ant-input-affix-wrapper {
    height: 48px !important;
    padding: 0 11px !important;
  }

  .ant-input-suffix {
    height: 48px !important;
    display: flex;
    align-items: center;
  }

  input {
    height: 48px !important;
    line-height: 48px !important;
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-control-input {
    height: 48px!important;
  }
`;

const LoginModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { authStore } = useStore();

  const handleSubmit = async (values) => {
    try {
      await authStore.onClientLogin(values);
      onClose();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <StyledModal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={500}
      centered
    >
      <Title>Log in</Title>
      <Subtitle>Enter your credentials to access your account.</Subtitle>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <StyledFormItem
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <StyledInput placeholder="Email" />
        </StyledFormItem>
        <StyledFormItem
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <StyledPassword placeholder="Password" />
        </StyledFormItem>
        <Form.Item>
          <StyledButton type="primary" htmlType="submit" block>
            Log in
          </StyledButton>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default LoginModal;