import styled from 'styled-components';
import { Input, Checkbox, Button } from 'antd';

export const BackgroundImageContainer = styled.div`
  background-image: ${props => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); // Adjust the opacity as needed
  }
`;

export const Divider = styled.hr`
  border: none;
  height: ${props => props.thickness || '1px'};
  background-color: ${props => props.color || '#e0e0e0'};
  margin: ${props => props.spacing || '40px 0'};
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    marginTop: -190px;
  }
`;

export const Header = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

export const Subheader = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

export const CalculatorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FormSection = styled.div`
  width: 50%;
  background: white;
  padding: 30px;
  border-radius: 17px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FormTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 20px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-size: 18px;
  margin-top: 20px;
`;

export const NextSteps = styled.div`
  margin-top: 40px;
`;

export const NextStepsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const NextStepsContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NextStepsText = styled.div`
  flex: 1;
`;

export const NextStepsImage = styled.img`
  width: 200px;
  height: auto;
`;

export const DesktopOnly = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;