import styled from 'styled-components';
import { Button, Input, Typography, Row } from 'antd';
import heroImage from '../../../../assets/images/hp-hero-desktop.jpg';

const { Title } = Typography;
const { Search } = Input;

export const HeroContainer = styled.div`
  background-image: url(${heroImage});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 80px 0;
  height: 450px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25); // Adjust the last value (0.5) to control the darkness
    z-index: 1;
  }
`;

export const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 24px!important;
  white-space: pre-line!important;
  color: white!important; // Change this to your preferred color

  @media (max-width: 768px) {
    text-align: left;
  }
`;

export const HeroContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  z-index: 2;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 600px;
  height: 40px;
  margin-bottom: 16px;
  background-color: #1890ff;
  color: white;
  &:hover {
    background-color: #40a9ff;
    color: white;
  }
`;

export const NavButtons = styled(Row)`
  margin-bottom: 2rem;
`;

export const NavButton = styled(Button)`
  margin: 0 5px 10px;
  border: none;
  background: transparent;
  color: white;
  font-size: 1rem;

  &:hover, &:focus {
    color: #40a9ff;
    background: transparent;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 4px 8px;
  }
`;

export const StyledSearch = styled(Search)`

  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3);

  .ant-input, .ant-input-search-button {
    height: 52px;
  }

  .ant-input {
    font-size: 1rem;
  }

  .ant-input-search-button {
    width: 60px;
    border-radius: 25px 25px 0 0;
  }

  @media (max-width: 768px) {
    .ant-input, .ant-input-search-button {
      height: 52px;
    }
    .ant-input {
      font-size: 0.9rem;
    }
  }
`;