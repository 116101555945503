import axios from 'axios';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const BASE_URL = 'https://maps.googleapis.com/maps/api/streetview';

export const getStreetViewImage = async ({ address, lat, lng, size = '600x300' }) => {
  let url = `${BASE_URL}?size=${size}&key=${GOOGLE_API_KEY}`;

  if (address) {
    url += `&location=${encodeURIComponent(address)}`;
  } else if (lat && lng) {
    url += `&location=${lat},${lng}`;
  } else {
    throw new Error('Either address or lat/lng must be provided.');
  }

  try {
    const response = await axios.get(url, { responseType: 'blob' });
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  } catch (error) {
    console.error('Error fetching Street View image:', error.response ? error.response.data : error.message);
    throw error;
  }
};
