import React from 'react';
import { Typography } from 'antd';
import mapImage from '../../../../assets/images/map.png'; // Adjust this path as needed
import {
  HeroContainer,
  StyledTitle,
  MapInsightsText,
  MapImage
} from './styled';

const MapSection = () => {
  return (
    <HeroContainer>
      <MapInsightsText>MAP INSIGHTS</MapInsightsText>
      <StyledTitle>
        Explore <span>Investment</span><br />
        Opportunities
      </StyledTitle>
      <MapImage src={mapImage} alt="Investment Opportunities Map" />
    </HeroContainer>
  );
};

export default MapSection;
