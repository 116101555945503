import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table, Button, Typography, Spin, Modal, Input, Select, Row, Col, Statistic, Dropdown, Menu } from 'antd';
import { SearchOutlined, MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { api } from '../../../../constants/api';

const { Title, Text } = Typography;
const { Option } = Select;

// Styled Components
const Container = styled.div`
  padding: 24px;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 24px;
  margin-bottom: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const BackButton = styled(Button)`
  align-self: flex-start;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const ApplicantsTitle = styled(Title)`
  && {
    margin-bottom: 0;
    font-size: 20px;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
`;

const StyledTable = styled(Table)`
  .ant-table-tbody > tr {
    cursor: pointer;
  }
`;

const SectionTitle = styled(Title)`
  margin-top: 24px !important;
  margin-bottom: 16px !important;
`;

const InfoRow = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const InfoLabel = styled(Text)`
  font-weight: bold;
  width: 150px;
`;

const InfoValue = styled(Text)`
  flex: 1;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 52px;
`;

const StatusFilterWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 35%;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const Applicants = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { listingID } = useParams();
  const { property } = location.state || {};
  const [applicants, setApplicants] = useState([]);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await fetch(api.lending.getApplicants(listingID));
        const data = await response.json();
        setApplicants(data);
        setFilteredApplicants(data);
      } catch (error) {
        console.error('Error fetching applicants:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (listingID) {
      fetchApplicants();
    }
  }, [listingID]);

  useEffect(() => {
    const filtered = applicants.filter(applicant =>
      (applicant.personalDetails.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        applicant.personalDetails.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        applicant.personalDetails.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (statusFilter === 'All' || applicant.status === statusFilter)
    );
    setFilteredApplicants(filtered);
  }, [searchTerm, statusFilter, applicants]);

  const handleBack = () => {
    navigate(-1);
  };

  const showModal = (applicant) => {
    setSelectedApplicant(applicant);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const handleQuickAction = (action, applicant) => {
    // Implement quick actions here (e.g., change status, send email, etc.)
    console.log(`Quick action ${action} for applicant:`, applicant);
  };

  const quickActionMenu = (applicant) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleQuickAction('approve', applicant)}>Approve</Menu.Item>
      <Menu.Item key="2" onClick={() => handleQuickAction('reject', applicant)}>Reject</Menu.Item>
      <Menu.Item key="3" onClick={() => handleQuickAction('contact', applicant)}>Contact</Menu.Item>
    </Menu>
  );

  const APPLICANTS_COLUMNS = [
    {
      title: 'Name',
      dataIndex: ['personalDetails', 'firstName'],
      key: 'name',
      render: (_, record) => `${record.personalDetails.firstName} ${record.personalDetails.lastName}`,
    },
    {
      title: 'Email',
      dataIndex: ['personalDetails', 'email'],
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: ['personalDetails', 'phone'],
      key: 'phone',
    },
    {
      title: 'Application Date',
      dataIndex: 'createdAt',
      key: 'applicationDate',
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={quickActionMenu(record)} trigger={['click']}>
          <Button icon={<MoreOutlined />} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  const renderDetailedInfo = () => {
    if (!selectedApplicant) return null;

    return (
      <>
        <SectionTitle level={4}>Personal Details</SectionTitle>
        <InfoRow>
          <InfoLabel>Name:</InfoLabel>
          <InfoValue>{selectedApplicant.personalDetails.firstName} {selectedApplicant.personalDetails.lastName}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Email:</InfoLabel>
          <InfoValue>{selectedApplicant.personalDetails.email}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Phone:</InfoLabel>
          <InfoValue>{selectedApplicant.personalDetails.phone}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Date of Birth:</InfoLabel>
          <InfoValue>{new Date(selectedApplicant.personalDetails.dateOfBirth).toLocaleDateString()}</InfoValue>
        </InfoRow>

        <SectionTitle level={4}>Employment Information</SectionTitle>
        <InfoRow>
          <InfoLabel>Status:</InfoLabel>
          <InfoValue>{selectedApplicant.employmentInfo.employmentStatus}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Annual Income:</InfoLabel>
          <InfoValue>${selectedApplicant.employmentInfo.annualIncome.toLocaleString()}</InfoValue>
        </InfoRow>

        <SectionTitle level={4}>Financial Information</SectionTitle>
        <InfoRow>
          <InfoLabel>Down Payment:</InfoLabel>
          <InfoValue>${selectedApplicant.financialInfo.downPayment.toLocaleString()}</InfoValue>
        </InfoRow>

        <SectionTitle level={4}>Loan Terms</SectionTitle>
        <InfoRow>
          <InfoLabel>Lending Amount:</InfoLabel>
          <InfoValue>${selectedApplicant.loanTerms.lendingAmount.toLocaleString()}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Interest Rate:</InfoLabel>
          <InfoValue>{selectedApplicant.loanTerms.interestRate}%</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Loan Length:</InfoLabel>
          <InfoValue>{selectedApplicant.loanTerms.loanLength} years</InfoValue>
        </InfoRow>

        <SectionTitle level={4}>Application Details</SectionTitle>
        <InfoRow>
          <InfoLabel>Status:</InfoLabel>
          <InfoValue>{selectedApplicant.status}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Application Date:</InfoLabel>
          <InfoValue>{new Date(selectedApplicant.createdAt).toLocaleString()}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Last Updated:</InfoLabel>
          <InfoValue>{new Date(selectedApplicant.updatedAt).toLocaleString()}</InfoValue>
        </InfoRow>
      </>
    );
  };

  const totalApplicants = applicants.length;
  const pendingApplicants = applicants.filter(a => a.status === 'Pending').length;
  const approvedApplicants = applicants.filter(a => a.status === 'Approved').length;

  return (
    <Container>
      <Card>
      <HeaderContainer>
        <BackButton onClick={handleBack}>Back to Properties</BackButton>
        <ApplicantsTitle level={3}>Applicants for {property?.address}</ApplicantsTitle>
      </HeaderContainer>
        <Row gutter={16}>
          <Col span={8}>
            <Statistic title="Total Applicants" value={totalApplicants} />
          </Col>
          <Col span={8}>
            <Statistic title="Pending Applications" value={pendingApplicants} />
          </Col>
          <Col span={8}>
            <Statistic title="Approved Applications" value={approvedApplicants} />
          </Col>
        </Row>
      </Card>
      <Card>
      <FilterContainer>
        <SearchWrapper>
          <StyledInput
            placeholder="Search applicants"
            prefix={<SearchOutlined />}
            onChange={handleSearch}
          />
        </SearchWrapper>
        <StatusFilterWrapper>
          <StyledSelect
            placeholder="Filter by status"
            onChange={handleStatusFilter}
            defaultValue="All"
          >
            <Option value="All">All Status</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Approved">Approved</Option>
            <Option value="Rejected">Rejected</Option>
          </StyledSelect>
        </StatusFilterWrapper>
      </FilterContainer>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <StyledTable
            columns={APPLICANTS_COLUMNS}
            dataSource={filteredApplicants}
            pagination={{ pageSize: 10 }}
            onRow={(record) => ({
              onClick: () => showModal(record),
            })}
          />
        )}
      </Card>

      <Modal
        title="Applicant Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>
        ]}
        width={800}
      >
        {renderDetailedInfo()}
      </Modal>
    </Container>
  );
};

export default Applicants;