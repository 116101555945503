import React from 'react';
import { Typography } from 'antd';
import { StyledTypography } from './styled';

const { Title, Text } = Typography;

const BaseTypography = ({
  align,
  marginBottom,
  color,
  type,
  fontSize,
  fontWeight,
  children,
  ...props
}) => {
  switch (type) {
    case 'title':
      return (
        <StyledTypography
          $align={align}
          $marginBottom={marginBottom}
          $color={color}
          $fontWeight={fontWeight}
        >
          <Title {...props}>{children}</Title>
        </StyledTypography>
      );
    default:
      return (
        <StyledTypography
          $align={align}
          $marginBottom={marginBottom}
          $color={color}
          $fontSize={fontSize}
        >
          <Text {...props}>{children}</Text>
        </StyledTypography>
      );
  }
};

export default BaseTypography;
