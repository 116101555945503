import React, { useState } from 'react';

// Constants
import priceRange from '../../../../../assets/images/priceRange.svg';

// Utils
import { formatNumberToDollars } from '../../../../../utils/numberFormat';

// Components
import {
  Drawer,
  Space,
  Flex,
  Button,
  Slider,
  Image,
  Radio,
  Segmented,
} from 'antd';
import BaseTypography from '../../../../../components/BaseTypography';
import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { DESKTOP_CATEGORIES, MOBILE_CATEGORIES, PERIOD_RADIO } from './utils';

// Styles
import styles from './MoreFiltersDrawer.module.scss';

const INCREMENT = 'incremenet';
const DECREMENT = 'decrement';

const MoreFiltersDrawer = ({
  handleClickFilterClose,
  isFilterOpen,
  container,
  setFilters,
  setIsFiltersActive,
  currentHighRange,
  searchOption,
  VIEW_OPTIONS,
  handleChange,
}) => {
  const [range, setRange] = useState({ low: 0, high: currentHighRange });
  const [category, setCategory] = useState(null);
  const [bedroom, setBedroom] = useState(0);
  const [bathroom, setBathroom] = useState(0);
  const [period, setPeriod] = useState(PERIOD_RADIO[0].value);

  const handleClickCategory = (event) => {
    setCategory(event.target.textContent.toLowerCase());
  };

  const handleChangePrice = (value) => {
    const low = value[0];
    const high = value[1];
    setRange({ low, high });
  };

  const handleClickBedroomIncrement = () => {
    setBedroom(bedroom + 1);
  };

  const handleClickBedroomDecrement = () => {
    setBedroom(bedroom + -1);
  };
  const handleClickBathroomIncrement = () => {
    setBathroom(bathroom + 1);
  };

  const handleClickBathroomDecrement = () => {
    setBathroom(bathroom - 1);
  };

  const handleChangePeriod = (event) => {
    setPeriod(event.target.value);
  };

  const handleClickApply = () => {
    // apply changes in a callback
    setIsFiltersActive(true);
    setFilters({
      range,
      category,
      bedroom,
      bathroom,
      period,
    });
    handleClickFilterClose();
  };

  const handleClickReset = () => {
    setIsFiltersActive(false);
    setCategory(null);
    setRange({ low: 500, high: 1000 });
    setBedroom(1);
    setBathroom(1);
    setPeriod(PERIOD_RADIO[0].value);

    handleClickFilterClose();
  };

  const lowDollar = formatNumberToDollars(range.low);
  const highDollar = formatNumberToDollars(range.high);

  return (
    <Drawer
      width={475}
      closable={false}
      onClose={handleClickFilterClose}
      open={isFilterOpen}
      getContainer={container}
      rootStyle={{ position: 'fixed' }}
      destroyOnClose={true}
      footer={
        <div className={styles.footer}>
          <Flex justify="space-between">
            <Button className={styles.footerButtons} onClick={handleClickReset}>
              Reset
            </Button>
            <Button
              type="primary"
              className={styles.footerButtons}
              onClick={handleClickApply}
            >
              Apply
            </Button>
          </Flex>
        </div>
      }
    >
      <div className={styles.moreFiltersContainer}>
        {isMobile ? (
          <Flex justify="space-between" className={styles.header} align='center'>
            <CloseOutlined onClick={handleClickFilterClose} /> <BaseTypography
              fontSize="18px"
            >
              Filters
            </BaseTypography>
            <div></div>
          </Flex>
        ) : (
          <BaseTypography
            className={styles.moreFiltersTitle}
            fontSize="24px"
            align="left"
          >
            More Filters
          </BaseTypography>
        )}

        {/* {isMobile && (
          <>
            <BaseTypography
              className={styles.moreFiltersSubTitle}
              fontSize="16px"
              align="left"
            >
              View
            </BaseTypography>

            <Flex className={styles.moreFiltersCategories} gap={15}>
              <Segmented
                value={searchOption}
                options={VIEW_OPTIONS}
                onChange={handleChange}
                block
              />
            </Flex>
          </>
        )} */}

        <BaseTypography
          className={styles.moreFiltersSubTitle}
          fontSize="16px"
          align="left"
        >
          Category
        </BaseTypography>

        <Flex className={styles.moreFiltersCategories} gap={15}>
          {(isMobile ? MOBILE_CATEGORIES : DESKTOP_CATEGORIES).map((option) => (
            <Button
              name={option.value}
              key={option.value}
              className={styles.moreFiltersButton}
              onClick={handleClickCategory}
              type={option.value === category ? 'primary' : 'default'}
            >
              {option.label}
            </Button>
          ))}
        </Flex>

        <hr className={styles.break} />
        <div className={styles.price}>
          <BaseTypography
            className={styles.moreFiltersSubTitle}
            fontSize="16px"
            align="left"
          >
            Price Range
          </BaseTypography>
          <Image
            className={styles.priceRange}
            height={140}
            width="100%"
            preview={false}
            src={priceRange}
          />
        </div>

        <Slider
          range
          defaultValue={[range.low, range.high]}
          max={currentHighRange}
          onChange={handleChangePrice}
        />

        <Flex justify="space-around">
          <BaseTypography className={styles.priceValue} fontSize="18px">
            {lowDollar}
          </BaseTypography>
          <BaseTypography className={styles.priceValue} fontSize="18px">
            {highDollar}
          </BaseTypography>
        </Flex>

        <hr className={styles.break} />

        <BaseTypography
          className={styles.moreFiltersSubTitle}
          fontSize="16px"
          align="left"
        >
          Features
        </BaseTypography>

        <Flex
          className={styles.features}
          align="center"
          justify="space-between"
        >
          <BaseTypography>Bedroom</BaseTypography>
          <Flex
            className={styles.featuresInput}
            align="center"
            justify="space-between"
          >
            <Button
              onClick={handleClickBedroomDecrement}
              name={DECREMENT}
              icon={<MinusCircleOutlined />}
              shape="circle"
              type="primary"
              disabled={bedroom === 0}
            />
            <span>{bedroom}</span>
            <Button
              onClick={handleClickBedroomIncrement}
              name={INCREMENT}
              icon={<PlusCircleOutlined />}
              type="primary"
              shape="circle"
            />
          </Flex>
        </Flex>

        <Flex
          className={styles.features}
          align="center"
          justify="space-between"
        >
          <BaseTypography>Bathroom</BaseTypography>
          <Flex
            className={styles.featuresInput}
            align="center"
            justify="space-between"
          >
            <Button
              onClick={handleClickBathroomDecrement}
              name={DECREMENT}
              icon={<MinusCircleOutlined />}
              shape="circle"
              type="primary"
              disabled={bathroom === 0}
            />
            <span>{bathroom}</span>
            <Button
              onClick={handleClickBathroomIncrement}
              name={INCREMENT}
              icon={<PlusCircleOutlined />}
              shape="circle"
              type="primary"
            />
          </Flex>
        </Flex>

        <hr className={styles.break} />
        <BaseTypography
          className={styles.moreFiltersSubTitle}
          fontSize="16px"
          align="left"
        >
          Period
        </BaseTypography>

        <Radio.Group
          className={styles.period}
          onChange={handleChangePeriod}
          value={period}
        >
          <Space direction="vertical">
            {PERIOD_RADIO.map((radio) => (
              <Radio
                className={styles.periodOption}
                onChange={handleChangePeriod}
                key={radio.value}
                value={radio.value}
              >
                {radio.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </Drawer>
  );
};

export default MoreFiltersDrawer;
