import React, { useState, useEffect, useCallback, useRef } from 'react';
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks/storeContext";
import BaseTypography from "../../../../components/BaseTypography";
import { COLORS } from "../../../../constants/colors";
import { StyledContainer, StyledFlex, StyledInputGroup, StyledLabel, StyledRegister } from "./styled";
import { BaseButton } from "../../../../components/Button";
import { Input, Slider } from 'antd';
import { Map, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const Onboarding2 = observer(({ setStep }) => {
    const [preferences, setPreferences] = useState({
        zipCode: '75201',
        radius: 5, // Default radius in miles
        lat: 32.7767, // Default to Dallas
        lng: -96.7970,
    });
    const [zoom, setZoom] = useState(10);
    const { authStore } = useStore();
    const map = useMap();
    const mapRef = useRef(null);
    const mapsLibrary = useMapsLibrary('maps');
    const geocodingLibrary = useMapsLibrary('geocoding');
    const circleRef = useRef(null);

    useEffect(() => {
        console.log('Component mounted or preferences/zoom changed:', { preferences, zoom });
    }, [preferences, zoom]);

    useEffect(() => {
        console.log('Zoom level changed:', zoom);
    }, [zoom]);

    const handleChange = (name, value) => {
        console.log(`Preference changed: ${name} = ${value}`);
        setPreferences(prev => ({ ...prev, [name]: value }));
    };

    const fetchLatLngFromZipCode = useCallback(async (zipCode) => {
        console.log('Fetching lat/lng for zipCode:', zipCode);
        if (!geocodingLibrary) return null;
        const geocoder = new geocodingLibrary.Geocoder();
        try {
            const result = await geocoder.geocode({ address: zipCode });
            if (result.results[0]) {
                const { lat, lng } = result.results[0].geometry.location;
                console.log('Fetched lat/lng:', { lat: lat(), lng: lng() });
                return { lat: lat(), lng: lng() };
            }
        } catch (error) {
            console.error("Geocoding failed:", error);
        }
        return null;
    }, [geocodingLibrary]);

    const fetchZipCodeFromLatLng = useCallback(async (lat, lng) => {
        if (!geocodingLibrary) return null;
        const geocoder = new geocodingLibrary.Geocoder();
        try {
            const result = await geocoder.geocode({ location: { lat, lng } });
            if (result.results[0]) {
                const zipCode = result.results[0].address_components.find(
                    component => component.types.includes('postal_code')
                )?.long_name;
                console.log('Fetched zipCode:', zipCode);
                return zipCode;
            }
        } catch (error) {
            console.error("Reverse geocoding failed:", error);
        }
        return null;
    }, [geocodingLibrary]);

    useEffect(() => {
        if (preferences.zipCode.length === 5) {
            fetchLatLngFromZipCode(preferences.zipCode).then((result) => {
                if (result) {
                    console.log('New lat/lng fetched:', result);
                    setPreferences(prev => ({ ...prev, ...result }));
                    map?.panTo(result);
                }
            });
        }
    }, [preferences.zipCode, fetchLatLngFromZipCode, map]);

    useEffect(() => {
        console.log('Updating circle on map');
        if (map && mapsLibrary && preferences.lat && preferences.lng) {
            if (circleRef.current) {
                circleRef.current.setMap(null);
            }
            circleRef.current = new mapsLibrary.Circle({
                strokeColor: '#005EB8',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#6cb5fb',
                fillOpacity: 0.35,
                map,
                center: { lat: preferences.lat, lng: preferences.lng },
                radius: preferences.radius * 1609.34, // Convert miles to meters
            });
        }
    }, [map, mapsLibrary, preferences.lat, preferences.lng, preferences.radius]);

    const handleMapClick = useCallback(async (e) => {
        console.log('Map clicked:', e.detail);
        if (e.detail && e.detail.latLng) {
            const lat = e.detail.latLng.lat;
            const lng = e.detail.latLng.lng;
            console.log('Setting new lat/lng:', { lat, lng });
            setPreferences(prev => ({ ...prev, lat, lng }));
            const zipCode = await fetchZipCodeFromLatLng(lat, lng);
            if (zipCode) {
                setPreferences(prev => ({ ...prev, zipCode }));
            }
        } else {
            console.error("latLng is not defined in the event details.");
        }
    }, [fetchZipCodeFromLatLng]);

    const handleZoomChange = () => {
        if (mapRef.current) {
            const currentZoom = mapRef.current.getZoom();
            console.log('Zoom changed:', currentZoom);
            setZoom(currentZoom);
        }
    };

    const onSubmit = async () => {
        console.log('Submitting preferences:', preferences);
        try {
            await authStore.saveLocationPreferences(preferences);
            console.log('Location preferences saved successfully');
            setStep(3);
        } catch (error) {
            console.error('Error saving location preferences:', error);
        }
    };

    const onSkip = () => {
        console.log('Skipping location preferences');
        setStep(3);
    };

    return (
        <StyledContainer>
            <StyledFlex vertical>
                <BaseTypography align="left" type="paragraph" color={COLORS.$greyScale500} level={0}>
                    Step 2/4
                </BaseTypography>
                <BaseTypography align="left" type="title" color={COLORS.$black} level={3} style={{ marginBottom: '20px' }}>
                    Investment Location Preferences
                </BaseTypography>

                <StyledInputGroup>
                    <StyledLabel>Zip Code</StyledLabel>
                    <Input
                        placeholder="Enter zip code"
                        style={{ width: '100%', height: '48px' }}
                        onChange={(e) => handleChange('zipCode', e.target.value)}
                        maxLength={5}
                        value={preferences.zipCode}
                    />
                </StyledInputGroup>

                <StyledInputGroup>
                    <StyledLabel>Preferred Radius: {preferences.radius} miles</StyledLabel>
                    <Slider
                        min={1}
                        max={25}
                        value={preferences.radius}
                        onChange={(value) => handleChange('radius', value)}
                    />
                </StyledInputGroup>

                <Map
                    center={{ lat: preferences.lat, lng: preferences.lng }}
                    zoom={zoom}
                    onLoad={(map) => {
                        console.log('Map loaded');
                        mapRef.current = map;
                        handleZoomChange(); // Initialize zoom on load
                    }}
                    onZoomChanged={handleZoomChange}
                    style={{ width: '100%', height: '400px' }}
                    gestureHandling={'greedy'}
                    disableDefaultUI={false}
                    zoomControl={true}
                    onClick={handleMapClick}
                >
                    <Marker position={{ lat: preferences.lat, lng: preferences.lng }} />
                </Map>

                <BaseButton type="primary" onClick={onSubmit} block style={{ marginTop: '20px' }}>
                    Next
                </BaseButton>
                <StyledRegister>
                    <button onClick={onSkip} style={{ background: 'none', border: 'none', color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
                        Skip For Now
                    </button>
                </StyledRegister>
            </StyledFlex>
        </StyledContainer>
    );
});

export default Onboarding2;