import styled from 'styled-components';
import { Form, Input, Select, DatePicker, Steps, Button, Checkbox, Card } from 'antd';

export const ApplicationWrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const PropertyInfo = styled.div`
  background-color: #f0f2f5;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 2px solid lightgrey;
`;

export const PropertyTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const LoanTerms = styled.p`
  margin: 5px 0;
`;

export const StyledSteps = styled(Steps)`
  margin-bottom: 30px;
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label {
    font-weight: bold;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 100px;
  height: 48px;

`;

export const StepTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
`;

export const FormSection = styled.div`
  margin-bottom: 30px;
`;

export const FormSectionTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 15px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  min-width: 120px;
`;

export const ReferenceSection = styled.div`
  background-color: #f0f2f5;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const ReferenceTitle = styled.h4`
  font-size: 16px;
  margin-bottom: 10px;
`;

export const ReferenceDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
`;

export const LoanTermsSummary = styled.div`
  background-color: #f0f8ff; // Light blue background
  border: 1px solid #b0d4ff; // Slightly darker blue border
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    color: #2c3e50; // Dark blue-gray color for the heading
    font-size: 18px;
    margin-bottom: 15px;
  }

  p {
    color: #34495e; // Slightly lighter than the heading for contrast
    font-size: 16px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
  }

  strong {
    font-weight: 600;
  }

  .highlight {
    color: #3498db; // Bright blue for emphasis
    font-weight: 600;
  }
`;

export const StyledCard = styled(Card)`
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const StyledInput = styled(Input)`
  height: 48px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    height: 48px !important;
    display: flex;
    align-items: center;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: ${props => props.isFirstStep ? 'flex-end' : 'space-between'};
  margin-top: 20px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;

  .ant-picker {
    height: 48px;
  }
`;

export const SummarySection = styled.div`
  margin-bottom: 20px;
`;

export const SummaryTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const SummaryItem = styled.p`
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
`;

export const SummaryLabel = styled.span`
  font-weight: bold;
`;

export const SummaryValue = styled.span`
  text-align: right;
`;