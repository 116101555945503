export const DEFAULT_FILTERS = {
  range: {
    low: null,
    high: null,
  },
  category: null,
  bedroom: null,
  bathroom: null,
  period: null,
};

export const propertiesOBJ = [
  {
    current_price: 250000,
    bathrooms: 2,
    bedrooms: 3,
    address: '123 Main St',
    city: 'Anytown',
    state: 'CA',
    subdivision: 'Oak Meadows',
    type: 'Single Family Home',
  },
  {
    current_price: 300000,
    bathrooms: 2.5,
    bedrooms: 4,
    address: '456 Elm St',
    city: 'Othertown',
    state: 'NY',
    subdivision: 'Maple Grove',
    type: 'Townhouse',
  },
  {
    current_price: 180000,
    bathrooms: 1.5,
    bedrooms: 2,
    address: '789 Pine St',
    city: 'Smalltown',
    state: 'TX',
    subdivision: 'Pinecrest',
    type: 'Condo',
  },
];

export const ACTION_OPTIONS = [
  { value: 'email', label: 'Email' },
  { value: 'print', label: 'Print' },
  { value: 'shareLink', label: 'Share Link' },
];
