import React from 'react';
import { isMobile } from 'react-device-detect';

// Components
import { Flex } from 'antd';
import BaseTypography from '../../../components/BaseTypography';
import {
  BedroomSvg,
  BathroomSvg,
  SqFtSvg,
  RepairSvg,
  StatusSvg,
} from '../../../components/Icons';
import Icon from '@ant-design/icons/lib/components/Icon';

// Styles
import styles from './Specifications.module.scss';

const Specifications = ({ price, propertyType, neighborhood }) => {
  return (
    <div className={styles.specifications}>
      <Flex justify="space-between" className={styles.outer}>
        {isMobile ? (
          <>
            <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                Bed
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                <Flex gap={8} align="center">
                  <Icon component={BedroomSvg} /> 4
                </Flex>
              </BaseTypography>
            </div>
            <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                Bath
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                <Flex gap={8} align="center">
                  <Icon component={BathroomSvg} /> 2
                </Flex>
              </BaseTypography>
            </div>
            <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                Sqft
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                <Flex gap={8} align="center">
                  <Icon component={SqFtSvg} /> 6x7.5 m²
                </Flex>
              </BaseTypography>
            </div>

            {/* <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                Repair Quality
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                <Flex gap={8} align="center">
                  <Icon component={RepairSvg} />
                  Modern Loft
                </Flex>
              </BaseTypography>
            </div> */}
          </>
        ) : (
          <>
            <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                MLS#
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                20464040
              </BaseTypography>
            </div>
            <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                Prop Type
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                {propertyType}
              </BaseTypography>
            </div>
            <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                Sub Type
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                {neighborhood ?? 'N/A'}
              </BaseTypography>
            </div>

            <div>
              <BaseTypography
                className={styles.specTitle}
                fontSize="16px"
                align="left"
              >
                List Type
              </BaseTypography>
              <BaseTypography
                className={styles.specValue}
                fontSize="18px"
                align="left"
              >
                {price}
              </BaseTypography>
            </div>
          </>
        )}
        <div>
          <BaseTypography
            className={styles.specTitle}
            fontSize="16px"
            align="left"
          >
            Status
          </BaseTypography>
          <BaseTypography
            className={styles.specValue}
            fontSize="18px"
            align="left"
          >
            <Flex gap={8} align="center">
              <Icon component={StatusSvg} /> Active
            </Flex>
          </BaseTypography>
        </div>
      </Flex>
    </div>
  );
};

export default Specifications;
