import styled from 'styled-components';
import { Typography } from 'antd';

const { Title, Paragraph, Link } = Typography;

export const FooterContainer = styled.footer`
  background: #000;
  color: white;
  padding: 64px 50px;
`;

export const Logo = styled.img`
  height: 32px;
  margin-bottom: 16px;
`;

export const SocialLink = styled.a`
  color: white;
  font-size: 20px;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

export const StyledTitle = styled(Title)`
  &&&& {
    color: white;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  color: white!important;
  margin-top: 5px;

  .ant-typography {
    color: white!important;
  }
`;

export const StyledLink = styled(Link)`
  color: white;
`;