import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

// Utils
import { createAddress } from '../utils/createAddress';
import { propertyFormat } from '../utils/propertyFormat';
import { getStreetViewImage } from '../utils/getStreetView';

// Constants
import { api } from '../constants/api';

const useGetPropertySearch = (initialParams) => {
  const [properties, setProperties] = useState([]);
  const [propertiesWithImages, setPropertiesWithImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [params, setParams] = useState(initialParams);

  const fetchProperties = useCallback(
    debounce(async (searchParams) => {
      try {
        setIsLoading(true);

        const {
          mlsNumber,
          unitNumber,
          streetNumber,
          streetName,
          city,
          state,
          zipcode,
          streetType,
          latitude,
          longitude,
          radius,
          propertyTypes,
          view,
          mls_listing_price_max,
          property_type,
          size,
          price_min,
          price_max,
          bedrooms_min,
          bedrooms_max,
          bathrooms_min,
          bathrooms_max,
          days_on_market,
          listing_status,
          ...otherParams
        } = searchParams;

        const address = createAddress({
          streetNumber,
          streetName,
          city,
          state,
          zipcode,
        });

        if (Object.keys(address).length === 0 && !latitude && !longitude) {
          console.log('No address or lat/lng provided, skipping API call');
          setProperties([]);
          return;
        }

        const apiParams = {
          ...address,
          latitude,
          longitude,
          radius,
          propertyTypes,
          property_type,
          mls_listing_price_max,
          mlsNumber,
          unitNumber,
          streetType,
          view,
          size,
          price_min,
          price_max,
          bedrooms_min,
          bedrooms_max,
          bathrooms_min,
          bathrooms_max,
          days_on_market,
          listing_status,
          ...otherParams
        };

        const response = await axios.post(api.listing.propertySearch, apiParams);

        if (!response.data.length) {
          console.log('No properties returned from API');
          setProperties([]);
          return;
        }

        const data = propertyFormat(response.data);
        setProperties(data);
      } catch (error) {
        console.error('API call failed:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  // Fetch property data
  useEffect(() => {
    fetchProperties(params);
  }, [params, fetchProperties]);

  // Fetch Street View images
  useEffect(() => {
    const fetchStreetViewImages = async () => {
      const propertiesWithImages = await Promise.all(
        properties.map(async (property) => {
          const fullAddress = `${property.address}, ${property.city}, ${property.state}`;
          const streetViewImage = await getStreetViewImage({
            address: fullAddress,
          });
          return {
            ...property,
            streetViewImage,
          };
        })
      );
      setPropertiesWithImages(propertiesWithImages);
    };

    if (properties.length > 0) {
      fetchStreetViewImages();
    } else {
      console.log('No properties available, skipping Street View image fetch');
    }
  }, [properties]);

  const updateSearchParams = useCallback((newParams) => {
    setParams(prevParams => ({ ...prevParams, ...newParams }));
  }, []);

  return { properties: propertiesWithImages, isLoading, hasError, updateSearchParams };
};

export default useGetPropertySearch;