import React, { useState, useMemo } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

// Styles
import styles from './Search.module.scss';

// Components
import { Col, Row, Input } from 'antd';
import BaseTypography from '../../../components/BaseTypography';
import { BaseButton } from '../../../components/Button';
import SelectComponent from '../../../components/SelectComponent';
import { ROUTE_PATH } from '../../../constants/routePath';

const DEFAULT_PROPERTY_DATA = {
  mlsNumber: '',
  unitNumber: '',
  streetNumber: '',
  streetName: '',
  state: 'Tx',
  city: '',
  zipcode: '',
  propertyType: 'SFR', // Set default to 'SFR'
};

export const PROPERTY_TYPE_OPTIONS = [
  { value: 'SFR', label: 'Single Family Residence' },
  { value: 'MFR', label: 'Multi-Family Residence' },
  { value: 'LAND', label: 'Land' },
  { value: 'CONDO', label: 'Condo' },
  { value: 'MOBILE', label: 'Mobile' },
  { value: 'OTHER', label: 'Other' },
];

const Search = () => {
  const [propertyData, setPropertyData] = useState(DEFAULT_PROPERTY_DATA);
  const navigate = useNavigate();

  const isSearchDisabled = useMemo(() => {
    return isEqual(propertyData, DEFAULT_PROPERTY_DATA);
  }, [propertyData]);

  const handleClick = (viewOption) => {
    const { propertyType, ...otherData } = propertyData;
    const searchParams = createSearchParams({
      ...otherData,
      property_type: propertyType, // Rename propertyType to property_type
      size: 40,
      view: viewOption,
    }).toString();
  
    const path = {
      pathname: ROUTE_PATH.SEARCH,
      search: searchParams,
    };
    
    navigate(path);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setPropertyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeSelect = (name, value) => {
    setPropertyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Row gutter={{ xs: 14, lg: 24 }} className={styles.formInput}>
        <Col xs={24} lg={12}>
          <BaseTypography className={styles.label} align="left">
            Street Number
          </BaseTypography>
          <Input
            onChange={handleChange}
            onInput={(e) => e.target.value = e.target.value.replace(/\D/, '')}
            name="streetNumber"
            className={styles.input}
            pattern="\d*"
          />
        </Col>
        <Col xs={24} lg={12}>
          <BaseTypography className={styles.label} align="left">
            Street Name
          </BaseTypography>
          <Input
            onChange={handleChange}
            name="streetName"
            className={styles.input}
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 14, lg: 24 }} className={styles.formInput}>
        <Col xs={24} lg={12}>
          <BaseTypography className={styles.label} align="left">
            State
          </BaseTypography>
          <SelectComponent
            onChange={(value) => handleChangeSelect('state', value)}
            name="state"
            defaultValue="Tx"
            options={[{ label: 'Texas', value: 'Tx' }]}
          />
        </Col>
        <Col xs={24} lg={12}>
          <BaseTypography className={styles.label} align="left">
            City
          </BaseTypography>
          <Input
            onChange={handleChange}
            name="city"
            className={styles.input}
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 14, lg: 24 }} className={styles.formInput}>
        <Col xs={24} lg={12}>
          <BaseTypography className={styles.label} align="left">
            Zipcode
          </BaseTypography>
          <Input
            onChange={handleChange}
            onInput={(e) => e.target.value = e.target.value.replace(/\D/, '')}
            name="zipcode"
            className={styles.input}
            pattern="\d*"
          />
        </Col>
        <Col xs={24} lg={12}>
          <BaseTypography className={styles.label} align="left">
            Property Type
          </BaseTypography>
          <SelectComponent
            onChange={(value) => handleChangeSelect('propertyType', value)}
            name="propertyType"
            defaultValue="SFR" 
            options={PROPERTY_TYPE_OPTIONS}
          />
        </Col>
      </Row>
      <Row className={styles.formFooter}>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 4, offset: 20 }}
        >
          <BaseButton
            disabled={isSearchDisabled}
            onClick={() => handleClick('Map')}
          >
            Search
          </BaseButton>
        </Col>
      </Row>
    </>
  );
};

export default Search;