import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const StyledLabel = styled.label`
  font-size: 14px;
  color: ${COLORS.$black};
  line-height: 140%;
  margin: 0 0 8px;
  display: block;
`;
