import { $colorPrimary, $colorSecondary, $fontFamily } from './styles/global';

export const getTheme = () => ({
  token: {
    colorPrimary: $colorPrimary,
    fontFamily: $fontFamily,
    fontSize: 16,
    lineHeight: 1.5,
    fontSizeHeading2: 40,
    fontSizeHeading3: 20,
    fontSizeHeading4: 24,
    fontWeightStrong: 700,
    blueShades98: '#DCE4F7', 
    blueShades92: '#AFC2F2', 
    mainDark50: '#000929',   
    gray: '#A0A0A0',         
  },
  components: {
    Button: {
      colorTextSecondary: $colorPrimary,
      colorFillSecondary: $colorSecondary,
      colorBorderSecondary: $colorSecondary,
    },
  },
});

export const theme = {
  colors: {
    blueShades98: '#DCE4F7', 
    blueShades92: '#AFC2F2', 
    mainDark50: '#000929',   
    colorPrimary: '#005EB8',
    gray: '#A0A0A0',        
  },
};
