// src/components/emailSection.js
import React from 'react';
import { Row, Col } from 'antd';
import {
  SectionContainer,
  NoSpamText,
  Heading,
  InputContainer,
  StyledInput,
  StyledButton,
  SubText,
  Highlight
} from './styled';

const EmailSection = () => (
  <SectionContainer>
    <Row justify="center" align="middle">
      <Col xs={24} md={18} lg={18}>
        <NoSpamText>No Spam Promise</NoSpamText>
        <Heading>Looking for a new home?</Heading>
        <InputContainer>
          <StyledInput placeholder="Enter your email address" />
          <StyledButton type="primary">Submit</StyledButton>
        </InputContainer>
        <SubText>
          Join <Highlight>10,000+</Highlight> other home owners in our Invested community.
        </SubText>
      </Col>
    </Row>
  </SectionContainer>
);

export default EmailSection;
