import React, { useState } from 'react';
import { Slider, Space, Checkbox, Select, Radio, Button } from 'antd';
import {
  PopupContainer,
  PopupHeader,
  SliderContainer,
  InputsContainer,
  StyledInput,
  DoneButton,
  PopupDoneButton
} from './styled';

export const PricePopup = ({ visible, onClose, onApply, initialValues }) => {
  const [priceRange, setPriceRange] = useState(initialValues || [0, 1800000]);

  const handleSliderChange = (value) => {
    setPriceRange(value);
  };

  const handleApply = () => {
    onApply(priceRange);
    onClose(); // Make sure this is called when applying
  };

  const formatPrice = value => `$${value.toLocaleString()}`;

  return (
    <PopupContainer>
      <PopupHeader>
        <h3>List Price</h3>
        <PopupDoneButton onClick={handleApply}>Done</PopupDoneButton>
      </PopupHeader>

      <SliderContainer>
        <Slider
          range
          min={0}
          max={1800000}
          step={1000}
          value={priceRange}
          onChange={handleSliderChange}
          tooltip={{
            formatter: formatPrice
          }}
        />
      </SliderContainer>

      <InputsContainer>
        <StyledInput 
          prefix="$" 
          value={priceRange[0].toLocaleString()}
          placeholder="No min"
          onChange={e => {
            const value = parseInt(e.target.value.replace(/\D/g, ''));
            setPriceRange([value || 0, priceRange[1]]);
          }}
        />
        <span>-</span>
        <StyledInput 
          prefix="$"
          value={priceRange[1].toLocaleString()}
          placeholder="1,800,000"
          onChange={e => {
            const value = parseInt(e.target.value.replace(/\D/g, ''));
            setPriceRange([priceRange[0], value || 1800000]);
          }}
        />
      </InputsContainer>
      <DoneButton 
        type="primary" 
        onClick={handleApply}
      >
        Done
      </DoneButton>
    </PopupContainer>
  );
};

export const PropertyTypePopup = ({ visible, onClose, onApply, initialValue, PROPERTY_TYPE_OPTIONS }) => {
  // Initialize with single selection
  const [selectedType, setSelectedType] = useState(initialValue || 'any');

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleApply = () => {
    onApply(selectedType);
    onClose();
  };

  return (
    <PopupContainer>
      <PopupHeader>
        <h3>Property Type</h3>
        <PopupDoneButton onClick={handleApply}>Done</PopupDoneButton>
      </PopupHeader>

      <Space direction="vertical" style={{ width: '100%' }}>
        <Radio.Group 
          value={selectedType} 
          onChange={(e) => handleTypeChange(e.target.value)}
          style={{ width: '100%' }}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Radio value="any">Any</Radio>
            {PROPERTY_TYPE_OPTIONS.map(option => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Space>
    </PopupContainer>
  );
};

// Create new popup components for Rooms and Listing Status
export const RoomsPopup = ({ visible, onClose, onApply, initialValues }) => {
  const [bedrooms, setBedrooms] = useState(initialValues?.bedrooms || { min: null, max: null });
  const [bathrooms, setBathrooms] = useState(initialValues?.bathrooms || { min: null, max: null });

  const handleApplyClick = () => {
    onApply({ 
      bedrooms: {
        min: bedrooms.min === 'No min' ? null : bedrooms.min,
        max: bedrooms.max === 'No max' ? null : bedrooms.max
      },
      bathrooms: {
        min: bathrooms.min === 'No min' ? null : bathrooms.min,
        max: bathrooms.max === 'No max' ? null : bathrooms.max
      }
    });
    onClose();
  };

  const options = ['No min', '1', '2', '3', '4', '5+'];

  return (
    <PopupContainer>
      <PopupHeader>
        <h3>Rooms</h3>
        <PopupDoneButton onClick={handleApplyClick}>Done</PopupDoneButton>
      </PopupHeader>

      <div style={{ marginBottom: '24px' }}>
        <div style={{ marginBottom: '8px' }}>Bedrooms</div>
        <Space>
          <Select
            style={{ width: 140 }}
            value={bedrooms.min || 'No min'}
            onChange={(value) => setBedrooms({ ...bedrooms, min: value })}
            options={options.map(opt => ({ value: opt, label: opt }))}
          />
          <span>-</span>
          <Select
            style={{ width: 140 }}
            value={bedrooms.max || 'No max'}
            onChange={(value) => setBedrooms({ ...bedrooms, max: value })}
            options={options.map(opt => ({ value: opt, label: opt }))}
          />
        </Space>
      </div>

      <div>
        <div style={{ marginBottom: '8px' }}>Bathrooms</div>
        <Space>
          <Select
            style={{ width: 140 }}
            value={bathrooms.min || 'No min'}
            onChange={(value) => setBathrooms({ ...bathrooms, min: value })}
            options={options.map(opt => ({ value: opt, label: opt }))}
          />
          <span>-</span>
          <Select
            style={{ width: 140 }}
            value={bathrooms.max || 'No max'}
            onChange={(value) => setBathrooms({ ...bathrooms, max: value })}
            options={options.map(opt => ({ value: opt, label: opt }))}
          />
        </Space>
      </div>
    </PopupContainer>
  );
};

export const ListingStatusPopup = ({ visible, onClose, onApply, initialValues }) => {
  const [selectedStatuses, setSelectedStatuses] = useState(initialValues?.statuses || ['Any - for sale']);
  const [filters, setFilters] = useState(initialValues?.filters || {
    hidePending: false,
    hideForeclosures: false,
    hide55Plus: false,
    openHouse: false,
    virtualTours: false,
    threeDTours: false
  });
  const [daysOnMarket, setDaysOnMarket] = useState(initialValues?.daysOnMarket || 'Any');

  const handleApply = () => {
    onApply({ statuses: selectedStatuses, filters, daysOnMarket });
    onClose();
  };

  const statusOptions = [
    'Any - for sale',
    'Existing homes',
    'New construction',
    'Foreclosures',
    'Recently sold',
    '55+ community'
  ];

  const daysOptions = ['Any', 'Today', '7', '14', '21', '30'];

  return (
    <PopupContainer>
      <PopupHeader>
        <h3>Listing Status</h3>
        <PopupDoneButton onClick={onClose}>Done</PopupDoneButton>
      </PopupHeader>

      <Space direction="vertical" style={{ width: '100%', marginBottom: '24px' }}>
        {statusOptions.map(status => (
          <Radio
            key={status}
            checked={selectedStatuses.includes(status)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedStatuses([status]);
              }
            }}
          >
            {status}
          </Radio>
        ))}
      </Space>

      <Space direction="vertical" style={{ width: '100%', marginBottom: '24px' }}>
        <Checkbox
          checked={filters.hidePending}
          onChange={(e) => setFilters({ ...filters, hidePending: e.target.checked })}
        >
          Hide pending / contingent
        </Checkbox>
        <Checkbox
          checked={filters.hideForeclosures}
          onChange={(e) => setFilters({ ...filters, hideForeclosures: e.target.checked })}
        >
          Hide foreclosures
        </Checkbox>
        <Checkbox
          checked={filters.hide55Plus}
          onChange={(e) => setFilters({ ...filters, hide55Plus: e.target.checked })}
        >
          Hide 55+ community
        </Checkbox>
        <Checkbox
          checked={filters.openHouse}
          onChange={(e) => setFilters({ ...filters, openHouse: e.target.checked })}
        >
          Open house
        </Checkbox>
        <Checkbox
          checked={filters.virtualTours}
          onChange={(e) => setFilters({ ...filters, virtualTours: e.target.checked })}
        >
          Virtual tours
        </Checkbox>
        <Checkbox
          checked={filters.threeDTours}
          onChange={(e) => setFilters({ ...filters, threeDTours: e.target.checked })}
        >
          3D tours
        </Checkbox>
      </Space>

      <div>
        <div style={{ marginBottom: '8px' }}>Days on Market</div>
        <Space wrap>
          {daysOptions.map(days => (
            <Button
              key={days}
              type={daysOnMarket === days ? 'primary' : 'default'}
              onClick={() => setDaysOnMarket(days)}
              style={{
                height: '36px',
                minWidth: '48px',
                backgroundColor: daysOnMarket === days ? '#000' : '#fff',
                borderColor: daysOnMarket === days ? '#000' : '#e0e0e0'
              }}
            >
              {days}
            </Button>
          ))}
        </Space>
      </div>
    </PopupContainer>
  );
};
