import React from 'react';
import { Row, Col, Typography, Avatar } from 'antd';
import { TestimonialsContainer, StyledTitle, StyledCard, TestimonialName } from './styled';
import lender1 from '../../../../assets/images/lender-1.jpg'
import lender2 from '../../../../assets/images/lender-2.jpg'
import lender3 from '../../../../assets/images/lender-3.jpg'

const { Paragraph } = Typography;

const TestimonialsSection = () => {
  return (
    <TestimonialsContainer>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <StyledTitle level={2}>Hear From Our <span>Investors</span></StyledTitle>
        </Col>
        <Col xs={24} md={8}>
          <StyledCard>
            <Avatar src={lender1} size={64} />
            <TestimonialName>Adam Harper</TestimonialName>
            <Paragraph>
              Efficiency is key in our industry, and this platform delivers just that. The quick access to market data and investment tracking features have made it an essential tool in my daily analysis and decision-making process.
            </Paragraph>
          </StyledCard>
        </Col>
        <Col xs={24} md={8}>
          <StyledCard>
            <Avatar src={lender2} size={64} />
            <TestimonialName>Samantha Lee</TestimonialName>
            <Paragraph>
              This platform has streamlined the way I invest in real estate notes. The intuitive design and detailed investment summaries help me to pick the right notes faster and with more confidence.
            </Paragraph>
          </StyledCard>
        </Col>
        <Col xs={24} md={8}>
          <StyledCard>
            <Avatar src={lender3} size={64} />
            <TestimonialName>Michael Andrews</TestimonialName>
            <Paragraph>
              I manage a diverse portfolio of real estate investments, and this tool has been invaluable. It offers detailed market insights and trend analysis that are crucial for our strategy development.
            </Paragraph>
          </StyledCard>
        </Col>
      </Row>
    </TestimonialsContainer>
  );
};

export default TestimonialsSection;