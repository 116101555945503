import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import PaymentSection from './PaymentSection';
import LoanAmortizationSection from './LoanAmortizationSection';
import InputSection from './InputSection';
import {
  CalculatorWrapper,
  Title,
  TabContainer,
  Tab,
  EligibilitySection,
  EligibilityItem,
  EligibilityButton,
  Divider
} from './styled';
import FAQSection from './FAQSection';
import CommonTerms from './CommonTerms';

const MortgageCalculator = () => {
  const [homeLocation, setHomeLocation] = useState('Dallas, Tx');
  const [homePrice, setHomePrice] = useState(375000);
  const [downPayment, setDownPayment] = useState(75000);
  const [downPaymentPercentage, setDownPaymentPercentage] = useState(20);
  const [loanType, setLoanType] = useState('30-year fixed');
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(6.29);
  const [propertyTax, setPropertyTax] = useState(397);
  const [homeInsurance, setHomeInsurance] = useState(141);
  const [hoaFees, setHoaFees] = useState(0);
  const [mortgageInsurance, setMortgageInsurance] = useState(0);
  const [applyVeteransBenefits, setApplyVeteransBenefits] = useState(false);
  const [activeTab, setActiveTab] = useState('payment');
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [principalAndInterest, setPrincipalAndInterest] = useState(0);

  const infoContent = {
    homeLocation: "We use your location to estimate interest rate, tax, and home insurance based on averages for the area.",
    principalAndInterest: "The amount that goes towards paying off your loan and the interest charged by the lender.",
    homePrice: "The total cost of the home you're planning to purchase.",
    closingCost: "Additional expenses due at closing when you buy, such as attorney fees, title search, title insurance, transaction taxes, lender costs, etc. Closing costs typically range from 2% to 5% of the home's purchase price.",
    downPayment: "The initial upfront portion of the total amount due, typically expressed as a percentage of the total.",
    loanType: "Lenders offer different loan programs. Common types include 30-year fixed, 15-year fixed, and 5-year adjustable-rate mortgages (ARM). Your monthly mortgage payment will vary depending on the loan program you choose.",
    interestRate: "The percentage applied to the loan principal to determine your monthly loan payment amount, and what is charged as interest to the borrower.",
    propertyTax: "Property tax amount is only an estimate. The estimates here have been based on one or more of the following: the property tax estimate provided by the local MLS; past property taxes assessed; and average state or county tax rate. The amount displayed may not take into account any increases or decreases that may occur after your purchase date. Actual property tax amount will differ. Contact your real estate agent or other advisor directly for more information.",
    homeInsurance: "An estimate of the annual cost to insure your home against damage and accidents.",
    hoaFees: "Monthly fees charged by Homeowners Associations for maintenance of common areas and amenities.",
    mortgageInsurance: "Insurance that protects the lender if you stop making payments on your loan. Usually required if your down payment is less than 20% of the home's purchase price.",
    veteransBenefits: "Special mortgage programs available to eligible veterans, active-duty service members, and certain military spouses."
  };

  useEffect(() => {
    calculateMortgage();
  }, [homePrice, downPayment, interestRate, propertyTax, homeInsurance, hoaFees, mortgageInsurance, loanType, loanTerm]);

  const calculateMortgage = () => {
    const loanAmount = homePrice - downPayment;
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;

    const monthlyPrincipalAndInterest = 
      (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

    const totalMonthlyPayment = 
      monthlyPrincipalAndInterest +
      propertyTax +
      homeInsurance +
      hoaFees +
      mortgageInsurance;

    setPrincipalAndInterest(Math.round(monthlyPrincipalAndInterest));
    setMonthlyPayment(Math.round(totalMonthlyPayment));
  };

  const handleHomePriceChange = (value) => {
    const roundedValue = Math.round(value);
    setHomePrice(roundedValue);
    setDownPayment(Math.round(roundedValue * (downPaymentPercentage / 100)));
  };

  const handleDownPaymentChange = (value) => {
    const roundedValue = Math.round(value);
    setDownPayment(roundedValue);
    setDownPaymentPercentage(Math.round((roundedValue / homePrice) * 100));
  };

  const handleDownPaymentPercentageChange = (value) => {
    const roundedValue = Math.round(value);
    setDownPaymentPercentage(roundedValue);
    setDownPayment(Math.round(homePrice * (roundedValue / 100)));
  };

  const handleLoanTypeChange = (value) => {
    setLoanType(value);
    setLoanTerm(value.includes('30') ? 30 : 15);
  };

  return (
    <CalculatorWrapper>
      <Title>Mortgage calculator</Title>
      <TabContainer>
        <Tab active={activeTab === 'payment'} onClick={() => setActiveTab('payment')}>Payment</Tab>
        <Tab active={activeTab === 'amortization'} onClick={() => setActiveTab('amortization')}>Loan amortization</Tab>
      </TabContainer>

      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          {activeTab === 'payment' ? (
            <PaymentSection
              monthlyPayment={monthlyPayment}
              loanType={loanType}
              interestRate={interestRate}
              principalAndInterest={principalAndInterest}
              propertyTax={propertyTax}
              homeInsurance={homeInsurance}
              hoaFees={hoaFees}
              mortgageInsurance={mortgageInsurance}
              homePrice={homePrice}
              downPaymentPercentage={downPaymentPercentage}
              applyVeteransBenefits={applyVeteransBenefits}
              setApplyVeteransBenefits={setApplyVeteransBenefits}
              infoContent={infoContent}
            />
          ) : (
            <LoanAmortizationSection 
              loanAmount={homePrice - downPayment}
              interestRate={interestRate}
              loanTerm={loanTerm}
            />
          )}
        </Col>

        <Col xs={24} md={12}>
          <InputSection
            homeLocation={homeLocation}
            setHomeLocation={setHomeLocation}
            homePrice={homePrice}
            handleHomePriceChange={handleHomePriceChange}
            downPayment={downPayment}
            handleDownPaymentChange={handleDownPaymentChange}
            downPaymentPercentage={downPaymentPercentage}
            handleDownPaymentPercentageChange={handleDownPaymentPercentageChange}
            loanType={loanType}
            handleLoanTypeChange={handleLoanTypeChange}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            propertyTax={propertyTax}
            setPropertyTax={setPropertyTax}
            homeInsurance={homeInsurance}
            setHomeInsurance={setHomeInsurance}
            hoaFees={hoaFees}
            setHoaFees={setHoaFees}
            mortgageInsurance={mortgageInsurance}
            setMortgageInsurance={setMortgageInsurance}
            infoContent={infoContent}
          />
        </Col>
      </Row>

      <FAQSection />
      <Divider thickness="1px" color="#cccccc" spacing="60px 0" />
      <CommonTerms />
    </CalculatorWrapper>
    
  );
};

export default MortgageCalculator;