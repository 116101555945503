// src/components/PublicLayout.js
import React from 'react';
import {
  StyledRow,
  StyledHeader,
  StyledCol,
  ContentWrapper,
} from './styled';
import { Image } from 'antd';
import { Outlet } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { observer } from 'mobx-react';

const OnboardingLayout = observer(() => {
  return (
    <StyledRow>
      <StyledCol span={24}>
        <StyledHeader>
          <Image preview={false} src={logo} />
          Invested
        </StyledHeader>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </StyledCol>
    </StyledRow>
  );
});

export default OnboardingLayout;
