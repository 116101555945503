import styled from 'styled-components';
import { Typography, Button } from 'antd';

export const HeroContainer = styled.div`
  padding: 100px 24px;
  background-color: #f5f5f5;
  text-align: left;
    max-width: 1024px;
    margin: auto;

    @media (max-width: 700px) {
      padding: 50px 24px;
    }
  
`;

export const StyledTitle = styled(Typography.Title)`
  &&&& {
    font-size: 58px;
    font-weight: bold;
    margin-bottom: 24px;
    
    span {
      color: #0066cc;
    }

    @media (max-width: 700px) {
      font-size: 42px;
    }
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  font-size: 18px;
  max-width: 100%;

  @media (max-width: 700px) {
      font-size: 16px;
    }
`;

export const StyledButton = styled(Button)`
  height: 55px;
  align-content: center;
  font-size: 18px;
  padding: 0 32px;
`;