import { createGlobalStyle } from 'styled-components';
import { COLORS } from './constants/colors';

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;

        box-sizing: border-box;
        font-family: "Plus Jakarta Sans", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-backface-visibility: hidden;
    }

    html,
    body,
    #root {
        position: relative;
        overflow: visible;
        min-width: 320px;
        height: 100%;
    }

    html,
    body {
        overflow-x: clip;
    }


    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
    }

    a {
        text-decoration: none;
        color: ${COLORS.$mainBlue};

        &:hover {
            color: inherit;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        &.ant-typography {
            margin-bottom: 0;
        }
    }

    a {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }

    .ant-btn-secondary {
        background: #E6F0F9;
        color: ${COLORS.$mainBlue};
        border: 1.5px solid #DEE8F7;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
    }
`;
