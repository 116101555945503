import styled from 'styled-components';
import { Form } from 'antd';

export const CalculatorContainer = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const CalculatorTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    text-align: left;
  }

  .ant-input {
    padding: 15px;
    border-radius: 5px;
  }

  .ant-input-affix-wrapper{
    padding: 15px;
  }
`;

export const ResultContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
`;

export const ResultItem = styled.div`
  margin-bottom: 10px;
  font-size: 16px;

  strong {
    font-weight: 600;
  }
`;

