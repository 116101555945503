import React from 'react'
import { isMobile } from 'react-device-detect'

import { Button, Flex } from 'antd'
import { HeartOutlined, SearchOutlined, ShareAltOutlined } from '@ant-design/icons'

import styles from './ButtonsGroup.module.scss'

const ButtonsGroup = () => {
  return (
    <Flex justify={isMobile ? 'space-between' : 'flex-end'} gap={20}>
    <Button
      type="secondary"
      icon={<ShareAltOutlined />}
      size="large"
      className={styles.btn}
    >
      Share
    </Button>
    <Button
      type="secondary"
      icon={<HeartOutlined />}
      size="large"
      className={styles.btn}
    >
      Favorite
    </Button>
    {/* {!isMobile && (
      <Button type="secondary" icon={<SearchOutlined />} size="large">
        Browse nearby listings
      </Button>
    )} */}
  </Flex>
  )
}

export default ButtonsGroup