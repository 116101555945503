import styled from 'styled-components';

export const AmortizationWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
`;

export const AmortizationTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 20px;
`;