import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// Styles
import styles from './ListingItem.module.scss';

// Constants
import popularBanner from '../../assets/images/popularBanner.svg';
import listingExample from '../../assets/images/listing-example.png';
import {
  BedroomSvg,
  SqFtSvg,
  BathroomSvg,
  FavoritedActiveSvg,
} from '../../components/Icons';

// Components
import { Flex, Image } from 'antd';
import BaseTypography from '../BaseTypography';
import Icon from '@ant-design/icons';
import { formatNumberToDollars } from '../../utils/numberFormat';

const ListingItem = ({ property, type = 'small', isPopular = false, onClick }) => {
  const isLarge = type === 'large';
  const isSmall = type === 'small';

  return (
    <div
      className={classnames(styles.listingItem, {
        [styles.small]: isSmall,
        [styles.large]: isLarge,
      })}
      onClick={onClick}
      style={{ cursor: 'pointer' }} 
    >
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          height="100%"
          width="100%"
          style={{borderRadius: "8px 8px 0 0"}}
          preview={false}
          src={property.streetViewImage || listingExample} // Use streetViewImage if available, otherwise use listingExample
        />
      </div>

      {/* <div className={styles.popularBanner}>
        {isPopular && <Image preview={false} src={popularBanner} />}
      </div> */}

      <Flex gap={5} vertical className={styles.listingContent}>
        <div className={styles.listingPrice}>
          <div>
            <Link className={styles.price}>
              {formatNumberToDollars(property.current_price)}
            </Link>
          </div>
          <Icon component={FavoritedActiveSvg} />
        </div>
        <BaseTypography align="left" className={styles.county} fontSize="16px">
          {property.neighborhood || property.type}
        </BaseTypography>
        <BaseTypography
          align="left"
          className={styles.cityState}
          fontSize="14px"
        >
          {property.city} , {property.state}
        </BaseTypography>
      </Flex>

      <hr className={classnames(styles.break, { [styles.large]: isLarge })} />

      <Flex
        className={classnames(styles.listingPropertiesContainer, {
          [styles.large]: isLarge,
        })}
        gap={15}
      >
        <div className={styles.listingProperties}>
          <Icon component={BedroomSvg} />
          <span className={styles.value}>
            {property.bedrooms ?? 0} {isLarge && 'Bedrooms'}
          </span>
        </div>
        <div className={styles.listingProperties}>
          <Icon component={BathroomSvg} />
          <span className={styles.value}>
            {property.bathrooms ?? 0} {isLarge && 'Bathrooms'}
          </span>
        </div>
      </Flex>
    </div>
  );
};

export default ListingItem;
