import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, ConfigProvider, Table, Modal, Select, Card, Statistic, List } from 'antd';
import { UserOutlined, HomeOutlined, ClockCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import BaseTypography from '../../../../components/BaseTypography';
import { ROUTE_PATH } from '../../../../constants/routePath';
import listingExample from '../../../../assets/images/listing-example.png';
import { api } from '../../../../constants/api';

const { Option } = Select;

// Custom hook for mobile detection
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

// Styled Components
const Container = styled.div`
  padding: 16px;
  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const StyledCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  .ant-card-body {
    padding: 12px;
  }
`;

const CardHeader = styled(Row)`
  margin-bottom: 16px;
`;

const CardTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const EmptyTable = styled.div`
  text-align: center;
  padding: 24px;
`;

const EmptyTableHeader = styled.h3`
  margin-bottom: 16px;
`;

const CustomImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  height: 48px;
  font-size: 16px;
`;

const MyProperties = () => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterCity, setFilterCity] = useState('All');

  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchLendingTerms = async () => {
      try {
        const response = await fetch(api.lending.getLendedHomesWithOffers);
        const data = await response.json();
        setProperties(data);
      } catch (error) {
        console.error('Error fetching lending terms:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLendingTerms();
  }, []);

  const showModal = (property) => {
    setSelectedProperty(property);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleImageClick = (property) => {
    navigate(`${ROUTE_PATH.DETAILS}/${property.listingID}`, {
      state: { property },
    });
  };

  const handleViewApplicants = (property) => {
    navigate(`/applicants/${property.listingID}`, {
      state: { property },
    });
  };

  const handleFilterChange = (value) => {
    setFilterCity(value);
  };

  const filteredProperties = filterCity === 'All'
    ? properties
    : properties.filter(property => property.city === filterCity);

  const myProperties = filteredProperties.map((property) => {
    const dateCreated = new Date(property.createdAt).toLocaleDateString('en-us', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    return {
      date: `${dateCreated}`,
      property,
      details: property,
    };
  });

  const totalProperties = properties.length;
  const totalApplicants = properties.reduce((sum, property) => sum + (property.applicationCount || 0), 0);
  const recentActivityCount = properties.filter(property => {
    const lastActivity = new Date(property.updatedAt || property.createdAt);
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return lastActivity > oneWeekAgo;
  }).length;

  const cities = ['All', ...new Set(properties.map(property => property.city))];

  const MY_PROPERTIES_COLUMNS = [
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      render: (property) => (
        <Row align="middle" gutter={[8, 8]}>
          <Col>
            <CustomImage
              src={property.image || listingExample}
              onClick={() => handleImageClick(property)}
              style={{ cursor: 'pointer', width: '100px', height: '100px' }}
            />
          </Col>
          <Col flex="auto">
            <BaseTypography align="left" fontSize="14px" strong>
              {property.address}
            </BaseTypography>
            <BaseTypography align="left" fontSize="12px">
              {property.city}, {property.state}
            </BaseTypography>
            <BaseTypography align="left" fontSize="12px">
              Date: {property.date}
            </BaseTypography>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Term Details',
      dataIndex: 'details',
      key: 'details',
      render: (property) => (
        <Button size={isMobile ? "small" : "large"} type="primary" onClick={() => showModal(property)}>
          View Terms
        </Button>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: 'Applicants',
      dataIndex: 'applicants',
      key: 'applicants',
      render: (_, record) => (
        <Button size={isMobile ? "small" : "large"} type="default" onClick={() => handleViewApplicants(record.property)}>
          View ({record.property.applicationCount || 0})
        </Button>
      ),
    },
  ];

  const renderMobileList = () => (
    <List
      dataSource={myProperties}
      renderItem={(item) => (
        <>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <CustomImage
                src={item.property.image || listingExample}
                onClick={() => handleImageClick(item.property)}
                style={{ cursor: 'pointer', width: '100%', height: 'auto', maxHeight: '200px' }}
              />
            </Col>
            <Col span={24}>
              <BaseTypography align="left" fontSize="16px" strong>
                {item.property.address}
              </BaseTypography>
              <BaseTypography align="left" fontSize="14px">
                {item.property.city}, {item.property.state}
              </BaseTypography>
              <BaseTypography align="left" fontSize="14px">
                Date: {item.date}
              </BaseTypography>
            </Col>
          </Row>
          <Row gutter={[0, 8]} style={{ marginTop: '8px' }}>
            <Col span={24}>
              <StyledButton type="primary" onClick={() => showModal(item.property)} block>
                View Terms
              </StyledButton>
            </Col>
            <Col span={24}>
              <StyledButton type="default" onClick={() => handleViewApplicants(item.property)} block>
                View Applicants ({item.property.applicationCount || 0})
              </StyledButton>
            </Col>
          </Row>
        </>
      )}
    />
  );

  return (
    <Container>
      <Row>
        <p style={{fontSize: '24px'}}>Properties with Applications</p>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <StyledCard>
            <Statistic
              title="Total Properties"
              value={totalProperties}
              prefix={<HomeOutlined />}
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StyledCard>
            <Statistic
              title="Total Applicants"
              value={totalApplicants}
              prefix={<UserOutlined />}
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StyledCard>
            <Statistic
              title="Recent Activity"
              value={recentActivityCount}
              prefix={<ClockCircleOutlined />}
              suffix="properties"
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StyledCard style={{height: '5rem'}}>
            <Select
              style={{ width: '100%' }}
              placeholder="Filter by City"
              onChange={handleFilterChange}
              defaultValue="All"
            >
              {cities.map(city => (
                <Option key={city} value={city}>{city}</Option>
              ))}
            </Select>
          </StyledCard>
        </Col>
      </Row>

      <StyledCard
        title={
          <CardHeader>
            <CardTitle>Investment Properties</CardTitle>
          </CardHeader>
        }
        style={{ marginTop: '16px' }}
      >
        <ConfigProvider
          renderEmpty={() => (
            <EmptyTable>
              <EmptyTableHeader>No properties available</EmptyTableHeader>
            </EmptyTable>
          )}
        >
          {isMobile ? (
            renderMobileList()
          ) : (
            <Table
              size="middle"
              loading={isLoading}
              dataSource={myProperties}
              columns={MY_PROPERTIES_COLUMNS}
              scroll={{ x: 'max-content' }}
            />
          )}
        </ConfigProvider>
      </StyledCard>

      <Modal
        title="Lending Terms"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {selectedProperty && (
          <div style={{ marginBottom: '77px' }}>
            <p style={{ marginBottom: '10px' }}><strong>Address:</strong> {selectedProperty.address}</p>
            <p style={{ marginBottom: '10px' }}><strong>City:</strong> {selectedProperty.city}</p>
            <p style={{ marginBottom: '10px' }}><strong>State:</strong> {selectedProperty.state}</p>
            <p style={{ marginBottom: '10px' }}><strong>Lending Amount:</strong> ${selectedProperty.lendingAmount.toLocaleString()}</p>
            <p style={{ marginBottom: '10px' }}><strong>Down Payment:</strong> ${selectedProperty.downPayment.toLocaleString()}</p>
            <p style={{ marginBottom: '10px' }}><strong>Interest Rate:</strong> {selectedProperty.interestRate}%</p>
            <p style={{ marginBottom: '10px' }}><strong>Loan Length:</strong> {selectedProperty.loanLength} years</p>
            <p style={{ marginBottom: '10px' }}><strong>Payment Frequency:</strong> {selectedProperty.paymentFrequency}</p>
            <p style={{ marginBottom: '10px' }}><strong>Late Payment Penalties:</strong> ${selectedProperty.latePaymentPenalties.toLocaleString()}</p>
          </div>
        )}
      </Modal>
    </Container>
  );
};

export default MyProperties;