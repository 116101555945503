import styled from 'styled-components';

export const CalculatorWrapper = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  margin: 20px auto;
  font-family: Arial, sans-serif;

  @media (min-width: 768px) {
    padding: 0 40px;
    margin: 40px auto;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

export const Divider = styled.hr`
  border: none;
  height: ${props => props.thickness || '1px'};
  background-color: ${props => props.color || '#e0e0e0'};
  margin: ${props => props.spacing || '40px 0'};
`;

export const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Tab = styled.button`
  padding: 10px 20px;
  background: ${props => props.active ? '#f0f0f0' : 'white'};
  border: none;
  border-bottom: 2px solid ${props => props.active ? 'black' : 'transparent'};
  cursor: pointer;
`;

export const ResultSection = styled.div`
  padding-right: 20px;
`;

export const MonthlyPayment = styled.h2`
  font-size: 36px;
  margin-bottom: 10px;

  span {
    font-size: 18px;
  }
`;

export const PaymentBreakdown = styled.div`
  margin-top: 20px;
`;

export const BreakdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${props => props.color};
    margin-right: 10px;
  }
`;

export const InfoIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ccc;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  margin-left: 5px;

  &::after {
    content: 'i';
  }
`;

export const DueAtClose = styled.div`
  font-weight: bold;
  margin-top: 20px;
`;

export const ApplyVeteransSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const GetPreApprovedButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
`;

export const ShowListingsLink = styled.a`
  display: block;
  margin-top: 10px;
  color: blue;
  text-decoration: underline;
`;

export const DisclosureLinks = styled.div`
  margin-top: 20px;

  a {
    margin-right: 10px;
    color: #666;
  }
`;

export const InputSection = styled.div`
  // Removed width: 60% as it's now controlled by Ant Design's Col
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const ViewRatesLink = styled.a`
  color: blue;
  text-decoration: underline;
`;

export const EligibilitySection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
  }
`;

export const EligibilityItem = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    width: 48%;
    padding: 20px;
    margin-bottom: 0;
  }
`;

export const EligibilityButton = styled.button`
  background-color: white;
  border: 1px solid black;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
`;