import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { CtaContainer, StyledTitle, StyledParagraph, StyledButton } from './styled';

const CtaSection = () => {
  return (
    <CtaContainer>
      <Row justify="center" align="middle">
        <Col xs={24} md={18}>
          <StyledTitle level={2}>Ready to Start Your Investment Journey?</StyledTitle>
          <StyledParagraph>
            Join thousands of investors who are already benefiting from our platform.
          </StyledParagraph>
          <StyledButton
            type="primary" 
            href="https://admin.invested.homes" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ height: '48px', alignContent: 'center', fontWeight: '600' }}
          >
            Get Started Now
          </StyledButton>
        </Col>
      </Row>
    </CtaContainer>
  );
};

export default CtaSection;