import styled from 'styled-components';
import { Select } from 'antd';

export const InputSectionWrapper = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;

  svg {
    margin-left: 5px;
    color: #ccc;
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 52px;
  padding: 0 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 52px !important;
    padding: 10px 15px !important;
  }
`;

export const ViewRatesLink = styled.a`
  display: block;
  margin-top: 5px;
  color: blue;
  text-decoration: underline;
`;

export const DownPaymentGroup = styled.div`
  display: flex;
  gap: 10px;

  ${StyledInput} {
    width: 50%;
  }
`;