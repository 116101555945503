export const PERIOD_RADIO = [
  {
    value: 'any',
    label: 'Any',
  },
  {
    value: '1-12 months',
    label: '1-12 months',
  },
  {
    value: '13-24 months',
    label: '13-24 months',
  },
  {
    value: '24+ months',
    label: '24+ months',
  },
];

export const DESKTOP_CATEGORIES = [
  {
    value: 'residential',
    label: 'Residential',
  },
  {
    value: 'commercial sale',
    label: 'Commercial Sale',
  },
  {
    value: 'land',
    label: 'Land',
  },
];

export const MOBILE_CATEGORIES = [
  {
    value: 'houses',
    label: 'Houses',
  },
  {
    value: 'rooms',
    label: 'Rooms',
  },
  {
    value: 'apartment',
    label: 'Apartment',
  },
];
