import styled from 'styled-components';
import { Layout, Row, Col, Button, Flex } from 'antd';
import { Link } from 'react-router-dom';

const { Header: AntHeader } = Layout;

export const Header = styled(AntHeader)`
  color: #000929;
  height: 77px;
  padding: 0 25px;
  line-height: 64px;
  background-color: #ffffff;
  border-bottom: 1px solid #dee8f7;
  text-align: center;
`;

export const HeaderContainer = styled(Row)`
  height: 100%;
  align-items: center;
`;

export const LinkStyle = styled(Link)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray};
  font-weight: 700;
  font-size: 14px;
  gap: 16px;
  opacity: 0.7;
  cursor: pointer;
`;

export const HeaderTitle = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
`;

export const HeaderProfile = styled(Flex)`
  align-items: center;
  justify-content: end;
`;

export const CustomButton = styled(Button)`
  background: ${props => props.theme.colors.blueShades98};
  border: 0;

  .ant-btn {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding-right: 10px;
  }
`;

export const Divider = styled.div`
  border: 1px solid #dee8f7;
  height: 40px;
`;

export const SearchField = styled(Col)`
  display: flex;
  justify-content: center;
  max-width: 813px;
  width: 100%;
  margin: auto;

  @media screen and (max-width: 1440px) {
    max-width: 600px;
  }

  .ant-input-prefix {
    margin-right: 16px;
  }

  .ant-input-affix-wrapper {
    padding: 12px 16px;
    border: 2px solid #dfdfdf;
    background: #f7fbfd;

    input {
      font-size: 16px;
      color: ${props => props.theme.colors.mainDark50};
    }
  }

  .anticon svg path {
    stroke: ${props => props.theme.colors.colorPrimary};
  }
`;

export const CustomCol = styled(Col)`
  margin-left: auto;  /* Pushes it to the right */
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 800px) {
    margin-inline-start: 0 !important;
  }
`;
