import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AmortizationWrapper, AmortizationTitle } from './styled';

const LoanAmortizationSection = ({ loanAmount, interestRate, loanTerm }) => {
  const generateAmortizationData = () => {
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment = Math.round(
      (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)
    );

    let balance = loanAmount;
    const data = [];

    for (let year = 0; year <= loanTerm; year++) {
      const yearlyInterest = year === 0 ? 0 : Math.round(12 * (balance * monthlyInterestRate));
      const yearlyPrincipal = year === 0 ? 0 : Math.round(12 * monthlyPayment - yearlyInterest);
      
      data.push({
        year,
        balance: Math.max(0, Math.round(balance)),
        interest: yearlyInterest,
        principal: yearlyPrincipal,
      });

      balance -= yearlyPrincipal;
    }

    return data;
  };

  const data = generateAmortizationData();
  const totalPayments = data.reduce((sum, item) => sum + item.interest + item.principal, 0);

  return (
    <AmortizationWrapper>
      <AmortizationTitle>
        Your mortgage payments over {loanTerm} years will add up to ${totalPayments.toLocaleString()}
      </AmortizationTitle>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="year" 
            label={{ value: 'Year', position: 'insideBottom', offset: -5 }}
          />
          <YAxis 
            label={{ value: 'Amount ($)', angle: -90, position: 'insideLeft' }}
            tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
          />
          <Tooltip 
            formatter={(value) => [`$${value.toLocaleString()}`, undefined]}
            labelFormatter={(label) => `Year ${label}`}
          />
          <Legend />
          <Line type="monotone" dataKey="balance" name="Remaining Balance" stroke="#8884d8" />
          <Line type="monotone" dataKey="interest" name="Interest Paid" stroke="#82ca9d" />
          <Line type="monotone" dataKey="principal" name="Principal Paid" stroke="#ffc658" />
        </LineChart>
      </ResponsiveContainer>
    </AmortizationWrapper>
  );
};

export default LoanAmortizationSection;