import React from 'react';
import { Layout } from 'antd';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import TestimonialsSection from './components/TestimonialsSection';
import CtaSection from './components/CtaSection';
import MapSection from './components/MapSection';
import SetTerms from './components/SetTerms';
import Footer from './components/Footer';

const { Content } = Layout;

const Home = () => {
  return (
    <Layout>
      <Header />
      <Content>
        <HeroSection />
        <FeaturesSection />
        <MapSection />
        <SetTerms />
        <TestimonialsSection />
        <CtaSection />
      </Content>
      <Footer />
    </Layout>
  );
};

export default Home;