import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
// Hooks
import usePrevious from './usePrevious';

/**
 *
 * @param {Object} points Takes fetch data from MLS API to format for markers
 * @param {Object} polygon Current Drawn polygon if exists
 * @param {Object} geometry Google Maps Geometry library
 *
 * @return {Object} Returns formatted markers and markers contained in polygon
 */
const useMarkers = ({ points = [], geometry, polygon, state, bounds }) => {
  const [markers, setMarkers] = useState([...points]);
  const { ne, sw } = bounds;

  const polygonPaths =
    state.now.length &&
    state.now[0].geometry
      .getPath()
      .getArray()
      .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

  const prevPolygonPaths = usePrevious(polygonPaths);

  useEffect(() => {
    // The changed polygon does not update when dragged
    const polygonPath =
      polygon &&
      geometry &&
      polygon
        .getPath()
        .getArray()
        .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
    if (polygon && !isEqual(polygonPath, prevPolygonPaths)) {
      const pointsWithinPolygon = points.filter((point) =>
        geometry.poly.containsLocation(point.position, polygon)
      );
      setMarkers(pointsWithinPolygon);
    } else if (!polygon && ne && sw) {
      /**
       * Handles if polygon is deleted and to only show markers within the viewport of
       * Google Maps
       */

      const markersInBounds = points.filter((point) => {
        const { lat, lng } = point.position;

        return lat > sw.lat && lat < ne.lat && lng > sw.lng && lng < ne.lng;
      });

      setMarkers([...markersInBounds]);
    }
  }, [polygon, points, prevPolygonPaths, ne, sw, geometry]);

  return {
    markers,
  };
};

export default useMarkers;
