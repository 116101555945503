// src/components/styled.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SectionItem = styled.div`
  padding: 24px;
  background: #ffffff;
  color: #000000;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SectionContent = styled.div`
  flex-grow: 1;
`;

export const StyledLink = styled.a`
  color: #007bff;
  text-decoration: underline;
  font-weight: 500;
  margin-top: auto;
  padding-top: 16px;

  &:hover {
    text-decoration: none;
  }
`;


export const SectionTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
  color: #000000;
  font-weight: bold;
  padding-right: 40px; // Add padding to prevent overlap with icon
`;

export const SectionDescription = styled.div`
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 16px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 24px;
  color: #007bff; // Changed to the requested color
`;

export const SectionContainer = styled.div`
  padding: 20px 0;
  background-color: #f8f9fa;
`;

export const SectionWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export const StyledRouterLink = styled(Link)`
  color: #007bff;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export const SectionHighlight = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #000;
  text-align: left;

  span {
    color: #007bff;
  }
`;

export const SectionSubheading = styled.h3`
  font-size: 16px;
  margin-bottom: 40px;
  color: #6c757d;
  text-align: left;
`;