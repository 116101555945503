import styled from "styled-components";
import { Row, Col, Card, Input, Slider, Select, Button, Divider, Modal } from "antd";
import { COLORS } from "../../constants/colors";

export const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
`;

export const ImageCard = styled.div`
  width: 100%;
  height: 265px;  // Adjust height as needed
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;  // Ensure we can position price absolutely within the card
  border: 1px solid #e8e8e8;  // Add border to resemble a card
  border-radius: 8px;  // Optional: rounded corners
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);  // Optional: add shadow for card effect
  background-color: #fff;  // Ensure background is white
`;

export const Image = styled.img`
  width: 120%;
  height: 120%;  // Ensure the image takes up the full height of the card
  object-fit: cover;  // Ensure the image covers the container without distortion
`;

export const PriceTag = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);  // Semi-transparent background for readability
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
`;

export const MortgageCalculatorCard = styled(Card)`
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light box shadow */
`;

export const ProfitCalculatorCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light box shadow */
`;

export const TermsCard = styled(Card)`
  margin-top: 20px;
  font-size: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light box shadow */

  .ant-modal-title {
    font-size: 24px !important;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0 0 10px;
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  height: 48px;
  border-radius: 8px;
  width: 100%;
  background: #005EB8;
  font-size: 16px;
  color: #fff;
  line-height: 150%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #005EB8;
  font-weight: 700;
`;

export const StyledRow = styled(Row)`
  margin-top: 20px;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 10px;
  height: 48px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 10px;
  height: 48px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const ModalValue = styled.div`
  margin-bottom: 16px;
`;

// New styles for the mobile header
export const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
`;

export const HeaderButton = styled(Button)`
  height: 40px;
  color: ${COLORS.$colorPrimary};
  border: 1.5px solid ${COLORS.$blueShades92};
  border-radius: 8px;
  font-size: 14px;
`;

export const SearchField = styled.div`
  @media (max-width: 1024px) {
    margin-top: 24px;
  }

  .ant-input-prefix {
    margin-right: 16px;
  }

  .ant-input-affix-wrapper {
    padding: 12px 16px;
    border: 2px solid ${COLORS.$blueShades92};
    background: ${COLORS.$blueShades98};

    input {
      font-size: 16px;
      color: ${COLORS.$mainDark50};
    }
  }

  .anticon svg path {
    stroke: ${COLORS.$colorPrimary};
  }
`;
