import { Form } from 'antd';
import { ROUTE_PATH } from '../../../../constants/routePath';
import { Link } from 'react-router-dom';
import BaseTypography from '../../../../components/BaseTypography';
import { isMobile } from 'react-device-detect';
import { COLORS } from '../../../../constants/colors';
import { BaseInput } from '../../../../components/Input';
import { StyledFlex, StyledRegister } from '../../styled';
import { BaseButton } from '../../../../components/Button';

//TODO: add reset password logic

const ForgotPassword = () => {
  return (
    <StyledFlex vertical>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        type="title"
        color={COLORS.$black}
        level={3}
      >
        Reset password
      </BaseTypography>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        marginBottom="32px"
        color={COLORS.$blackOpacity50}
      >
        Please enter your email, and we will send you instructions
      </BaseTypography>
      <Form>
        <Form.Item name="email">
          <BaseInput label="Email" placeholder="Email" />
        </Form.Item>
        <Form.Item>
          <BaseButton type="primary" htmlType="submit" block>
            Reset password
          </BaseButton>
        </Form.Item>
        <StyledRegister>
          Don’t have an account?{' '}
          <Link to={ROUTE_PATH.REGISTER}>Sign up for free</Link>
        </StyledRegister>
      </Form>
    </StyledFlex>
  );
};

export default ForgotPassword;
