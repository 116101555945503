import styled from 'styled-components';
import { Typography, Card } from 'antd';

const { Title } = Typography;

export const TestimonialsContainer = styled.div`
  padding: 64px 50px;
`;

export const StyledTitle = styled(Title)`
  text-align: center;
  &&&& {
    span {
      color: #0066cc;
    }
  }
`;

export const StyledCard = styled(Card)`
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const TestimonialName = styled.h4`
  margin-top: 16px;
  font-weight: bold;
`;