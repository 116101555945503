// FeaturesSection.js
import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import homeImage from '../../../../assets/images/invested-popular.png';
import { useNavigate } from 'react-router-dom';
import {
  FeaturesContainer,
  StyledTitle,
  StyledParagraph,
  StyledImage
} from './styled';

const { Text } = Typography;


const FeaturesSection = () => {
  const navigate = useNavigate();

  return (
    <FeaturesContainer>
      <Row style={{ maxWidth: '1024px', margin: 'auto' }} gutter={[24, 24]} align="top">
        <Col xs={24} lg={12}>
          <Text style={{ color: '#0066cc', fontSize: '16px', fontWeight: 'bold' }}>GUIDANCE</Text>
          <StyledTitle>
            Find Your Ideal<br />
            Real Estate<br />
            <span>Note</span><br />
            <span>Investment.</span>
          </StyledTitle>
          <StyledParagraph>
            Our platform guides you from note selection<br />
            to investment completion.
          </StyledParagraph>
          <Button
            type="primary" 
            href="https://admin.invested.homes" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ background: '#000', borderColor: '#000', height: '48px', alignContent: 'center' }}
          >
            Get Started
          </Button>
        </Col>
        <Col xs={24} lg={12}>
          <StyledImage src={homeImage} alt="Real Estate Note Investing" />
        </Col>
      </Row>
    </FeaturesContainer>
  );
};

export default FeaturesSection;