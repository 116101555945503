import styled from 'styled-components';
import { Card, Select, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 0 16px;
`;

export const Header = styled.header`
  padding: 16px 0;
`;

export const MainContent = styled.div`
  padding: 32px 0;
  max-width: 1247px;
  margin: auto;
`;

export const LocationSearch = styled.div`
  margin: 16px 0;
`;

export const PropertyList = styled.div`
  margin: 32px;
  max-width: 1400px;
  margin: auto;
  padding: 25px;

  .ant-card-cover img {
    width: 100%;
    height: auto;
  }

  .ant-card-meta-title {
    margin-top: 16px;
  }

  .ant-card-meta-description {
    margin-top: 4px;
  }
`;

export const Footer = styled.footer`
  padding: 32px 0;
  text-align: center;
`;

export const StyledCard = styled(Card)`
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 48px !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-item {
    line-height: 48px !important;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  width: 270px;
  margin: 50px auto;
  background-color: black;
  color: white;
  border: none;
  height: 48px;
  &:hover {
    background-color: #333;
    color: white;
  }
`;

export const SectionHighlight = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 7px;
  color: #000;
  text-align: left; // Align text to the left

  span {
    color: #007bff;
  }
`;

export const LinkStyle = styled(Link)`
  display: flex;
  align-items: center;
  color: #1890ff;
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .anticon {
    margin-right: 8px;
  }
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const SectionSubheading = styled.h3`
  font-size: 16px;
  margin-bottom: 20px;
  color: #b0b0b0;
  text-align: left; // Align text to the left
`;

export const StyledFilterCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 28px;
  background-color: white;
  padding: 17px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 24px;
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 23%; // Adjust as needed
`;

export const StyledSearchButton = styled(Button)`
  background-color: #1890ff;
  color: white;
  height: 48px;
  border: none;
  align-self: end;
  &:hover {
    background-color: #40a9ff;
    color: white;
  }
`;

export const PriceTypography = styled(Typography.Text)`
  font-size: 18px;
  font-weight: bold;
`;

export const NeighborhoodTypography = styled(Typography.Text)`
  font-size: 14px;
  color: #666;
`;

export const AddressTypography = styled(Typography.Text)`
  font-size: 14px;
  margin-top: 8px;
`;

export const ListingProperties = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Value = styled.span`
  font-size: 14px;
  margin-left: 4px;
`;