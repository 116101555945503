import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../../constants/api';
import { useStore } from "../../../hooks/storeContext";

// Styles
import {
  ListingGridContainer,
  ImageContainer,
  ListingImage,
  ListingGridContent,
  PriceTypography,
  NeighborhoodTypography,
  AddressTypography,
  Break,
  ListingPropertiesContainer,
  ListingProperties,
  Value,
  Sqft
} from './Styled';

// Constants
import {
  FavoritedSvg,
  FavoritedFilledSvg,
  BedroomSvg,
  SqFtSvg,
  BathroomSvg,
} from '../../../components/Icons';
import { ROUTE_PATH } from '../../../constants/routePath';
import listingExample from '../../../assets/images/listing-grid-example.png';

// Utils
import { formatNumberToDollars } from '../../../utils/numberFormat';
import { getStreetViewImage } from '../../../utils/getStreetView';

// Components
import { Flex } from 'antd';
import Icon from '@ant-design/icons';

const MarketSearchGridItem = ({ property }) => {
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(false);
  const { authStore } = useStore();

  const {
    current_price,
    bathrooms,
    bedrooms,
    address,
    city,
    state,
    subdivision,
    type,
    streetViewImage,
  } = property;

  const handleClick = () => {
    navigate(`${ROUTE_PATH.DETAILS}/${property.id}`, {
      state: { property },
    });
  };

  const handleFavoriteClick = async (event) => {
    event.stopPropagation();
    const listingId = property.id;
    const userEmail = authStore.me.email;

    try {
      const response = await axios.post(api.user.setFavorite, { 
        email: userEmail,
        listingId 
      });

      if (response.status === 200) {
        console.log('Favorite added successfully:', response.data.favorites);

        const fullAddress = `${address}, ${city}, ${state}`;
        const streetViewImage = await getStreetViewImage({ address: fullAddress });

        setIsFavorited(!isFavorited);
        property.streetViewImage = streetViewImage;
      } else {
        console.error('Failed to add favorite:', response.data.msg);
      }
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  return (
    <ListingGridContainer onClick={handleClick}>
      <ListingImage
        src={streetViewImage || listingExample}
        alt="Property"
        preview={false}
      />
      <ImageContainer>
      </ImageContainer>
      <ListingGridContent>
        <Flex vertical>
          <div>
            <Flex justify="space-between" gap={4}>
              <div>
                <PriceTypography>
                  {formatNumberToDollars(property.mlsListingPrice)}
                </PriceTypography>
                <NeighborhoodTypography>
                  {subdivision || type}
                </NeighborhoodTypography>
              </div>
              <div onClick={handleFavoriteClick}>
                <Icon component={isFavorited ? FavoritedFilledSvg : FavoritedSvg} />
              </div>
            </Flex>
          </div>

          <AddressTypography>
            {`${address.address} ${address.city}, ${address.state}`}
          </AddressTypography>

          <Break />

          <ListingPropertiesContainer gap={15}>
            <ListingProperties>
              <Icon component={BedroomSvg} />
              <Value>{bedrooms}</Value>
            </ListingProperties>
            <ListingProperties>
              <Icon component={BathroomSvg} />
              <Value>{bathrooms}</Value>
            </ListingProperties>
          </ListingPropertiesContainer>
        </Flex>
      </ListingGridContent>
    </ListingGridContainer>
  );
};

export default MarketSearchGridItem;
