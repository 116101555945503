import AuthStore from './AuthStore';
import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import { notification } from 'antd';

const RootStore = types
  .model('Rootstore', {
    authStore: types.optional(AuthStore, {}),
  })
  .actions((self) => ({
    getToken: () => {
      return localStorage.getItem('investedAuthToken');
    },
    post: flow(function* (api, values) {
      try {
        return yield axios.post(api, values, {
          headers: { 'x-access-token': self.getToken },
        });
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Something went wrong',
        });
        return false;
      }
    }),
    get: flow(function* (api) {
      try {
        return yield axios.get(api, {
          headers: { 'x-access-token': self.getToken },
        });
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Something went wrong',
        });
        return false;
      }
    }),
  }));

export default RootStore;
