import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Space, Flex, Button, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faHome,
  faUser,
  faBuilding,
  faUsers,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { useStore } from "../../../hooks/storeContext";

// Styles
import styles from './SideNavigation.module.scss';

// Constants
import { ROUTE_PATH } from '../../../constants/routePath';
import { AGENT_ROUTE_PATH } from '../../../constants/routePath';

// Custom Icons
import { LogoDarkSvg } from '../../../components/Icons';

const SideNavigation = () => {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useStore();

  const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#000929',
    backgroundColor: '#FFFFFF',
    borderRight: '1px solid #DEE8F7',
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100vh',
    zIndex: '10',
  };

  const activeStyle = {
    backgroundColor: '#005eb8',
    color: 'white',
  };

  const buttonStyle = (isActive) => ({
    ...(isActive ? activeStyle : {}),
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const iconStyle = (isActive) => ({
    color: isActive ? 'white' : '#999da9',
  });

  const isDashboard = location.pathname.includes('/dashboard');
  const isProperties = location.pathname.includes('properties');
  const isApplicants = location.pathname.includes('applicants');
  const isDetails = location.pathname.includes('details');
  const isUserApproval = location.pathname.includes('approval');

  const handleClickDashboard = () => navigate(AGENT_ROUTE_PATH.DASHBOARD);
  const handleClickProperties = () => navigate(AGENT_ROUTE_PATH.PROPERTIES);
  const handleClickApplicants = () => navigate(AGENT_ROUTE_PATH.APPLICANTS);
  const handleClickUserApproval = () => navigate(AGENT_ROUTE_PATH.APPROVALS);

  const handleMenuClick = (e) => {
    if (e.key === 'signOut') {
      authStore.logout();
    } else if (e.key === 'Profile' || e.key === 'My Account') {
      navigate(ROUTE_PATH.SETTINGS, { state: { activeTab: e.key } });
    }
  };

  const items = [
    {
      type: 'divider',
    },
    {
      label: 'Sign out',
      key: 'signOut',
    },
  ];

  if (isDetails) return null;

  return (
    <div style={{ width: '88px' }}>
      <Sider width="88px" style={siderStyle}>
        <Space
          className={styles.logo}
          onClick={handleClickDashboard}
          align="start"
        >
          <LogoDarkSvg />
        </Space>

        <Flex align="center" gap={36} vertical>
          <Button
            onClick={handleClickDashboard}
            style={buttonStyle(isDashboard)}
            icon={<FontAwesomeIcon icon={faHome} style={iconStyle(isDashboard)} />}
          />
          <Button
            onClick={handleClickProperties}
            style={buttonStyle(isProperties)}
            icon={<FontAwesomeIcon icon={faBuilding} style={iconStyle(isProperties)} />}
          />
          <Button
            onClick={handleClickApplicants}
            style={buttonStyle(isApplicants)}
            icon={<FontAwesomeIcon icon={faUsers} style={iconStyle(isApplicants)} />}
          />
          {authStore.isAdmin && (
            <Button
              onClick={handleClickUserApproval}
              style={buttonStyle(isUserApproval)}
              icon={<FontAwesomeIcon icon={faUserCheck} style={iconStyle(isUserApproval)} />}
            />
          )}
        </Flex>

        <Flex
          className={styles.settingsMenuContainer}
          align="center"
          gap={36}
          vertical
        >
          <Dropdown menu={{ items, onClick: handleMenuClick }} placement="rightTop" trigger={['click']}>
            <Button
              style={buttonStyle(false)}
              icon={<FontAwesomeIcon icon={faUser} style={iconStyle(false)} />}
            />
          </Dropdown>
        </Flex>
      </Sider>
    </div>
  );
};

export default SideNavigation;