// src/pages/Company.js
import React from 'react';
import { ApplicationWrapper, Title, Content } from './styled';

const Company = () => {
    return (
        <ApplicationWrapper>
            <Title>Company</Title>
            <Content>
                <h2>About Invested</h2>
                <p>
                    Invested is a pioneering platform dedicated to connecting buyers and lenders for property financing opportunities. Our mission is to simplify the real estate financing process by providing a transparent and efficient platform for all parties involved.
                </p>

                <h2>Our Mission</h2>
                <p>
                    At Invested, our mission is to revolutionize the way property financing is conducted. We strive to empower buyers and lenders with the tools and information they need to make informed decisions and facilitate successful transactions.
                </p>

                <h2>Our Vision</h2>
                <p>
                    Our vision is to create a world where property financing is accessible, transparent, and seamless for everyone. We aim to be the leading platform in the real estate industry, recognized for our innovation and commitment to customer satisfaction.
                </p>

                <h2>Our Values</h2>
                <ul>
                    <li><strong>Integrity:</strong> We believe in conducting business with honesty and transparency.</li>
                    <li><strong>Innovation:</strong> We are committed to continuously improving our platform and services.</li>
                    <li><strong>Customer Focus:</strong> Our customers are at the heart of everything we do.</li>
                    <li><strong>Excellence:</strong> We strive for excellence in all aspects of our business.</li>
                    <li><strong>Collaboration:</strong> We believe in the power of teamwork and collaboration to achieve our goals.</li>
                </ul>

                <h2>Our Team</h2>
                <p>
                    The Invested team is comprised of experienced professionals with a passion for real estate and technology. Our diverse team brings together expertise from various fields, including finance, technology, and customer service, to deliver the best possible experience for our users.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions or would like to learn more about Invested, please contact us at [support email] or call us at [support phone number]. We look forward to hearing from you!
                </p>
            </Content>
        </ApplicationWrapper>
    );
};

export default Company;
