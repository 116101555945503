import styled from 'styled-components';
import { Card as AntCard, Row, Col, Table as AntTable } from 'antd';

export const Container = styled.div`
  padding: 15px;
`;

export const Toolbar = styled.div`
  height: 56px;
  border-radius: 8px;
  background-color: #ffffff;
  align-items: center;
  padding: 8px;
`;

export const Card = styled(AntCard)`
  
`;

export const CardHeader = styled(Row)`
  padding: 20px;
`;

export const CardHeaderSection = styled(Col)`
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const CardTitle = styled.div`
  font-size: 18px;
  padding-bottom: 10px;
  text-align: left;
`;

export const CardValue = styled.div`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.7;
  text-align: left;
`;

export const Divider = styled.div`
  border: 1px solid #dee8f7;
  height: 50px;
  margin-right: 80px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const EmptyTable = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
`;

export const EmptyTableHeader = styled.div`
  font-size: 16px;
  opacity: 0.7;
`;

export const Property = styled.div`
  
`;

export const PropertyTitle = styled.div`
  font-size: 16px;
  padding-bottom: 5px;
`;

export const PropertyInfo = styled.div`
  font-size: 14px;
  opacity: 0.7;
`;

export const SearchField = styled.div`
  @media screen and (max-width: 1440px) {
    max-width: 600px;
  }

  .ant-input-prefix {
    margin-right: 16px;
  }

  .ant-input-affix-wrapper {
    padding: 12px 16px;
    border: 2px solid #afc2f2;
    background: #dce4f7;

    input {
      font-size: 16px;
      color: #000929;
    }
  }

  .anticon svg path {
    stroke: #005eb8;
  }
`;

export const CustomImage = styled.img`
  width: 240px;
  height: 140px;
  border-radius: 8px;
  object-fit: cover;
`;
