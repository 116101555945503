import styled from "styled-components";
import { Pagination } from "antd";

export const ListContainer = styled.div`
  height: 100%;

  @media (max-width: 1024px) {
    height: auto;
  }
`;

export const ListContent = styled.div`
  max-height: 85%;
`;

export const ListPagination = styled(Pagination)`
  display: flex;
  justify-content: end;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const Bottom = styled.div`
  padding-bottom: 20px;
`;
