import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Flex, Form, Upload, message, Input } from 'antd';
import Avatar from '../../../assets/images/avatar.png';
import PhoneInput from 'react-phone-input-2';
import { BaseButton, OutlinedButton } from '../../../components/Button';
import { LoadingOutlined } from '@ant-design/icons';

import 'react-phone-input-2/lib/style.css';
import './PhoneInput.scss';
import styles from './Profile.module.scss';
import { BaseInput } from '../../../components/Input';
import {observer} from "mobx-react";
import {useStore} from "../../../hooks/storeContext";
import {toJS} from "mobx";
import axios from "axios";
import {api} from "../../../constants/api";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};

const uploadImage = ({ onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};


const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
};

const Profile = observer(() => {
    const { authStore } = useStore();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = await form.validateFields()
        const formData = new FormData();
        formData.append("avatar", values.avatar?.fileList?.[0]?.originFileObj);
        formData.append("image", authStore.me.avatar);
        formData.append("phone", values.phone);
        authStore.onUpdateProfile(formData)
    };

    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                authStore.setAvatar(url);
            });
        }
    };

    if (authStore.pending) return <LoadingOutlined />

    return (
        <div className={styles.formWrapper}>
            <Form initialValues={{
                phone: authStore.me?.phone,
                avatar: authStore.me?.avatar,
            }}
                  form={form} className={styles.form}
            >
                <h4 className={styles.titleForm}>Personal Info</h4>
                <div className={styles.avatarWrapper}>
                    <div>Avatar</div>
                    <Form.Item name='avatar'>
                        <Upload
                            customRequest={uploadImage}
                            showUploadList={false}
                            onChange={handleChange}
                            beforeUpload={beforeUpload}
                        >
                            {authStore.me.avatar ? (
                                <Flex justify='space-between' gap={24} align="center">
                                    <img
                                        src={authStore.me.avatar}
                                        alt="avatar"
                                        style={{
                                            width: "100px",
                                            height: '100px',
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <div className={styles.buttonsWrapper}>
                                        <BaseButton type="submit"> Upload</BaseButton>
                                        <OutlinedButton type="submit" onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            authStore.setAvatar('')
                                        }}> Remove</OutlinedButton>
                                    </div>
                                </Flex>
                            ) : (
                                <Flex justify='space-between' gap={24} align="center">
                                    <img src={Avatar} alt="avatar" />
                                    <div className={styles.buttonsWrapper}>
                                        <BaseButton type="submit"> Upload</BaseButton>
                                        <OutlinedButton type="submit" onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            authStore.setAvatar('')
                                        }}> Remove</OutlinedButton>
                                    </div>
                                </Flex>
                            )}
                        </Upload>
                    </Form.Item>
                </div>
                <div className={styles.inner}>
                    <div className={styles.inputsWrapper}>
                        <Form.Item name='firstname'>
                            <BaseInput
                                className={styles.input}
                                size="large"
                                placeholder={'First name'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your first name!',
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item name='lastname'>
                            <BaseInput
                                className={styles.input}
                                size="large"
                                name="lastName"
                                placeholder={'Last name'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your last name!',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </div>
                    <div className={styles.label}>Phone Number</div>
                    <Form.Item name='phone'>
                        <PhoneInput
                            className={styles.phone}
                            country={'us'}
                        />
                    </Form.Item>

                    {isMobile && (
                        <Flex gap={8}>
              <span className={styles.helpText}>
                Need Help
                <Link className={styles.helpLink} to="/">
                  Send us a message
                </Link>
              </span>
                        </Flex>
                    )}
                </div>
                <Form.Item>
                    <Flex justify="end">
                        <div className={styles.button}>
                            <BaseButton onClick={handleSubmit} htmltype="submit">Save changes</BaseButton>
                        </div>
                    </Flex>
                </Form.Item>
            </Form>
        </div>
    );
});

export default Profile;
