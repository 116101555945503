import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const Section = styled.section`
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 8px;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
`;

const List = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
`;

const StyledCollapse = styled(Collapse)`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const FAQTitle = styled.h3`
  font-size: 32px;
  color: #333;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 25px 16px!important;
  }
  .ant-collapse-expand-icon {
    order: 2;
    margin-left: 12px;
  }
`;

const RotatingIcon = styled(CaretRightOutlined)`
  transition: transform 0.2s;
  transform: ${props => props.isActive ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

const Introduction = () => (
  <>
    <Title>Understanding Home Affordability</Title>
    <Paragraph>
      When considering home ownership, its crucial to understand the factors that influence how much house you can afford. This knowledge will help you make informed decisions and find a home that fits both your lifestyle and budget.
    </Paragraph>
    <List>
      <ListItem>Your income and existing debts significantly impact affordability.</ListItem>
      <ListItem>Monthly payments include more than just the mortgage - consider property taxes, insurance, and maintenance costs.</ListItem>
      <ListItem>Lenders use specific ratios and criteria to assess your loan repayment ability.</ListItem>
      <ListItem>Understanding key financial terms empowers you in the home buying process.</ListItem>
    </List>
    <Paragraph>
      By considering these factors and using tools like our Affordability Calculator, you can better understand your home buying power and set realistic expectations for your search.
    </Paragraph>
  </>
);

const FAQItem = ({ question, answer, isActive, onClick }) => (
  <StyledPanel 
    header={question} 
    key={question} 
    isActive={isActive} 
    onClick={onClick}
    expandIcon={({ isActive }) => <RotatingIcon isActive={isActive} />}
  >
    {answer.split('\n\n').map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph.includes('\n') ? (
          <List>
            {paragraph.split('\n').map((item, itemIndex) => (
              <ListItem key={itemIndex}>{item.replace(/^\d+\.\s/, '')}</ListItem>
            ))}
          </List>
        ) : (
          <Paragraph>{paragraph}</Paragraph>
        )}
      </React.Fragment>
    ))}
  </StyledPanel>
);

const faqData = [
  {
    question: "What is the 28/36 rule in home affordability?",
    answer: "The 28/36 rule is a crucial guideline used by mortgage lenders to assess a potential borrower's ability to afford a home. This rule consists of two key components:\n\n" +
      "1. Your total housing costs (including mortgage payments, property taxes, and insurance) should not exceed 28% of your gross monthly income.\n" +
      "2. Your total debt payments, including housing costs and all other debts (such as car loans, student loans, and credit card payments), should not exceed 36% of your gross monthly income.\n\n" +
      "For example, if your gross monthly income is $5,000:\n" +
      "Your housing costs should ideally be no more than $1,400 (28% of $5,000)\n" +
      "Your total monthly debt payments should not exceed $1,800 (36% of $5,000)\n\n" +
      "This rule helps ensure that you're not overextending yourself financially when buying a home, reducing the risk of default and foreclosure. It's important to note that while this rule is widely used, some lenders may be more flexible, especially for borrowers with excellent credit scores or substantial assets. Conversely, if you have a lower credit score or unstable income, lenders might apply stricter standards. Understanding and adhering to the 28/36 rule can help you determine a realistic budget for your home purchase and improve your chances of mortgage approval."
  },
  {
    question: "How is the debt-to-income ratio (DTI) calculated and why is it important?",
    answer: "The debt-to-income ratio (DTI) is a critical financial metric used by lenders to evaluate a borrower's ability to manage monthly payments and repay debts. It's calculated by dividing your total monthly debt payments by your gross monthly income, then expressing this as a percentage.\n\n" +
      "For example:\n" +
      "If your monthly debt payments total $2,000\n" +
      "And your gross monthly income is $6,000\n" +
      "Your DTI would be 33.33% ($2,000 / $6,000 = 0.3333)\n\n" +
      "DTI is crucial because:\n" +
      "1. It provides lenders with a clear picture of your financial health and ability to take on additional debt.\n" +
      "2. A lower DTI (ideally 36% or less) suggests you have a good balance between debt and income.\n" +
      "3. It can improve your chances of mortgage approval and better loan terms.\n\n" +
      "Lenders typically view DTI in two parts:\n" +
      "1. Front-end ratio: Considers only housing-related expenses\n" +
      "2. Back-end ratio: Includes all monthly debt obligations\n\n" +
      "Most lenders prefer:\n" +
      "A front-end ratio no higher than 28%\n" +
      "A back-end ratio no higher than 36%\n\n" +
      "Your DTI affects:\n" +
      "Loan approval chances\n" +
      "Interest rates offered\n" +
      "Loan terms\n" +
      "Maximum loan amount you might qualify for\n\n" +
      "To improve your DTI, consider:\n" +
      "1. Paying down existing debts\n" +
      "2. Increasing your income\n" +
      "3. Or both\n\n" +
      "Remember, while DTI is significant, lenders also consider other factors such as credit score, employment history, and assets when evaluating loan applications."
  },
  {
    question: "What should I include in my annual household income and monthly debt calculations?",
    answer: "Accurately calculating your annual household income and monthly debt is crucial for determining your home affordability and securing a mortgage.\n\n" +
      "For annual household income, include all earnings before taxes for you and any co-borrowers:\n" +
      "1. Salary and wages from all jobs\n" +
      "2. Tips and commissions\n" +
      "3. Regular bonuses (if consistent and likely to continue)\n" +
      "4. Self-employment income\n" +
      "5. Rental income from investment properties\n" +
      "6. Alimony or child support received\n" +
      "7. Social Security benefits\n" +
      "8. Pension or retirement account distributions\n" +
      "9. Investment income (dividends, interest)\n\n" +
      "It's important to use gross income (before taxes and other deductions) rather than net income. If your income varies significantly from year to year, lenders may use an average of the past two years.\n\n" +
      "For monthly debt calculations, include all recurring debt obligations such as:\n" +
      "1. Auto loan payments\n" +
      "2. Student loan payments\n" +
      "3. Personal loan payments\n" +
      "4. Minimum credit card payments\n" +
      "5. Alimony or child support payments you make\n" +
      "6. Any other regular loan payments appearing on your credit report\n\n" +
      "Do not include in your monthly debt:\n" +
      "1. Current rent or mortgage payments\n" +
      "2. Utilities (electricity, water, gas)\n" +
      "3. Insurance premiums\n" +
      "4. Groceries and other daily living expenses\n" +
      "5. Discretionary expenses like entertainment or dining out\n\n" +
      "It's crucial to be thorough and honest when calculating these figures, as they directly impact your debt-to-income ratio and overall loan eligibility. Lenders will verify this information against your tax returns, pay stubs, and credit report. Including all relevant income sources can potentially increase your borrowing power, while accurately reporting debts helps ensure you don't overextend yourself financially. Remember, the goal is to paint a comprehensive picture of your financial situation to determine a comfortable and sustainable mortgage payment."
  },
  {
    question: "What are closing costs and how much should I expect to pay?",
    answer: "Closing costs are the various fees and expenses paid by both the buyer and seller when finalizing a real estate transaction. These costs are due at the closing of the property sale, which is when the title of the property is transferred from the seller to the buyer. Closing costs typically range from 2% to 5% of the home's purchase price, but can vary based on factors such as location, property value, and the specific terms of the transaction.\n\n" +
      "Common closing costs for buyers include:\n" +
      "1. Loan origination fees (0.5% to 1% of the loan amount)\n" +
      "2. Appraisal fees ($300 to $700)\n" +
      "3. Home inspection fees ($300 to $500)\n" +
      "4. Title search and title insurance ($500 to $3,500)\n" +
      "5. Attorney fees (varies widely by location and complexity)\n" +
      "6. Escrow fees (1% to 2% of the home price)\n" +
      "7. Recording fees ($25 to $250)\n" +
      "8. Mortgage insurance (if down payment is less than 20%)\n" +
      "9. Property tax and homeowners insurance prepayments\n\n" +
      "Sellers typically pay for:\n" +
      "1. Real estate agent commissions (usually 5-6% of the sale price)\n" +
      "2. Transfer taxes or recording fees\n" +
      "3. Any agreed-upon credits to the buyer\n" +
      "4. Potential home warranty fees\n\n" +
      "The exact amount you'll pay in closing costs depends on various factors:\n" +
      "1. Your location: Some states and municipalities have higher fees and taxes than others.\n" +
      "2. The price of the home: Many closing costs are calculated as a percentage of the home's price.\n" +
      "3. The type of loan: Different loan programs (conventional, FHA, VA) have different associated costs.\n" +
      "4. Your lender: Some lenders charge higher fees than others.\n\n" +
      "To get a more accurate estimate of your closing costs, you can:\n" +
      "1. Request a Loan Estimate from potential lenders, which will outline expected closing costs.\n" +
      "2. Shop around and compare offers from multiple lenders.\n" +
      "3. Negotiate with the seller to cover some of the closing costs.\n" +
      "4. Consider a 'no-closing-cost' mortgage, where the lender covers the closing costs in exchange for a higher interest rate.\n\n" +
      "Remember, while closing costs can be substantial, they're a necessary part of the home buying process. Budgeting for these costs in advance can help ensure a smoother transaction and prevent last-minute financial stress."
  },
  {
    question: "How do mortgage rates affect affordability and what factors influence them?",
    answer: "Mortgage rates play a crucial role in home affordability, significantly impacting both your monthly payments and the total cost of your loan over time. Even a small difference in interest rates can have a substantial effect on your long-term finances. For example, on a $300,000 30-year fixed-rate mortgage, the difference between a 3.5% and a 4% interest rate could mean paying an additional $30,000 over the life of the loan.\n\n" +
      "Lower mortgage rates generally mean:\n" +
      "1. Lower monthly payments\n" +
      "2. More of your payment goes towards principal rather than interest\n" +
      "3. You might qualify for a larger loan amount\n" +
      "4. Less total interest paid over the life of the loan\n\n" +
      "Conversely, higher rates can reduce your purchasing power and increase the overall cost of homeownership.\n\n" +
      "Several factors influence mortgage rates:\n\n" +
      "1. Economic Conditions: The overall state of the economy, including inflation rates and employment levels, can affect mortgage rates. In times of economic growth, rates tend to rise, while they often fall during economic downturns.\n\n" +
      "2. Federal Reserve Monetary Policy: While the Fed doesn't set mortgage rates directly, its decisions on the federal funds rate can influence them. When the Fed raises or lowers this rate, mortgage rates often follow suit.\n\n" +
      "3. Bond Market: Mortgage rates often move in tandem with the yield on 10-year Treasury bonds. When bond yields increase, mortgage rates typically rise as well.\n\n" +
      "4. Housing Market Conditions: High demand for mortgages can lead to higher rates, while lower demand might result in more competitive rates.\n\n" +
      "5. Your Financial Profile: Personal factors such as your credit score, down payment amount, and debt-to-income ratio can affect the rate you're offered. A higher credit score and larger down payment generally lead to better rates.\n\n" +
      "6. Loan Type and Term: Different loan types (e.g., conventional, FHA, VA) and terms (e.g., 15-year vs. 30-year) often have different rate structures.\n\n" +
      "7. Property Type and Use: Rates can vary based on whether the property is a primary residence, second home, or investment property.\n\n" +
      "To maximize affordability:\n" +
      "1. Improve your credit score before applying for a mortgage\n" +
      "2. Save for a larger down payment\n" +
      "3. Compare offers from multiple lenders\n" +
      "4. Consider buying discount points to lower your rate\n" +
      "5. Stay informed about economic trends that might affect rates\n\n" +
      "Remember, while a low rate is important, it shouldn't be the only factor in your home buying decision. Consider the overall cost of the loan, including fees and closing costs, and ensure the home and mortgage fit within your long-term financial goals."
  },
  {
    question: "What's the difference between a fixed-rate and an adjustable-rate mortgage (ARM)?",
    answer: "Fixed-rate and adjustable-rate mortgages (ARMs) are two primary types of home loans, each with distinct characteristics that can significantly impact your long-term financial planning and monthly budget.\n\n" +
      "Fixed-Rate Mortgages:\n" +
      "1. Interest Rate Stability: The interest rate remains constant for the entire loan term, typically 15 or 30 years.\n" +
      "2. Predictable Payments: Your principal and interest payments remain the same throughout the loan, making budgeting easier.\n" +
      "3. Protection from Rate Increases: You're insulated from market fluctuations that could increase your rate.\n" +
      "4. Higher Initial Rates: Generally start with higher rates compared to ARMs.\n" +
      "5. Ideal for: Long-term homeowners, those who prefer stability, and in low-interest-rate environments.\n\n" +
      "Adjustable-Rate Mortgages (ARMs):\n" +
      "1. Variable Interest Rate: The rate adjusts periodically based on market indexes.\n" +
      "2. Lower Initial Rates: Often start with lower rates than fixed-rate mortgages, potentially allowing you to afford more house initially.\n" +
      "3. Potential for Lower Payments: If rates decrease, your payments could go down.\n" +
      "4. Risk of Higher Payments: If rates increase, your payments could rise significantly.\n" +
      "5. Complex Structures: Often described as 5/1, 7/1, etc., where the first number indicates the fixed-rate period and the second shows how often the rate adjusts afterward.\n" +
      "6. Rate Caps: Limit how much the rate can increase, but these caps can still allow for substantial payment increases.\n" +
      "7. Ideal for: Short-term homeowners, those expecting income increases, or in high-interest-rate environments expecting future decreases.\n\n" +
      "Choosing between fixed-rate and ARM:\n" +
      "1. Market Conditions: In a low-rate environment, locking in a fixed rate might be advantageous. In a high-rate environment, an ARM could be beneficial if rates are expected to decrease.\n" +
      "2. Home Ownership Plans: If you plan to stay in the home long-term, a fixed-rate mortgage offers more stability. For shorter-term plans, an ARM's lower initial rate could be advantageous.\n" +
      "3. Risk Tolerance: ARMs involve more risk due to potential rate increases. Consider your comfort level with payment uncertainty.\n" +
      "4. Financial Situation: If you expect significant income increases, you might be better positioned to handle potential ARM rate increases.\n" +
      "5. Loan Amount: With larger loans, even small rate increases on an ARM can significantly impact monthly payments.\n\n" +
      "It's crucial to thoroughly understand the terms of any mortgage you're considering. For ARMs, pay close attention to:\n" +
      "1. The initial fixed-rate period\n" +
      "2. How often the rate can adjust after the fixed period\n" +
      "3. Rate caps (periodic and lifetime)\n" +
      "4. The index used for rate adjustments and the margin added to that index\n\n" +
      "Regardless of which type you choose, ensure it aligns with your financial goals and long-term plans. Consider consulting with a financial advisor to determine the best option for your specific situation."
  }
];

const FAQSection = () => {
  const [activeKey, setActiveKey] = React.useState(null);

  const onChange = (keys) => {
    setActiveKey(keys);
  };

  return (
    <Section>
      <Introduction />
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <StyledCollapse activeKey={activeKey} onChange={onChange}>
      {faqData.map((item) => (
        <FAQItem
          key={item.question}
          question={item.question}
          answer={item.answer}
          isActive={activeKey === item.question}
          onClick={() => setActiveKey(activeKey === item.question ? null : item.question)}
        />
        ))}
      </StyledCollapse>
    </Section>
  );
};

export default FAQSection;