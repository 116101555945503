// src/pages/Contact.js
import React, { useState } from 'react';
import { ApplicationWrapper, Title, Content, Form, Input, TextArea, Button } from './styled';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form submitted:', formData);
        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <ApplicationWrapper>
            <Title>Contact Us</Title>
            <Content>
                <h2>Get in Touch</h2>
                <p>
                    We would love to hear from you! Whether you have a question about our services, need assistance with your account, or want to provide feedback, our team is here to help.
                </p>

                <h2>Contact Information</h2>
                <p>
                    <strong>Email:</strong> [support email]<br />
                    <strong>Phone:</strong> [support phone number]<br />
                    <strong>Address:</strong> [company address]
                </p>

                <h2>Send Us a Message</h2>
                <Form onSubmit={handleSubmit}>
                    <label htmlFor="name">Name</label>
                    <Input 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <label htmlFor="email">Email</label>
                    <Input 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <label htmlFor="subject">Subject</label>
                    <Input 
                        type="text" 
                        id="subject" 
                        name="subject" 
                        value={formData.subject} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <label htmlFor="message">Message</label>
                    <TextArea 
                        id="message" 
                        name="message" 
                        value={formData.message} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <Button type="submit">Submit</Button>
                </Form>
            </Content>
        </ApplicationWrapper>
    );
};

export default Contact;
