// FeaturesStyled.js
import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

export const FeaturesContainer = styled.div`
  padding: 50px 35px;
  background: #ffffff;
`;

export const StyledTitle = styled(Title)`
  font-size: 48px!important;
  font-weight: bold;
  margin-bottom: 24px;
  line-height: 1.1;
  margin-top: 20px!important;

  span {
    color: #0066cc;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 18px;
  margin-bottom: 32px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
`;