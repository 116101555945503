import styled from 'styled-components';

export const ResultSection = styled.div`
  padding: 20px;
  border-radius: 8px;
`;

export const MonthlyPayment = styled.h2`
  font-size: 36px;
  margin-bottom: 10px;

  span {
    font-size: 18px;
  }
`;

export const PaymentBreakdown = styled.div`
  margin-top: 20px;
`;

export const BreakdownItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${props => props.color};
    border-radius: 50%;
    margin-right: 10px;
  }

  span:first-child {
    flex-grow: 1;
    margin-left: 5px;
  }

  span:last-child {
    margin-left: auto;
  }
`;

export const DueAtClose = styled.div`
  font-weight: bold;
  margin-top: 20px;
`;

export const GetPreApprovedButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
`;

export const ShowListingsLink = styled.a`
  display: block;
  margin-top: 10px;
  color: blue;
  text-decoration: underline;
`;

export const DisclosureLinks = styled.div`
  margin-top: 20px;

  a {
    margin-right: 10px;
    color: #666;
  }
`;

export const DownPaymentInput = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  input {
    width: 50px;
    margin-right: 5px;
  }
`;