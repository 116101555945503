import React from 'react';

// Constants
import listingExample from '../../../assets/images/listing-grid-example.png';

// Components
import { Row, Col, Image } from 'antd';

// Styles
import styles from './ListingGallery.module.scss';

const ListingGallery = ({ streetViewImage }) => {
  return (
    <div className={styles.galleryContainer}>
      <Row className={styles.gridContainer} gutter={18}>
        <Col xs={24}>
          <div className={styles.imageWrapper}>
            <Image
              className={styles.image}
              width="100%"
              height="100%"
              preview={false}
              src={streetViewImage || listingExample} // Use the passed streetViewImage
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ListingGallery;
