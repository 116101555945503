import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { StyledHeader, Logo, MenuWrapper, StyledButton, LogoText, LogoWrapper } from './styled';
import investedLogo from '../../../../assets/images/logo.svg'

const HeaderComponent = () => {
  return (
    <StyledHeader>
      <LogoWrapper>
        <Logo src={investedLogo} alt="logo" />
        <LogoText>Invested</LogoText>
      </LogoWrapper>
      <MenuWrapper>
        <StyledButton href="https://admin.invested.homes" target="_blank" rel="noopener noreferrer">
          Login
        </StyledButton>
      </MenuWrapper>
    </StyledHeader>
  );
};

export default HeaderComponent;