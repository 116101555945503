import { useEffect, useState } from 'react';
import axios from 'axios';

// Utils
import { propertyDetailFormat } from '../utils/propertyFormat';

// Constants
import { api } from '../constants/api';

const useGetPropertyDetail = (propertyId) => {
  const [propertyDetail, setPropertyDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  // Check if data is different
  // Transform the  data match the request contract (utils)
  useEffect(() => {
    /**
     * If we have the MLS number, we only need to search with that
     * otherwise we can search by address
     */

    const getPropertyDetail = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(api.listing.propertyDetail, {
          id: propertyId,
        });

        const data = propertyDetailFormat(response.data);

        setIsLoading(false);
        setPropertyDetail(data);
      } catch (error) {
        console.log('>> ERROR', error);
        setIsLoading(false);
        setHasError(true);
      }
    };

    getPropertyDetail();
  }, [propertyId]);

  return { propertyDetail, isLoading, hasError };
};

export default useGetPropertyDetail;
