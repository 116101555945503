import styled from 'styled-components';
import { Row, Col, Flex, Image } from 'antd';
import { COLORS } from '../../constants/colors';

export const StyledRow = styled(Row)`
  min-height: 100dvh;
`;

export const StyledContent = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 0 auto;
  width: fit-content;
`;

export const StyledImage = styled(Image)`
  && {
    margin: 0 auto 80px auto;
    width: fit-content;
    display: flex;
  }
`;

export const StyledWrapper = styled(Col)`
  background: ${COLORS.$blueShades98};
  padding-top: 135px;
`;

export const StyledSquare = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  border-top: 300px solid ${COLORS.$blueShades92};
  border-right: 300px solid transparent;
`;

export const StyledCol = styled(Col)`
  form {
    width: 100%;
  }
`;

export const StyledHeader = styled.div`
  height: 77px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 60px;
  border-bottom: 2px solid ${COLORS.$blueShades96};
  margin-bottom: 80px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: ${COLORS.$black};

  @media (max-width: 1024px) {
    margin-bottom: 48px;
    height: 72px;
    padding: 0 27px 0 37px;
    justify-content: left;
  }
`;
