import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// Styles
import styles from './MarketSearchDrawer.module.css';

// Constants
import { DRAWER_OPTIONS, DRAWER_OPTION } from '../../../../constants';
import { SearchIconSvg, FiltersSvg } from '../../../../components/Icons';

// Components
import { Breadcrumb, Col, Drawer, Flex, Input, Row, Segmented } from 'antd';
import Icon from '@ant-design/icons';
import BaseTypography from '../../../../components/BaseTypography';
import { BaseButton } from '../../../../components/Button';
import MapListings from '../MapListings/MapListings';

const MarketSearchDrawer = ({
  handleClickClose,
  isOpen = false,
  container,
  setIsFiltersDrawerOpen,
  properties,
}) => {
  const [searchParams] = useSearchParams();
  const params = {};
  const count = properties.length;

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const handleClickFilterOpen = () => {
    setIsFiltersDrawerOpen(true);
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={handleClickClose}
      open={isOpen}
      getContainer={container}
      rootStyle={{ position: 'absolute' }}
      destroyOnClose={true}
      width={800}
    >
      <div>
        <Breadcrumb
          items={[
            {
              title: 'Home',
            },
            {
              title: <a href="">Search</a>,
            },
            {
              title: 'Search Results',
            },
          ]}
        />

        <Flex className={styles.titleContainer} align="left" vertical gap={10}>
          <BaseTypography
            className={styles.drawerTitle}
            fontSize="32px"
            align="left"
          >
            {count.toLocaleString()} Listings
          </BaseTypography>
          <BaseTypography
            className={styles.drawerSubTitle}
            fontSize="16px"
            align="left"
          >
            Invest in your next home
          </BaseTypography>
          <BaseButton
              onClick={handleClickFilterOpen}
              className={styles.filtersButton}
              style={{width:'180px'}}
            >
              <Icon
                className={styles.filtersButtonIcon}
                component={() => (
                  <FiltersSvg className={styles.filtersButtonIcon} />
                )}
              />
              More Filters
            </BaseButton>
        </Flex>

        <MapListings properties={properties} />
      </div>
    </Drawer>
  );
};

export default MarketSearchDrawer;
