import styled from 'styled-components';

export const ApplicationWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
`;

export const Title = styled.h1`
    font-size: 2.5rem;
    text-align: left;
    margin-bottom: 20px;
    color: #333;
`;

export const Content = styled.div`
    font-size: 1rem;
    line-height: 1.6;
    color: black;

    h2 {
        font-size: 1.75rem;
        margin-top: 20px;
        color: #333;
    }

    h3 {
        font-size: 1.5rem;
        margin-top: 15px;
        color: #444;
    }

    p {
        margin: 10px 0;
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
        margin: 10px 0;
        
        li {
            margin: 5px 0;
        }
    }

    strong {
        color: #333;
    }
`;

export const SupportContact = styled.p`
    font-size: 1rem;
    color: #333;
    margin-top: 20px;
    text-align: center;

    a {
        color: #007bff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Input = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

export const TextArea = styled.textarea`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
`;

export const Button = styled.button`
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }
`;
