import React, { useState } from 'react';
import { Input, Button, Form } from 'antd';
import {
  CalculatorContainer,
  CalculatorTitle,
  FormItem,
  ResultContainer,
  ResultItem,
} from './Styled';
import { StyledButton } from '../../Lend/styled';

const MortgageNoteCalculator = () => {
  const [form] = Form.useForm();
  const [results, setResults] = useState({ presentValue: 0, futureValue: 0, yield: 0 });

  const onFinish = (values) => {
    // Implement the calculation logic here
    const presentValue = 0; // Replace with actual calculation
    const futureValue = 0;  // Replace with actual calculation
    const yieldValue = 0;   // Replace with actual calculation

    setResults({ presentValue, futureValue, yield: yieldValue });
  };

  return (
    <CalculatorContainer>
      <CalculatorTitle>Mortgage Note Calculator</CalculatorTitle>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem 
          label="Principal Amount"
          name="principal"
          rules={[{ required: true, message: 'Please input the principal amount!' }]}
        >
          <Input prefix="$" type="number" placeholder="Principal Amount" />
        </FormItem >
        <FormItem
          label="Interest Rate (%)"
          name="interestRate"
          rules={[{ required: true, message: 'Please input the interest rate!' }]}
        >
          <Input suffix="%" type="number" placeholder="Interest Rate" />
        </FormItem>
        <FormItem
          label="Term (years)"
          name="term"
          rules={[{ required: true, message: 'Please input the term!' }]}
        >
          <Input type="number" placeholder="Term" />
        </FormItem>
        <FormItem
          label="Monthly Payment"
          name="payment"
          rules={[{ required: true, message: 'Please input the monthly payment!' }]}
        >
          <Input prefix="$" type="number" placeholder="Monthly Payment" />
        </FormItem>
        <FormItem
          label="Purchase Price"
          name="purchasePrice"
          rules={[{ required: true, message: 'Please input the purchase price!' }]}
        >
          <Input prefix="$" type="number" placeholder="Purchase Price" />
        </FormItem>
        <FormItem>
          <StyledButton type="primary" htmlType="submit">
            Calculate
          </StyledButton>
        </FormItem>
      </Form>
      <ResultContainer>
        <ResultItem>
          <strong>Present Value:</strong> ${results.presentValue.toFixed(2)}
        </ResultItem>
        <ResultItem>
          <strong>Future Value:</strong> ${results.futureValue.toFixed(2)}
        </ResultItem>
        <ResultItem>
          <strong>Yield:</strong> {results.yield.toFixed(2)}%
        </ResultItem>
      </ResultContainer>
    </CalculatorContainer>
  );
};

export default MortgageNoteCalculator;
