import React from 'react';
import { Row, Col, Typography } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';
import { FooterContainer, Logo, SocialLink, StyledTitle, StyledParagraph, StyledLink } from './styled';
import investedLogo from '../../../../assets/images/logo.svg'

const { Paragraph } = Typography;

const FooterComponent = () => {
  return (
    <FooterContainer>
      <Row gutter={[32, 32]}>
        <Col xs={24} md={8}>
          <Logo src={investedLogo} alt="Logo" />
          <Paragraph style={{color:'white'}}>Discover the Power of Real Estate Notes with Invested.</Paragraph>
          <div>
            <SocialLink href="#"><FacebookOutlined /></SocialLink>
            <SocialLink href="#"><TwitterOutlined /></SocialLink>
            <SocialLink href="#"><InstagramOutlined /></SocialLink>
            <SocialLink href="#"><LinkedinOutlined /></SocialLink>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <StyledTitle level={4}>Quick Links</StyledTitle>
          <StyledParagraph><StyledLink href="#">Home</StyledLink></StyledParagraph>
          <StyledParagraph><StyledLink href="#">About Us</StyledLink></StyledParagraph>
          <StyledParagraph><StyledLink href="#">Services</StyledLink></StyledParagraph>
          <StyledParagraph><StyledLink href="#">Contact</StyledLink></StyledParagraph>
        </Col>
        <Col xs={24} md={8}>
          <StyledTitle level={4}>Contact Us</StyledTitle>
          <StyledParagraph>Dallas, Tx</StyledParagraph>
          <StyledParagraph>Email: info@invested.com</StyledParagraph>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '32px' }}>
        <Col>
          <StyledParagraph>© 2024 Invested. All rights reserved.</StyledParagraph>
        </Col>
      </Row>
    </FooterContainer>
  );
};

export default FooterComponent;