/* global google */
import { useEffect } from 'react';

function isPolygon(overlay) {
  return overlay.getPath !== undefined;
}

export const UndoRedoActionTypes = {
  UPDATE_OVERLAYS: 'UPDATE_OVERLAYS',
  SET_OVERLAY: 'SET_OVERLAY',
  UNDO: 'UNDO',
  REDO: 'REDO',
  DELETE: 'DELETE',
};
export default function reducer(state, action) {
  switch (action.type) {
    // This action is called whenever anything changes on any overlay.
    // We then take a snapshot of the relevant values of each overlay and
    // save them as the new "now". The old "now" is added to the "past" stack
    case UndoRedoActionTypes.UPDATE_OVERLAYS: {
      const overlays = state.now.map((overlay) => {
        const snapshot = {};
        const { geometry } = overlay;

        if (isPolygon(geometry)) {
          snapshot.path = geometry.getPath()?.getArray();
        }

        return {
          ...overlay,
          snapshot,
        };
      });

      return {
        now: [...overlays],
        past: [...state.past, state.now],
        future: [],
      };
    }

    // This action is called when a new overlay is added to the map.
    // We then take a snapshot of the relevant values of the new overlay and
    // add it to the "now" state. The old "now" is added to the "past" stack
    case UndoRedoActionTypes.SET_OVERLAY: {
      const { overlay } = action.payload;

      const snapshot = {};

      if (isPolygon(overlay)) {
        snapshot.path = overlay.getPath()?.getArray();
      }

      return {
        past: [...state.past, state.now],
        now: [
          ...state.now,
          {
            type: action.payload.type,
            geometry: action.payload.overlay,
            snapshot,
          },
        ],
        future: [],
      };
    }

    // This action is called when the undo button is clicked.
    // Get the top item from the "past" stack and set it as the new "now".
    // Add the old "now" to the "future" stack to enable redo functionality
    case UndoRedoActionTypes.UNDO: {
      const last = state.past.slice(-1)[0];

      if (!last) return state;

      return {
        past: [...state.past].slice(0, -1),
        now: last,
        future: state.now ? [...state.future, state.now] : state.future,
      };
    }

    // This action is called when the redo button is clicked.
    // Get the top item from the "future" stack and set it as the new "now".
    // Add the old "now" to the "past" stack to enable undo functionality
    case UndoRedoActionTypes.REDO: {
      const next = state.future.slice(-1)[0];

      if (!next) return state;

      return {
        past: state.now ? [...state.past, state.now] : state.past,
        now: next,
        future: [...state.future].slice(0, -1),
      };
    }

    case UndoRedoActionTypes.DELETE: {
      const now = state.now[0];

      if (!now) {
        return state;
      }

      return {
        past: [],
        now: [],
        future: [],
      };
    }
  }
}

// Handle drawing manager events
export function useDrawingManagerEvents(
  drawingManager,
  overlaysShouldUpdateRef,
  dispatch
) {
  useEffect(() => {
    if (!drawingManager) return;

    const eventListeners = [];

    const addUpdateListener = (eventName, drawResult) => {
      const updateListener = google.maps.event.addListener(
        drawResult.overlay,
        eventName,
        () => {
          if (eventName === 'dragstart') {
            overlaysShouldUpdateRef.current = false;
          }

          if (eventName === 'dragend') {
            overlaysShouldUpdateRef.current = true;
          }

          if (overlaysShouldUpdateRef.current) {
            dispatch({ type: UndoRedoActionTypes.UPDATE_OVERLAYS });
          }
        }
      );

      eventListeners.push(updateListener);
    };

    const overlayCompleteListener = google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (drawResult) => {
        switch (drawResult.type) {
          case google.maps.drawing.OverlayType.POLYGON: {
            ['mouseup'].forEach((eventName) => {
              addUpdateListener(eventName, drawResult);
              drawingManager.setDrawingMode(null);
              drawingManager.setOptions({ drawingControl: false });
            });
            break;
          }
        }

        dispatch({
          type: UndoRedoActionTypes.SET_OVERLAY,
          payload: drawResult,
        });
      }
    );

    eventListeners.push(overlayCompleteListener);

    return () => {
      eventListeners.forEach((listener) =>
        google.maps.event.removeListener(listener)
      );
    };
  }, [dispatch, drawingManager, overlaysShouldUpdateRef]);
}

// Update overlays with the current "snapshot" when the "now" state changes
export function useOverlaySnapshots(map, state, overlaysShouldUpdateRef) {
  useEffect(() => {
    if (!map || !state.now) return;

    for (const overlay of state.now) {
      overlaysShouldUpdateRef.current = false;

      overlay.geometry.setMap(map);
      const { path } = overlay.snapshot;

      if (isPolygon(overlay.geometry)) {
        overlay.geometry.setPath(path ?? []);
      }
      overlaysShouldUpdateRef.current = true;
    }

    return () => {
      for (const overlay of state.now) {
        overlay.geometry.setMap(null);
      }
    };
  }, [map, overlaysShouldUpdateRef, state.now]);
}
