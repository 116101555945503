import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Map, AdvancedMarker } from '@vis.gl/react-google-maps';

// Styles
import styles from './Details.module.scss';

// Constants
import { DETAILS_TABLE_COLUMNS } from '../../constants';
import { COLORS } from '../../constants/colors';

// Utils
import { formatNumberToDollars } from '../../utils/numberFormat';
import { getStreetViewImage } from '../../utils/getStreetView';

// Hooks
import useGetPropertyDetail from '../../hooks/useGetPropertyDetail';

// Components
import { Button, Col, Row, Flex, Skeleton, Card } from 'antd';
import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons';
import BaseTypography from '../../components/BaseTypography';
import Specifications from './Specifications';
import ListingGallery from './ListingGallery';
import {
  LogoDarkPlainSvg,
  LendSvg,
  PropertyDetailsSvg,
} from '../../components/Icons';
import { isMobile } from 'react-device-detect';
import { BaseButton } from '../../components/Button';
import ButtonsGroup from './ButtonsGroup';
import DescriptionDetails from './DescriptionDetails';
import { api } from '../../constants/api';

const HomeDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [streetViewImage, setStreetViewImage] = useState(null);
  const [terms, setTerms] = useState(null);
  const { propertyDetail, isLoading, hasError } = useGetPropertyDetail(id);
  const { propertyInfo, schools } = propertyDetail;
  const isSchools =
    schools &&
    Object.values(schools).filter((school) => school.length > 0).length > 0;

  const [showMoreSchools, setShowMoreSchools] = useState({
    preschool: false,
    elementary: false,
    middle: false,
    high: false,
  });

  const fetchData = useCallback(async () => {
    if (!propertyInfo) return;

    try {
      // Fetch terms
      const termsResponse = await fetch(api.buyers.getTermsByListingId(id));
      if (!termsResponse.ok) {
        throw new Error('Network response was not ok');
      }
      const termsData = await termsResponse.json();
      setTerms(termsData);

      // Fetch street view image
      const fullAddress = `${propertyInfo.address.address}, ${propertyInfo.address.city}, ${propertyInfo.address.state}`;
      const image = await getStreetViewImage({ address: fullAddress });
      setStreetViewImage(image);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [id, propertyInfo]);

  useEffect(() => {
    if (!isLoading && propertyInfo) {
      fetchData();
    }
  }, [isLoading, propertyInfo, fetchData]);

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleLendClick = () => {
    navigate(`/application/${id}`, {
      state: {
        propertyDetails: {
          address: propertyInfo.address.address,
        },
        loanTerms: terms,
      }
    });
  };

  const handleToggleViewMore = (type) => {
    setShowMoreSchools((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const renderSchools = (type) => {
    if (!schools[type] || schools[type].length === 0) return null;
    const schoolsToShow = schools[type].slice(0, 3); // Only show top 3 schools

    return (
      <div className={styles.schoolTypeContainer}>
        <BaseTypography align="left" className={styles.infoTitle} style={{ color: 'black' }}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </BaseTypography>
        {schoolsToShow.map((school, index) => (
          <BaseTypography key={index} align="left" className={styles.schoolName}>
            {school}
          </BaseTypography>
        ))}
      </div>
    );
  };

  if (loading || isLoading) {
    return <Skeleton active />;
  }

  if (hasError) {
    return <div>Error fetching property details.</div>;
  }

  return (
    <div >
      <div className={styles.content}>
        <div className={styles.navigation}>
          <Button
            onClick={handleClickBack}
            size="large"
            type="link"
            style={{ padding: 0 }}
            icon={<LeftOutlined />}
          >
            Back
          </Button>
        </div>

        <Row align="top" className={styles.titleContainer}>
          <Col xs={24} lg={12}>
            <BaseTypography
              className={styles.listingName}
              fontSize={isMobile ? '32px' : '40px'}
              align="left"
            >
              {propertyDetail.neighborhood || propertyDetail.type}
            </BaseTypography>
            <BaseTypography
              className={styles.listingAddress}
              fontSize={isMobile ? '16px' : '20px'}
              align="left"
              color={COLORS.$mainDark}
            >
              {propertyInfo.address.address}
            </BaseTypography>
          </Col>
          <Col xs={24} lg={12}>
            <ButtonsGroup />
          </Col>
        </Row>

        <ListingGallery streetViewImage={streetViewImage} />

        <Row className={styles.cardContainer} gutter={20}>
          <Col xs={24} lg={16}>
            <Specifications
              propertyType={propertyDetail.propertyType}
              price={formatNumberToDollars(propertyDetail.current_price)}
              neighborhood={propertyDetail.neighborhood}
            />
            {isMobile && <DescriptionDetails />}

            <Card style={{ marginBottom: '20px' }} title="General">
              <Flex gap={50}>
                <div className={styles.infoBlock}>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      HOA
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      None
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Acres
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyDetail.acres}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Type
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyDetail.lotInfo.landUse}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Laundry
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      In Unit
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Cooling
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.airConditioningType}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Heating
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.heatingType}
                    </BaseTypography>
                  </div>
                </div>

                <div className={styles.infoBlock}>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      City
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.address.city}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Year Built
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.yearBuilt ?? 'N/A'}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Size
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {`${propertyInfo.buildingSquareFeet} sqft`}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Lot Size
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {`${propertyInfo.lotSquareFeet} sqft`}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Parking Area
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.parkingArea ? 'Yes' : 'No'}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Garage Size
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {`${propertyInfo.garageSquareFeet ?? 0} sqft`}
                    </BaseTypography>
                  </div>
                </div>
              </Flex>
            </Card>

            {isSchools && (
              <Card title="Schools">
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={12}>
                    {renderSchools('preschool')}
                    {renderSchools('elementary')}
                  </Col>
                  <Col xs={24} sm={12}>
                    {renderSchools('middle')}
                    {renderSchools('high')}
                  </Col>
                </Row>
              </Card>
            )}

          </Col>
          <Col xs={24} lg={8}>

            {terms && (
              <Card title="Lending Terms" className={styles.termsCard}>
                <div className={styles.termsContainer}>
                  <BaseTypography align="left" className={styles.termsText}>
                    Lending Amount: {formatNumberToDollars(terms.lendingAmount)}
                  </BaseTypography>
                  <BaseTypography align="left" className={styles.termsText}>
                    Down Payment: {formatNumberToDollars(terms.downPayment)}
                  </BaseTypography>
                  <BaseTypography align="left" className={styles.termsText}>
                    Interest Rate: {terms.interestRate}%
                  </BaseTypography>
                  <BaseTypography align="left" className={styles.termsText}>
                    Loan Length: {terms.loanLength} years
                  </BaseTypography>
                  <BaseTypography align="left" className={styles.termsText}>
                    Payment Frequency: {terms.paymentFrequency}
                  </BaseTypography>
                  <BaseTypography align="left" className={styles.termsText}>
                    Late Payment Penalties: {terms.latePaymentPenalties}
                  </BaseTypography>
                </div>
              </Card>
            )}

            <div className={styles.investContainer}>
              <BaseTypography
                className={styles.listPrice}
                align="left"
                fontSize="14px"
              >
                List Price
              </BaseTypography>
              <span className={styles.listPriceValue}>
                {formatNumberToDollars(propertyDetail.current_price)}
              </span>

              <Button
                icon={
                  <Icon className={styles.lendIcon} component={LendSvg} />
                }
                className={styles.lendButton}
                type="primary"
                size="large"
                onClick={handleLendClick}
              >
                Request this property
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeDetails;
