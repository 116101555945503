import React from 'react';
import { Row, Col, Image, Spin } from 'antd';
import styles from './ListingGallery.module.scss';
import listingExample from '../../../assets/images/listing-grid-example.png';

const ListingGallery = ({ streetViewImage }) => {
  return (
    <div className={styles.galleryContainer}>
      <Row className={styles.gridContainer} gutter={18}>
        <Col xs={24}>
          <div className={styles.imageWrapper}>
            <Image
              className={styles.image}
              width="100%"
              height="100%"
              preview={false}
              src={streetViewImage || listingExample}
              placeholder={
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  minHeight: '300px', // Adjust this value as needed
                  background: '#f0f0f0' // Light grey background
                }}>
                  <Spin size="large" />
                </div>
              }
              fallback={listingExample} 
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ListingGallery;