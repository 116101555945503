import React, { useState, useEffect } from 'react';
import { Table, Button, Typography, Spin, Modal, Input, Select, Row, Col, Statistic, Dropdown, Menu, message } from 'antd';
import { SearchOutlined, MoreOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { api } from '../../../constants/api.js';

const { Title, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

// Styled Components
const Container = styled.div`
  padding: 16px;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledTable = styled(Table)`
  .ant-table-tbody > tr {
    cursor: pointer;
  }
`;

const SectionTitle = styled(Title)`
  margin-top: 24px !important;
  margin-bottom: 16px !important;
`;

const InfoRow = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const InfoLabel = styled(Text)`
  font-weight: bold;
  width: 150px;
`;

const InfoValue = styled(Text)`
  flex: 1;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 52px;
`;

const StatusFilterWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 35%;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const Approval = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [approvedEmails, setApprovedEmails] = useState([]);
  const [newApprovedEmail, setNewApprovedEmail] = useState('');
  const [isApprovedEmailModalVisible, setIsApprovedEmailModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState('user');
  const [approvedLendersCount, setApprovedLendersCount] = useState(0);
  const [approvedAgentsCount, setApprovedAgentsCount] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(api.user.getAllUsers);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);

        // Count approved lenders and agents
        const approvedLenders = data.filter(user => user.isApproved && user.role === 'lender').length;
        const approvedAgents = data.filter(user => user.isApproved && user.role === 'agent').length;
        setApprovedLendersCount(approvedLenders);
        setApprovedAgentsCount(approvedAgents);
      } catch (error) {
        console.error('Error fetching users:', error);
        message.error('Failed to fetch users');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchApprovedEmails = async () => {
      try {
        const response = await fetch(api.approvedEmails.getAll);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setApprovedEmails(data);
      } catch (error) {
        console.error('Error fetching approved emails:', error);
        message.error('Failed to fetch approved emails');
      }
    };

    fetchUsers();
    fetchApprovedEmails();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user =>
      (user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (statusFilter === 'All' || (statusFilter === 'Approved' ? user.isApproved : !user.isApproved))
    );
    setFilteredUsers(filtered);
  }, [searchTerm, statusFilter, users]);

  const showModal = (user) => {
    setSelectedUser(user);
    setSelectedRole(user.role || 'user');
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const handleQuickAction = async (action, user) => {
    if (action === 'approve') {
      showModal(user);
    } else if (action === 'deny') {
      confirm({
        title: 'Are you sure you want to deny this user?',
        icon: <ExclamationCircleOutlined />,
        content: 'User will lose access until you approve them again.',
        onOk() {
          performDenial(user);
        },
        onCancel() {
          message.info('User denial cancelled');
        },
      });
    }
  };

  const quickActionMenu = (user) => (
    <Menu>
      <Menu.Item key="1" onClick={() => showModal(user)}>Approve</Menu.Item>
      <Menu.Item key="2" onClick={() => handleQuickAction('deny', user)}>Deny</Menu.Item>
    </Menu>
  );

  const performDenial = async (user) => {
    try {
      const response = await fetch(api.user.approveUser, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user._id,
          isApproved: false,
          role: user.role // Keep the current role
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const updatedUser = await response.json();
      setUsers(users.map(u => u._id === updatedUser._id ? updatedUser : u));
      message.success('User denied successfully');

      // Close the modal if it's open
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error denying user:', error);
      message.error('Failed to deny user');
    }
  };

  const performApproval = async () => {
    try {
      const response = await fetch(api.user.approveUser, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: selectedUser._id,
          isApproved: true,
          role: selectedRole
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const updatedUser = await response.json();
      setUsers(users.map(u => u._id === updatedUser._id ? updatedUser : u));
      message.success('User approved successfully');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error approving user:', error);
      message.error('Failed to approve user');
    }
  };

  const handleApprove = async () => {
    if (selectedRole === 'admin') {
      confirm({
        title: 'Confirm Admin Role Assignment',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you absolutely sure you want to assign admin role to this user? This action gives full system access.',
        onOk() {
          performApproval();
        },
        onCancel() {
          message.info('Admin role assignment cancelled');
        },
      });
    } else {
      performApproval();
    }
  };

  const USERS_COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Approved',
      dataIndex: 'isApproved',
      key: 'isApproved',
      render: (isApproved) => isApproved ? 'Yes' : 'No',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={quickActionMenu(record)} trigger={['click']}>
          <Button icon={<MoreOutlined />} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  const renderDetailedInfo = () => {
    if (!selectedUser) return null;

    return (
      <>
        <InfoRow>
          <InfoLabel>Name:</InfoLabel>
          <InfoValue>{selectedUser.fullName}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Email:</InfoLabel>
          <InfoValue>{selectedUser.email}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Phone:</InfoLabel>
          <InfoValue>{selectedUser.phone}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Role:</InfoLabel>
          <InfoValue>{selectedUser.role}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Approved:</InfoLabel>
          <InfoValue>{selectedUser.isApproved ? 'Yes' : 'No'}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Created At:</InfoLabel>
          <InfoValue>{new Date(selectedUser.createdAt).toLocaleString()}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>New Role:</InfoLabel>
          <InfoValue>
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              style={{ width: 200 }}
            >
              <Option value="agent">Agent</Option>
              <Option value="lender">Lender</Option>
              <Option value="admin">Admin</Option>
            </Select>
          </InfoValue>
        </InfoRow>
      </>
    );
  };

  const handleRoleChange = (value) => {
    if (value === 'admin') {
      confirm({
        title: 'Are you sure you want to set this user as an admin?',
        icon: <ExclamationCircleOutlined />,
        content: 'This will give the user full access to all system features.',
        onOk() {
          setSelectedRole(value);
        },
        onCancel() {
          // If cancelled, revert to the previous role
          setSelectedRole(selectedUser.role || 'agent');
        },
      });
    } else {
      setSelectedRole(value);
    }
  };

  const handleAddApprovedEmail = async () => {
    try {
      const response = await fetch(api.approvedEmails.add, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newApprovedEmail }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const addedEmail = await response.json();
      setApprovedEmails([...approvedEmails, addedEmail]);
      setNewApprovedEmail('');
      message.success('Approved email added successfully');
    } catch (error) {
      console.error('Error adding approved email:', error);
      message.error('Failed to add approved email');
    }
  };

  const handleRemoveApprovedEmail = async (email) => {
    try {
      const response = await fetch(`${api.approvedEmails.remove}/${email}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setApprovedEmails(approvedEmails.filter(e => e.email !== email));
      message.success('Approved email removed successfully');
    } catch (error) {
      console.error('Error removing approved email:', error);
      message.error('Failed to remove approved email');
    }
  };

  const totalUsers = users.length;
  const approvedUsers = users.filter(u => u.isApproved).length;

  return (
    <Container>
      <Row style={{ marginBottom: '20px'}} gutter={[16, 16]} align="middle" justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Title style={{ marginBottom: 0 }} level={3}>User Management</Title>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsApprovedEmailModalVisible(true)}
          >
            Approved Email
          </Button>
        </Col>
      </Row>
      <Card>
        <Row gutter={16}>
          <Col span={8}>
            <Statistic title="Total Users" value={users.length} />
          </Col>
          <Col span={8}>
            <Statistic title="Approved Lenders" value={approvedLendersCount} />
          </Col>
          <Col span={8}>
            <Statistic title="Approved Agents" value={approvedAgentsCount} />
          </Col>
        </Row>
      </Card>
      <Card>
        <FilterContainer>
          <SearchWrapper>
            <StyledInput
              placeholder="Search users"
              prefix={<SearchOutlined />}
              onChange={handleSearch}
            />
          </SearchWrapper>
          <StatusFilterWrapper>
            <StyledSelect
              placeholder="Filter by status"
              onChange={handleStatusFilter}
              defaultValue="All"
            >
              <Option value="All">All Status</Option>
              <Option value="Approved">Approved</Option>
              <Option value="Not Approved">Not Approved</Option>
            </StyledSelect>
          </StatusFilterWrapper>
        </FilterContainer>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <StyledTable
            columns={USERS_COLUMNS}
            dataSource={filteredUsers}
            pagination={{ pageSize: 10 }}
            onRow={(record) => ({
              onClick: () => showModal(record),
            })}
          />
        )}
      </Card>

      <Modal
        title="User Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button key="approve" type="primary" onClick={handleApprove}>
            Approve
          </Button>
        ]}
        width={800}
      >
        {renderDetailedInfo()}
      </Modal>

      <Modal
        title="Manage Approved Emails"
        visible={isApprovedEmailModalVisible}
        onCancel={() => setIsApprovedEmailModalVisible(false)}
        footer={null}
      >
        <Input
          placeholder="Enter email to approve"
          value={newApprovedEmail}
          onChange={(e) => setNewApprovedEmail(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Button type="primary" onClick={handleAddApprovedEmail}>Add Approved Email</Button>
        <Table
          dataSource={approvedEmails}
          columns={[
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => (
                <Button onClick={() => handleRemoveApprovedEmail(record.email)} type="link">
                  Remove
                </Button>
              ),
            },
          ]}
          pagination={false}
          style={{ marginTop: '20px' }}
        />
      </Modal>
    </Container>
  );
};

export default Approval;