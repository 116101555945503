import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { isMobile, isDesktop } from 'react-device-detect';
import { Link } from 'react-router-dom';

// Constants
import { DEFAULT_FILTERS, ACTION_OPTIONS } from './utils';
import {
  BEDROOM_SELECT_OPTIONS,
  COLUMNS,
  HOUSING_PRICE_TYPE_OPTIONS,
  HOUSING_SELECT_OPTIONS,
  PRICE_SELECT_OPTIONS,
  VIEW_OPTION,
  VIEW_OPTIONS,
} from '../../constants';

// Hooks
import useGetPropertySearch from '../../hooks/useGetPropertySearch';

// Components
import MoreFiltersDrawer from './MarketSearchMap/MarketSearchDrawer/MoreFiltersDrawer';
import { ROUTE_PATH } from '../../constants/routePath';
import BaseTypography from '../../components/BaseTypography';
import { BaseInput } from '../../components/Input';
import { BaseButton, OutlinedButton } from '../../components/Button';
import {
  Button,
  Col,
  Flex,
  Input,
  Row,
  Segmented,
  Table,
  Modal,
  InputNumber,
} from 'antd';
import { FiltersDrawerSvg, MapPicSvg, SearchIconSvg } from '../../components/Icons';
import SelectComponent from '../../components/SelectComponent';
import Icon, { PlusOutlined, RightOutlined } from '@ant-design/icons';
import MarketSearchGrid from './MarketSearchGrid';
import DropdownComponent from '../../components/DropdownComponent';
import MarketSearchMap from './MarketSearchMap';

// Styles
import styles from './MarketSearch.module.scss';

const MarketSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Separate view state
  const [view, setView] = useState(searchParams.get('view') || VIEW_OPTION.table);

  // Memoize API params, excluding 'view'
  const apiParams = useMemo(() => {
    const params = Object.fromEntries(searchParams);
    const { view: _, ...rest } = params;
    return rest;
  }, [searchParams]);

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMapDrawerOpen, setIsMapDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const drawerContainerRef = useRef(null);

  // Use apiParams for API call
  const { properties, isLoading, hasError } = useGetPropertySearch(apiParams);

  // Update URL without triggering API call
  const updateUrlWithView = useCallback((newView) => {
    navigate(`?${new URLSearchParams({ ...apiParams, view: newView })}`, { replace: true });
  }, [navigate, apiParams]);

  // Handle view change
  const handleViewChange = useCallback((newView) => {
    setView(newView);
    updateUrlWithView(newView);
  }, [updateUrlWithView]);

  // Update view when URL changes, without affecting API params
  useEffect(() => {
    const urlView = searchParams.get('view');
    if (urlView && VIEW_OPTIONS.includes(urlView) && urlView !== view) {
      setView(urlView);
    }
  }, [searchParams, view]);

  const currentHighRange = useMemo(() => {
    return properties.length
      ? properties.reduce((max, property) => Math.max(max, property.current_price), 0)
      : 5000;
  }, [properties]);

  const filteredProperties = useMemo(() => {
    if (!isFiltersActive) return properties;
    return properties.filter((property) => {
      const meetsRangeFilter =
        property.current_price <= filters.range.high &&
        property.current_price >= filters.range.low;
      const meetsBedFilter = property.bedrooms >= filters.bedroom;
      const meetsBathFilter = property.bathrooms >= filters.bathroom;
      return meetsRangeFilter && meetsBedFilter && meetsBathFilter;
    });
  }, [isFiltersActive, properties, filters]);

  const filtersCount = useMemo(() => {
    return isFiltersActive
      ? `(${Object.values(filters).filter((value) => value).length})`
      : '';
  }, [isFiltersActive, filters]);

  const handleClickFiltersOpen = useCallback(() => {
    setIsFiltersDrawerOpen(true);
  }, []);

  const handleClickFilterClose = useCallback(() => {
    setIsFiltersDrawerOpen(false);
  }, []);

  const handleClickMapDrawerOpen = useCallback(() => {
    setIsMapDrawerOpen(true);
  }, []);

  const handleClickMapDrawerClose = useCallback(() => {
    setIsMapDrawerOpen(false);
  }, []);

  const handleClickRow = useCallback((property) => {
    navigate(`${ROUTE_PATH.DETAILS}/${property.id}`, { state: { property } });
  }, [navigate]);

  const handleRowSelection = useCallback((selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  }, []);

  const handleModalOpen = useCallback((event) => {
    event.preventDefault();
    setIsModalOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleSaveSearch = useCallback(() => {
    const savedSearches =
      JSON.parse(localStorage.getItem('INVESTED_SAVED_SEARCHES')) ?? [];
    const saveSearchObject = {
      query: apiParams,
      date: new Date(),
      searchName,
    };

    const newSavedSearches = [...savedSearches, saveSearchObject];

    localStorage.setItem(
      'INVESTED_SAVED_SEARCHES',
      JSON.stringify(newSavedSearches)
    );

    setIsModalOpen(false);
  }, [apiParams, searchName]);

  const handleChangeSearch = useCallback((event) => {
    const { value } = event.target;
    setSearchName(value);
  }, []);

  return (
    <div className={styles.container} ref={drawerContainerRef}>
      {isDesktop && (
        <Row className={styles.searchContainer} gutter={12}>
          <Col xs={12} md={16} lg={17} xl={18} xxl={20}>
            {view !== VIEW_OPTION.map && (
              <Input
                width={'auto'}
                size="large"
                style={{ marginBottom: '10px!important' }}
                placeholder="Enter Shorthand or MLS#"
                prefix={<Icon component={SearchIconSvg} />}
              />
            )}
            {view === VIEW_OPTION.map && (
              <Row align="center" justify="start" gutter={10}>
                <Col xs={24} xl={6} xxl={8}>
                  <Input
                    size="large"
                    placeholder="Search..."
                    prefix={<Icon component={SearchIconSvg} />}
                    className={styles.input}
                  />
                </Col>
                <Col xs={24} xl={18} xxl={16}>
                  <Flex
                    justify="center"
                    align="center"
                    gap={10}
                    className={styles.selectsWrapper}
                  >
                    <SelectComponent
                      options={PRICE_SELECT_OPTIONS}
                      placeholder="Select one"
                      value="anyPrice"
                      size="large"
                    />
                    <SelectComponent
                      options={BEDROOM_SELECT_OPTIONS}
                      placeholder="Select one"
                      value="2to4"
                      size="large"
                    />
                    <SelectComponent
                      options={HOUSING_SELECT_OPTIONS}
                      placeholder="Select one"
                      value="allTypes"
                      size="large"
                    />
                    <SelectComponent
                      options={HOUSING_PRICE_TYPE_OPTIONS}
                      placeholder="Select one"
                      value="newest"
                      size="large"
                    />
                  </Flex>
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12} md={8} lg={7} xl={6} xxl={4}>
            <Flex justify="center" gap={10} className={styles.filters}>
              <Segmented
                value={view}
                options={VIEW_OPTIONS}
                onChange={handleViewChange}
                block
              />
              <Button
                className={styles.filtersButton}
                onClick={
                  view === VIEW_OPTION.map
                    ? handleClickMapDrawerOpen
                    : handleClickFiltersOpen
                }
                icon={<PlusOutlined />}
              >
                Filters {filtersCount}
              </Button>
            </Flex>
          </Col>
        </Row>
      )}

      {isMobile && (
        <>
          <Flex
            justify="space-between"
            align="center"
            className={styles.header}
          >
            <div>
              <span className={styles.grayNav}>
                <Link to={ROUTE_PATH.DASHBOARD} className={styles.link}>
                  Home <RightOutlined className={styles.icon} />
                </Link>
              </span>
              <span className={styles.blackNav}>Search</span>
            </div>

            {isMobile && (
              <>
                <Flex className={`${styles.moreFiltersCategories} ${styles.segmentedContainer}`} gap={15}>
                  <Segmented
                    value={view}
                    options={VIEW_OPTIONS}
                    onChange={handleViewChange}
                    block
                  />
                </Flex>
              </>
            )}

            <div>
              <OutlinedButton
                className={styles.btnFilters}
                onClick={handleClickFiltersOpen}
              >
                <Icon component={FiltersDrawerSvg} />
              </OutlinedButton>
            </div>
          </Flex>

          {view === VIEW_OPTION.table && (
            <Flex
              justify="space-between"
              align="center"
              gap={12}
              className={styles.inputWrapper}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder="Any Price"
                type="number"
              />
              <InputNumber
                className={styles.inputNumber}
                placeholder="2-4 Beds"
                type="number"
              />
              <InputNumber
                className={styles.inputNumber}
                placeholder="All Types"
                type="number"
              />
            </Flex>
          )}
        </>
      )}
      <div className={styles.searchContent}>
        {view === VIEW_OPTION.grid && (
          <>
            <MarketSearchGrid properties={filteredProperties} />
            {isMobile && (
              <div className={styles.btnWrapper}>
                <BaseButton className={styles.btn}>
                  <Icon component={MapPicSvg} /> Open Maps
                </BaseButton>
              </div>
            )}
          </>
        )}

        {view === VIEW_OPTION.table && (
          <div>
            <Table
              className={styles.searchTable}
              style={{ backgroundColor: 'white' }}
              pagination={{
                total: filteredProperties.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
              }}
              size="large"
              rowKey={(record) => record.id}
              columns={COLUMNS}
              dataSource={filteredProperties}
              loading={isLoading}
              rowClassName={styles.row}
              onRow={(record) => {
                return { onClick: () => handleClickRow(record) };
              }}
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) =>
                  handleRowSelection(selectedRowKeys, selectedRows),
              }}
            />
          </div>
        )}

        {view === VIEW_OPTION.map && (
          <MarketSearchMap
            drawerContainerRef={drawerContainerRef}
            properties={filteredProperties}
            setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
            isMapDrawerOpen={isMapDrawerOpen}
            params={apiParams}
            handleClickMapDrawerClose={handleClickMapDrawerClose}
          />
        )}

        {view === VIEW_OPTION.table && (
          <Flex justify="space-between" gap={10} className={styles.bottom}>
            <DropdownComponent
              className={styles.actions}
              items={ACTION_OPTIONS}
              placement="top"
            >
              Actions
            </DropdownComponent>
            <BaseButton onClick={handleModalOpen}>Save Search</BaseButton>
          </Flex>
        )}
      </div>

      <Modal
        okText="Save"
        onCancel={handleCancel}
        onOk={handleSaveSearch}
        open={isModalOpen}
        title="Save Search"
        okButtonProps={{ disabled: !searchName }}
      >
        <div>
          <BaseTypography align="left">Search Name</BaseTypography>
          <BaseInput
            onChange={handleChangeSearch}
            value={searchName}
            placeholder="Enter search name"
          />
        </div>
      </Modal>

      <MoreFiltersDrawer
        handleClickFilterClose={handleClickFilterClose}
        isFilterOpen={isFiltersDrawerOpen}
        container={drawerContainerRef.current}
        setFilters={setFilters}
        currentHighRange={currentHighRange}
        setIsFiltersActive={setIsFiltersActive}
        searchOption={view}
        VIEW_OPTIONS={VIEW_OPTIONS}
        handleChange={handleViewChange}
      />
    </div>
  );
};

export default MarketSearch;