import styled from 'styled-components';
import { COLORS } from "../../../../constants/colors";

export const StyledContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};
  gap: ${props => props.gap || '10px'};
  align-items: ${props => props.alignItems || 'stretch'};
  margin-bottom: 20px;
`;

export const StyledInputGroup = styled.div`
  margin-bottom: 20px;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${COLORS.$greyScale700};
`;

export const StyledRegister = styled.div`
  margin-top: 30px;
  text-align: center;
`;