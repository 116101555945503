import { StyledInput } from './styled';
import { Label } from '../Label';
import { Fragment } from 'react';
import { Password } from './components/Password';

export const BaseInput = ({ label, ...props }) => {
  return (
    <Fragment>
      <Label text={label} />
      <StyledInput {...props} />
    </Fragment>
  );
};

BaseInput.Password = Password;
