import React from 'react';

// Components
import { Row, Col, Flex, Button } from 'antd';
import BaseTypography from '../../../components/BaseTypography';
import { QuestionCircleOutlined } from '@ant-design/icons';

// Styles
import styles from './PropertyOwner.module.scss';
import { isMobile } from 'react-device-detect';


const PropertyOwner = ({ owner, contact }) => {
  return (
    <div className={styles.container}>
      <Row className={styles.titleContainer}>
        <Col span={24}>
          <BaseTypography className={styles.title} align="left" fontSize="16px">
            Agent/Office Information
          </BaseTypography>
        </Col>
      </Row>
      <Flex justify="space-between" align={isMobile? '' : "center"} vertical={isMobile} gap={isMobile? 20 : 0 }>
        <div>
          <BaseTypography className={styles.owner} align="left" fontSize="16px">
            {owner}
          </BaseTypography>

          <BaseTypography
            className={styles.contact}
            align="left"
            fontSize="14px"
          >
            {contact}
          </BaseTypography>
        </div>
        <Button type="secondary" size="large" icon={<QuestionCircleOutlined />} className={styles.btn} >
          Get more info
        </Button>
      </Flex>
    </div>
  );
};

export default PropertyOwner;
