import React from 'react';
import { ApplicationWrapper, Title, Content } from './styled';

const TermsAndSafety = () => {
    return (
        <ApplicationWrapper>
            <Title>Trust & Safety</Title>
            <Content>
                <h2>Introduction</h2>
                <p>
                    At Invested, we prioritize the safety and trust of our buyers. Our platform is designed to ensure that every connection between buyers and lenders is secure, transparent, and trustworthy. We understand the significance of financing a property purchase and are committed to providing a safe environment for all our users.
                </p>

                <h2>How Invested Ensures Trust & Safety</h2>
                <h3>Verification Process</h3>
                <p>
                    We have implemented a thorough verification process for all lenders and properties listed on Invested. Our verification process includes:
                </p>
                <ul>
                    <li><strong>Identity Verification:</strong> All lenders must provide valid identification and undergo a rigorous verification process.</li>
                    <li><strong>Property Verification:</strong> We ensure that all properties listed on our platform are legitimate and accurately represented by the lenders.</li>
                    <li><strong>Background Checks:</strong> Lenders undergo background checks to ensure they have a trustworthy history.</li>
                </ul>

                <h3>Secure Transactions</h3>
                <p>
                    Invested uses secure communication channels to facilitate interactions between buyers and lenders. Our platform ensures that all sensitive information is protected and only shared with verified parties.
                </p>

                <h3>Transparent Listings</h3>
                <p>
                    We believe in transparency and provide detailed information about each property and lending opportunity. Our listings include:
                </p>
                <ul>
                    <li><strong>Comprehensive Property Details:</strong> Buyers can access all relevant information about a property, including its history, condition, and any legal considerations.</li>
                    <li><strong>Clear Financing Information:</strong> All costs and terms associated with the financing are clearly outlined, ensuring there are no hidden fees.</li>
                    <li><strong>Lender Reviews:</strong> Buyers can read reviews and ratings of lenders to make informed decisions.</li>
                </ul>

                <h2>Buyer&apos;s Responsibilities</h2>
                <h3>Conducting Due Diligence</h3>
                <p>
                    While Invested strives to ensure the accuracy of all listings, it is crucial for buyers to conduct their own due diligence. This includes:
                </p>
                <ul>
                    <li><strong>Researching the Property:</strong> Verify all information provided in the listing and consider hiring a professional inspector.</li>
                    <li><strong>Reviewing Legal Documents:</strong> Ensure all legal documents are in order and consult with a legal professional if necessary.</li>
                    <li><strong>Assessing Financials:</strong> Carefully review the financial aspects of the transaction and ensure you understand the terms offered by the lender.</li>
                </ul>

                <h3>Communicating with Lenders</h3>
                <p>
                    Effective communication with lenders is essential. Buyers should:
                </p>
                <ul>
                    <li><strong>Ask Questions:</strong> Don&apos;t hesitate to ask lenders for additional information or clarification on any aspect of the property or financing terms.</li>
                    <li><strong>Schedule Meetings:</strong> Arrange meetings with lenders to discuss terms and ensure mutual understanding.</li>
                    <li><strong>Negotiate Terms:</strong> Communicate openly with lenders to negotiate terms that are favorable and clear.</li>
                </ul>

                <h2>Dispute Resolution</h2>
                <h3>Process for Addressing Issues</h3>
                <p>
                    In the event of a dispute, Invested provides a structured process for resolution:
                </p>
                <ul>
                    <li><strong>Report the Issue:</strong> Contact our support team to report any issues or concerns.</li>
                    <li><strong>Investigation:</strong> We will investigate the matter thoroughly and impartially.</li>
                    <li><strong>Resolution:</strong> Our team will work with both parties to reach a fair and satisfactory resolution.</li>
                </ul>

                <h3>Mediation Services</h3>
                <p>
                    For more complex disputes, Invested offers mediation services to help both parties reach an agreement. Our professional mediators are experienced in resolving conflicts and ensuring fair outcomes.
                </p>

                <h2>Privacy and Data Security</h2>
                <h3>Protection of Personal Information</h3>
                <p>
                    Invested is committed to protecting your personal information. We adhere to strict privacy policies and use advanced security measures to safeguard your data.
                </p>

                <h3>Data Encryption</h3>
                <p>
                    All data transmitted on our platform is encrypted using industry-standard encryption protocols. This ensures that your information remains secure and confidential.
                </p>

                <h2>Frequently Asked Questions (FAQs)</h2>
                <h3>How do I verify the legitimacy of a property listing?</h3>
                <p>
                    Invested provides comprehensive information and verification for each property. Additionally, we encourage buyers to conduct their own due diligence.
                </p>

                <h3>What should I do if I encounter a fraudulent lender?</h3>
                <p>
                    Report the lender to our support team immediately. We will investigate and take appropriate action.
                </p>

                <h3>How are disputes handled on Invested?</h3>
                <p>
                    Disputes are addressed through our structured resolution process, which includes reporting, investigation, and resolution steps.
                </p>

                <h2>Contact Information</h2>
                <p>
                    If you have any questions or concerns about trust and safety on Invested, please contact our support team at [support email] or call us at [support phone number]. We are here to help you and ensure a safe and secure experience on our platform.
                </p>
            </Content>
        </ApplicationWrapper>
    );
};

export default TermsAndSafety;
