// src/pages/Investors.js
import React, { useState } from 'react';
import { ApplicationWrapper, Title, Content, Form, Input, TextArea, Button } from './styled';

const Investors = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form submitted:', formData);
        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <ApplicationWrapper>
            <Title>Investors</Title>
            <Content>
                <h2>Welcome Investors</h2>
                <p>
                    At Invested, we provide a unique opportunity for investors to connect with potential property buyers. Our platform simplifies the process, making it easy for investors to find and evaluate investment opportunities in the real estate market.
                </p>

                <h2>Why Invest with Us?</h2>
                <ul>
                    <li><strong>Access to Qualified Buyers:</strong> Connect with a wide range of buyers who are actively seeking financing for their property purchases.</li>
                    <li><strong>Transparent Listings:</strong> Detailed property and buyer information to help you make informed investment decisions.</li>
                    <li><strong>Facilitated Connections:</strong> Our agents assist in facilitating connections and negotiations between you and potential buyers.</li>
                    <li><strong>Secure Platform:</strong> Robust security measures to protect your information and investment transactions.</li>
                    <li><strong>Supportive Team:</strong> Our dedicated support team is here to assist you throughout the investment process.</li>
                </ul>

                <h2>How to Get Started</h2>
                <h3>1. Sign Up</h3>
                <p>
                    Create an account on Invested by providing your basic information. As an investor, you’ll gain access to our platform’s features designed to help you find the best investment opportunities.
                </p>

                <h3>2. Complete Your Profile</h3>
                <p>
                    Provide detailed information about your investment preferences and criteria. This helps us match you with suitable buyers and properties.
                </p>

                <h3>3. Browse Listings</h3>
                <p>
                    Explore property listings and review detailed information about potential buyers. Use our filters to find opportunities that meet your investment goals.
                </p>

                <h3>4. Express Interest</h3>
                <p>
                    When you find a promising opportunity, express your interest through the platform. This notifies the buyer and our agents, who will facilitate the connection.
                </p>

                <h3>5. Engage with Buyers</h3>
                <p>
                    Our agents will help coordinate communication and negotiations between you and the buyer, ensuring that you have all the information needed to proceed with confidence.
                </p>

                <h3>6. Finalize the Investment</h3>
                <p>
                    Once you and the buyer agree on the terms, finalize the investment. Invested does not handle the financial transactions directly but supports both parties through our agents.
                </p>

                <h2>Investor Resources</h2>
                <p>
                    We offer a range of resources to support our investors, including market analysis, investment guides, and customer support. Visit our <a href="/contact">Contact</a> page to learn more.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions or need assistance, please contact our support team at [support email] or call us at [support phone number]. We’re here to help you make the most of your investment opportunities.
                </p>

                <h2>Send Us a Message</h2>
                <Form onSubmit={handleSubmit}>
                    <label htmlFor="name">Name</label>
                    <Input 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <label htmlFor="email">Email</label>
                    <Input 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <label htmlFor="subject">Subject</label>
                    <Input 
                        type="text" 
                        id="subject" 
                        name="subject" 
                        value={formData.subject} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <label htmlFor="message">Message</label>
                    <TextArea 
                        id="message" 
                        name="message" 
                        value={formData.message} 
                        onChange={handleChange} 
                        required 
                    />
                    
                    <Button type="submit">Submit</Button>
                </Form>
            </Content>
        </ApplicationWrapper>
    );
};

export default Investors;
