import React, { useState } from 'react';
import { Input, Button, Form } from 'antd';
import {
  CalculatorContainer,
  FormItem,
  ResultContainer,
  ResultItem
} from './Styled';
import { StyledButton } from '../../Lend/styled';

const YieldCalculator = () => {
  const [form] = Form.useForm();
  const [yieldResult, setYieldResult] = useState(0);

  const onFinish = (values) => {
    const { purchasePrice, annualIncome } = values;

    // Calculate Yield
    const yieldValue = (annualIncome / purchasePrice) * 100;

    setYieldResult(yieldValue);
  };

  return (
    <CalculatorContainer>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label="Purchase Price"
          name="purchasePrice"
          rules={[{ required: true, message: 'Please input the purchase price!' }]}
        >
          <Input prefix="$" type="number" placeholder="Purchase Price" />
        </FormItem>
        <FormItem
          label="Annual Income"
          name="annualIncome"
          rules={[{ required: true, message: 'Please input the annual income!' }]}
        >
          <Input prefix="$" type="number" placeholder="Annual Income" />
        </FormItem>
        <FormItem>
          <StyledButton type="primary" htmlType="submit">
            Calculate
          </StyledButton>
        </FormItem>
      </Form>
      <ResultContainer>
        <ResultItem>
          <strong>Yield:</strong> {yieldResult.toFixed(2)}%
        </ResultItem>
      </ResultContainer>
    </CalculatorContainer>
  );
};

export default YieldCalculator;
