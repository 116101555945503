import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Select, Steps, message, Modal } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { api } from '../../../constants/api';
import {
    ApplicationWrapper,
    Title,
    PropertyInfo,
    PropertyTitle,
    LoanTerms,
    StyledSteps,
    StyledForm,
    StyledInput,
    StyledSelect,
    StyledDatePicker,
    ButtonGroup,
    StyledButton,
    StepTitle,
    SummarySection,
    SummaryTitle,
    SummaryItem,
    SummaryLabel,
    SummaryValue,
    StyledCard
} from './styled';

const { Step } = Steps;
const { Option } = Select;

const PropertyLoanApplication = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { propertyDetails, loanTerms } = location.state || {};
    const [form] = Form.useForm();

    const formatNumberWithCommas = (value) => {
        if (value == null) return '';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const steps = [
        {
            title: 'Personal',
            content: (
                <StyledCard>
                    <StepTitle>Personal Details</StepTitle>
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <StyledSelect>
                            <Option value="mr">Mr</Option>
                            <Option value="mrs">Mrs</Option>
                            <Option value="ms">Ms</Option>
                        </StyledSelect>
                    </Form.Item>
                    <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
                        <StyledInput />
                    </Form.Item>
                    <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
                        <StyledInput />
                    </Form.Item>
                    <Form.Item name="dateOfBirth" label="Date of Birth" rules={[{ required: true }]}>
                        <StyledDatePicker
                            format="MM/DD/YYYY"
                            disabledDate={(current) => {
                                return current && current > moment().endOf('day');
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                        <StyledInput />
                    </Form.Item>
                    <Form.Item name="phone" label="Phone Number" rules={[{ required: true }]}>
                        <StyledInput />
                    </Form.Item>
                </StyledCard>
            ),
        },
        {
            title: 'Employment',
            content: (
                <StyledCard>
                    <StepTitle>Employment Information</StepTitle>
                    <Form.Item name="employmentStatus" label="Employment Status" rules={[{ required: true }]}>
                        <StyledSelect>
                            <Option value="employed">Employed</Option>
                            <Option value="self-employed">Self-Employed</Option>
                            <Option value="unemployed">Unemployed</Option>
                        </StyledSelect>
                    </Form.Item>
                    <Form.Item name="annualIncome" label="Annual Income" rules={[{ required: true }]}>
                        <StyledInput
                            prefix="$"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </StyledCard>
            ),
        },
        {
            title: 'Financials',
            content: (
                <StyledCard>
                    <StepTitle>Financials</StepTitle>
                    <Form.Item name="downPayment" label="Down Payment Amount" rules={[{ required: true }]}>
                        <StyledInput
                            prefix="$"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </StyledCard>
            ),
        },
        {
            title: 'Review',
            content: (
                <StyledCard>
                    <StepTitle>Review and Submit</StepTitle>
                    <p style={{marginBottom: '20px'}}>Please review all the information you&apos;ve provided. Make sure everything is correct before submitting your application.</p>
                </StyledCard>
            ),
        },
    ];

    const renderReviewContent = () => {
        const formData = form.getFieldsValue(true);
        return (
            <>
                <SummarySection>
                    <SummaryTitle>Personal Details</SummaryTitle>
                    <SummaryItem>
                        <SummaryLabel>Title:</SummaryLabel>
                        <SummaryValue>{formData.title}</SummaryValue>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryLabel>First Name:</SummaryLabel>
                        <SummaryValue>{formData.firstName}</SummaryValue>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryLabel>Last Name:</SummaryLabel>
                        <SummaryValue>{formData.lastName}</SummaryValue>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryLabel>Date of Birth:</SummaryLabel>
                        <SummaryValue>{formData.dateOfBirth ? formData.dateOfBirth.format('MM/DD/YYYY') : ''}</SummaryValue>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryLabel>Email:</SummaryLabel>
                        <SummaryValue>{formData.email}</SummaryValue>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryLabel>Phone:</SummaryLabel>
                        <SummaryValue>{formData.phone}</SummaryValue>
                    </SummaryItem>
                </SummarySection>

                <SummarySection>
                    <SummaryTitle>Employment Information</SummaryTitle>
                    <SummaryItem>
                        <SummaryLabel>Employment Status:</SummaryLabel>
                        <SummaryValue>{formData.employmentStatus}</SummaryValue>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryLabel>Annual Income:</SummaryLabel>
                        <SummaryValue>${formatNumberWithCommas(formData.annualIncome)}</SummaryValue>
                    </SummaryItem>
                </SummarySection>

                <SummarySection>
                    <SummaryTitle>Financial Information</SummaryTitle>
                    <SummaryItem>
                        <SummaryLabel>Down Payment Amount:</SummaryLabel>
                        <SummaryValue>${formatNumberWithCommas(formData.downPayment)}</SummaryValue>
                    </SummaryItem>
                </SummarySection>
            </>
        );
    };

    const nextStep = () => {
        form.validateFields().then(() => {
            setCurrentStep(currentStep + 1);
        }).catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = () => {
        form.validateFields().then(() => {
            setIsSubmitting(true);
            const currentFormValues = form.getFieldsValue(true);
            const applicationData = {
                propertyDetails: propertyDetails,
                loanTerms: loanTerms,
                personalDetails: {
                    title: currentFormValues.title,
                    firstName: currentFormValues.firstName,
                    lastName: currentFormValues.lastName,
                    dateOfBirth: currentFormValues.dateOfBirth,
                    email: currentFormValues.email,
                    phone: currentFormValues.phone,
                },
                employmentInfo: {
                    employmentStatus: currentFormValues.employmentStatus,
                    annualIncome: currentFormValues.annualIncome,
                },
                financialInfo: {
                    downPayment: currentFormValues.downPayment,
                },
            };
    
            console.log('Application data to be submitted:', applicationData);
    
            axios.post(api.application.submit, applicationData)
                .then(response => {
                    console.log('Application submitted:', response.data);
                    setIsModalVisible(true);
                })
                .catch(error => {
                    console.error('Error submitting application:', error);
                    message.error('Failed to submit application. Please try again.');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }).catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
        // Redirect to home page or a thank you page
        navigate('/');
    };

    return (
        <ApplicationWrapper>
            <Title>Property Application</Title>
            <PropertyInfo>
                <PropertyTitle>Property: {propertyDetails?.address}</PropertyTitle>
                <LoanTerms>Loan Amount: ${loanTerms?.lendingAmount}</LoanTerms>
                <LoanTerms>Interest Rate: {loanTerms?.interestRate}%</LoanTerms>
                <LoanTerms>Term: {loanTerms?.loanLength} years</LoanTerms>
            </PropertyInfo>

            <StyledSteps current={currentStep}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </StyledSteps>

            <StyledForm form={form} layout="vertical">
                {currentStep === steps.length - 1 ? renderReviewContent() : steps[currentStep].content}

                <ButtonGroup isFirstStep={currentStep === 0}>
                    {currentStep > 0 && (
                        <StyledButton onClick={prevStep} disabled={isSubmitting}>Previous</StyledButton>
                    )}
                    {currentStep < steps.length - 1 ? (
                        <StyledButton type="primary" onClick={nextStep} disabled={isSubmitting}>Next</StyledButton>
                    ) : (
                        <StyledButton 
                            type="primary" 
                            onClick={handleSubmit} 
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            Submit Application
                        </StyledButton>
                    )}
                </ButtonGroup>
            </StyledForm>

            <Modal
                title="Application Submitted Successfully"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalOk}
                footer={[
                    <StyledButton key="submit" type="primary" onClick={handleModalOk}>
                        OK
                    </StyledButton>,
                ]}
            >
                <p>Thank you for submitting your application to Invested.</p>
                <p>Our team of experts will carefully review your application over the next 5-7 business days. We appreciate your patience during this process.</p>
                <p>If we require any additional information or have updates regarding your application, we will contact you via the email or phone number you provided.</p>
                <p>We&apos;re excited about the possibility of helping you achieve your homeownership goals.</p>
            </Modal>
        </ApplicationWrapper>
    );
};

export default PropertyLoanApplication;