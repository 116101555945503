import styled from 'styled-components';
import { Card } from 'antd';

export const Container = styled.div`
min-height: 100vh; // Ensure it takes at least full viewport height
overflow: hidden;
`;

export const Header = styled.header`
  padding: 16px 0;
`;

export const MainContent = styled.div`
  padding: 32px 0;
  max-width: 1440px;
  margin: auto
`;

export const LocationSearch = styled.div`
  margin: 16px 0;
`;

export const PropertyList = styled.div`
  margin: 32px 0;

  .ant-card-cover img {
    width: 100%;
    height: auto;
  }

  .ant-card-meta-title {
    margin-top: 16px;
  }

  .ant-card-meta-description {
    margin-top: 4px;
  }
`;

export const Footer = styled.footer`
  padding: 32px 0;
  text-align: center;
`;

export const StyledCard = styled(Card)`
  .ant-card-body {
    border: 1px solid #d9d9d9;
    border-top: none;
  }
`;


