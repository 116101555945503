import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import { notification } from 'antd';
import { api } from '../constants/api';

const AuthStore = types
  .model('AuthStore', {
    pending: true,
    me: types.maybeNull(
      types.model('MeModel', {
        id: types.maybeNull(types.union(types.string, types.number)),
        firstName: types.maybeNull(types.string),
        lastName: types.maybeNull(types.string),
        fullName: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        avatar: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        areaCode: types.maybeNull(types.string),
        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string), 
        zip: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        state: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        emailFrequency: types.maybeNull(types.string),
        newsUpdates: types.maybeNull(types.string),
        role: types.optional(types.string, 'user'),
        isApproved: types.optional(types.boolean, false),
      })
    ),
  })
  .views((self) => ({
    get isAdmin() {
      return self.me?.role === 'admin';
    },
    get isLoggedIn() {
      return !!self.me;
    },
  }))
  .actions((self) => ({
    setToken: (token) => {
      localStorage.setItem('investedAuthToken', token);
    },
    setMe: (userData) => {
      self.me = {
        id: userData.id || userData._id || null,
        firstName: userData.firstName || null,
        lastName: userData.lastName || null,
        fullName: userData.fullName || null,
        email: userData.email || null,
        avatar: userData.avatar || null,
        phone: userData.phone || null,
        areaCode: userData.areaCode || null,
        address1: userData.address1 || null, 
        address2: userData.address2 || null,
        zip: userData.zip || null,
        city: userData.city || null,
        state: userData.state || null,
        country: userData.country || null,
        emailFrequency: userData.emailFrequency || 'never',
        newsUpdates: userData.newsUpdates || 'yes',
        role: userData.role || 'user',
        isApproved: userData.isApproved || false,
      };
    },
    fetchUserProfile: flow(function* () {
      self.pending = true;
      const token = localStorage.getItem('investedAuthToken');
      try {
        const response = yield axios.get(api.auth.getProfile, {
          headers: { 'x-access-token': token },
        });
        self.setMe(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        notification.error({
          message: 'Failed to fetch user profile',
          description: error.response?.data?.message || 'An error occurred',
        });
      } finally {
        self.pending = false;
      }
    }),
    updateUserProfile: flow(function* (profileData) {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const response = yield axios.post(api.auth.updateProfile, profileData, {
          headers: { 'x-access-token': token },
        });
        self.setMe(response.data);
        notification.success({
          message: 'Profile updated successfully',
        });
      } catch (error) {
        console.error('Error updating user profile:', error);
        notification.error({
          message: 'Failed to update profile',
          description: error.response?.data?.message || 'An error occurred',
        });
      }
    }),
    onCreateUser: flow(function* (values) {
      try {
        const result = yield axios.post(api.auth.signUp, values);
        self.setToken(result.data.token);
        self.setMe(result.data.profile);
        return result.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Something went wrong',
        });
        throw error;
      }
    }),
    onLogin: flow(function* (values) {
      try {
        const result = yield axios.post(api.auth.signIn, values);
        self.setToken(result?.data?.token);
        self.setMe(result.data.profile);
        return result.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Something went wrong',
        });
        throw error;
      }
    }),
    onClientLogin: flow(function* (values) {
      try {
        const result = yield axios.post(api.auth.clientSignIn, values);
        self.setToken(result?.data?.token);
        self.setMe(result.data.client);
        return result.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Something went wrong',
        });
        throw error;
      }
    }),
    onAgentLogin: flow(function* (values) {
      try {
        const result = yield axios.post(api.auth.agentSignIn, values);
        self.setToken(result?.data?.token);
        self.setMe(result.data.profile);
        return result.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Something went wrong',
        });
        throw error;
      }
    }),
    logout: () => {
      self.me = null;
      localStorage.clear();
    },
    whoami: flow(function* () {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const result = yield axios.get(api.auth.whoami, {
          headers: { 'x-access-token': token },
        });
        self.setMe(result.data?.profile);
      } catch (error) {
        console.log(error);
      } finally {
        self.pending = false;
      }
    }),
    setAvatar: (avatar) => {
      if (self.me) {
        self.me.avatar = avatar;
      }
    },
    onUpdateProfile: flow(function* (values) {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const { data } = yield axios.post(api.user.updateProfile, values, {
          headers: { 'x-access-token': token },
        });
        if (self.me) {
          self.me.phone = data?.phone;
          self.me.avatar = data?.avatar;
        }
      } catch (error) {
        console.log(error);
      }
    }),
    saveInvestmentPreferences: flow(function* (preferences) {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const response = yield axios.post(api.investorProfile.saveInvestmentPreferences, preferences, {
          headers: { 'x-access-token': token },
        });
        return response.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Error saving investment preferences',
        });
        throw error;
      }
    }),
    saveLocationPreferences: flow(function* (preferences) {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const response = yield axios.post(api.investorProfile.saveLocationPreferences, preferences, {
          headers: { 'x-access-token': token },
        });
        return response.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Error saving location preferences',
        });
        throw error;
      }
    }),
    saveFinancialInformation: flow(function* (financialInfo) {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const response = yield axios.post(api.investorProfile.saveFinancialInformation, financialInfo, {
          headers: { 'x-access-token': token },
        });
        return response.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Error saving financial information',
        });
        throw error;
      }
    }),
    getInvestorDetails: flow(function* () {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const response = yield axios.get(api.investorProfile.getProfile, {
          headers: { 'x-access-token': token },
        });
        return response.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Error fetching investor details',
        });
        throw error;
      }
    }),
    searchPropGPT: flow(function* (query) {
      const token = localStorage.getItem('investedAuthToken');
      try {
        const response = yield axios.post(api.listing.propGPTSearch, { query }, {
          headers: { 'x-access-token': token },
        });
        return response.data;
      } catch (error) {
        notification.error({
          message: error.response?.data?.message || 'Error fetching data from PropGPT',
        });
        throw error;
      }
    }),
    onClientSignUp: flow(function* (values) {
      try {
        const result = yield axios.post(api.auth.clientSignUp, values);
        self.setToken(result.data.token);
        self.setMe(result.data.client);
        notification.success({
          message: 'Sign up successful',
          description: 'Welcome to Invested.homes!',
        });
        return result.data;
      } catch (error) {
        notification.error({
          message: 'Sign up failed',
          description: error.response?.data?.message || 'Something went wrong',
        });
        throw error;
      }
    }),
  }));

export default AuthStore;