import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const StyledTypography = styled.div`
  && {
    h3 {
      text-align: ${({ $align = 'center' }) => $align};
      font-size: 32px;
      line-height: 125%;
      color: ${({ $color = COLORS.$black }) => $color};
      margin-bottom: ${({ $marginBottom = '8px' }) => $marginBottom};
      font-weight: ${({ $fontWeight = '600' }) => $fontWeight};
    }

    span.ant-typography {
      text-align: ${({ $align = 'center' }) => $align};
      font-size: ${({ $fontSize = '16px' }) => $fontSize};
      line-height: 150%;
      display: block;
      color: ${({ $color = COLORS.$black }) => $color};
      margin-bottom: ${({ $marginBottom = '0px' }) => $marginBottom};

      a {
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
      }
    }
  }
`;

export const StyledText = styled(Text)``;
