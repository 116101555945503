import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// Components
import MortgageNoteCalculator from '../Calculators/MortgageNoteCalculator';
import YieldCalculator from '../Calculators/YieldCalculator';
import AmortizationScheduleCalculator from '../Calculators/AmortizationSchedule';
import DiscountedCashFlowCalculator from '../Calculators/DiscountedCashFlow';
// import NoteInvestmentROICalculator from './NoteInvestmentROICalculator';

// Styles
import {
  Wrapper,
  LinkStyle,
  TitlePage,
  Outer,
  Nav,
  Tab,
  IconStyle
} from './Styled';

const MORTGAGE_NOTE_CALCULATOR = 'Note Calculator';
const YIELD_CALCULATOR = 'Yield Calculator';
const AMORTIZATION_SCHEDULE_CALCULATOR = 'Amortization Schedule';
const DISCOUNTED_CASH_FLOW_CALCULATOR = 'Discounted Cash Flow ';
//const NOTE_INVESTMENT_ROI_CALCULATOR = 'Note Investment ROI';

const NAV_LIST = [
  { title: MORTGAGE_NOTE_CALCULATOR },
  { title: YIELD_CALCULATOR },
  { title: AMORTIZATION_SCHEDULE_CALCULATOR },
  { title: DISCOUNTED_CASH_FLOW_CALCULATOR },
];

const Calculators = () => {
  const location = useLocation();
  const activeTab = location.state?.activeTab ?? MORTGAGE_NOTE_CALCULATOR;
  const [isActiveTab, setIsActiveTab] = useState(activeTab);

  useEffect(() => {
    setIsActiveTab(activeTab);
  }, [activeTab]);

  return (
    <Wrapper>
      {isMobile && <TitlePage>Calculators</TitlePage>}
      <Outer>
        <Nav>
          {NAV_LIST.map((nav) => (
            <Tab
              key={nav.title}
              className={nav.title === isActiveTab ? 'active' : ''}
              onClick={() => setIsActiveTab(nav.title)}
            >
              {nav.title}
            </Tab>
          ))}
        </Nav>

        {isActiveTab === MORTGAGE_NOTE_CALCULATOR && (
          <MortgageNoteCalculator />
        )}

        {isActiveTab === YIELD_CALCULATOR && (
          <YieldCalculator />
        )}

        {isActiveTab === AMORTIZATION_SCHEDULE_CALCULATOR && (
          <AmortizationScheduleCalculator />
        )}

        {isActiveTab === DISCOUNTED_CASH_FLOW_CALCULATOR && (
          <DiscountedCashFlowCalculator />
        )}
{/*
        {isActiveTab === NOTE_INVESTMENT_ROI_CALCULATOR && (
          <NoteInvestmentROICalculator />
        )} */}
      </Outer>
    </Wrapper>
  );
};

export default Calculators;
