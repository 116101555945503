import styled from "styled-components";
import { Image, Flex } from "antd";
import { COLORS } from "../../../constants/colors";
import BaseTypography from "../../../components/BaseTypography";

export const ListingGridContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.$blueShades98};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add a slight shadow for better visibility */
  margin-bottom: 20px; /* Add space below each card */
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 65%; /* 4:3 Aspect Ratio */
  overflow: hidden;
`;

export const ListingImage = styled(Image)`
  position: absolute;
  top: 0;
  right: -55px; /* Adjusted */
  left: auto; /* Adjusted */
  width: 130%!important; /* Adjusted */
  height: 100%;
  object-fit: cover;
`;

export const ListingGridContent = styled.div`
  padding: 16px;
  display: flex;
  min-height: 175px;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid ${COLORS.$blueShades98}; /* Add a border to separate content from the image */
  text-align: left !important; /* Align text to the left */
`;

export const PriceTypography = styled(BaseTypography)`
  color: ${COLORS.$mainBlue};
  font-size: 16px;
  text-align: left!important;
`;

export const NeighborhoodTypography = styled(BaseTypography)`
  font-size: 20px;
  color: ${COLORS.$mainDark};
  text-align: left!important;
`;

export const AddressTypography = styled(BaseTypography)`
  font-size: 14px;
  text-align: left!important;
`;

export const Break = styled.hr`
  border: none;
  border-top: 1px solid ${COLORS.$blueShades98};
  margin: 16px 0;
`;

export const ListingPropertiesContainer = styled(Flex)`
  gap: 15px;
`;

export const ListingProperties = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Value = styled.span`
  font-size: 14px;
  color: ${COLORS.$mainDark};
`;

export const Sqft = styled.span`
  font-size: 14px;
  color: ${COLORS.$mainDark};
`;
