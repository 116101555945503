import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/storeContext';

import flowerImage from '../../../assets/images/flower-pot.png';

const { TabPane } = Tabs;

// Styled components definitions
const ProfileContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.h1`
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const Email = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

const Content = styled.div`
  margin-bottom: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Footer = styled.footer`
  font-size: 0.8em;
  color: #666;
`;

const BuyerProfile = observer(() => {
  const location = useLocation();
  const { authStore } = useStore();

  return (
    <ProfileContainer>
      <Header>
        Hi there{authStore.me?.firstName ? `, ${authStore.me.firstName}` : ''}!
      </Header>
      <Email>{authStore.me?.email}</Email>
      
      <Tabs defaultActiveKey={location.pathname}>
        <TabPane tab={<Link to="/buyer-profile">Buyer profile</Link>} key="/buyer-profile" />
        <TabPane tab={<Link to="/saved-homes">Saved Listings</Link>} key="/saved-homes" />
        <TabPane tab={<Link to="/settings">Settings</Link>} key="/settings" />
      </Tabs>
      
      <Content>
        <p style={{fontSize: '18px'}}>
          Use our <Link to="/tools/affordability-calculator" style={{fontSize: '18px', fontWeight: '600'}}>affordability calculator</Link> to determine how much you can afford to spend on a home. We save that information in your buyer profile, so you can use it in your home search.
        </p>
        
        <Image src={flowerImage} alt="flower pot" width={500} />
      </Content>
      
      <Footer>
        The personal data presented on this page is collected according to the invested.homes <Link to="/privacy-policy">Privacy Policy</Link>.
      </Footer>
    </ProfileContainer>
  );
});

export default BuyerProfile;
