import React from 'react';
import { Row, Col, Typography } from 'antd';
import { HeroContainer, StyledTitle, StyledParagraph, StyledButton } from './styled';

const HeroSection = () => {
  return (
    <HeroContainer>
      <Row align="middle">
        <Col xs={24} md={24} lg={24} xl={24}>
          <StyledTitle>
            ELEVATE YOUR PORTFOLIO<br />
            WITH <span>INVESTED</span>
          </StyledTitle>
          <StyledParagraph>
            Streamline your investment journey with cutting-edge tools and insights. Navigate the world of
            note investing with our comprehensive digital platform.
          </StyledParagraph>
          <StyledButton 
            type="primary" 
            href="https://admin.invested.homes" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Start Note Investing
          </StyledButton>
        </Col>
      </Row>
    </HeroContainer>
  );
};

export default HeroSection;