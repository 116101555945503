import styled from 'styled-components';
import { Input } from 'antd';
import { COLORS } from '../../../../constants/colors';

export const StyledInput = styled(Input.Password)`
  height: 48px;
  border-radius: 8px;
  background: ${COLORS.$blueShades98};
  font-size: 16px;
  color: ${COLORS.$black};
  line-height: 150%;
  padding: 0 16px;
  display: flex;
  align-items: center;

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px ${COLORS.$blueShades98} inset !important;
    -webkit-text-fill-color: ${COLORS.$black} !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px ${COLORS.$white} inset !important;
  }

  input::placeholder {
    opacity: 0.5;
    color: ${COLORS.$mainDark};
  }
`;
