import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { isDesktop, isMobile } from 'react-device-detect';
import { useStore } from "../../../hooks/storeContext";
import Icon from '@ant-design/icons';
import DropdownComponent from '../../../components/DropdownComponent';
import HeaderMenu from './HeaderMenu';
import BaseTypography from '../../../components/BaseTypography';
import { Avatar, Flex, Col, Menu } from 'antd';
import {
  Header,
  HeaderContainer,
  HeaderProfile,
  HeaderLogo,
  Divider,
  CustomCol,
} from './Styled';

// Icons
import {
  LogoSvg,
} from '../../../components/Icons';

// Constants
import { ROUTE_PATH } from '../../../constants/routePath';
import { AGENT_ROUTE_PATH } from '../../../constants/routePath'; 

const HEADER_TITLES = {
  dashboard: 'Dashboard',
  properties: 'Properties',
  applicants: 'Applicants',
};

const CustomHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authStore } = useStore();
  const [isMenuVisible, setMenuVisible] = useState(false);

  const menuItems = [

  ];

  const profileItems = [
    {
      type: 'divider',
    },
    {
      label: 'Sign out',
      key: 'signOut',
      onClick: () => authStore.logout(),
    },
  ];

  const toggleMenu = () => {
    setMenuVisible((prevState) => !prevState);
  };

  const handleMenuClose = () => {
    setMenuVisible(false);
  };

  const handleMenuItemClick = () => {
    setMenuVisible(false);
  };

  const isDashboard = location.pathname === AGENT_ROUTE_PATH.DASHBOARD;
  const isProperties = location.pathname === AGENT_ROUTE_PATH.PROPERTIES;
  const isApplicants = location.pathname === AGENT_ROUTE_PATH.APPLICANTS;
  
  const handleClickDashboard = () => {
    navigate(AGENT_ROUTE_PATH.DASHBOARD);
  };

  const handleClick = (data) => {
    navigate(ROUTE_PATH.SETTINGS, { state: { activeTab: data.key } });
  };

  const title = () => {
    switch (true) {
      case isDashboard:
        return HEADER_TITLES.dashboard;
      case isProperties:
        return HEADER_TITLES.properties;
      case isApplicants:
        return HEADER_TITLES.applicants;
      default:
        return HEADER_TITLES.dashboard;
    }
  };

  return (
    <Header>
      <HeaderContainer justify="space-between" align="middle">
        {isMobile && (
          <>
            <Col>
              <Flex align="center">
                <Icon component={LogoSvg} />
                <HeaderLogo onClick={handleClickDashboard}>
                  {title()}
                </HeaderLogo>
              </Flex>
            </Col>
            <Col span={2}>
              <MenuOutlined onClick={toggleMenu} />
            </Col>
            {isMenuVisible && (
              <HeaderMenu items={menuItems} onClose={handleMenuClose} onItemClick={handleMenuItemClick} />
            )}
          </>
        )}

        {isDesktop && (
          <CustomCol span={4}>
            <Flex justify="flex-end">
              <HeaderProfile gap={20}>
                <Divider />
                <DropdownComponent items={profileItems} onClick={handleClick}>
                  <Flex gap={8} align="center">
                    <Avatar
                      size={32}
                      style={{
                        backgroundColor: '#005EB8',
                      }}
                    >
                      {authStore.me?.fullName ? authStore.me.fullName.charAt(0).toUpperCase() : ' '}
                    </Avatar>
                    <BaseTypography type={'text'}>
                      {authStore.me?.fullName
                        ? (() => {
                          const firstName = authStore.me.fullName.split(' ')[0];
                          const truncatedName = firstName.slice(0, 9);
                          return truncatedName.charAt(0).toUpperCase() + truncatedName.slice(1).toLowerCase();
                        })()
                        : 'Guest'}
                    </BaseTypography>
                  </Flex>
                </DropdownComponent>
              </HeaderProfile>
            </Flex>
          </CustomCol>
        )}
      </HeaderContainer>
    </Header>
  );
};

export default CustomHeader;