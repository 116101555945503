// src/pages/TermsOfService.js
import React from 'react';
import { ApplicationWrapper, Title, Content } from './styled';

const TermsOfService = () => {
    return (
        <ApplicationWrapper>
            <Title>Terms of Service</Title>
            <Content>
                <h2>Introduction</h2>
                <p>
                    Welcome to Invested! These Terms of Service (&quot;Terms&quot;) govern your use of our platform and services. By accessing or using Invested, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use our platform.
                </p>

                <h2>Definitions</h2>
                <p>
                    &quot;Invested&quot; refers to the online platform that connects buyers and lenders for property financing opportunities.<br />
                    &quot;User&quot; refers to anyone who accesses or uses Invested, including buyers, lenders, and other visitors.<br />
                    &quot;Buyer&quot; refers to a user seeking financing for property purchases.<br />
                    &quot;Lender&quot; refers to a user offering financing for property purchases.<br />
                    &quot;Services&quot; refers to all features, functionalities, and services provided by Invested.
                </p>

                <h2>Eligibility</h2>
                <p>
                    To use Invested, you must be at least 18 years old and capable of forming legally binding contracts. By using our platform, you represent and warrant that you meet these requirements.
                </p>

                <h2>User Accounts</h2>
                <h3>Account Registration</h3>
                <p>
                    To access certain features of Invested, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                </p>

                <h3>Account Security</h3>
                <p>
                    You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify Invested immediately of any unauthorized use of your account.
                </p>

                <h2>Use of the Platform</h2>
                <h3>Permitted Use</h3>
                <p>
                    You agree to use Invested only for lawful purposes and in accordance with these Terms. You shall not use our platform to engage in any activity that is fraudulent, harmful, or otherwise inappropriate.
                </p>

                <h3>Prohibited Use</h3>
                <p>
                    You agree not to:
                </p>
                <ul>
                    <li>Violate any applicable laws or regulations.</li>
                    <li>Post or transmit any false, misleading, or deceptive information.</li>
                    <li>Use the platform to harm or exploit any individual, especially minors.</li>
                    <li>Interfere with or disrupt the platform&#39;s operations or security.</li>
                    <li>Attempt to gain unauthorized access to any part of the platform or its systems.</li>
                </ul>

                <h2>Listings and Transactions</h2>
                <h3>Property Listings</h3>
                <p>
                    Invested provides a platform for lenders to list properties for financing opportunities. While we strive to ensure the accuracy of listings, Invested does not guarantee the accuracy, completeness, or reliability of any property listing.
                </p>

                <h3>Buyer and Lender Responsibilities</h3>
                <p>
                    Buyers: You are responsible for conducting your own due diligence before entering into any financing agreement. This includes verifying property details, assessing financial terms, and seeking professional advice as needed.
                </p>
                <p>
                    Lenders: You are responsible for providing accurate and truthful information about the properties and financing terms you offer.
                </p>

                <h2>Fees and Payments</h2>
                <h3>Service Fees</h3>
                <p>
                    Invested may charge service fees for certain features or services. Any applicable fees will be disclosed to you before you use the service.
                </p>

                <h3>Payment Terms</h3>
                <p>
                    All payments made through Invested are subject to our payment terms. By making a payment, you agree to comply with the terms and conditions of our payment processors.
                </p>

                <h2>Dispute Resolution</h2>
                <h3>Reporting Issues</h3>
                <p>
                    If you encounter any issues or disputes with other users, you agree to report the matter to Invested for investigation. We will work to resolve disputes in a fair and timely manner.
                </p>

                <h3>Mediation and Arbitration</h3>
                <p>
                    For more complex disputes, Invested may offer mediation or arbitration services. By using our platform, you agree to participate in these processes if required.
                </p>

                <h2>Intellectual Property</h2>
                <h3>Ownership</h3>
                <p>
                    Invested and its licensors retain all rights, title, and interest in and to the platform, including all intellectual property rights.
                </p>

                <h3>User Content</h3>
                <p>
                    By posting or transmitting any content on Invested, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute your content for the purpose of operating and improving the platform.
                </p>

                <h2>Privacy</h2>
                <p>
                    Your use of Invested is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information.
                </p>

                <h2>Termination</h2>
                <h3>Termination by You</h3>
                <p>
                    You may terminate your account at any time by following the account termination procedures on our platform.
                </p>

                <h3>Termination by Invested</h3>
                <p>
                    We reserve the right to suspend or terminate your account or access to the platform at any time, for any reason, without notice or liability.
                </p>

                <h2>Limitation of Liability</h2>
                <p>
                    To the fullest extent permitted by law, Invested and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the platform; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the platform; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our platform by any third party; or (v) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the platform, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not Invested is advised of the possibility of such damages.
                </p>

                <h2>Governing Law</h2>
                <p>
                    These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
                </p>

                <h2>Changes to Terms</h2>
                <p>
                    Invested reserves the right to modify these Terms at any time. We will provide notice of any changes by posting the new Terms on our platform. Your continued use of Invested after any such changes constitutes your acceptance of the new Terms.
                </p>

                <h2>Contact Information</h2>
                <p>
                    If you have any questions or concerns about these Terms, please contact us at [support email] or call us at [support phone number].
                </p>
            </Content>
        </ApplicationWrapper>
    );
};

export default TermsOfService;
