import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
`;

const TermContainer = styled.div`
  margin-bottom: 20px;
`;

const TermTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  
  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 1em;
    background-color: #007bff;
    margin-right: 10px;
  }
`;

const TermDefinition = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.5;
`;

const terms = [
  {
    title: "Borrower",
    definition: "A borrower is an individual who obtains a loan from a financial institution. In the context of a mortgage loan, the borrower is often referred to as the mortgagor, while the lending institution is known as the mortgagee."
  },
  {
    title: "Conventional Loans",
    definition: "A conventional loan is a type of mortgage that is not insured or guaranteed by any government agency. These loans typically require higher credit scores and down payments compared to government-backed loans."
  },
  {
    title: "Debt Payments",
    definition: "Debt payments refer to the regular financial obligations made to repay borrowed money. This includes payments towards mortgages, car loans, student loans, credit cards, and other forms of debt."
  },
  {
    title: "Gross Monthly Income",
    definition: "Gross monthly income is the total amount of money an individual earns in a month before any taxes or deductions are applied. This figure is crucial in determining loan eligibility and affordability."
  },
  {
    title: "Lender",
    definition: "A lender is a financial institution or entity that provides loans directly to borrowers. In the mortgage industry, lenders can be banks, credit unions, or specialized mortgage companies."
  },
  {
    title: "Monthly Budgets",
    definition: "A monthly budget is a financial plan that estimates and allocates income and expenses for a given month. It's an essential tool for managing personal finances and determining how much can be safely spent on housing costs."
  },
  {
    title: "Mortgage Affordability Calculator",
    definition: "A mortgage affordability calculator is a tool used to estimate the maximum monthly mortgage payment a borrower might qualify for, based on factors such as income, debts, and down payment. It helps potential homebuyers understand how much home they can afford."
  },
  {
    title: "Private Mortgage Insurance (PMI)",
    definition: "Private Mortgage Insurance (PMI) is a type of insurance required by lenders when a borrower's down payment is less than 20% of the home's purchase price on a conventional loan. PMI protects the lender in case of default and typically has lower rates than FHA mortgage insurance for borrowers with good credit."
  },
  {
    title: "Federal Housing Administration (FHA) Loan",
    definition: "An FHA loan is a mortgage insured by the Federal Housing Administration, designed to make homeownership more accessible. These loans typically offer lower down payments, more lenient credit requirements, and can be especially beneficial for first-time homebuyers."
  },
  {
    title: "Veterans Affairs (VA) Loan",
    definition: "A VA loan is a mortgage guaranteed by the U.S. Department of Veterans Affairs, available to eligible veterans, active-duty service members, and certain military spouses. VA loans often require no down payment and offer competitive interest rates, making homeownership more attainable for those who have served in the military."
  }
];

const CommonTerms = () => (
  <Section>
    <Title>Common Terms</Title>
    {terms.map((term, index) => (
      <TermContainer key={index}>
        <TermTitle><span> </span>{term.title}</TermTitle>
        <TermDefinition>{term.definition}</TermDefinition>
      </TermContainer>
    ))}
  </Section>
);

export default CommonTerms;