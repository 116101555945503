import React, { useEffect, useState } from 'react';

// Components
import { Row, Col } from 'antd';
import MarketSearchGridItem from '../MarketSearchGridItem/';

// Styles
import { ListContainer, ListContent, ListPagination, Bottom } from './Styled';

const PAGE_SIZE = 9;
const MarketSearchGrid = ({ properties }) => {
  const [page, setPage] = useState(1);
  const [paginatedProperties, setPaginatedProperties] = useState([]);
  const handleChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    const paginate = (array, pageNumber) => {
      return array.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE);
    };

    setPaginatedProperties(paginate(properties, page));
  }, [page, properties]);

  return (
    <>
      <ListContainer>
        <ListContent>
          <Row gutter={16}>
            {paginatedProperties.map((property) => {
              return (
                <Col xs={24} sm={12} md={8} key={property.id}>
                  <MarketSearchGridItem property={property} />
                </Col>
              );
            })}
          </Row>
        </ListContent>
      </ListContainer>
      {properties.length > 1 && (
        <Bottom>
          <ListPagination
            onChange={handleChange}
            size="large"
            total={properties.length}
            pageSize={PAGE_SIZE}
          />
        </Bottom>
      )}
    </>
  );
};

export default MarketSearchGrid;
