import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHouse,
  faBuilding,
  faUsers,
  faUserCheck,
  faSignOutAlt  // Added sign-out icon
} from '@fortawesome/free-solid-svg-icons';
import {
  LogoDarkSvg,
} from '../../../../components/Icons';
import { ROUTE_PATH } from '../../../../constants/routePath'; 
import { AGENT_ROUTE_PATH } from '../../../../constants/routePath'; 
import { Flex } from 'antd';
import { useStore } from "../../../../hooks/storeContext";
import styles from './HeaderMenu.module.scss';

const HeaderMenu = ({ onClose, onItemClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useStore();

  const navList = [
    [
      { title: 'Dashboard', icon: faHouse, route: AGENT_ROUTE_PATH.DASHBOARD },
      { title: 'All Properties', icon: faBuilding, route: AGENT_ROUTE_PATH.PROPERTIES },
      { title: 'Applicants', icon: faUsers, route: AGENT_ROUTE_PATH.APPLICANTS },
      ...(authStore.isAdmin ? [{ title: 'User Management', icon: faUserCheck, route: AGENT_ROUTE_PATH.APPROVALS }] : []),      
    ],
    [
      { title: 'Log Out', icon: faSignOutAlt, action: 'logout'},  // Changed to Log Out with a new icon
    ],
  ];

  const onClickLink = (path) => {
    navigate(path);
    onItemClick(); // Close the menu when an item is clicked
  };

  const handleLogout = () => {
    authStore.logout();
    onItemClick(); // Close the menu after logout
    // You might want to navigate to a login page or home page after logout
    navigate(ROUTE_PATH.LOGIN); // Adjust this as needed
  };

  const handleMenuItemClick = (item) => {
    if (item.action === 'logout') {
      handleLogout();
    } else if (item.route) {
      onClickLink(item.route);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Flex gap={12} align="center">
          <Icon
            component={LogoDarkSvg}
            onClick={() => onClickLink(AGENT_ROUTE_PATH.DASHBOARD)}
          />
          <span className={styles.logo}>Invested</span>
        </Flex>

        <CloseOutlined className={styles.iconX} onClick={onClose} />
      </div>
      <div className={styles.content}>
        <Flex vertical gap={16}>
          {navList[0].map((tab) => (
            <Flex
              gap={12}
              key={tab.title}
              className={
                location.pathname === tab.route ? styles.active : styles.tab
              }
              onClick={() => handleMenuItemClick(tab)}
            >
              <FontAwesomeIcon icon={tab.icon} />
              <span>{tab.title}</span>
            </Flex>
          ))}
        </Flex>

        <Flex vertical gap={16}>
          {navList[1].map((tab) => (
            <Flex
              gap={12}
              key={tab.title}
              className={styles.tab}
              onClick={() => handleMenuItemClick(tab)}
            >
              <FontAwesomeIcon icon={tab.icon} />
              <span>{tab.title}</span>
            </Flex>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default HeaderMenu;