import { StyledButton, StyledOutlineButton } from './styled';

export const BaseButton = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export const OutlinedButton = ({ children, ...props }) => {
  return <StyledOutlineButton {...props}>{children}</StyledOutlineButton>;
};

export const GhostButton = ({ children, ...props }) => {
  return <StyledOutlineButton {...props}>{children}</StyledOutlineButton>;
};
