import styled from "styled-components";
import { Card, Button } from "antd";
import { COLORS } from "../../../constants/colors";

export const ContentContainer = styled.div`
  margin: auto;
  background-color: #f5f5f5; /* Set background color */
  
  .ant-row {
    margin-bottom: 24px;
    row-gap: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
  padding:0!important;
  border-radius: 8px; /* Optional: Add border radius for smoother corners */
`;

export const HeaderButton = styled(Button)`
  height: 40px;
  color: ${COLORS.$colorPrimary};
  border: 1.5px solid ${COLORS.$blueShades92};
  border-radius: 8px;
  font-size: 14px;
`;

export const SearchField = styled.div`

  .ant-input-prefix {
    margin-right: 16px;
  }

  .ant-input-affix-wrapper {
    padding: 12px 16px;
    border: 2px solid ${COLORS.$blueShades92};
    background: ${COLORS.$blueShades98};

    input {
      font-size: 16px;
      color: ${COLORS.$mainDark50};
    }
  }

  .anticon svg path {
    stroke: ${COLORS.$colorPrimary};
  }
`;

export const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
`;
