// Helper function to convert string to camel case while preserving spaces
const toCamelCaseWithSpaces = (str) => {
  return str
    .split(' ')
    .map((word, index) => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ');
};

export const createAddress = ({
  streetNumber,
  streetName,
  state,
  city,
  zipcode,
}) => {
  const addressObject = {};

  if (streetNumber && streetNumber.trim()) addressObject.house = streetNumber.trim();
  if (streetName && streetName.trim()) addressObject.street = streetName.trim();
  if (state && state.trim()) addressObject.state = state.trim();
  if (city && city.trim()) addressObject.city = toCamelCaseWithSpaces(city.trim());
  if (zipcode && zipcode.trim()) addressObject.zip = zipcode.trim();

  return addressObject;
};