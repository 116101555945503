// src/components/Footer.js
import React from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import {
  Container,
  FooterContainer,
  FooterRow,
  FooterColumn,
  FooterHeading,
  FooterLink,
  FooterLogo,
  FooterLogoText
} from './styled';
import logo from '../../../../assets/images/logo.svg';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterRow gutter={[16, 16]}>
        <FooterColumn xs={24} sm={12} md={6}>
          <FooterLogo>
            <Image preview={false} src={logo} />
            <FooterLogoText>Invested</FooterLogoText>
          </FooterLogo>
        </FooterColumn>
        <FooterColumn xs={12} sm={12} md={6}>
          <FooterHeading>BUY A HOME</FooterHeading>
          <FooterLink as={Link} to="/how-it-works">How it works</FooterLink>
          <FooterLink as={Link} to="/contact">Contact</FooterLink>
        </FooterColumn>
        <FooterColumn xs={12} sm={12} md={6}>
          <FooterHeading>TERMS & PRIVACY</FooterHeading>
          <FooterLink as={Link} to="/trust-and-safety">Trust & Safety</FooterLink>
          <FooterLink as={Link} to="/terms-of-service">Terms of Service</FooterLink>
          <FooterLink as={Link} to="/privacy-policy">Privacy Policy</FooterLink>
        </FooterColumn>
        <FooterColumn xs={12} sm={12} md={6}>
          <FooterHeading>ABOUT</FooterHeading>
          <FooterLink as={Link} to="/company">Company</FooterLink>
          <FooterLink as={Link} to="/investors">Investors</FooterLink>
        </FooterColumn>
      </FooterRow>
    </FooterContainer>
  );
};

export default Footer;
