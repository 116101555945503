import styled from "styled-components";
import { Flex } from "antd";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants/colors";

export const StyledUploadButton = styled.div`
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
      width: 125px !important;
      height: 125px !important;
      margin-bottom: 0;
  }
`;

export const StyledFlex = styled(Flex)`
    width: 100%;
    margin: 25px auto 0;
    margin-bottom: 20px;
    padding: 40px;
`;

export const StyledLink = styled(Link)`
    display: flex;
    justify-content: center;
    margin-top: -16px;
    margin-bottom: 32px;
`;

export const StyledContainer = styled.div`
    margin: 20px!important;;
    max-width: 750px;
    padding: 24px;
    margin-bottom: 100px;
    margin: auto!important;
`;

export const StyledRegister = styled.div`
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${COLORS.$greyScale500};

    a {
        font-weight: 700;
        color: ${COLORS.$black};
    }
`;

export const ThemePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f3f3f1;
    border-radius: 20px;
    width: 100%;
    margin: 20px 0;
`;

export const ThemeHeader = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

export const ThemeNumber = styled.p`
    color: #808080;
    font-size: 18px;
    margin: 0;
`;

export const ThemeTitle = styled.h3`
    color: #000;
    font-size: 24px;
    margin: 0;
`;

export const TemplateSelector = styled.div`
    width: 100%;
    margin-bottom: 20px;
    text-align: center;

    .slick-prev,
    .slick-next {
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
    }

    .slick-prev {
        left: -35px;
    }

    .slick-next {
        right: -35px;
    }

    .slick-dots {
        bottom: -30px;
    }

    .slick-dots li button:before {
        color: #007bff;
    }
`;

export const TemplateImage = styled.div`
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 300px;

    img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
`;

export const UseTemplateButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #000000;
        border: 1px solid #000000;
    }
`;

export const FeatureItem = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 8px;
`;

export const FeatureText = styled.span`
    color: ${COLORS.$black};
    text-align: left;
    flex: 1;
`;

export const UserCount = styled.div`
    display: flex;
    align-items: center;
    margin: 16px 0;
`;

export const UserAvatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
`;

export const PlanOption = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: ${({ selected }) =>
        selected
            ? `1px solid ${COLORS.$mainBlue}`
            : `1px solid ${COLORS.$greyScale300}`};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    background-color: ${({ selected }) =>
        selected ? COLORS.$black : "transparent"};
`;
