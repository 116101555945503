import React, { useReducer, useState, useMemo, useEffect, useCallback } from 'react';
import {
  Map,
  MapControl,
  ControlPosition,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';

import { useStore } from "../../../hooks/storeContext";

// Styles
import styles from './MarketSearchMap.module.scss';

// Reducer
import reducer from '../../../reducers/UndoRedoReducer';
import debounce from 'lodash/debounce';

// Hooks
import useDrawingManager from '../../../hooks/useDrawingManager';
import useMarkers from '../../../hooks/useMarkers';

// Components
import Markers from './Markers';
import UndoRedoControl from './UndoRedoControl';
import MarketSearchDrawer from './MarketSearchDrawer';
import { formatNumberToDollars } from '../../../utils/numberFormat';
import LoadingOverlay from './LoadingOverlay';

const searchDelay = 1200; //Time in ms

const MapSearchWithBounds = ({
  drawerContainerRef,
  properties,
  setIsFiltersDrawerOpen,
  isMapDrawerOpen,
  handleClickMapDrawerClose,
  params,
  onMapMove,
  isLoading
}) => {
  const [bounds, setBounds] = useState({
    ne: {},
    sw: {},
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [center, setCenter] = useState({ lat: 32.779167, lng: -96.808891 }); // Default center
  const [shouldUpdateOnMove, setShouldUpdateOnMove] = useState(true);
  const geometry = useMapsLibrary('geometry');
  // const geocodingLibrary = useMapsLibrary('geocoding');
  // const { authStore } = useStore();
  const [state, dispatch] = useReducer(reducer, {
    past: [],
    now: [],
    future: [],
  });
  const drawingManager = useDrawingManager();
  const [mapRef, setMapRef] = useState(null);
  const [allMarkers, setAllMarkers] = useState([]);

  useEffect(() => {
    if (params.latitude && params.longitude) {
      setCenter({
        lat: parseFloat(params.latitude),
        lng: parseFloat(params.longitude)
      });
    }
  }, [params.latitude, params.longitude]);

  const polygonPath = state.now.length
    ? state.now[0].snapshot.path.map((coord) => ({
      lat: coord.lat(),
      lng: coord.lng(),
    }))
    : [];

  const polygon = state.now.length ? state.now[0].geometry : false;
  const points = useMemo(
    () =>
      properties.map((property) => ({
        id: property.id,
        position: property.position,
        value: formatNumberToDollars(property.current_price),
        property,
      })),
    [properties]
  );

  const markerParams = useMemo(() => ({
    points,
    polygonPath,
    geometry,
    polygon,
    state,
    bounds,
  }), [points, polygonPath, geometry, polygon, state, bounds]);

  const { markers } = useMarkers(markerParams);

  useEffect(() => {
    const newMarkers = properties.map(property => ({
      id: property.id,
      position: property.position,
      value: formatNumberToDollars(property.current_price),
      property,
      isLoading: false
    }));

    setAllMarkers(prevMarkers => {
      const existingMarkerIds = new Set(prevMarkers.map(m => m.id));
      const updatedMarkers = prevMarkers.map(m => ({
        ...m,
        isLoading: false
      }));
      
      const markersToAdd = newMarkers.filter(m => !existingMarkerIds.has(m.id));
      return [...updatedMarkers, ...markersToAdd];
    });
  }, [properties]);

  useEffect(() => {
    if (isLoading) {
      setAllMarkers(prevMarkers => 
        prevMarkers.map(m => ({
          ...m,
          isLoading: true
        }))
      );
    }
  }, [isLoading]);

  const fitBoundsToMarkers = useCallback(() => {
    if (mapRef && markers.length) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.position.lat, marker.position.lng));
      });

      const center = bounds.getCenter();
      mapRef.setCenter(center);

      const padding = { top: 50, right: 50, bottom: 50, left: 50 };
      mapRef.fitBounds(bounds, padding);

      const maxZoom = 9;
      const currentZoom = mapRef.getZoom();
      if (currentZoom > maxZoom) {
        mapRef.setZoom(maxZoom);
      }
    }
  }, [mapRef, markers]);

  useEffect(() => {
    fitBoundsToMarkers();
  }, [fitBoundsToMarkers, markers]);
  
  const handleBoundsChanged = useCallback((props) => {
    const { map } = props;
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const newBounds = {
      ne: { lat: ne.lat(), lng: ne.lng() },
      sw: { lat: sw.lat(), lng: sw.lng() },
    };

    setBounds(newBounds);
    
    // Only update if no property is selected
    if (shouldUpdateOnMove) {
      onMapMove(newBounds);
    }
  }, [onMapMove, shouldUpdateOnMove]);

  const debouncedHandleBoundsChanged = useMemo(
    () => debounce(handleBoundsChanged, searchDelay), // 1200ms delay
    [handleBoundsChanged]
  );

  useEffect(() => {
    return () => {
      debouncedHandleBoundsChanged.cancel();
    };
  }, [debouncedHandleBoundsChanged]);

  const handleMapLoad = useCallback((map) => {
    setMapRef(map);
    map.setCenter(center);
  }, [center]);

  const handleMarkerClick = useCallback((property) => {
    setSelectedProperty((prevSelected) => {
      const newSelected = prevSelected && prevSelected.id === property.id ? null : property;
      setShouldUpdateOnMove(!newSelected);
      return newSelected;
    });
  }, []);

  const handleMapClick = useCallback(() => {
    setSelectedProperty(null);
    setShouldUpdateOnMove(true);
  }, []);

  const handleMapIdle = useCallback(() => {
    if (mapRef) {
      const center = mapRef.getCenter();
      console.log('Map moved to:', { lat: center.lat(), lng: center.lng() });
    }
  }, [mapRef]);

  return (
    <div className={styles.mapContainer}>
      <Map
        onBoundsChanged={debouncedHandleBoundsChanged}
        onLoad={handleMapLoad}
        onIdle={() => handleMapIdle(mapRef)}
        mapId="marketSearchMap"
        defaultCenter={{ lat: 32.779167, lng: -96.808891 }}
        defaultZoom={13}
        onClick={handleMapClick}
      >
        {Boolean(allMarkers.length) &&
          allMarkers.map((marker) => (
            <Markers
              key={marker.id}
              point={marker}
              onClick={() => handleMarkerClick(marker.property)}
              isSelected={selectedProperty && selectedProperty.id === marker.property.id}
              isLoading={marker.isLoading}
            />
          ))}
        <MapControl position={ControlPosition.TOP_CENTER}>
          <UndoRedoControl
            drawingManager={drawingManager}
            state={state}
            dispatch={dispatch}
          />
        </MapControl>
      </Map>
      {isLoading && <LoadingOverlay />}
      <MarketSearchDrawer
        handleClickClose={handleClickMapDrawerClose}
        isOpen={isMapDrawerOpen}
        container={drawerContainerRef.current}
        setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
        properties={properties}
        property={selectedProperty}
      />
    </div>
  );
};

export default MapSearchWithBounds;