import styled from 'styled-components';
import { Flex, Form } from 'antd';
import { Link } from 'react-router-dom';
import { COLORS } from '../../constants/colors';

export const StyledFlex = styled(Flex)`
  padding: 0 24px;
  width: 100%;
  margin: 0 auto;
  max-width: 382px;
`;
export const StyledForm = styled(Form)`
  & .ant-form-item {
    @media (max-width: 1024px) {
      margin-bottom: 16px;
    }
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
`;

export const StyledForgot = styled.div`
  margin-top: -16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.$blackOpacity50};
`;

export const StyledRegister = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${COLORS.$greyScale500};

  @media (max-width: 1024px) {
    font-weight: 400;
  }

  a {
    font-weight: 700;
    color: ${COLORS.$black};

    @media (max-width: 1024px) {
      font-weight: 500;
    }
  }
`;
