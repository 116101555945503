import React, { useState, useEffect } from 'react';
import { Slider, Input, Form, Tooltip } from 'antd';

// Styles
import {
  MortgageCalculatorCard,
  CustomDivider,
  StyledButton,
  StyledInput,
  StyledSelect,
} from './styled';

const { Option } = StyledSelect;

const MortgageCalculator = ({ initialPrice }) => {
  const [price, setPrice] = useState(initialPrice);
  const [downPaymentPercentage, setDownPaymentPercentage] = useState(10);
  const [downPayment, setDownPayment] = useState((initialPrice * 0.10).toFixed(2));
  const [loanLength, setLoanLength] = useState(30);
  const [interestRate, setInterestRate] = useState(3.5);
  const [propertyTax, setPropertyTax] = useState(1.25);
  const [homeInsurance, setHomeInsurance] = useState(0.5);
  const [pmi, setPmi] = useState(0.5);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [downPaymentLabel, setDownPaymentLabel] = useState(`Down Payment (${downPaymentPercentage}%)`);

  useEffect(() => {
    calculateMonthlyPayment();
  }, [price, downPaymentPercentage, loanLength, interestRate, propertyTax, homeInsurance, pmi]);

  const calculateMonthlyPayment = () => {
    const principal = price - (price * (downPaymentPercentage / 100));
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanLength * 12;
    const mortgagePayment = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    const monthlyPropertyTax = (price * (propertyTax / 100)) / 12;
    const monthlyHomeInsurance = (price * (homeInsurance / 100)) / 12;
    const monthlyPMI = (price * (pmi / 100)) / 12;

    const totalMonthlyPayment = mortgagePayment + monthlyPropertyTax + monthlyHomeInsurance + monthlyPMI;
    setMonthlyPayment(totalMonthlyPayment.toFixed(2));
  };

  const handleDownPaymentPercentageChange = (value) => {
    setDownPaymentPercentage(value);
    setDownPayment((price * (value / 100)).toFixed(2));
    setDownPaymentLabel(`Down Payment (${value}%)`);
  };

  const handleDownPaymentChange = (e) => {
    const value = Number(e.target.value);
    setDownPayment(value.toFixed(2));
    const percentage = ((value / price) * 100).toFixed(2);
    setDownPaymentPercentage(percentage);
    setDownPaymentLabel(`Down Payment (${percentage}%)`);
  };

  return (
    <MortgageCalculatorCard title="Mortgage Calculator">
      <Form layout="vertical">
        <Form.Item label="Price">
          <StyledInput value={price} onChange={(e) => setPrice(Number(e.target.value))} />
        </Form.Item>
        <Form.Item label="Down Payment">
          <StyledInput value={downPayment} onChange={handleDownPaymentChange} />
        </Form.Item>
        <Form.Item label={downPaymentLabel}>
          <Tooltip
            title={`$${downPayment} (${downPaymentPercentage}%)`}
            placement="top"
          >
            <Slider min={0} max={100} value={downPaymentPercentage} onChange={handleDownPaymentPercentageChange} />
          </Tooltip>
        </Form.Item>
        <Form.Item label="Loan Length (years)">
          <StyledSelect value={loanLength} onChange={(value) => setLoanLength(Number(value))}>
            <Option value={15}>15 years</Option>
            <Option value={30}>30 years</Option>
          </StyledSelect>
        </Form.Item>
        <Form.Item label="Interest Rate (%)">
          <StyledInput value={interestRate} onChange={(e) => setInterestRate(Number(e.target.value))} />
        </Form.Item>
        <CustomDivider />
        <h3>Monthly Payment: ${monthlyPayment}</h3>
      </Form>
    </MortgageCalculatorCard>
  );
};

export default MortgageCalculator;
