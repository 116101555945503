import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

// Styles
import styles from './SavedSearches.module.scss';

// Components
import { Flex, Table, Button } from 'antd';
import BaseTypography from '../../../components/BaseTypography';
import { PlusOutlined } from '@ant-design/icons';
import { ROUTE_PATH } from '../../../constants/routePath';
import AdvancedSearch from './AdvancedSearch';

const SAVED_SEARCH_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => (a.name < b.name ? -1 : 1),
  },
  {
    title: 'Date Created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sorter: (a, b) => (a.dateCreated < b.dateCreated ? -1 : 1),
  },
  {
    title: 'Manage',
    dataIndex: 'manage',
    key: 'manage',
    render: () => <a>Manage</a>,
  },
];

const SavedSearches = () => {
  const [savedSearches, setSavedSearches] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickRow = (row) => {
    const { query } = row;
    const search = createSearchParams(query).toString();
    const path = { pathname: ROUTE_PATH.SEARCH, search };
    navigate(path);
  };

  const handleClickOpenModal = () => {
    setIsSearchModalOpen(true);
  };

  const handleSaveSearch = (searchValues) => {
    const query = {
      property_type: searchValues.propertySubType,
      baths_min: searchValues.bathrooms,
      beds_min: searchValues.bedrooms,
      lot_size_min: searchValues.lotSize,
      construction: searchValues.constructionMaterials,
      mls_listing_price_min: searchValues.priceLow,
      mls_listing_price_high: searchValues.priceHigh,
      year_built_min: searchValues.yearBuilt,
      year_built_max: searchValues.yearBuilt,
    };
  };

  useEffect(() => {
    const localStorageSavedSearches =
      JSON.parse(localStorage.getItem('INVESTED_SAVED_SEARCHES')) ?? [];

    if (localStorageSavedSearches.length) {
      const savedSearchesData = localStorageSavedSearches.reduce(
        (total, current) => {
          const { query, searchName, date } = current;
          const dateCreated = new Date(date).toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
          const currentData = {
            name: searchName,
            dateCreated,
            query,
          };

          return [...total, currentData];
        },
        []
      );

      setSavedSearches(savedSearchesData);
    }
  }, []);

  return (
    <div>
      <Flex className={styles.header} justify="space-between" align="center">
        <Button
          onClick={handleClickOpenModal}
          type="primary"
          style={{background: 'black'}}
          icon={<PlusOutlined />}
        >
          Add New
        </Button>
      </Flex>
      <Table
        onRow={(row) => ({ onClick: () => handleClickRow(row) })}
        dataSource={savedSearches}
        columns={SAVED_SEARCH_COLUMNS}
        rowClassName={styles.row}
      />
      {isSearchModalOpen && (
        <AdvancedSearch
          isSearchModalOpen={isSearchModalOpen}
          setIsSearchModalOpen={setIsSearchModalOpen}
        />
      )}
    </div>
  );
};

export default SavedSearches;