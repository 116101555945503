import React, { useState } from 'react';
import { Input, Button, Form, Table } from 'antd';
import {
  CalculatorContainer,
  CalculatorTitle,
  ResultContainer,
  FormItem,
} from './Styled';
import { StyledButton } from '../../Lend/styled';

const AmortizationCalculator = () => {
  const [form] = Form.useForm();
  const [schedule, setSchedule] = useState([]);

  const onFinish = (values) => {
    const { principal, interestRate, term } = values;
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = term * 12;
    const monthlyPayment = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    let balance = principal;
    let amortizationSchedule = [];

    for (let i = 0; i < numberOfPayments; i++) {
      const interest = balance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interest;
      balance -= principalPayment;

      amortizationSchedule.push({
        month: i + 1,
        payment: monthlyPayment.toFixed(2),
        principal: principalPayment.toFixed(2),
        interest: interest.toFixed(2),
        balance: balance.toFixed(2)
      });
    }

    setSchedule(amortizationSchedule);
  };

  const columns = [
    { title: 'Month', dataIndex: 'month', key: 'month' },
    { title: 'Payment', dataIndex: 'payment', key: 'payment' },
    { title: 'Principal', dataIndex: 'principal', key: 'principal' },
    { title: 'Interest', dataIndex: 'interest', key: 'interest' },
    { title: 'Balance', dataIndex: 'balance', key: 'balance' },
  ];

  return (
    <CalculatorContainer>
      <CalculatorTitle>Amortization Schedule Calculator</CalculatorTitle>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label="Principal Amount"
          name="principal"
          rules={[{ required: true, message: 'Please input the principal amount!' }]}
        >
          <Input prefix="$" type="number" placeholder="Principal Amount" />
        </FormItem>
        <FormItem
          label="Interest Rate (%)"
          name="interestRate"
          rules={[{ required: true, message: 'Please input the interest rate!' }]}
        >
          <Input suffix="%" type="number" placeholder="Interest Rate" />
        </FormItem>
        <FormItem
          label="Term (years)"
          name="term"
          rules={[{ required: true, message: 'Please input the term!' }]}
        >
          <Input type="number" placeholder="Term" />
        </FormItem>
        <FormItem>
          <StyledButton type="primary" htmlType="submit">
            Calculate
          </StyledButton>
        </FormItem>
      </Form>
      {schedule.length > 0 && (
        <ResultContainer>
          <Table
            columns={columns}
            dataSource={schedule}
            rowKey="month"
            pagination={false}
          />
        </ResultContainer>
      )}
    </CalculatorContainer>
  );
};

export default AmortizationCalculator;
