import React, { useState, useEffect } from 'react';
import { Row, Col, Skeleton, Flex, Divider } from 'antd';
import { isMobile, isDesktop } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  PropertyList,
  StyledCard,
  StyledSelect,
  SectionHighlight,
  SectionSubheading,
  StyledFilterCard,
  StyledSearchButton,
  LinkStyle,
  FilterGroup,
  PriceTypography,
  NeighborhoodTypography,
  AddressTypography,
} from './styled';
// import { FavoritedSvg, FavoritedFilledSvg, BedroomSvg, BathroomSvg } from '../../../../components/Icons';
import { formatNumberToDollars } from '../../../../utils/numberFormat';
import listingItem from '../../../../assets/images/listing-example.png';

const { Option } = StyledSelect;

const ListItem = ({ lendingTerms }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (lendingTerms && lendingTerms.length > 0) {
      setLoading(false);
    }
  }, [lendingTerms]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const FilterCard = () => {
    return (
      <StyledFilterCard>
        <FilterGroup>
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>Location</div>
          <StyledSelect defaultValue="Select City">
            <Option value="Allen">Allen</Option>
            <Option value="Arlington">Arlington</Option>
            <Option value="Carrollton">Carrollton</Option>
            <Option value="Dallas">Dallas</Option>
            <Option value="Denton">Denton</Option>
            <Option value="Euless">Euless</Option>
            <Option value="Flower Mound">Flower Mound</Option>
            <Option value="Fort Worth">Fort Worth</Option>
            <Option value="Frisco">Frisco</Option>
            <Option value="Garland">Garland</Option>
            <Option value="Grand Prairie">Grand Prairie</Option>
            <Option value="Irving">Irving</Option>
            <Option value="Lewisville">Lewisville</Option>
            <Option value="McKinney">McKinney</Option>
            <Option value="Mesquite">Mesquite</Option>
            <Option value="Plano">Plano</Option>
            <Option value="Richardson">Richardson</Option>
            <Option value="Rowlett">Rowlett</Option>
            <Option value="Southlake">Southlake</Option>
            <Option value="The Colony">The Colony</Option>
          </StyledSelect>
        </FilterGroup>

        <FilterGroup>
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>Select Price</div>
          <StyledSelect defaultValue="$500 - $2,500">
            <Option value="$500 - $2,500">$500 - $2,500</Option>
          </StyledSelect>
        </FilterGroup>

        {isDesktop && (
          <FilterGroup>
            <div style={{ textAlign: 'left', marginBottom: '10px' }}>Property Type</div>
            <StyledSelect defaultValue="Select Type">
              <Option value="SFR">Single Family</Option>
            </StyledSelect>
          </FilterGroup>
        )}

        <StyledSearchButton>Apply Filter</StyledSearchButton>
      </StyledFilterCard>
    );
  };

  const renderCards = () => (
    lendingTerms.map(term => (
      <Col key={term.listingID} xs={24} s={12} md={8}>
        <Link to={`/details/${term.listingID}`}>
          <StyledCard
            cover={<img alt={term.address} src={term.image || listingItem} />}
            hoverable
          >
            <Flex vertical>
              <Flex justify="space-between" align="center">
                <div>
                  <PriceTypography>
                    {formatNumberToDollars(term.price)}
                  </PriceTypography>
                  <NeighborhoodTypography>
                    {term.subdivision || term.type}
                  </NeighborhoodTypography>
                </div>
              </Flex>

              <Divider style={{ margin: '7px 0' }} />

              <AddressTypography>
                {`${term.address} ${term.city}, ${term.state}`}
              </AddressTypography>
            </Flex>
          </StyledCard>
        </Link>
      </Col>
    ))
  );

  return (
    <>
      {isDesktop ? (
        <PropertyList>
          <LinkStyle to="/">
            <ArrowLeftOutlined /> Back Home
          </LinkStyle>
          <SectionHighlight>All Properties</SectionHighlight>
          <SectionSubheading>We thought you might like these.</SectionSubheading>
          {/* <FilterCard /> */}
          <Row gutter={[16, 16]}>
            {loading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              renderCards()
            )}
          </Row>
        </PropertyList>
      ) : (
        <PropertyList>
          <LinkStyle to="/">
            <ArrowLeftOutlined /> Back Home
          </LinkStyle>
          <SectionHighlight>All Properties</SectionHighlight>
          <SectionSubheading>We thought you might like these.</SectionSubheading>
          {/* <FilterCard /> */}
          <Row gutter={[16, 16]}>
            {loading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              renderCards()
            )}
          </Row>
        </PropertyList>
      )}
    </>
  );
};

export default ListItem;