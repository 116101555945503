import styled from 'styled-components';
import { Button } from 'antd';
import { COLORS } from '../../constants/colors';

export const StyledButton = styled(Button)`
  &&& {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    background: ${COLORS.$mainBlue};
    font-size: 16px;
    color: ${COLORS.$white};
    line-height: 150%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${COLORS.$mainBlue};
    font-weight: 700;

    &:hover:not([disabled]) {
      border: 2px solid ${COLORS.$blueShades92};
      border-color: ${COLORS.$blueShades92};
      color: ${COLORS.$blueShades98};
      background: ${COLORS.$mainBlue};
      opacity: 0.95;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;

export const StyledOutlineButton = styled(Button)`
  &&& {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    background: ${COLORS.$white};
    font-size: 16px;
    color: ${COLORS.$mainBlue};
    line-height: 150%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${COLORS.$mainBlue};
    font-weight: 700;

    &:hover:not([disabled]) {
      border: 2px solid ${COLORS.$blueShades92};
      border-color: ${COLORS.$blueShades92};
      color: ${COLORS.$mainBlue};
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;
