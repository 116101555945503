import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button, Radio, Tabs, Spin, Divider } from 'antd';
import { UserOutlined, EnvironmentOutlined, PhoneOutlined, LockOutlined, BellOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from '../../../hooks/storeContext';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

const { TabPane } = Tabs;

const StyledInput = styled(Input)`
  height: 48px;
  padding: 0 11px;
`;

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full viewport height */
`;

const Header = styled.h1`
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const Email = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const SettingItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const IconWrapper = styled.span`
  margin-right: 15px;
  font-size: 18px;
`;

const SettingContent = styled.span`
  flex-grow: 1;
`;

const EditButton = styled(Button)`
  margin-left: 10px;
`;

const StyledDivider = styled(Divider)`
  margin: 40px 0;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const SettingsPage = observer(() => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [notificationForm] = Form.useForm();
  const location = useLocation();
  const { authStore } = useStore();

  const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];  

  useEffect(() => {
    authStore.fetchUserProfile();
  }, [authStore]);

  useEffect(() => {
    if (authStore.me) {
      form.setFieldsValue({
        firstName: authStore.me.firstName,
        lastName: authStore.me.lastName,
        address1: authStore.me.address1,
        zip: authStore.me.zip,
        city: authStore.me.city,
        state: authStore.me.state,
        country: authStore.me.country,
        areaCode: authStore.me.areaCode,
        phone: authStore.me.phone,
      });

      notificationForm.setFieldsValue({
        emailFrequency: authStore.me.emailFrequency || 'never',
        newsUpdates: authStore.me.newsUpdates || 'yes',
      });
    }
  }, [authStore.me, form, notificationForm]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    authStore.updateUserProfile(values);
    setIsModalVisible(false);
  };

  const onNotificationSettingsFinish = (values) => {
    authStore.updateUserProfile(values);
  };

  if (authStore.pending) {
    return (
      <SpinnerContainer>
        <Spin indicator={antIcon} />
      </SpinnerContainer>
    );
  }

  if (!authStore.me) {
    return (
      <PageContainer>
        <Header>Please log in to view your settings.</Header>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header>
        Hi there{authStore.me?.firstName ? `, ${authStore.me.firstName}` : ''}!
      </Header>
      <Email>{authStore.me?.email}</Email>

      <Tabs defaultActiveKey={location.pathname}>
        <TabPane tab={<Link to="/buyer-profile">Buyer profile</Link>} key="/buyer-profile" />
        <TabPane tab={<Link to="/saved-homes">Saved Listings</Link>} key="/saved-homes" />
        <TabPane tab={<Link to="/settings">Settings</Link>} key="/settings" />
      </Tabs>

      <SectionTitle>Personal Information</SectionTitle>

      <SettingItem>
        <IconWrapper><UserOutlined /></IconWrapper>
        <SettingContent>
          {authStore.me.firstName || authStore.me.lastName
            ? `${authStore.me.firstName || ''} ${authStore.me.lastName || ''}`.trim()
            : 'Add name'}
        </SettingContent>
        <EditButton type="link" onClick={showModal}>Edit</EditButton>
      </SettingItem>

      <SettingItem>
        <IconWrapper><EnvironmentOutlined /></IconWrapper>
        <SettingContent>
          {authStore.me.address1
            ? `${authStore.me.address1}, ${authStore.me.city || ''}, ${authStore.me.state || ''}, ${authStore.me.zip || ''}, ${authStore.me.country || ''}`
            : 'Add address'}
        </SettingContent>
        <EditButton type="link" onClick={showModal}>Edit</EditButton>
      </SettingItem>

      <SettingItem>
        <IconWrapper><PhoneOutlined /></IconWrapper>
        <SettingContent>
          {authStore.me.phone ? `${authStore.me.areaCode || ''} ${authStore.me.phone}` : 'Add phone number'}
        </SettingContent>
        <EditButton type="link" onClick={showModal}>Edit</EditButton>
      </SettingItem>

      <Button type="primary" onClick={showModal}>Edit Profile</Button>

      <StyledDivider />

      <SectionTitle>Account Settings</SectionTitle>

      <SettingItem>
        <IconWrapper><LockOutlined /></IconWrapper>
        <SettingContent>Password</SettingContent>
        <Button type="link">Reset password</Button>
      </SettingItem>

      <StyledDivider />

      <SectionTitle>Notification Settings</SectionTitle>

      <Form form={notificationForm} layout="vertical" onFinish={onNotificationSettingsFinish}>
        <SettingItem>
          <IconWrapper><BellOutlined /></IconWrapper>
          <SettingContent>Email me saved listings and searches</SettingContent>
        </SettingItem>
        <Form.Item name="emailFrequency">
          <Radio.Group>
            <Radio value="realtime">In realtime</Radio>
            <Radio value="daily">Once a day</Radio>
            <Radio value="never">No, thanks</Radio>
          </Radio.Group>
        </Form.Item>

        <SettingItem>
          <SettingContent>Send me real estate and moving related updates, tips, promos and news</SettingContent>
        </SettingItem>

        <Form.Item name="newsUpdates">
          <Radio.Group>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">Save Notification Settings</Button>
        </Form.Item>
      </Form>

      <Modal
        title="Edit Profile"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item name="firstName" label="First Name">
            <StyledInput />
          </Form.Item>
          <Form.Item name="lastName" label="Last Name">
            <StyledInput />
          </Form.Item>
          <Form.Item name="address1" label="Address">
            <StyledInput />
          </Form.Item>
          <Form.Item name="zip" label="ZIP">
            <StyledInput />
          </Form.Item>
          <Form.Item name="city" label="City">
            <StyledInput />
          </Form.Item>
          <Form.Item name="state" label="State">
            <Select placeholder="Select a state">
              {states.map((state) => (
                <Select.Option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="phone" label="Phone">
            <StyledInput placeholder="e.g. 999-9999" />
          </Form.Item>
          <Form.Item>
            <Button type="link" danger>Delete my account</Button>
          </Form.Item>
          <Form.Item>
            <Button style={{height: '48px'}} type="primary" htmlType="submit">
              Save Changes
            </Button>
            <Button style={{height: '48px', marginLeft: 8 }} onClick={handleCancel}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </PageContainer>
  );
});

export default SettingsPage;