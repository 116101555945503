import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined } from '@ant-design/icons';

const StyledButton = styled(Button)`
  height: 48px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  
  &:hover {
    border-color: #000;
    color: #000;
    background: #fff;
  }

  &:focus {
    background: #fff;
    color: #000;
  }
  
  .price-text {
    flex: 1;
    text-align: left;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-left: 4px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  
  .close-icon {
    color: #666;
    font-size: 12px;
    flex-shrink: 0;
    
    &:hover {
      color: #000;
    }
  }

  .anticon {
    flex-shrink: 0;
  }

  &.ant-btn:not(:disabled):hover {
    color: #000;
    border-color: #000;
  }

  &.ant-btn:not(:disabled):active {
    color: #000;
    border-color: #000;
  }
`;

export const formatPrice = (price) => {
  if (!price && price !== 0) return '$0';
  
  if (price >= 1000000) {
    return `$${(price / 1000000).toFixed(1)}M`;
  } else if (price >= 1000) {
    return `$${(price / 1000).toFixed(0)}K`;
  }
  return `$${price}`;
};

export const PriceFilterButton = ({ 
  priceRange,
  isPopupVisible,
  onClick,
  onReset
}) => {
  const [minPrice, maxPrice] = priceRange;
  
  const getButtonText = useCallback(() => {
    // Check if we're at default/initial values
    const isDefaultState = (minPrice === 0 && maxPrice === 2000000) || 
                          (minPrice === 0 && maxPrice === 0) || 
                          (!minPrice && !maxPrice);
    
    if (isDefaultState) {
      return 'Pricing';
    }
    
    if (minPrice === 0) {
      return `Up to ${formatPrice(maxPrice)}`;
    }
    if (maxPrice === 2000000) {
      return `From ${formatPrice(minPrice)}`;
    }
    return `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`;
  }, [minPrice, maxPrice]);

  const handleReset = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onReset(e);
  }, [onReset]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    onClick(e);
  }, [onClick]);

  const buttonText = getButtonText();
  const isDefaultPrice = buttonText === 'Pricing';

  return (
    <StyledButton 
      onClick={handleClick}
      type="default"
    >
      <span className="price-text">{buttonText}</span>
      <div className="icon-wrapper">
        {isDefaultPrice ? (
          isPopupVisible ? <CaretUpOutlined /> : <CaretDownOutlined />
        ) : (
          <CloseOutlined 
            className="close-icon" 
            onClick={handleReset}
            aria-label="Reset price filter"
          />
        )}
      </div>
    </StyledButton>
  );
};