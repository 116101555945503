import Auth from '../pages/Auth';
import Login from '../pages/Auth/components/Login';
import RequireAuth from '../pages/RequireAuth';
import AgentAuth from '../pages/AgentAuth';
import Dashboard from '../pages/Dashboard';
import AgentDashboard from '../pages/Agents/AgentDashboard/index.js';
import MarketSearch from '../pages/MarketSearch';
import Register from '../pages/Auth/components/Register';
import ForgotPassword from '../pages/Auth/components/ForgotPassword';
import AuthLayout from '../layouts/AuthLayout';
import Details from '../pages/Details';
import UserFavorites from '../pages/UserFavorites';
import Lend from '../pages/Lend';
import Calculators from '../pages/Calculators'
import Settings from '../pages/Settings';
import MyProperties from '../pages/MyProperties';
import AgentProperties from '../pages/Agents/MyProperties'
import PublicLayout from "../layouts/PublicLayout";
import OnboardingLayout from "../layouts/OnboardingLayout";
import Onboarding from "../pages/Onboarding"
import Home from "../pages/Home";
import HomeDetails from '../pages/HomeDetails/Details';
import HomeSearch from '../pages/HomeSearch'
import TrustAndSafety from '../pages/HomeDetails/CompanyPages/trustAndSafty.js'
import TermsOfService from '../pages/HomeDetails/CompanyPages/termsOfService.js';
import PrivacyPolicy from '../pages/HomeDetails/CompanyPages/privacyPolicy.js';
import Company from '../pages/HomeDetails/CompanyPages/company.js';
import HowItWorks from '../pages/HomeDetails/CompanyPages/howItWorks.js';
import Contact from '../pages/HomeDetails/CompanyPages/contact.js';
import PropertyLoanApplication from '../pages/HomeDetails/components/PropertyLoanApplication.js'
import Investors from '../pages/HomeDetails/CompanyPages/investors.js';
import Map from '../pages/Map'
import Applicants from '../pages/Agents/AgentDashboard/Applicants/index.js';
import Approval from '../pages/Agents/Approval/index.js';
import AllApplicants from '../pages/Agents/Applicants/index.js';
import PendingApproval from '../pages/PendingApproval/index.js';

// Assume we have a LandingPage component for invested.homes
import LandingPage from '../pages/LendersHome';
import AffordabilityCalculator from '../pages/Home/Calculators/AffordabilityCalculator/index.js';
import MortgageCalculator from '../pages/Home/Calculators/MortgageCalculator/index.js';
import AgentLogin from '../pages/AgentAuth/components/Login/index.js';
import BuyerProfile from '../pages/Home/BuyerProfile/index.js';
import SettingsPage from '../pages/Home/SettingsPage/index.js';
import SavedHomes from '../pages/Home/SavedHomes/index.js';

const currentDomain = window.location.hostname;
//const currentDomain = 'invested.homes';

export const ROUTES = (() => {
  switch (currentDomain) {
    case 'admin.invested.homes':
      return [
        {
          path: '/',
          element: <AuthLayout />,
          children: [
            { index: true, element: <Login /> },
            { path: 'login', element: <Login /> },
            { path: 'register', element: <Register /> },
            { path: 'forgot', element: <ForgotPassword /> },
            { path: 'pending-approval', element: <PendingApproval /> },
          ],
        },
        {
          path: '/',
          element: <RequireAuth />,
          children: [
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'map', element: <Map /> },
            { path: 'search', element: <MarketSearch /> },
            { path: 'details/:id', element: <Details /> },
            { path: 'favorites', element: <UserFavorites /> },
            { path: 'lend', element: <Lend /> },
            { path: 'calculators', element: <Calculators /> },
            { path: 'settings', element: <Settings /> },
            { path: 'myproperties', element: <MyProperties /> },
          ],
        },
        {
          path: '/onboarding',
          element: <OnboardingLayout />,
          children: [
            { index: true, element: <Onboarding /> },
          ],
        },
      ];
      case 'agent.invested.homes':
        return [
          {
            path: '/',
            element: <AuthLayout />,
            children: [
              { index: true, element: <AgentLogin /> },
              { path: 'login', element: <AgentLogin /> },
              { path: 'register', element: <Register /> },
              { path: 'forgot', element: <ForgotPassword /> },
            ],
          },
          {
            path: '/',
            element: <AgentAuth />,
            children: [
              { path: 'agent/dashboard', element: <AgentDashboard /> },
              { path: 'applicants/:listingID', element: <Applicants /> },
              { path: 'agent/properties', element: <AgentProperties /> }, 
              { path: 'agent/applicants', element: <AllApplicants /> },
              { path: 'agent/approvals', element: <Approval /> },
            ],
          },
        ];
    case 'invested.homes':
      return [
        {
          path: '/',
          element: <PublicLayout />,
          children: [
            { index: true, element: <Home /> },
            { path: 'search', element: <HomeSearch /> },
            { path: 'details/:id', element: <HomeDetails /> },
            { path: 'application/:id', element: <PropertyLoanApplication /> },
            { path: 'trust-and-safety', element: <TrustAndSafety /> },
            { path: 'terms-of-service', element: <TermsOfService /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'company', element: <Company /> },
            { path: 'how-it-works', element: <HowItWorks /> },
            { path: 'contact', element: <Contact /> },
            { path: 'investors', element: <Investors /> },
            { path: 'tools/affordability-calculator', element: <AffordabilityCalculator /> },
            { path: 'tools/mortgage-calculator', element: <MortgageCalculator /> },
            { path: 'buyer-profile', element: <BuyerProfile /> },
            { path: 'settings', element: <SettingsPage /> }, 
            { path: 'saved-homes', element: <SavedHomes /> }, 
          ],
        },
      ];
    case 'buy.invested.homes':
    default:
      return [
        {
          path: '/',
          element: <LandingPage />,
        },
        // Add any additional routes for invested.homes if needed
      ];
  }
})();