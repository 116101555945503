import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CustomSpinner = () => (
  <LoadingOutlined
    style={{
      fontSize: 50,
      color: '#1890ff' // Ant Design's primary color
    }}
    spin
  />
);

const LoadingOverlay = () => (
  <div style={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  }}>
    <Spin indicator={<CustomSpinner />} />
  </div>
);

export default LoadingOverlay;