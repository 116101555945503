import { useState } from "react";
import Onboarding1 from "./components/Onboarding1";
import Onboarding2 from "./components/Onboarding2";
import Onboarding3 from "./components/Onboarding3";
import Onboarding4 from "./components/Onboarding4";

const Onboarding = () => {
    const [step, setStep] = useState(1);

    const handleSetStep = (newStep = {}) => {
        setStep(newStep);
    };

    switch (step) {
        case 1:
            return <Onboarding1 setStep={handleSetStep} />;
        case 2:
            return <Onboarding2 setStep={handleSetStep} />;
        case 3:
            return <Onboarding3 setStep={handleSetStep} />;
        case 4:
            return <Onboarding4 setStep={handleSetStep} />;
        default:
            return <Onboarding1 setStep={handleSetStep} />;
    }
};

export default Onboarding;
