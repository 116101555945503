import Icon from '@ant-design/icons';
import { GridSvg, TableSvg, MapSvg } from '../components/Icons';
import { formatNumberToDollars } from '../utils/numberFormat';
import styles from '../pages/MarketSearch/MarketSearch.module.scss';

export const COLUMNS = [
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    sorter: (a, b) => (a.address < b.address ? -1 : 1),
  },
  {
    title: 'Subdivision',
    dataIndex: 'subdivision',
    key: 'subdivision',
    sorter: (a, b) => (a.subdivision < b.subdivision ? -1 : 1),
    render: (text) => <span>{text ?? 'N/A'}</span>,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    sorter: (a, b) => (a.city < b.city ? -1 : 1),
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    sorter: (a, b) => (a.state < b.state ? -1 : 1),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => (a.type < b.type ? -1 : 1),
  },
  {
    title: 'Lot Sqft',
    dataIndex: 'lot_sqft',
    key: 'lot_sqft',
    sorter: (a, b) => a.loft_sqft - b.loft_sqft,
  },
  {
    title: 'Price',
    dataIndex: 'current_price',
    key: 'current_price',
    sorter: (a, b) => a.current_price - b.current_price,
    render: (text) => formatNumberToDollars(text),
  },
  {
    title: 'DOM',
    dataIndex: 'dom',
    key: 'dom',
    sorter: (a, b) => a.dom - b.dom,
  },
  {
    title: 'Status',
    dataIndex: 'change_type',
    key: 'change_type',
    sorter: (a, b) => (a.change_type < b.change_type ? -1 : 1),
  },
];

export const VIEW_OPTION = {
  grid: 'Grid',
  table: 'Table',
  map: 'Map',
};

export const VIEW_OPTIONS = [
  {
    value: VIEW_OPTION.grid,
    icon: <Icon className={styles.searchIcon} component={GridSvg} />,
  },
  {
    value: VIEW_OPTION.table,
    icon: <Icon className={styles.searchIcon} component={TableSvg} />,
  },
  {
    value: VIEW_OPTION.map,
    icon: <Icon className={styles.searchIcon} component={MapSvg} />,
  },
];

export const DRAWER_OPTION = {
  grid: 'Grid',
  list: 'List',
};

export const DRAWER_OPTIONS = [
  {
    value: VIEW_OPTION.grid,
    icon: <Icon className={styles.searchIcon} component={GridSvg} />,
  },
  {
    value: VIEW_OPTION.table,
    icon: <Icon className={styles.searchIcon} component={TableSvg} />,
  },
];

export const PRICE_SELECT_OPTIONS = [
  { value: 'anyPrice', label: 'Any Price' },
  { value: '1000-2000', label: '$1000 - $2000' },
  { value: '2000-3000', label: '$2000 - $3000' },
  { value: '3000+', label: '$3000+' },
];

export const BEDROOM_SELECT_OPTIONS = [
  { value: '1to2', label: '1-2 Beds' },
  { value: '2to4', label: '2-4 Beds' },
  { value: '4plus', label: '4+ Beds' },
];

export const HOUSING_SELECT_OPTIONS = [
  { value: 'allTypes', label: 'All Types' },
  { value: 'residential', label: 'Residential' },
  { value: 'commercialSale', label: 'Commercial Sale' },
  { value: 'land', label: 'Land' },
];

export const HOUSING_PRICE_TYPE_OPTIONS = [
  { value: 'newest', label: 'Newest' },
  { value: 'lowestPrice', label: 'Lowest Price' },
  { value: 'highestPrice', label: 'Highest Price' },
  { value: 'largest', label: 'Largest' },
  { value: 'smallest', label: 'Smallest' },
];

export const PROPERTY_TYPE_OPTIONS = [
  { value: 'SFR', label: 'Single Family Residence' },
  { value: 'MFR', label: 'Multi-Family Residence' },
  { value: 'LAND', label: 'Land' },
  { value: 'CONDO', label: 'Condo' },
  { value: 'MOBILE', label: 'Mobile' },
  { value: 'OTHER', label: 'Other' },
];

export const CONSTRUCTION_MATERIALS_OPTIONS = [
  { value: 'adobe', label: 'Adobe' },
  { value: 'archedDome', label: 'Arched/Dome' },
  { value: 'brickConcrete', label: 'Brick/Concrete' },
  { value: 'frame', label: 'Frame' },
  { value: 'heavyFrame', label: 'Heavy Frame' },
  { value: 'steelFrame', label: 'Steel Frame' },
  { value: 'woodFrame', label: 'Wood Frame' },
  { value: 'metalFrame', label: 'Metal Frame' },
  { value: 'lightFrame', label: 'Light Frame' },
  { value: 'poleFrame', label: 'Pole Frame' },
  { value: 'unknown', label: 'Unknown' },
  { value: 'wood', label: 'Wood' },
  { value: 'masonryCmus', label: 'Masonry/Concrete Masonry Units (CMUS)' },
  { value: 'brick', label: 'Brick' },
  { value: 'stone', label: 'Stone' },
  { value: 'tiltUpConcrete', label: 'Tilt-up Concrete' },
  { value: 'manufactured', label: 'Manufactured' },
  { value: 'mixed', label: 'Mixed' },
  { value: 'log', label: 'Log' },
  { value: 'steelConcrete', label: 'Steel & Concrete' },
];

export const DETAILS_TABLE_COLUMNS = [
  {
    title: 'Effective Date',
    dataIndex: 'effective_date',
    key: 'effective_date',
    sorter: (a, b) => (a.change_time < b.change_time ? -1 : 1),

  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    sorter: (a, b) => a.price - b.price,
    render: (text) => formatNumberToDollars(text),

  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => (a.change_time < b.change_time ? -1 : 1),

  },
  {
    title: 'DOM',
    dataIndex: 'dom',
    key: 'dom',
    sorter: (a, b) => a.dom - b.dom,

  },
  {
    title: 'Agent',
    dataIndex: 'agent',
    key: 'agent',
    sorter: (a, b) => a.dom - b.dom,
    render: (agent) => agent.name,
  },
];

export const RESIDENTIAL_TYPE_OPTIONS = [
  { value: 'SFR', label: 'Residential' },
  { value: 'MFR', label: 'Multi Family Residential' },
  { value: 'LAND', label: 'Land' },
  { value: 'CONDO', label: 'Condo' },
  { value: 'MOBILE', label: 'Mobile' },
  { value: 'OTHER', label: 'Other' },
];

export const ROUTE_PATH = {
  DASHBOARD: '/dashboard',
  ONBOARDING: "/onboarding",
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot',
  SEARCH: '/search',
  DETAILS: '/details',
  FAVORITES: '/favorites',
  MAP: '/map',
  LEND: '/lend',
  CALCULATORS: '/calculators',
  SETTINGS: '/settings',
  MY_PROPERTIES: '/myproperties',
  PENDING_APPROVAL: '/pending-approval',
};

export const AGENT_ROUTE_PATH = {
  DASHBOARD: '/agent/dashboard',
  PROPERTIES: "/agent/properties",
  APPLICANTS: '/agent/applicants',
  APPROVALS: '/agent/approvals',
};
