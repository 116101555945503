import styled from 'styled-components';
import { Layout, Button } from 'antd';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  background: #fff;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px!important;
`;


export const Logo = styled.img`
  height: 32px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoText = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
  color: #333; // Adjust the color to match your design
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-menu {
    border-bottom: none;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 20px;
  background-color: #005eb8;
  border-color: #005eb8;
  color: white;
  align-content: center;
  width: 100px;
  height: 48px;

  &&&& {
    background-color: #005eb8;
    border-color: #005eb8;
    color: white;
  }
`;