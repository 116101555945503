import React, { useState } from 'react';
import { isEqual } from 'lodash';

// Styles
import styles from './AdvancedSearch.module.scss';

// Constants
import {
  CONSTRUCTION_MATERIALS_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
} from '../../../../constants';

// Components
import { Modal, Flex, Input, Button } from 'antd';
import BaseTypography from '../../../../components/BaseTypography';
import SelectComponent from '../../../../components/SelectComponent';

const ADVANCE_SEARCH_DEFAULT_VALUES = {
  housingType: '',
  subdivision: '',
  propertySubType: '',
  constructionMaterials: '',
  lotSize: '',
  parking: '',
  sellerType: '',
  lotDescription: '',
  priceLow: '',
  priceHigh: '',
  bathrooms: '',
  bedrooms: '',
  acres: '',
  sqft: '',
  yearBuilt: '',
};

const AdvancedSearch = ({
  isSearchModalOpen,
  setIsSearchModalOpen,
  submitFormCB,
}) => {
  const [searchValues, setSearchValues] = useState(
    ADVANCE_SEARCH_DEFAULT_VALUES
  );

  const isSaveDisabled = isEqual(searchValues, ADVANCE_SEARCH_DEFAULT_VALUES);
  const handleChangeOptionInput = (event, name) => {
    const value = event.target.value;
    setSearchValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeOptionSelect = (value, name) => {
    setSearchValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSearchModalOpen(false);
    submitFormCB(searchValues);
  };
  return (
    <Modal
      open={isSearchModalOpen}
      okText="Save"
      title={
        <BaseTypography
          className={styles.modalTitle}
          fontSize="24px"
          align="left"
        >
          Advanced Search
        </BaseTypography>
      }
      footer={
        <Button
          onClick={handleSubmit}
          className={styles.save}
          type="primary"
          size="large"
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      }
      onCancel={() => setIsSearchModalOpen(false)}
      width={823}
    >
      <div className={styles.container}>
        <Flex className={styles.searchSection} gap={50}>
          <div className={styles.searchBlock}>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Subdivision
              </BaseTypography>
              <Input
                size="large"
                className={styles.searchOption}
                onChange={(event) =>
                  handleChangeOptionInput(event, 'subdivision')
                }
              />
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Property Sub Type
              </BaseTypography>
              <SelectComponent
                className={styles.searchOption}
                placeholder="Select Option"
                options={PROPERTY_TYPE_OPTIONS}
                onChange={(value) =>
                  handleChangeOptionSelect(value, 'propertySubType')
                }
              />
            </div>
          </div>
          <div className={styles.searchBlock}>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Housing Type
              </BaseTypography>
              <SelectComponent
                className={styles.searchOption}
                placeholder="Select Option"
                onChange={(value) =>
                  handleChangeOptionSelect(value, 'housingType')
                }
              />
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Construction Materials
              </BaseTypography>
              <SelectComponent
                className={styles.searchOption}
                placeholder="Select Option"
                options={CONSTRUCTION_MATERIALS_OPTIONS}
                onChange={(value) =>
                  handleChangeOptionSelect(value, 'constructionMaterials')
                }
              />
            </div>
          </div>
        </Flex>

        <hr className={styles.break} />

        <Flex className={styles.searchSection} gap={50}>
          <div className={styles.searchBlock}>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Lot Size/Acreage
              </BaseTypography>
              <SelectComponent
                className={styles.searchOption}
                placeholder="Select Option"
                onChange={(value) => handleChangeOptionSelect(value, 'lotSize')}
              />
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Parking/Garage
              </BaseTypography>
              <SelectComponent
                className={styles.searchOption}
                placeholder="Select Option"
                onChange={(value) => handleChangeOptionSelect(value, 'parking')}
              />
            </div>
          </div>
          <div className={styles.searchBlock}>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Seller Type
              </BaseTypography>
              <SelectComponent
                className={styles.searchOption}
                placeholder="Select Option"
                onChange={(value) =>
                  handleChangeOptionSelect(value, 'sellerType')
                }
              />
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Lot Description
              </BaseTypography>
              <SelectComponent
                className={styles.searchOption}
                placeholder="Select Option"
                onChange={(value) =>
                  handleChangeOptionSelect(value, 'lotDescription')
                }
              />
            </div>
          </div>
        </Flex>

        <hr className={styles.break} />
        <Flex className={styles.searchSection} gap={50}>
          <div className={styles.searchBlock}>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Price Range
              </BaseTypography>
              <Flex gap={20} align="center">
                <Input
                  size="large"
                  className={styles.searchOption}
                  onChange={(event) =>
                    handleChangeOptionInput(event, 'priceLow')
                  }
                />
                <span> - </span>
                <Input
                  size="large"
                  className={styles.searchOption}
                  onChange={(event) =>
                    handleChangeOptionInput(event, 'priceHigh')
                  }
                />
              </Flex>
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Bathrooms
              </BaseTypography>
              <Input
                size="large"
                className={styles.searchOption}
                onChange={(event) =>
                  handleChangeOptionInput(event, 'bathrooms')
                }
              />
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Acres
              </BaseTypography>
              <Input
                size="large"
                className={styles.searchOption}
                onChange={(event) => handleChangeOptionInput(event, 'acres')}
              />
            </div>
          </div>
          <div className={styles.searchBlock}>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Bedrooms
              </BaseTypography>
              <Input
                size="large"
                className={styles.searchOption}
                onChange={(event) => handleChangeOptionInput(event, 'bedrooms')}
              />
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Total Sqft
              </BaseTypography>
              <Input
                size="large"
                className={styles.searchOption}
                onChange={(event) => handleChangeOptionInput(event, 'sqft')}
              />
            </div>
            <div className={styles.searchItem}>
              <BaseTypography align="left" className={styles.searchTitle}>
                Year Built
              </BaseTypography>
              <Input
                size="large"
                className={styles.searchOption}
                onChange={(event) =>
                  handleChangeOptionInput(event, 'yearBuilt')
                }
              />
            </div>
          </div>
        </Flex>
      </div>
    </Modal>
  );
};

export default AdvancedSearch;
