import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks/storeContext';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { Outlet, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/routePath';
import AgentLayout from '../../layouts/AgentLayout/HomeLayout';

const CustomSpinner = () => (
  <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 60,
          color: '#1890ff', 
        }}
        spin
      />
    }
  />
);

const AgentAuth = observer(() => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const token = localStorage.getItem('investedAuthToken');
  const location = useLocation();
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    (async () => {
      if (!token) {
        console.log('No token found, redirecting to login.');
        navigate(ROUTE_PATH.LOGIN, {
          replace: true,
        });
        return;
      }

      if (authStore.me) {
        setLoading(false); // Authentication check is done
        return;
      }

      try {
        await authStore.whoami();
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false); // Authentication check is done
      }
    })();
  }, [authStore, authStore.me, navigate, token]);

  const isAuthenticated = authStore.me || Boolean(token);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CustomSpinner />
      </div>
    );
  }

  return (
    <>
      {isAuthenticated ? (
        <AgentLayout>
          <Outlet />
        </AgentLayout>
      ) : (
        <Navigate to={ROUTE_PATH.LOGIN} state={{ from: location }} />
      )}
    </>
  );
});

export default AgentAuth;
