import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { Flex, Image, Button } from 'antd';
import BaseTypography from '../../../../components/BaseTypography';
import Icon from '@ant-design/icons';
import { getStreetViewImage } from '../../../../utils/getStreetView';
import { BedroomSvg, BathroomSvg } from '../../../../components/Icons';
import styles from './Markers.module.scss';
import { ROUTE_PATH } from '../../../../constants/routePath';

const Markers = ({ point, onClick, isSelected }) => {
  const navigate = useNavigate();
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [streetViewImage, setStreetViewImage] = useState('');

  const { property } = point;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const formattedLastSaleDate = formatDate(property.last_sale_date);

  useEffect(() => {
    if (isSelected) {
      const fetchImage = async () => {
        try {
          const image = await getStreetViewImage({ lat: point.position.lat, lng: point.position.lng });
          setStreetViewImage(image);
        } catch (error) {
          console.error('Error fetching Street View image:', error);
        }
      };
      fetchImage();
    }
  }, [isSelected, point.position]);

  const handleMarkerClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    onClick();
  };

  const handleListingClick = (e) => {
    e.stopPropagation();
    navigate(`${ROUTE_PATH.DETAILS}/${property.id}`);
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={handleMarkerClick}
        className={styles.priceTag}
        position={point.position}
      >
        <span>{point.value}</span>
      </AdvancedMarker>

      {isSelected && (
        <InfoWindow 
          anchor={marker} 
          onCloseClick={() => onClick()}
        >
          <div className={styles.infoWindowContainer}>
            <div className={styles.infoWindowHeader}>
              <BaseTypography
                className={styles.name}
                align="left"
                fontSize="16px"
                marginBottom="10px"
              >
                {property.neighborhood || property.type}
              </BaseTypography>
            </div>
            <div className={styles.imageContainer}>
              {streetViewImage && (
                <Image
                  className={styles.image}
                  height="100%"
                  width="100%"
                  preview={false}
                  src={streetViewImage}
                />
              )}
            </div>
            <div className={styles.content}>
              <Flex gap={5} vertical>
                <BaseTypography
                  align="left"
                  className={styles.cityState}
                  fontSize="14px"
                >
                  <span style={{fontWeight: '700'}}>{property.address}</span>
                </BaseTypography>
                <BaseTypography
                  align="left"
                  className={styles.cityState}
                  fontSize="14px"
                >
                  {property.city} , {property.state}
                </BaseTypography>
                <hr className={styles.break} />
                <BaseTypography
                  align="left"
                  className={styles.subHeader}
                  fontSize="14px"
                >
                  <span style={{fontWeight: '700'}}>Land Use:</span>
                  <span className={styles.landUse}>{property.type}</span>
                </BaseTypography>
                <BaseTypography
                  align="left"
                  className={styles.subHeader}
                  fontSize="14px"
                >
                  <span style={{fontWeight: '700'}}>Last Sale Date:</span> {formattedLastSaleDate}
                </BaseTypography>
              </Flex>

              <div className={styles.actions}>
                <Button
                  className={styles.actionButton}
                  style={{color:'#005eb8', fontWeight: '800'}}
                  type="link"
                  size="small"
                  onClick={handleListingClick}
                >
                  <span style={{textDecoration: 'underline'}}>Listing</span>
                </Button>
                <Button
                  className={styles.actionButton}
                  style={{color:'#005eb8', fontWeight: '800'}}
                  type="link"
                  size="small"
                >
                  <span style={{textDecoration: 'underline'}}>Tax</span>
                </Button>
              </div>

              <hr className={styles.break} />

              <Flex className={styles.propertiesInfo}>
                <div className={styles.listingProperties}>
                  <Icon component={BedroomSvg} />
                  <span className={styles.value}>{property.bedrooms}</span>
                </div>
                <div className={styles.listingProperties}>
                  <Icon component={BathroomSvg} />
                  <span className={styles.value}>{property.bathrooms}</span>
                </div>
              </Flex>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default Markers;