// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import { Container } from './styled';
import HeroSection from './components/heroSection';
import ListingItem from './components/homeSearch';
import FeatureSection from './components/featureSection';
import EmailSection from './components/emailSection';
import ImageSection from './components/ImageSection';
import { api } from '../../constants/api';
import image1 from '../../assets/images/hp-father.jpg';
import image2 from '../../assets/images/hp-family.jpg';

const Home = () => {
  const [state, setState] = useState('');
  const [lendingTerms, setLendingTerms] = useState([]);

  useEffect(() => {
    const fetchLendingTerms = async () => {
      try {
        const response = await fetch(api.buyers.getLendedHomes);
        const data = await response.json();
        setLendingTerms(data);
      } catch (error) {
        console.error('Error fetching lending terms:', error);
      }
    };

    fetchLendingTerms();
  }, []);

  const handleStateChange = (value) => {
    window.location.href = `/search`;
  };

  return (
    <Container>
      <HeroSection state={state} handleStateChange={handleStateChange} />
      <ListingItem lendingTerms={lendingTerms} />
      <ImageSection
        title="Find Your Dream Home"
        description="Discover a wide range of properties tailored to your needs. Our expert agents are ready to help you find the perfect home."
        buttonText="Browse all properties"
        imageSrc={image1}
        imageOnLeft={true}
        backgroundColor="#f0f2f5"
        linkTo="/search"
      />
      <ImageSection
        title="Zero In on Your Price Point"
        description="Pinpoint your perfect price bracket and seamlessly incorporate it into your personalized buyer profile, paving the way for a customized property search journey."
        buttonText="Explore"
        imageSrc={image2}
        imageOnLeft={false}
        linkTo="/tools/affordability-calculator"
      />
      <FeatureSection />
      <EmailSection />
    </Container>
  );
};

export default Home;
