import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { isMobile, isDesktop } from 'react-device-detect';
import { Slider, Space, Checkbox } from 'antd';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import DesktopFilters from './componenets/DesktopFilters';
// Constants & Hooks
import { DEFAULT_FILTERS } from './utils';
import {
  BEDROOM_SELECT_OPTIONS,
  HOUSING_PRICE_TYPE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  VIEW_OPTION,
  ROUTE_PATH
} from '../../constants';
import useMapGetPropertySearch from '../../hooks/useMapGetPropertySearch';

// Components
import MoreFiltersDrawer from './MarketSearchMap/MarketSearchDrawer/MoreFiltersDrawer';
import { OutlinedButton } from '../../components/Button';
import { FiltersDrawerSvg } from '../../components/Icons';
import MapSearchWithBounds from './MarketSearchMap/MapSearchWithBounds';

// Styled Components
import {
  Container,
  FilterButton,
  Header,
  GrayNav,
  BlackNav,
  StyledLink,
} from './styled';

const MapSearch = () => {
  // Refs
  const drawerContainerRef = useRef(null);

  // State Management
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [isPricePopupVisible, setIsPricePopupVisible] = useState(false);
  const [isPropertyTypePopupVisible, setIsPropertyTypePopupVisible] = useState(false);
  const [isRoomsPopupVisible, setIsRoomsPopupVisible] = useState(false);
  const [isListingStatusPopupVisible, setIsListingStatusPopupVisible] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [priceRange, setPriceRange] = useState([0, 2000000]);
  const [propertyType, setPropertyType] = useState('any');
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const [isMapDrawerOpen, setIsMapDrawerOpen] = useState(false);
  const [mapBounds, setMapBounds] = useState(null);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Default Center Configuration
  const defaultCenter = useMemo(() => ({
    latitude: 32.7767,
    longitude: -96.7970
  }), []);

  const initialBounds = useMemo(() => ({
    ne: { lat: defaultCenter.latitude + 0.1, lng: defaultCenter.longitude + 0.1 },
    sw: { lat: defaultCenter.latitude - 0.1, lng: defaultCenter.longitude - 0.1 }
  }), [defaultCenter]);

  // Map Search API Call
  const { properties, isLoading, hasError, updateSearchParams } = useMapGetPropertySearch({
    // Location parameters
    latitude: defaultCenter.latitude,
    longitude: defaultCenter.longitude,
    radius: 10,
    
    // Property type and size
    property_type: propertyType,
    size: 30,
    
    // Price filters
    price_min: priceRange[0],
    price_max: priceRange[1],
    
    // Search term
    search: searchTerm,
    
    // Room filters
    bedrooms_min: filters.bedroom || null,
    bedrooms_max: null,
    bathrooms_min: filters.bathroom || null,
    bathrooms_max: null,
    
    // Status filters
    listing_status: ['Any - for sale'],
    days_on_market: null,
    
    // Boolean filters
    hide_pending: false,
    hide_foreclosures: false,
    hide_55_plus: false,
    open_house: false,
    virtual_tours: false,
    three_d_tours: false
  });

  // Clear selected marker when filters change
  useEffect(() => {
    setSelectedMarkerId(null);
  }, [priceRange, propertyType, filters, searchTerm]);

  // Effect for Initial Bounds
  useEffect(() => {
    if (initialBounds) {
      updateSearchParams({ mapBounds: initialBounds });
    }
  }, [initialBounds, updateSearchParams]);

  // Map Movement Handlers
  const calculateCenterAndRadius = useCallback((bounds) => {
    const center = {
      latitude: (bounds.ne.lat + bounds.sw.lat) / 2,
      longitude: (bounds.ne.lng + bounds.sw.lng) / 2
    };

    const latDiff = bounds.ne.lat - bounds.sw.lat;
    const lngDiff = bounds.ne.lng - bounds.sw.lng;
    const radius = Math.max(latDiff, lngDiff) * 69 / 2;

    return { center, radius };
  }, []);

  const handleMapMove = useCallback((newBounds) => {
    setMapBounds(newBounds);
    const { center, radius } = calculateCenterAndRadius(newBounds);

    updateSearchParams({
      latitude: center.latitude,
      longitude: center.longitude,
      radius: Math.ceil(radius),
      property_type: propertyType,
      size: 30,
    });
  }, [updateSearchParams, calculateCenterAndRadius, propertyType]);

  // Filter Handlers
  const handlePriceFilterApply = useCallback((range) => {
    setPriceRange(range);
    updateSearchParams({
      price_min: range[0],
      price_max: range[1]
    });
  }, [updateSearchParams]);

  const handlePriceReset = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setPriceRange([0, 1800000]);
    updateSearchParams({
      price_min: 0,
      price_max: 1800000
    });
  }, [updateSearchParams]);

  const handlePropertyTypeApply = useCallback((type) => {
    setPropertyType(type);
    updateSearchParams({
      property_type: type
    });
  }, [updateSearchParams]);

  const handleRoomsApply = useCallback((values) => {
    // Convert string values to numbers or null
    const convertToNumber = (value) => {
      if (value === 'No min' || value === 'No max' || value === null) return null;
      if (value === '5+') return 5;
      return parseInt(value, 10);
    };
  
    // Update local filter state - keeping the existing structure
    setFilters(prev => ({
      ...prev,
      bedroom: convertToNumber(values.bedrooms.min),
      bathroom: convertToNumber(values.bathrooms.min)
    }));
  
    // Update search params
    updateSearchParams({
      bedrooms_min: convertToNumber(values.bedrooms.min),
      bedrooms_max: convertToNumber(values.bedrooms.max),
      bathrooms_min: convertToNumber(values.bathrooms.min),
      bathrooms_max: convertToNumber(values.bathrooms.max)
    });
  }, [updateSearchParams]);

  const handleListingStatusApply = useCallback((values) => {
    updateSearchParams({
      listing_status: values.statuses,
      days_on_market: values.daysOnMarket === 'Any' ? null : parseInt(values.daysOnMarket),
      hide_pending: values.filters.hidePending,
      hide_foreclosures: values.filters.hideForeclosures,
      hide_55_plus: values.filters.hide55Plus,
      open_house: values.filters.openHouse,
      virtual_tours: values.filters.virtualTours,
      three_d_tours: values.filters.threeDTours
    });
  }, [updateSearchParams]);

  const handleSearch = useCallback((value) => {
    setSearchTerm(value);
    updateSearchParams({
      search: value
    });
  }, [updateSearchParams]);

  // Drawer Handlers
  const handleClickFiltersOpen = useCallback(() => {
    setIsFiltersDrawerOpen(true);
  }, []);

  const handleClickFilterClose = useCallback(() => {
    setIsFiltersDrawerOpen(false);
  }, []);

  const handleClickMapDrawerClose = useCallback(() => {
    setIsMapDrawerOpen(false);
  }, []);

  // Filter Properties
  const filteredProperties = useMemo(() => {
    if (!isFiltersActive) return properties;
    return properties.filter((property) => {
      const meetsRangeFilter =
        property.current_price <= filters.range.high &&
        property.current_price >= filters.range.low;
      const meetsBedFilter = property.bedrooms >= filters.bedroom;
      const meetsBathFilter = property.bathrooms >= filters.bathroom;
      return meetsRangeFilter && meetsBedFilter && meetsBathFilter;
    });
  }, [isFiltersActive, properties, filters]);

  return (
    <Container ref={drawerContainerRef}>
      {/* Desktop Filters */}
{isDesktop && (
  <DesktopFilters
    isPricePopupVisible={isPricePopupVisible}
    setIsPricePopupVisible={setIsPricePopupVisible}
    priceRange={priceRange}
    setPriceRange={setPriceRange}
    handlePriceFilterApply={handlePriceFilterApply}
    handlePriceReset={handlePriceReset}
    isPropertyTypePopupVisible={isPropertyTypePopupVisible}
    setIsPropertyTypePopupVisible={setIsPropertyTypePopupVisible}
    propertyType={propertyType}
    handlePropertyTypeApply={handlePropertyTypeApply}
    isRoomsPopupVisible={isRoomsPopupVisible}
    setIsRoomsPopupVisible={setIsRoomsPopupVisible}
    handleRoomsApply={handleRoomsApply}
    isListingStatusPopupVisible={isListingStatusPopupVisible}
    setIsListingStatusPopupVisible={setIsListingStatusPopupVisible}
    handleListingStatusApply={handleListingStatusApply}
    handleClickFiltersOpen={handleClickFiltersOpen}
    PROPERTY_TYPE_OPTIONS={PROPERTY_TYPE_OPTIONS}
    onSearch={handleSearch}
    searchTerm={searchTerm}
    filters={filters}  // Add this line
  />
)}

      {/* Mobile Filter Section */}
      {isMobile && (
        <Header
          justify="space-between"
          align="center"
        >
          <div>
            <GrayNav>
              <StyledLink to={ROUTE_PATH.DASHBOARD}>
                Home <RightOutlined className="icon" />
              </StyledLink>
            </GrayNav>
            <BlackNav>Search</BlackNav>
          </div>

          <div>
            <OutlinedButton
              onClick={handleClickFiltersOpen}
            >
              <Icon component={FiltersDrawerSvg} />
            </OutlinedButton>
          </div>
        </Header>
      )}

      <div style={{ height: '100%' }}>
        <MapSearchWithBounds
          drawerContainerRef={drawerContainerRef}
          properties={filteredProperties}
          setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
          isMapDrawerOpen={isMapDrawerOpen}
          handleClickMapDrawerClose={handleClickMapDrawerClose}
          params={defaultCenter}
          onMapMove={handleMapMove}
          selectedMarkerId={selectedMarkerId}
          setSelectedMarkerId={setSelectedMarkerId}
          isLoading={isLoading}
        />
      </div>

      <MoreFiltersDrawer
        handleClickFilterClose={handleClickFilterClose}
        isFilterOpen={isFiltersDrawerOpen}
        container={drawerContainerRef.current}
        setFilters={setFilters}
        currentHighRange={properties.length ? Math.max(...properties.map(p => p.current_price)) : 2000000}
        setIsFiltersActive={setIsFiltersActive}
        searchOption={VIEW_OPTION.map}
        VIEW_OPTIONS={[VIEW_OPTION.map]}
        handleChange={() => {}}
      />
    </Container>
  );
};

export default MapSearch;