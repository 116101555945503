// src/pages/PrivacyPolicy.js
import React from 'react';
import { ApplicationWrapper, Title, Content } from './styled';

const PrivacyPolicy = () => {
    return (
        <ApplicationWrapper>
            <Title>Privacy Policy</Title>
            <Content>
                <h2>Introduction</h2>
                <p>
                    At Invested, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our platform.
                </p>

                <h2>Information We Collect</h2>
                <h3>Personal Information</h3>
                <p>
                    We collect personal information that you voluntarily provide to us when you register on the platform, express an interest in obtaining information about us or our products and services, or otherwise contact us. This includes:
                </p>
                <ul>
                    <li>Contact Information: Name, email address, phone number, and mailing address.</li>
                    <li>Account Information: Username, password, and profile details.</li>
                    <li>Financial Information: Payment information such as credit card numbers and billing addresses.</li>
                </ul>
                
                <h3>Non-Personal Information</h3>
                <p>
                    We also collect non-personal information that does not directly identify you, including:
                </p>
                <ul>
                    <li>Usage Data: Information about how you use our platform, including your interactions with listings, search queries, and other activities.</li>
                    <li>Device Data: Information about the device you use to access our platform, such as your IP address, browser type, operating system, and device identifiers.</li>
                </ul>
                
                <h3>Cookies and Tracking Technologies</h3>
                <p>
                    We use cookies and similar tracking technologies to collect and use information about your interactions with our platform. This helps us provide a better user experience and analyze how our platform is used.
                </p>

                <h2>How We Use Your Information</h2>
                <p>
                    We use the information we collect for various purposes, including:
                </p>
                <ul>
                    <li>To Provide and Improve Our Services: We use your information to operate, maintain, and improve the platform and our services.</li>
                    <li>To Communicate with You: We use your contact information to send you updates, marketing communications, and respond to your inquiries.</li>
                    <li>To Process Transactions: We use your financial information to process payments and manage transactions.</li>
                    <li>To Personalize Your Experience: We use your usage data to tailor the content and features you see on our platform.</li>
                    <li>To Ensure Security and Compliance: We use your information to protect against fraud, comply with legal obligations, and ensure the security of our platform.</li>
                </ul>

                <h2>How We Share Your Information</h2>
                <p>
                    We may share your information with third parties in the following circumstances:
                </p>
                <ul>
                    <li>With Your Consent: We may share your information when you give us explicit consent to do so.</li>
                    <li>With Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and customer service.</li>
                    <li>For Legal Reasons: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                    <li>In Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
                </ul>

                <h2>Data Security</h2>
                <p>
                    We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. However, no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee its absolute security.
                </p>

                <h2>Your Data Protection Rights</h2>
                <p>
                    Depending on your location, you may have the following rights regarding your personal information:
                </p>
                <ul>
                    <li>Access: You have the right to request a copy of the personal information we hold about you.</li>
                    <li>Correction: You have the right to request that we correct any inaccuracies in your personal information.</li>
                    <li>Deletion: You have the right to request that we delete your personal information.</li>
                    <li>Objection: You have the right to object to our processing of your personal information.</li>
                    <li>Restriction: You have the right to request that we restrict the processing of your personal information.</li>
                    <li>Portability: You have the right to request that we transfer your personal information to another organization or directly to you.</li>
                </ul>
                <p>
                    To exercise any of these rights, please contact us at [support email].
                </p>

                <h2>Children&#39;s Privacy</h2>
                <p>
                    Our platform is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently received personal information from a user under the age of 18, we will delete such information from our records.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform. Your continued use of the platform after any such changes constitutes your acceptance of the new Privacy Policy.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy, please contact us at [support email] or call us at [support phone number].
                </p>
            </Content>
        </ApplicationWrapper>
    );
};

export default PrivacyPolicy;
