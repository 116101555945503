import React from 'react';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import ListingItem from '../../../../components/ListingItem';
import styles from './MapListings.module.css';
import { ROUTE_PATH } from '../../../../constants/routePath'; // Make sure to import ROUTE_PATH

const MapListings = ({ properties }) => {
  const navigate = useNavigate();
  const filterProperties = properties.slice(0, 6);

  const handlePropertyClick = (property) => {
    navigate(`${ROUTE_PATH.DETAILS}/${property.id}`, {
      state: { property },
    });
  };

  return (
    <div className={styles.listingsContainer}>
      <Row gutter={[12, 12]}>
        {filterProperties.map((property) => (
          <Col key={property.id} xs={24} sm={12} md={8} lg={8} xl={8}>
            <ListingItem 
              property={property} 
              isPopular 
              onClick={() => handlePropertyClick(property)}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MapListings;