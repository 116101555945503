import React from 'react';
import { Row, Col } from 'antd';
import {
    HeroContainer,
    HeroContent,
    StyledTitle,
    NavButtons,
    StyledSearch,
} from './styled';

const HeroSection = ({ handleStateChange }) => (
    <HeroContainer>
        <HeroContent>
            <Row justify="center">
                <Col xs={24} sm={20} md={18} lg={16}>
                    <StyledTitle level={1}>
                        {"Find Your New Home,\nSkip the Bank."}
                    </StyledTitle>
                    <NavButtons justify="center" gutter={[8, 8]}>
                    </NavButtons>
                    <Row justify="center">
                        <Col xs={24} sm={20} md={18} lg={16}>
                            <StyledSearch
                                placeholder="Enter an address, neighborhood, city, or ZIP code"
                                onSearch={handleStateChange}
                                enterButton
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </HeroContent >
    </HeroContainer >
);

export default HeroSection;