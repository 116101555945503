// src/pages/HowItWorks.js
import React from 'react';
import { ApplicationWrapper, Title, Content } from './styled';

const HowItWorks = () => {
    return (
        <ApplicationWrapper>
            <Title>How It Works</Title>
            <Content>
                <h2>Overview</h2>
                <p>
                    Invested is a platform designed to connect buyers and lenders for property financing opportunities. Our goal is to simplify the real estate financing process and make it accessible to everyone. Here’s how it works:
                </p>

                <h2>Step-by-Step Process</h2>
                
                <h3>1. Sign Up</h3>
                <p>
                    Create an account on Invested by providing your basic information. Both buyers and lenders need to register to access the platform’s features.
                </p>
                
                <h3>2. Create Your Profile</h3>
                <p>
                    Complete your profile by adding relevant details. Buyers can specify their financing needs, while lenders can outline the terms of the financing they offer.
                </p>
                
                <h3>3. Browse Listings</h3>
                <p>
                    Buyers can browse through property listings to find suitable financing options. Each listing provides detailed information about the property and the financing terms.
                </p>
                
                <h3>4. Express Interest</h3>
                <p>
                    Once buyers find a suitable listing, they can express their interest directly through the platform. This notifies the lender of their interest.
                </p>
                
                <h3>5. Agent Facilitation</h3>
                <p>
                    Our agents will reach out to both buyers and lenders to facilitate the connection. They will help coordinate communication and ensure that both parties have the information they need to proceed.
                </p>
                
                <h3>6. Direct Negotiation</h3>
                <p>
                    Buyers and lenders can then negotiate terms directly. Our platform provides tools to facilitate communication and document exchange, ensuring a smooth process.
                </p>

                <h3>7. Finalize the Deal</h3>
                <p>
                    Once both parties agree on the terms, they can finalize the deal. Invested does not handle the loan process directly but provides support through our agents to ensure a successful transaction.
                </p>

                <h2>Benefits of Using Invested</h2>
                <ul>
                    <li><strong>Transparency:</strong> Detailed property and financing information is available to all users.</li>
                    <li><strong>Efficiency:</strong> The platform streamlines the real estate financing process, saving time and effort.</li>
                    <li><strong>Security:</strong> We implement robust security measures to protect your information and transactions.</li>
                    <li><strong>Support:</strong> Our customer support team is available to assist you at every step.</li>
                </ul>

                <h2>Get Started</h2>
                <p>
                    Ready to start your property financing journey with Invested? <a href="/signup">Sign up now</a> and take the first step towards a seamless and efficient real estate financing experience.
                </p>
            </Content>
        </ApplicationWrapper>
    );
};

export default HowItWorks;
