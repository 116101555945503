import React, { useState } from 'react';
import { Button } from 'antd';
import {
  CalculatorWrapper,
  FormSection,
  FormTitle,
  InputGroup,
  Label,
  StyledInput,
  StyledCheckbox,
  SubmitButton,
  NextSteps,
  NextStepsTitle,
  NextStepsContent,
  NextStepsText,
  NextStepsImage,
  DesktopOnly,
  MobileOnly,
  StyledButton,
  BuyingPowerContainer,
  BuyingPowerCard,
  AffordabilityHeader,
  AffordabilityRange,
  AffordabilityDescription,
  FormGrid,
  FormColumn,
  InputDescription
} from './styled';
import { Link } from 'react-router-dom';
import illistration from '../../../../../assets/images/home-illistration.png';

const CalculatorSection = () => {
  const [annualIncome, setAnnualIncome] = useState('60,000');
  const [monthlyDebt, setMonthlyDebt] = useState('0');
  const [location, setLocation] = useState('75201, Dallas Tx');
  const [availableFunds, setAvailableFunds] = useState('10,000');
  const [isVeteran, setIsVeteran] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [buyingPower, setBuyingPower] = useState(null);

  const calculateBuyingPower = () => {
    // Convert string inputs to numbers
    const income = parseFloat(annualIncome.replace(/,/g, ''));
    const debt = parseFloat(monthlyDebt.replace(/,/g, ''));
    const funds = parseFloat(availableFunds.replace(/,/g, ''));

    // Simple calculation (you may want to adjust this based on your specific requirements)
    const maxAffordable = income * 4 - debt * 12 + funds;
    const stretch = maxAffordable * 1.15;
    const difficult = maxAffordable * 1.3;

    return {
      affordable: maxAffordable,
      stretch: stretch,
      difficult: difficult
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = calculateBuyingPower();
    setBuyingPower(result);
    setShowResults(true);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
  };

  return (
    <>
      <CalculatorWrapper>
        <FormSection showingResults={showResults}>
          {!showResults ? (
            <>
              <FormTitle>Calculate your buying power</FormTitle>
              <form onSubmit={handleSubmit}>
                <DesktopOnly>
                  <FormGrid>
                    <FormColumn>
                      <InputGroup>
                        <Label>Annual income</Label>
                        <StyledInput
                          prefix="$"
                          value={annualIncome}
                          onChange={(e) => setAnnualIncome(e.target.value)}
                        />
                        <InputDescription>Total income before taxes for you and your household members.</InputDescription>
                      </InputGroup>
                      <InputGroup>
                        <Label>Monthly debt</Label>
                        <StyledInput
                          prefix="$"
                          value={monthlyDebt}
                          onChange={(e) => setMonthlyDebt(e.target.value)}
                        />
                        <InputDescription>Payments you make for loans or other debt, but not living expenses like rent, groceries or utilities.</InputDescription>
                      </InputGroup>
                    </FormColumn>
                    <FormColumn>
                      <InputGroup>
                        <Label>Location</Label>
                        <StyledInput
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                        <InputDescription>City or ZIP code you are searching in.</InputDescription>
                      </InputGroup>
                      <InputGroup>
                        <Label>Available funds</Label>
                        <StyledInput
                          prefix="$"
                          value={availableFunds}
                          onChange={(e) => setAvailableFunds(e.target.value)}
                        />
                        <InputDescription>Money that you can spend on the down payment and closing costs.</InputDescription>
                      </InputGroup>
                    </FormColumn>
                  </FormGrid>
                </DesktopOnly>
                <MobileOnly>
                  <InputGroup>
                    <Label>Annual income</Label>
                    <StyledInput
                      prefix="$"
                      value={annualIncome}
                      onChange={(e) => setAnnualIncome(e.target.value)}
                    />
                    <InputDescription>Total income before taxes for you and your household members.</InputDescription>
                  </InputGroup>
                  <InputGroup>
                    <Label>Monthly debt</Label>
                    <StyledInput
                      prefix="$"
                      value={monthlyDebt}
                      onChange={(e) => setMonthlyDebt(e.target.value)}
                    />
                    <InputDescription>Payments you make for loans or other debt, but not living expenses like rent, groceries or utilities.</InputDescription>
                  </InputGroup>
                </MobileOnly>
                <StyledCheckbox
                  checked={isVeteran}
                  onChange={(e) => setIsVeteran(e.target.checked)}
                >
                  Yes, I or my spouse served in the U.S. Military
                </StyledCheckbox>
                <SubmitButton type="primary" htmlType="submit">
                  Estimate home price range
                </SubmitButton>
              </form>
            </>
          ) : (
            <>
              <Button onClick={() => setShowResults(false)} style={{ marginBottom: '20px' }}>
                Back
              </Button>
              <FormTitle>Your buying power</FormTitle>
              <BuyingPowerContainer>
                <BuyingPowerCard>
                  <AffordabilityHeader type="affordable">Affordable</AffordabilityHeader>
                  <AffordabilityRange>{formatCurrency(0)} - {formatCurrency(buyingPower.affordable)}</AffordabilityRange>
                  <AffordabilityDescription>Fits your budget.</AffordabilityDescription>
                </BuyingPowerCard>
                <BuyingPowerCard>
                  <AffordabilityHeader type="stretch">Stretch</AffordabilityHeader>
                  <AffordabilityRange>{formatCurrency(buyingPower.affordable + 1)} - {formatCurrency(buyingPower.stretch)}</AffordabilityRange>
                  <AffordabilityDescription>Stretches your budget.</AffordabilityDescription>
                </BuyingPowerCard>
                <BuyingPowerCard>
                  <AffordabilityHeader type="difficult">Difficult</AffordabilityHeader>
                  <AffordabilityRange>{formatCurrency(buyingPower.stretch + 1)} - {formatCurrency(buyingPower.difficult)}</AffordabilityRange>
                  <AffordabilityDescription>Over your budget.</AffordabilityDescription>
                </BuyingPowerCard>
              </BuyingPowerContainer>
            </>
          )}
        </FormSection>
      </CalculatorWrapper>

      <NextSteps>
        <NextStepsContent>
          <NextStepsText>
            <NextStepsTitle>Next steps</NextStepsTitle>
            <h3>Find the perfect home for you and your family</h3>
            <Link to="/search">
              <StyledButton type="default">Search Properties</StyledButton>
            </Link>
          </NextStepsText>
          <NextStepsImage src={illistration} alt="House icon" />
        </NextStepsContent>
      </NextSteps>
    </>
  );
};

export default CalculatorSection;