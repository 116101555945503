import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';

// Components
import { PriceFilterButton } from '../Buttons/PriceFilterButton.js';
import { PricePopup, PropertyTypePopup, RoomsPopup, ListingStatusPopup } from './Popups';

// Styled Components
import {
  SearchContainer,
  SelectsWrapper,
  FilterButton,
  FiltersButton,
  SearchInput,
} from './styled';

export const DesktopFilters = ({
  isPricePopupVisible,
  updateSearchParams,
  setIsPricePopupVisible,
  priceRange,
  setPriceRange,
  handlePriceFilterApply,
  isPropertyTypePopupVisible,
  setIsPropertyTypePopupVisible,
  propertyType,
  handlePropertyTypeApply,
  handleClickFiltersOpen,
  PROPERTY_TYPE_OPTIONS,
  onSearch,
  handleRoomsApply,
  handleListingStatusApply,
  filters,
}) => {
  // New state for rooms and listing status
  const [isRoomsPopupVisible, setIsRoomsPopupVisible] = useState(false);
  const [isListingStatusPopupVisible, setIsListingStatusPopupVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [roomsFilter, setRoomsFilter] = useState({
    bedrooms: { min: null, max: null },
    bathrooms: { min: null, max: null }
  });
  const [listingStatus, setListingStatus] = useState({
    statuses: ['Any - for sale'],
    filters: {
      hidePending: false,
      hideForeclosures: false,
      hide55Plus: false,
      openHouse: false,
      virtualTours: false,
      threeDTours: false
    },
    daysOnMarket: 'Any'
  });

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearch?.(value); // Call the parent's search handler if provided
  };

  const getRoomsButtonText = () => {
    const bedroomsMin = filters.bedroom;
    const bathroomsMin = filters.bathroom;

    if (!bedroomsMin && !bathroomsMin) {
      return 'Rooms';
    }

    const parts = [];
    if (bedroomsMin) {
      parts.push(`${bedroomsMin}+ bed`);
    }
    if (bathroomsMin) {
      parts.push(`${bathroomsMin}+ bath`);
    }

    return parts.join(', ');
  };

  const getPropertyTypeButtonText = () => {
    if (!propertyType || propertyType === 'any') {
      return 'Property Type';
    }
    // Just find the label for the single selected value
    const selectedLabel = PROPERTY_TYPE_OPTIONS.find(opt => opt.value === propertyType)?.label;
    return selectedLabel || 'Property Type';
  };

  return (
    <SearchContainer>
      <Row gutter={12}>
        <Col xs={12} md={16} lg={17} xl={20} xxl={20}>
          <SelectsWrapper
            justify="center"
            align="center"
          >
            <SearchInput
              placeholder="Search by location, ZIP, or MLS#"
              prefix={<SearchOutlined />}
              value={searchValue}
              onChange={handleSearch}
              allowClear
            />
            <Popover
              content={
                <PricePopup
                  visible={isPricePopupVisible}
                  onClose={() => setIsPricePopupVisible(false)}
                  onApply={handlePriceFilterApply}
                  initialValues={priceRange}
                />
              }
              trigger="click"
              open={isPricePopupVisible}
              onOpenChange={(visible) => setIsPricePopupVisible(visible)}
              placement="bottomLeft"
            >
              <PriceFilterButton
                priceRange={priceRange}
                isPopupVisible={isPricePopupVisible}
                onClick={() => setIsPricePopupVisible(!isPricePopupVisible)} // Toggle the state
                onReset={(e) => {
                  e.stopPropagation(); // Prevent the click from triggering the Popover
                  setPriceRange([0, 1800000]);
                  handlePriceFilterApply([0, 1800000], false);
                }}
              />
            </Popover>

            <Popover
              content={
                <PropertyTypePopup
                  visible={isPropertyTypePopupVisible}
                  onClose={() => setIsPropertyTypePopupVisible(false)}
                  onApply={handlePropertyTypeApply}
                  initialValue={propertyType}
                  PROPERTY_TYPE_OPTIONS={PROPERTY_TYPE_OPTIONS}
                />
              }
              trigger="click"
              open={isPropertyTypePopupVisible}
              onOpenChange={setIsPropertyTypePopupVisible}
              placement="bottomLeft"
            >
              <FilterButton title={getPropertyTypeButtonText()}> {/* Add title for hover tooltip */}
                <span className="filter-text">{getPropertyTypeButtonText()}</span>
                <span className="anticon">
                  {isPropertyTypePopupVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </span>
              </FilterButton>
            </Popover>

            <Popover
              content={
                <RoomsPopup
                  visible={isRoomsPopupVisible}
                  onClose={() => setIsRoomsPopupVisible(false)}
                  onApply={handleRoomsApply}
                  initialValues={roomsFilter}
                />
              }
              trigger="click"
              open={isRoomsPopupVisible}
              onOpenChange={setIsRoomsPopupVisible}
              placement="bottomLeft"
            >
              <FilterButton>
                <span className="filter-text">{getRoomsButtonText()}</span>
                <span className="anticon">
                  {isRoomsPopupVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </span>
              </FilterButton>
            </Popover>

            <Popover
              content={
                <ListingStatusPopup
                  visible={isListingStatusPopupVisible}
                  onClose={() => setIsListingStatusPopupVisible(false)}
                  onApply={handleListingStatusApply}
                  initialValues={listingStatus}
                />
              }
              trigger="click"
              open={isListingStatusPopupVisible}
              onOpenChange={setIsListingStatusPopupVisible}
              placement="bottomLeft"
            >
              <FilterButton>
                <span className="filter-text">Listing Status</span>
                <span className="anticon">
                  {isListingStatusPopupVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </span>
              </FilterButton>
            </Popover>
          </SelectsWrapper>
        </Col>
        <Col xs={12} md={8} lg={7} xl={4} xxl={4}>
          <SelectsWrapper justify="center" gap={10}>
            <FiltersButton
              onClick={handleClickFiltersOpen}
              icon={<PlusOutlined />}
            >
              More Filters
            </FiltersButton>
          </SelectsWrapper>
        </Col>
      </Row>
    </SearchContainer>
  );
};

DesktopFilters.propTypes = {
  isPricePopupVisible: PropTypes.bool.isRequired,
  setIsPricePopupVisible: PropTypes.func.isRequired,
  priceRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPriceRange: PropTypes.func.isRequired,
  handlePriceFilterApply: PropTypes.func.isRequired,
  isPropertyTypePopupVisible: PropTypes.bool.isRequired,
  setIsPropertyTypePopupVisible: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired,
  handlePropertyTypeApply: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  filters: PropTypes.shape({
    bedroom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bathroom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  handleClickFiltersOpen: PropTypes.func.isRequired,
  PROPERTY_TYPE_OPTIONS: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  })).isRequired,
};

export default DesktopFilters;