import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// Components
import Avatar from '../../assets/images/avatar.png';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { BaseButton, OutlinedButton } from '../../components/Button';
import Account from './Account';
import Profile from './Profile';

// Styles
import styles from './Settings.module.scss';


const PROFILE = 'Profile';
const MY_ACCOUNT = 'My Account';
const NAV_LIST = [{ title: PROFILE }, { title: MY_ACCOUNT }];

const Settings = () => {

 

  const location = useLocation();
  const activeTab = location.state?.activeTab ?? PROFILE;
  const [isActiveTab, setIsActiveTab] = useState(PROFILE);
 
  useEffect(() => {
    setIsActiveTab(activeTab);
  }, [activeTab]);


  return (
    <div className={styles.wrapper}>
      <Link to={'/'} className={styles.link}>
        <ArrowLeftOutlined className={styles.icon} /> Back to Dashboard
      </Link>

      {isMobile && <h4 className={styles.titlePage}>Settings</h4>}
      <div className={styles.outer}>
        <div className={styles.nav}>
          {NAV_LIST.map((nav) => (
            <div
              key={nav.title}
              className={`${nav.title === isActiveTab ? styles.activeTab : styles.tab}`}
              onClick={() => setIsActiveTab(nav.title)}
            >
              {nav.title}
            </div>
          ))}
        </div>

        {isActiveTab === 'Profile' && (
        <Profile/>
        )}

        {isActiveTab === 'My Account' && (
          <Account/>
        )}
      </div>
    </div>
  );
};

export default Settings;
