import React, { useState, useEffect } from 'react';
import { useBreakpoint } from '../../../hooks/media';
import { Col, Row, Avatar, Button } from 'antd';
import BaseTypography from '../../../components/BaseTypography';
import SelectComponent from '../../../components/SelectComponent';
import Icon from '@ant-design/icons';
import { COLORS } from '../../../constants/colors';
import { MarketWatchSettingsSvg, MarketWatchSvg } from '../../../components/Icons';
import { api } from '../../../constants/api';
import styles from './MarketWatch.module.scss';

export const RESIDENTIAL_TYPE_OPTIONS = [
  { value: 'SFR', label: 'Residential' },
  { value: 'MFR', label: 'Multi Family Residential' },
  { value: 'LAND', label: 'Land' },
  { value: 'CONDO', label: 'Condo' },
  { value: 'MOBILE', label: 'Mobile' },
  { value: 'OTHER', label: 'Other' },
];

const STATES_OPTIONS = [
  { value: 'TX', label: 'Texas' },
  // Add more states here if needed
];

const MarketWatch = () => {
  const { isMobile, isTablet } = useBreakpoint();
  const [selectedState, setSelectedState] = useState('TX');
  const [selectedType, setSelectedType] = useState('SFR');
  const [listingCounts, setListingCounts] = useState(null);

  useEffect(() => {
    const fetchListingCounts = async (state) => {
      try {
        const response = await fetch(api.listing.counts, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ state }), // Only pass the state
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setListingCounts(data);
      } catch (error) {
        console.error('Error fetching listing counts:', error);
      }
    };

    fetchListingCounts(selectedState);
  }, [selectedState]);

  const items = [
    { label: 'Active', status: 'mls_active' },
    { label: 'Pending', status: 'mls_pending' },
    { label: 'Cancelled', status: 'mls_cancelled' }
  ];

  const getCount = (status) => {
    if (!listingCounts) return '...';
    const typeCounts = listingCounts[status].byPropertyType;
    return typeCounts[selectedType] !== undefined ? typeCounts[selectedType] : listingCounts[status].overall;
  };

  return (
    <>
      <Row className={styles.marketWatch} gutter={16} justify="space-between" align="middle">
        <Col xs={24} lg={12} className={styles.marketWatchTitleContainer}>
          <BaseTypography align="left" color={COLORS.$mainDark}>
            Listing Types
          </BaseTypography>
        </Col>
        <Col xs={24} lg={6} className={styles.marketWatchIcon}>
          <Avatar className={styles.avatar} size={72} icon={<Icon component={MarketWatchSvg} />} />
          {(isMobile || isTablet) && (
            <Button
              type="text"
              icon={<Icon component={MarketWatchSettingsSvg} />}
              className={styles.marketWatchSettingsMore}
            />
          )}
        </Col>
      </Row>

      <Row className={styles.marketWatchGrid}>
        {items.map((item, index) => (
          <div key={item.status} className={styles.marketWatchGridItem}>
            <BaseTypography fontSize="24px" className={styles.marketWatchValue}>
              {getCount(item.status)} {/* Display the filtered counts */}
            </BaseTypography>
            <BaseTypography
              fontSize="14px"
              className={styles.marketWatchValueDesc}
              color={COLORS.$mainDark70}
            >
              {item.label} Listings
            </BaseTypography>
          </div>
        ))}
      </Row>

      <Row className={styles.marketWatchInputContainer} gutter={{ xs: 16, lg: 150 }}>
        <Col xs={24} lg={12}>
          <SelectComponent
            options={STATES_OPTIONS}
            placeholder="Select a state"
            label="State"
            value={selectedState}
            defaultValue="TX"
            onChange={setSelectedState}
          />
        </Col>
        <Col xs={24} lg={12}>
          <SelectComponent
            options={RESIDENTIAL_TYPE_OPTIONS}
            placeholder="Select one"
            label="Type"
            value={selectedType}
            defaultValue="SFR"
            onChange={setSelectedType}
          />
        </Col>
      </Row>
    </>
  );
};

export default MarketWatch;
