export const COLORS = {
  $black: '#000',
  $blackOpacity50: 'rgba(0,0,0,.5)',
  $white: '#fff',
  $mainDark: '#000929',
  $mainDark70: 'rgba(0,9,41,0.7)',
  $mainDark50: 'rgba(0,9,41,0.5)',
  $mainBlue: '#005EB8',
  $blueShades98: '#F7FBFD',
  $blueShades96: '#EFF4FB',
  $blueShades94: '#E6F0F9',
  $blueShades92: '#DEE8F7',
  $blueShades90: '#D6E2F5',
  $greyScale50: '#F9FAFB',
  $greyScale100: '#F4F4F6',
  $greyScale200: '#E5E6EB',
  $greyScale300: '#D3D5DA',
  $greyScale400: '#9EA3AE',
  $greyScale500: '#6C727F',
  $greyScale600: '#4D5461',
  $greyScale700: '#394150',
  $greyScale800: '#212936',
  $greyScale900: '#0B0A0F',
};
