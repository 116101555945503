export const propertyFormat = (propertyData) => {
  return propertyData.reduce((properties, property) => {
    properties.push({
      // Identification
      id: property.id,

      // Address information
      address: property.address.street,
      city: property.address.city,
      state: property.address.state,
      zip: property.address.zip,
      county: property.address.county,
      full_address: property.address.address,

      // Property characteristics
      lot_sqft: property.squareFeet,
      acres: property.listhub?.LotSizeAcres,
      bedrooms: property.bedrooms ?? 0,
      bathrooms: property.bathrooms ?? 0,
      type: property.propertyUse,

      // Location details
      subdivision: property.neighborhood?.type === 'subdivision' ? property.neighborhood.name : null,
      neighborhood: property.neighborhood?.name ? property.neighborhood.name : null,
      position: {
        lat: property.latitude,
        lng: property.longitude,
      },

      // Listing information
      current_price: property.mlsListingPrice,
      change_type: property.mlsStatus,
      dom: property.mlsDaysOnMarket,
      list_office: property.listhub?.ListOfficeName,

      // Property features
      absentee_owner: property.absenteeOwner,
      adjustable_rate: property.adjustableRate,
      air_conditioning_available: property.airConditioningAvailable,

      // Dates
      last_sale_date: property.lastSaleDate,

      // Additional fields (add all that are available in the data)
      year_built: property.yearBuilt,
      heating_type: property.heatingType,
      cooling_type: property.coolingType,
      garage_spaces: property.garageSpaces,
      parking_type: property.parkingType,
      lot_size: property.lotSize,
      property_style: property.propertyStyle,
      roof_type: property.roofType,
      foundation_type: property.foundationType,
      exterior_wall_type: property.exteriorWallType,
      pool: property.hasPool,
      spa: property.hasSpa,
      view: property.hasView,
      waterfront: property.isWaterfront,
      fireplace: property.hasFireplace,
      basement: property.hasBasement,
      
      // Financial information
      tax_year: property.taxYear,
      annual_tax_amount: property.annualTaxAmount,
      hoa_fee: property.hoaFee,
      zoning: property.zoning,
      
      // Listing details
      listing_date: property.listingDate,
      original_list_price: property.originalListPrice,
      days_on_market: property.daysOnMarket,
      mls_number: property.mlsNumber,
      
      // Agent and office information
      listing_agent: property.listingAgent,
      listing_office: property.listingOffice,
      
      // Additional details
      virtual_tour_url: property.virtualTourUrl,
      description: property.description,
      
      // Any other fields that might be present in the data
      // ...
    });
    return properties;
  }, []);
};

export const propertyDetailFormat = (propertyData) => {
  const { propertyInfo, lotInfo } = propertyData;

  const mlsHistory = propertyData.mlsHistory.reduce((dates, date) => {
    const history = {
      effective_date: new Date(date.statusDate).toLocaleDateString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      price: date.price,
      dom: date.daysOnMarket ?? 'N/A',
      status: date.status.charAt(0).toUpperCase() + date.status.slice(1),
      agent: {
        email: date.agentEmail,
        name: date.agentName,
        office: date.agentOffice,
        phone: date.agentPhone,
      },
    };

    dates.push(history);
    return dates;
  }, []);

  const schools = propertyData.schools.reduce(
    (schools, school) => {
      const { levels } = school;
      return {
        ...schools,
        preschool: levels.preschool
          ? [...schools.preschool, school.name]
          : schools.preschool,

        elementary: levels.elementary
          ? [...schools.elementary, school.name]
          : schools.elementary,
        middle: levels.middle
          ? [...schools.middle, school.name]
          : [...schools.middle],
        high: levels.high ? [...schools.high, school.name] : schools.high,
      };
    },
    { preschool: [], middle: [], elementary: [], high: [] }
  );

  const propertyDetail = {
    id: propertyData.id,
    propertyInfo,
    lotInfo,
    subdivision:
      propertyData.neighborhood?.type === 'subdivision'
        ? propertyData.neighborhood.name
        : null,
    neighborhood: propertyData.neighborhood?.name
      ? propertyData.neighborhood.name
      : null,
    current_price: propertyData.mlsListingPrice,
    change_type: propertyData.mlsStatus,
    type: propertyData.propertyUse,
    dom: propertyData.mlsDaysOnMarket,
    position: {
      lat: propertyInfo.latitude,
      lng: propertyInfo.longitude,
    },
    acres: lotInfo.lotAcres,
    list_office: propertyData.listhub?.ListOfficeName,
    mlsHistory: mlsHistory,
    propertyType: propertyData.propertyType,
    ownerInfo: propertyData.ownerInfo,
    schools,
  };

  console.log('>> propertyDetail', propertyDetail);
  return propertyDetail;
};
