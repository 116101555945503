import styled from 'styled-components';
import { Row, Flex } from 'antd';

export const Container = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1350px;
  margin: auto;
  padding: 20px;
  height: 100%;
  background-color: #f5f5f5; /* Add background color */
`;

export const Toolbar = styled(Flex)`
  height: 56px;
  border-radius: 8px;
  background-color: #ffffff;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow wrapping on small screens */
`;

export const ToolbarOptions = styled(Flex)`
  align-items: center;
  margin-bottom: 10px; /* Add spacing for wrapping */
  flex-grow: 1; /* Allow the ToolbarOptions to take up available space */
  justify-content: space-between; /* Center the ToolbarOptions */

  &:last-child {
    margin-bottom: 0; /* Remove bottom margin for the last ToolbarOptions */
  }
`;

export const Divider = styled.div`
  border: 1px solid #dee8f7;
  height: 100%;
  margin: 0 10px;
`;

export const ListingItemContainer = styled(Row)`
  width: 100%;
  gap: 25px;
`;

export const CenteredMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(75vh - 200px); /* Adjust based on your layout */
  text-align: center;

  .ant-empty-image {
    height: 50px; /* Override the height of the empty image */
  }
`;

export const PropertyActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  button {
    flex: 1;
    margin: 0 5px;
  }
`;
