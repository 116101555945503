import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Empty } from 'antd';
import axios from 'axios';
import { useStore } from '../../hooks/storeContext';
import { api } from '../../constants/api';

// Styles
import {
  Container,
  Content,
  ListingItemContainer,
  CenteredMessage,
} from './Styled';

// Components
import { HeartOutlined } from '@ant-design/icons';
import ListingItem from './ListingItem/ListingItem.js';
import { getStreetViewImage } from '../../utils/getStreetView';

const UserFavorites = () => {
  const [properties, setProperties] = useState([]);
  const { authStore } = useStore();
  const isFetching = useRef(false);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (isFetching.current) return;
      isFetching.current = true;

      if (!authStore.me || !authStore.me.email) {
        console.log('User is not authenticated yet.');
        isFetching.current = false;
        return;
      }

      const userEmail = authStore.me.email;

      try {
        const response = await axios.get(api.user.getFavorites, {
          params: { email: userEmail }
        });
        if (response.status === 200) {
          const propertiesWithImages = await Promise.all(response.data.properties.map(async (property) => {
            const fullAddress = `${property.address.address}`;
            const streetViewImage = await getStreetViewImage({ address: fullAddress });
            return {
              ...property,
              streetViewImage,
            };
          }));
          setProperties(propertiesWithImages);
        } else {
          console.error('Failed to fetch favorites:', response.data.msg);
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      } finally {
        isFetching.current = false;
      }
    };

    if (authStore.me && authStore.me.email) {
      fetchFavorites();
    }
  }, [authStore.me, authStore.me?.email]);

  return (
    <Container>
      <Content>

        {properties.length === 0 ? (
          <CenteredMessage>
            <Empty
              image={<HeartOutlined style={{ fontSize: 48 }} />}
              description="No Favorites Yet"
            />
          </CenteredMessage>
        ) : (
          <ListingItemContainer>
            <Row gutter={[16, 16]}>
              {properties.map((property) => {
                return (
                  <Col xs={24} sm={12} md={8} key={property.id}>
                    <ListingItem property={property} />
                  </Col>
                );
              })}
            </Row>
          </ListingItemContainer>
        )}
      </Content>
    </Container>
  );
};

export default UserFavorites;
