import React, { useState, useEffect } from 'react';
import { Tabs, Select, Button, Spin, Image } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/storeContext';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { LoadingOutlined } from '@ant-design/icons';

import home from '../../../assets/images/save-home.png';

const { TabPane } = Tabs;
const { Option } = Select;

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const mapContainerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: 31.9686, // Latitude for center of Texas
  lng: -99.9018 // Longitude for center of Texas
};

const Header = styled.h1`
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const Email = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

const HomeValueSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const HomeIllustration = styled.div`
  width: 33%;
`;

const HomeValueContent = styled.div`
  width: 67%;
  padding-left: 32px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const SectionDescription = styled.p`
  color: #666;
  margin-bottom: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const NoThanksLink = styled(Link)`
  margin-left: 16px;
  color: blue;
  text-decoration: underline;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const FilterGroup = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)`
  width: 150px;
  margin-right: 16px;
`;

const ViewToggle = styled.div`
  display: flex;
`;

const ToggleButton = styled(Button)`
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const NoSavedHomesMessage = styled.div`
  margin-top: 32px;
`;

const NoSavedHomesTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const NoSavedHomesDescription = styled.p`
  color: #666;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: #a6cae0;
  position: relative;
`;

const MapOverlay = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

const MapButton = styled(Button)`
  background-color: white;
  border: 1px solid #ccc;
`;

const MapBottomOverlay = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const SavedHomes = observer(() => {
  const [viewMode, setViewMode] = useState("list");
  const location = useLocation();
  const { authStore } = useStore();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY 
  });


  useEffect(() => {
    authStore.fetchUserProfile();
  }, [authStore]);

  if (authStore.pending) {
    return (
      <SpinnerContainer>
        <Spin indicator={antIcon} />
      </SpinnerContainer>
    );
  }

  if (!authStore.me) {
    return (
      <PageContainer>
        <Header>Please log in to view your settings.</Header>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header>
        Hi there{authStore.me?.firstName ? `, ${authStore.me.firstName}` : ''}!
      </Header>
      <Email>{authStore.me?.email}</Email>

      <Tabs defaultActiveKey={location.pathname}>
        <TabPane tab={<Link to="/buyer-profile">Buyer profile</Link>} key="/buyer-profile" />
        <TabPane tab={<Link to="/saved-homes">Saved Listings</Link>} key="/saved-homes" />
        <TabPane tab={<Link to="/settings">Settings</Link>} key="/settings" />
      </Tabs>

      <HomeValueSection>
        <Image src={home} alt="flower pot" width={200} />
        <HomeValueContent>
          <SectionTitle>No saved homes yet</SectionTitle>
          <SectionDescription>
            Thats ok, you can save any of the listings to look at later simply by tapping on the ♥
          </SectionDescription>
        </HomeValueContent>
      </HomeValueSection>

      <FilterSection>
        <FilterGroup>
          <StyledSelect defaultValue="all">
            <Option value="all">All Listings</Option>
          </StyledSelect>
          <StyledSelect defaultValue="recent">
            <Option value="recent">Recently Added</Option>
          </StyledSelect>
        </FilterGroup>
        <ViewToggle>
          <ToggleButton 
            type={viewMode === "list" ? "primary" : "default"}
            onClick={() => setViewMode("list")}
          >
            List
          </ToggleButton>
          <ToggleButton 
            type={viewMode === "map" ? "primary" : "default"}
            onClick={() => setViewMode("map")}
          >
            Map
          </ToggleButton>
        </ViewToggle>
      </FilterSection>

      {viewMode === "list" ? (
        <NoSavedHomesMessage>

        </NoSavedHomesMessage>
      ) : (
        <MapContainer>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={6}
            >
              {/* You can add markers or other map components here */}
            </GoogleMap>
          ) : <Spin />}
          <MapOverlay>
            <MapButton>Street View</MapButton>
            <MapButton>Satellite</MapButton>
          </MapOverlay>
          <MapBottomOverlay>
            <MapButton>Nearby</MapButton>
            <MapButton>Schools</MapButton>
            <MapButton>Neighborhoods</MapButton>
          </MapBottomOverlay>
        </MapContainer>
      )}
    </PageContainer>
  );
});

export default SavedHomes;