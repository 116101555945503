import styled from 'styled-components';
import { Typography, Button } from 'antd';

const { Title, Paragraph } = Typography;

export const CtaContainer = styled.div`
  padding: 64px 50px;
  background: #0066cc;
  text-align: center;
`;

export const StyledTitle = styled(Title)`
  &&&& {
    color: white;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: 18px;
  margin-bottom: 24px;
  color: white;
`;

export const StyledButton = styled(Button)`
  &&&& {
    background: white;
    height:48px;
    border-color: white;
    color: #0066cc;
  }
`;