// src/components/styled.js
import styled from 'styled-components';
import { Card, Input, Button } from 'antd';
import { COLORS } from '../../../../constants/colors';

export const Container = styled.div`
  padding: 0 16px;
  min-height: 100vh; // Ensure it takes at least full viewport height
  overflow: hidden;
`;

export const Header = styled.header`
  padding: 16px 0;
`;

export const MainContent = styled.div`
  padding: 32px 0;
  max-width: 1440px;
  margin: auto;
`;

export const LocationSearch = styled.div`
  margin: 16px 0;
`;

export const PropertyList = styled.div`
  margin: 32px 0;

  .ant-card-cover img {
    width: 100%;
    height: auto;
  }

  .ant-card-meta-title {
    margin-top: 16px;
  }

  .ant-card-meta-description {
    margin-top: 4px;
  }
`;

export const Footer = styled.footer`
  padding: 32px 0;
  text-align: center;
  background: #f8f9fa; // Match the footer background to the section background
`;

export const StyledCard = styled(Card)`
  .ant-card-body {
    border: 1px solid #d9d9d9;
    border-top: none;
  }
`;

// Styles for the email section
export const SectionContainer = styled.div`
  background: #fff;
  padding: 50px 25px;
  text-align: center;
`;

export const NoSpamText = styled.p`
  color: #007bff;
  margin-bottom: 8px;
  font-size: 14px;
`;

export const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  max-width: 400px;
  border-radius: 10px 0 0 10px;
  margin-right: 0px;
  height: 48px;
`;

export const StyledButton = styled(Button)`
  height: 48px;
  border-radius: 0 10px 10px 0;
`;

export const SubText = styled.p`
  font-size: 14px;
  color: #6c757d;
`;

export const Highlight = styled.span`
  color: #007bff;
  font-weight: bold;
`;
