// src/components/styled.js
import styled from 'styled-components';
import { Row, Col, Flex, Image } from 'antd';
import { COLORS } from '../../../../constants/colors';

export const StyledRow = styled(Row)`
  min-height: 100vh; // Ensure the row takes at least the full viewport height
  display: flex;
  flex-direction: column;
`;

export const StyledContent = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 0 auto;
  width: fit-content;
`;

export const StyledImage = styled(Image)`
  && {
    margin: 0 auto 80px auto;
    width: fit-content;
    display: flex;
  }
`;

export const StyledWrapper = styled(Col)`
  background: ${COLORS.$blueShades98};
  padding-top: 135px;
`;

export const StyledSquare = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  border-top: 300px solid ${COLORS.$blueShades92};
  border-right: 300px solid transparent;
`;

export const StyledCol = styled(Col)`
  form {
    width: 100%;
  }
`;

export const StyledHeader = styled.div`
  height: 96px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 60px;
  border-bottom: 2px solid ${COLORS.$blueShades96};
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: ${COLORS.$black};

  @media (max-width: 1024px) {
    height: 72px;
    padding: 0 27px 0 37px;
  }
`;

export const FooterContainer = styled.footer`
  background: #fff;
  padding: 40px;
  border-top: 1px solid #dee2e6;
  margin-top: auto; // Push footer to the bottom of the page
`;

export const FooterRow = styled(Row)`
  max-width: 1200px;
  margin: auto;
`;

export const FooterColumn = styled(Col)`
  text-align: left;

  @media (max-width: 576px) {
    margin-bottom: 20px;
  }
`;

export const FooterHeading = styled.h3`
  font-size: 14px;
  margin-bottom: 16px;
  color: #333;
`;

export const FooterLink = styled.a`
  display: block;
  color: #007bff;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const FooterLogoText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure it takes at least full viewport height
  overflow: auto; // Allow scrolling if content overflows
`;
