import React from 'react';
// import BaseTypography from '../../../components/BaseTypography';

// import styles from './DescriptionDetails.module.scss';

const DescriptionDetails = () => {
  return (
    <>
      {/* <BaseTypography
        className={styles.titleDescription}
        fontSize={'20px'}
        align="left"
      >
        About this home
      </BaseTypography>
      <p className={styles.description}>
        Check out that Custom Backyard Entertaining space! 3237sqft, 4 Bedrooms,
        2 Bathrooms house on a Lake.
        <button className={styles.link}>Read more</button>
      </p> */}
    </>
  );
};

export default DescriptionDetails;
