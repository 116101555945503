import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import { Image, Spin } from 'antd';

// Styles
import styles from './Details.module.scss';

// Constants
import { DETAILS_TABLE_COLUMNS } from '../../constants';
import { COLORS } from '../../constants/colors';

// Utils
import { formatNumberToDollars } from '../../utils/numberFormat';
import { getStreetViewImage } from '../../utils/getStreetView';

// Hooks
import useGetPropertyDetail from '../../hooks/useGetPropertyDetail';

// Components
import { Button, Col, Row, Flex, Table } from 'antd';
import Icon, { LeftOutlined } from '@ant-design/icons';
import BaseTypography from '../../components/BaseTypography';
import Card from './Card';
import Specifications from './Specifications';
import PropertyOwner from './PropertyOwner/PropertyOwner';
import ListingGallery from './ListingGallery';
import {
  LogoDarkPlainSvg,
  LendSvg,
  PropertyDetailsSvg,
} from '../../components/Icons';
import Spinner from '../../components/Spinner';
import { isMobile } from 'react-device-detect';
import { BaseButton } from '../../components/Button';
import ButtonsGroup from './ButtonsGroup';
import DescriptionDetails from './DescriptionDetails';

const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [streetViewImage, setStreetViewImage] = useState(null);
  const { propertyDetail, isLoading, hasError } = useGetPropertyDetail(id);
  const [imageLoading, setImageLoading] = useState(true);
  const { propertyInfo, mlsHistory, schools } = propertyDetail;
  const agent = mlsHistory && mlsHistory[0].agent;
  const isSchools =
    schools &&
    Object.values(schools).filter((school) => school.length > 0).length > 0;

  const [showMoreSchools, setShowMoreSchools] = useState({
    preschool: false,
    elementary: false,
    middle: false,
    high: false,
  });

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleLendClick = () => {
    navigate('/lend', {
      state: {
        price: propertyDetail.current_price,
        address: propertyInfo.address.address,
        listingName: propertyDetail.neighborhood || propertyDetail.type,
        streetViewImage,
        propertyDetail,
      },
    });
  };

  useEffect(() => {
    const fetchStreetViewImage = async () => {
      if (propertyInfo?.address) {
        setImageLoading(true); // Set loading to true before fetching
        const fullAddress = `${propertyInfo.address.address}, ${propertyInfo.address.city}, ${propertyInfo.address.state}`;
        const image = await getStreetViewImage({ address: fullAddress });
        setStreetViewImage(image);
        setImageLoading(false); // Set loading to false after fetching
      }
    };

    fetchStreetViewImage();
  }, [propertyInfo]);

  const handleToggleViewMore = (type) => {
    setShowMoreSchools((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const renderSchools = (type) => {
    if (!schools[type] || schools[type].length === 0) return null;
    const schoolsToShow = schools[type].slice(0, 3); // Only show top 3 schools

    return (
      <div className={styles.schoolTypeContainer}>
        <BaseTypography align="left" className={styles.infoTitle} style={{color: 'black'}}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </BaseTypography>
        {schoolsToShow.map((school, index) => (
          <BaseTypography key={index} align="left" className={styles.schoolName}>
            {school}
          </BaseTypography>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.navigation}>
          <Button
            onClick={handleClickBack}
            size="large"
            type="link"
            style={{ padding: 0 }}
            icon={<LeftOutlined />}
          >
            Back to list
          </Button>
        </div>

        <Row align="top" className={styles.titleContainer}>
          <Col xs={24} lg={12}>
            <BaseTypography
              className={styles.listingName}
              fontSize={isMobile ? '32px' : '40px'}
              align="left"
            >
              {propertyDetail.neighborhood || propertyDetail.type}
            </BaseTypography>
            <BaseTypography
              className={styles.listingAddress}
              fontSize={isMobile ? '16px' : '20px'}
              align="left"
              color={COLORS.$mainDark}
            >
              {propertyInfo.address.address}
            </BaseTypography>
          </Col>
          <Col xs={24} lg={12}>
            <ButtonsGroup />
          </Col>
        </Row>

        <ListingGallery streetViewImage={streetViewImage} />

        <Row className={styles.cardContainer} gutter={20}>
          <Col xs={24} lg={16}>
            <Specifications
              propertyType={propertyDetail.propertyType}
              price={formatNumberToDollars(propertyDetail.current_price)}
              neighborhood={propertyDetail.neighborhood}
            />
            {isMobile && <DescriptionDetails />}

            {agent && (
              <PropertyOwner contact={agent.name} owner={agent.office} />
            )}

            <Card title="General">
              <Flex gap={30}>
                <div className={styles.infoBlock}>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      HOA
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      None
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Acres
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyDetail.acres}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Type
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyDetail.lotInfo.landUse}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Laundry
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      In Unit
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Cooling
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.airConditioningType}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Heating
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.heatingType}
                    </BaseTypography>
                  </div>
                </div>

                <div className={styles.infoBlock}>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      City
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.address.city}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Year Built
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.yearBuilt ?? 'N/A'}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Size
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {`${propertyInfo.buildingSquareFeet} sqft`}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Lot Size
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {`${propertyInfo.lotSquareFeet} sqft`}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Parking Area
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {propertyInfo.parkingArea ? 'Yes' : 'No'}
                    </BaseTypography>
                  </div>
                  <div className={styles.info}>
                    <BaseTypography align="left" className={styles.infoTitle}>
                      Garage Size
                    </BaseTypography>
                    <BaseTypography align="right" className={styles.infoValue}>
                      {`${propertyInfo.garageSquareFeet ?? 0} sqft`}
                    </BaseTypography>
                  </div>
                </div>
              </Flex>
            </Card>

            {isSchools && (
            <Card title="Schools">
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={12}>
                  {renderSchools('preschool')}
                  {renderSchools('elementary')}
                </Col>
                <Col xs={24} sm={12}>
                  {renderSchools('middle')}
                  {renderSchools('high')}
                </Col>
              </Row>
            </Card>
          )}

            {isMobile && (
              <div className={styles.investWrapper}>
                <div className={styles.investTitle}>List Price</div>
                <div className={styles.investPrice}>
                  {formatNumberToDollars(propertyDetail.current_price)}
                </div>
                <BaseButton
                  className={styles.investBtn}
                  icon={<PropertyDetailsSvg />}
                  onClick={handleLendClick}
                >
                  Lend on this property
                </BaseButton>
              </div>
            )}

            <Card title="History">
              <Table
                columns={DETAILS_TABLE_COLUMNS}
                dataSource={propertyDetail.mlsHistory}
                rowKey={(record) => record.id}
              />
            </Card>

            <Card title="Map">
              <div className={styles.mapContainer}>
                <Map
                  mapId="detailsMap"
                  defaultCenter={propertyDetail.position}
                  defaultZoom={13}
                >
                  <AdvancedMarker
                    className={styles.listingPositionMarker}
                    position={propertyDetail.position}
                  >
                    <Icon component={LogoDarkPlainSvg} />
                  </AdvancedMarker>
                </Map>
              </div>
              <Button
                onClick={handleClickBack}
                size="large"
                type="link"
                style={{ padding: 0, marginTop: '10px' }}
                icon={<LeftOutlined />}
              >
                See more listings in {propertyInfo.address.city}
              </Button>
            </Card>
          </Col>
          {!isMobile && (
            <Col xs={24} lg={8}>
              <div className={styles.investContainer}>
                <BaseTypography
                  className={styles.listPrice}
                  align="left"
                  fontSize="14px"
                >
                  List Price
                </BaseTypography>
                <span className={styles.listPriceValue}>
                  {formatNumberToDollars(propertyDetail.current_price)}
                </span>

                <Button
                  icon={
                    <Icon className={styles.lendIcon} component={LendSvg} />
                  }
                  className={styles.lendButton}
                  type="primary"
                  size="large"
                  onClick={handleLendClick}
                >
                  Lend On This Property
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Details;