import React from 'react';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  ResultSection,
  MonthlyPayment,
  PaymentBreakdown,
  BreakdownItem,
  DueAtClose,
  GetPreApprovedButton,
  ShowListingsLink,
  DisclosureLinks,
} from './styled';

const PaymentSection = ({
  monthlyPayment,
  loanType,
  interestRate,
  principalAndInterest,
  propertyTax,
  homeInsurance,
  hoaFees,
  mortgageInsurance,
  homePrice,
  downPaymentPercentage,
  infoContent,
}) => {
  return (
    <ResultSection>
      <MonthlyPayment>${Math.round(monthlyPayment)}<span>/month</span></MonthlyPayment>
      <p>{loanType} at {interestRate.toFixed(3)}%</p>
      <PaymentBreakdown>
        <BreakdownItem color="teal">
          <span>Principal & interest</span>
          <span>${Math.round(principalAndInterest)}</span>
        </BreakdownItem>
        <BreakdownItem color="orange">
          <span>Property tax</span>
          <span>${Math.round(propertyTax)}</span>
        </BreakdownItem>
        <BreakdownItem color="blue">
          <span>Home insurance</span>
          <span>${Math.round(homeInsurance)}</span>
        </BreakdownItem>
        <BreakdownItem color="pink">
          <span>HOA fees</span>
          <span>${Math.round(hoaFees)}</span>
        </BreakdownItem>
        <BreakdownItem color="navy">
          <span>Mortgage insurance</span>
          <span>${Math.round(mortgageInsurance)}</span>
        </BreakdownItem>
      </PaymentBreakdown>
      <DueAtClose>
        ${Math.round(homePrice * 0.24)} due at close <Popover content={infoContent.closingCost}><FontAwesomeIcon icon={faInfoCircle} /></Popover>
      </DueAtClose>
      <p>{Math.round(downPaymentPercentage)}% down payment, 4% closing cost</p>
      <ShowListingsLink>Show listings within my budget</ShowListingsLink>
    </ResultSection>
  );
};

export default PaymentSection;