import React from 'react';
import { Slider, Select, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  InputSectionWrapper,
  InputGroup,
  Label,
  StyledInput,
  StyledSelect,
  ViewRatesLink,
  DownPaymentGroup
} from './styled';

const { Option } = Select;

const InputSection = ({
  homeLocation,
  setHomeLocation,
  homePrice,
  handleHomePriceChange,
  downPayment,
  handleDownPaymentChange,
  downPaymentPercentage,
  handleDownPaymentPercentageChange,
  loanType,
  handleLoanTypeChange,
  interestRate,
  setInterestRate,
  propertyTax,
  setPropertyTax,
  homeInsurance,
  setHomeInsurance,
  hoaFees,
  setHoaFees,
  mortgageInsurance,
  setMortgageInsurance,
  infoContent
}) => {
  return (
    <InputSectionWrapper>
      <InputGroup>
        <Label>Home location <Popover content={infoContent.homeLocation}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledInput value={homeLocation} onChange={(e) => setHomeLocation(e.target.value)} />
      </InputGroup>
      <InputGroup>
        <Label>Home price <Popover content={infoContent.homePrice}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledInput
          value={homePrice}
          onChange={(e) => handleHomePriceChange(Math.round(Number(e.target.value)))}
          prefix="$"
        />
        <Slider
          min={300000}
          max={850000}
          step={1000}
          value={homePrice}
          onChange={handleHomePriceChange}
        />
      </InputGroup>
      <InputGroup>
        <Label>Down payment <Popover content={infoContent.downPayment}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <DownPaymentGroup>
          <StyledInput
            value={downPayment}
            onChange={(e) => handleDownPaymentChange(Math.round(Number(e.target.value)))}
            prefix="$"
          />
          <StyledInput
            value={downPaymentPercentage}
            onChange={(e) => handleDownPaymentPercentageChange(Math.round(Number(e.target.value)))}
            suffix="%"
          />
        </DownPaymentGroup>
        <Slider
          min={0}
          max={100}
          step={1}
          value={downPaymentPercentage}
          onChange={handleDownPaymentPercentageChange}
        />
      </InputGroup>
      <InputGroup>
        <Label>Loan type <Popover content={infoContent.loanType}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledSelect value={loanType} onChange={handleLoanTypeChange} style={{ width: '100%' }}>
          <Option value="30-year fixed">30-year fixed</Option>
          <Option value="15-year fixed">15-year fixed</Option>
          <Option value="5/1 ARM">5/1 ARM</Option>
        </StyledSelect>
      </InputGroup>
      <InputGroup>
        <Label>Interest rate <Popover content={infoContent.interestRate}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledInput
          value={interestRate.toFixed(3)}
          onChange={(e) => setInterestRate(Number(e.target.value))}
          suffix="%"
        />
      </InputGroup>
      <InputGroup>
        <Label>Property tax <Popover content={infoContent.propertyTax}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledInput
          value={Math.round(propertyTax)}
          onChange={(e) => setPropertyTax(Math.round(Number(e.target.value)))}
          prefix="$"
        />
      </InputGroup>
      <InputGroup>
        <Label>Home insurance <Popover content={infoContent.homeInsurance}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledInput
          value={Math.round(homeInsurance)}
          onChange={(e) => setHomeInsurance(Math.round(Number(e.target.value)))}
          prefix="$"
        />
      </InputGroup>
      <InputGroup>
        <Label>HOA fees <Popover content={infoContent.hoaFees}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledInput
          value={Math.round(hoaFees)}
          onChange={(e) => setHoaFees(Math.round(Number(e.target.value)))}
          prefix="$"
        />
      </InputGroup>
      <InputGroup>
        <Label>Mortgage insurance <Popover content={infoContent.mortgageInsurance}><FontAwesomeIcon icon={faInfoCircle} /></Popover></Label>
        <StyledInput
          value={Math.round(mortgageInsurance)}
          onChange={(e) => setMortgageInsurance(Math.round(Number(e.target.value)))}
          prefix="$"
        />
      </InputGroup>
    </InputSectionWrapper>
  );
};

export default InputSection;