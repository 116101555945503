export const ROUTE_PATH = {
  DASHBOARD: '/dashboard',
  ONBOARDING: "/onboarding",
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot',
  SEARCH: '/search',
  DETAILS: '/details',
  FAVORITES: '/favorites',
  MAP: '/map',
  LEND: '/lend',
  CALCULATORS: '/calculators',
  SETTINGS: '/settings',
  MY_PROPERTIES: '/myproperties',
  PENDING_APPROVAL: '/pending-approval',
};

export const AGENT_ROUTE_PATH = {
  DASHBOARD: '/agent/dashboard',
  PROPERTIES: "/agent/properties",
  APPLICANTS: '/agent/applicants',
  APPROVALS: '/agent/approvals',
};
