import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Space, Button } from 'antd';
import { StyledDropdown } from './styled';
const DropdownComponent = ({
  children,
  items = [],
  trigger = 'click',
  styles = {},
  placement = 'bottomLeft',
  disabled = false,
  onClick,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledDropdown
      trigger={trigger}
      menu={{ items, onClick }}
      onOpenChange={(open) => setIsOpen(open)}
      placement={placement}
      disabled={disabled}
      {...props}
    >
      <Button style={{ ...styles }} size="large">
        <Space align="center">
          {children}
          {!isOpen ? <DownOutlined /> : <UpOutlined />}
        </Space>
      </Button>
    </StyledDropdown>
  );
};

export default DropdownComponent;
