import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Slider, Select, Form, Modal, notification } from 'antd';
import listingExample from '../../assets/images/listing-grid-example.png';
import MortgageCalculator from '../../calculators/mortgageCalculator';
import { api } from '../../constants/api';
import { useStore } from '../../hooks/storeContext';
import { useNavigate } from 'react-router-dom';

// Styles
import {
  Container,
  ImageCard,
  Image,
  TermsCard,
  StyledButton,
  StyledRow,
  StyledInput,
  StyledSelect,
  ModalContent,
  ModalTitle,
  ModalValue,
  PriceTag,
} from './styled';

const { Option } = Select;

const Lend = () => {
  const location = useLocation();
  const { price, address, listingName, streetViewImage, propertyDetail } = location.state || {};
  const initialLendingAmount = price || 100000;
  const [isLoading, setIsLoading] = useState(false);
  const [lendingAmount, setLendingAmount] = useState(initialLendingAmount);
  const [downPayment, setDownPayment] = useState(lendingAmount * 0.05);
  const [interestRate, setInterestRate] = useState(10);
  const [loanLength, setLoanLength] = useState(30);
  const [paymentFrequency, setPaymentFrequency] = useState('Monthly');
  const [latePaymentPenalties, setLatePaymentPenalties] = useState(150);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const { authStore } = useStore();
  const navigate = useNavigate();

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
  };

  const handleLendingAmountChange = (e) => {
    const value = parseFloat(e.target.value.replace(/[^0-9]/g, ''));
    setLendingAmount(value);
    setDownPayment(value * 0.05);
  };

  const handleDownPaymentChange = (e) => {
    const value = parseFloat(e.target.value.replace(/[^0-9]/g, ''));
    setDownPayment(value);
  };

  const handleInterestRateChange = (value) => {
    setInterestRate(value);
  };

  const handleLoanLengthChange = (value) => {
    setLoanLength(value);
  };

  const handlePaymentFrequencyChange = (value) => {
    setPaymentFrequency(value);
  };

  const handleLatePaymentPenaltiesChange = (e) => {
    const value = parseFloat(e.target.value.replace(/[^0-9]/g, ''));
    setLatePaymentPenalties(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const calculateMonthlyPayment = () => {
    const principal = lendingAmount - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = loanLength * 12;

    const payment = principal * (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    setMonthlyPayment(payment);
  };

  useEffect(() => {
    calculateMonthlyPayment();
  }, [lendingAmount, downPayment, interestRate, loanLength]);

  const handleConfirmTerms = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(api.lending.setLendingTerms, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          listingID: propertyDetail.id,
          lendingAmount,
          downPayment,
          interestRate,
          loanLength,
          paymentFrequency,
          latePaymentPenalties,
          address: propertyDetail.propertyInfo.address.address,
          city: propertyDetail.propertyInfo.address.city,
          state: propertyDetail.propertyInfo.address.state,
          price,
          image: streetViewImage,
          email: authStore.me.email,
        }),
      });

      if (response.ok) {
        notification.success({ message: 'Lending terms set successfully' });
        navigate('/myproperties');
      } else {
        notification.error({ message: 'Failed to set lending terms' });
      }
    } catch (error) {
      notification.error({ message: 'Error setting lending terms', description: error.message });
    } finally {
      setIsLoading(false);
      setIsModalVisible(false);
    }
  };

  return (
    <Container>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <ImageCard>
            <Image src={streetViewImage || listingExample} alt="Property" />
            <PriceTag>{formatPrice(lendingAmount)}</PriceTag>
          </ImageCard>
          <TermsCard title="Set Your Terms">
            <Form layout="vertical">
              <Form.Item label="Lending Amount">
                <StyledInput value={formatPrice(lendingAmount)} onChange={handleLendingAmountChange} />
              </Form.Item>
              <Form.Item label="Down Payment">
                <StyledInput value={formatPrice(downPayment)} onChange={handleDownPaymentChange} />
              </Form.Item>
              <Form.Item label={`Interest Rate (${interestRate}%)`}>
                <Slider min={0} max={20} value={interestRate} onChange={handleInterestRateChange} />
              </Form.Item>
              <Form.Item label="Loan Length">
                <StyledSelect defaultValue={loanLength} onChange={handleLoanLengthChange}>
                  <Option value={15}>15 years</Option>
                  <Option value={30}>30 years</Option>
                </StyledSelect>
              </Form.Item>
              <Form.Item label="Payment Frequency">
                <StyledSelect defaultValue={paymentFrequency} onChange={handlePaymentFrequencyChange}>
                  <Option value="Monthly">Monthly</Option>
                  <Option value="Quarterly">Quarterly</Option>
                </StyledSelect>
              </Form.Item>
              <Form.Item label="Late Payment Penalties">
                <StyledInput value={`$${latePaymentPenalties}`} onChange={handleLatePaymentPenaltiesChange} />
              </Form.Item>
              <Form.Item style={{marginBottom: '0'}}>
                <strong>Monthly Payment: {formatPrice(monthlyPayment)}</strong>
              </Form.Item>
              <StyledRow style={{marginTop: '0'}}>
                <Col span={24}>
                  <StyledButton type="primary" block onClick={showModal}>
                    Set Terms
                  </StyledButton>
                </Col>
              </StyledRow>
            </Form>
          </TermsCard>
        </Col>
        <Col xs={24} md={12}>
          <MortgageCalculator initialPrice={lendingAmount} />
        </Col>
      </Row>

      <Modal
        title="Set Your Terms"
        visible={isModalVisible}
        onCancel={handleCancel}
        className="custom-modal"
        footer={[
          <StyledButton key="submit" type="primary" onClick={handleConfirmTerms} loading={isLoading}>
            Confirm Your Terms
          </StyledButton>
        ]}
      >
        <ModalContent>
          <ModalTitle>Lending Amount:</ModalTitle>
          <ModalValue>{formatPrice(lendingAmount)}</ModalValue>

          <ModalTitle>Down Payment:</ModalTitle>
          <ModalValue>{formatPrice(downPayment)}</ModalValue>

          <ModalTitle>Interest Rate:</ModalTitle>
          <ModalValue>{interestRate}%</ModalValue>

          <ModalTitle>Loan Length:</ModalTitle>
          <ModalValue>{loanLength} Years</ModalValue>

          <ModalTitle>Payment Frequency:</ModalTitle>
          <ModalValue>{paymentFrequency}</ModalValue>

          <ModalTitle>Late Payment Penalties:</ModalTitle>
          <ModalValue>{`$${latePaymentPenalties}/month`}</ModalValue>

          <ModalTitle>Monthly Payment:</ModalTitle>
          <ModalValue style={{fontSize:'19px'}}>{formatPrice(monthlyPayment)}</ModalValue>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Lend;