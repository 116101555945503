import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Skeleton } from 'antd';
import { PropertyList, StyledCard, StyledSelect, LinkToMore, SectionHighlight, SectionSubheading, HouseStatus } from './styled';
import { isMobile, isDesktop } from 'react-device-detect';
import { Link } from 'react-router-dom';
import listingItem from '../../../../assets/images/listing-example.png';

const { Option } = StyledSelect;

const ListItem = ({ lendingTerms }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (lendingTerms && lendingTerms.length > 0) {
      setLoading(false);
    }
  }, [lendingTerms]);

  const renderCards = () => (
    lendingTerms.slice(0, 6).map(term => (
      <Col key={term.listingID} xs={24} s={12} md={8}>
        <Link to={`/details/${term.listingID}`}>
          <StyledCard cover={<img alt={term.address} src={term.image || listingItem} />}>
            <HouseStatus>Available for sale</HouseStatus>
            <Card.Meta title={term.address} />
            <p>{term.city}, {term.state} {term.zipCode}</p>
          </StyledCard>
        </Link>
      </Col>
    ))
  );

  const renderContent = () => (
    <PropertyList>
      <SectionHighlight style={{ marginBottom: '10px' }}>Properties based on your location</SectionHighlight>
      <SectionSubheading style={{ marginBottom: '5px', fontSize: '20px' }}>Some of our picked properties near your location.</SectionSubheading>
      <div style={{ marginBottom: '20px' }}>
        <Link to="/search">
          <LinkToMore>Browse all properties</LinkToMore>
        </Link>
      </div>
      <Row gutter={[16, 16]}>
        {loading ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : (
          renderCards()
        )}
      </Row>
    </PropertyList>
  );

  return (
    <>
      {isDesktop && renderContent()}
      {isMobile && renderContent()}
    </>
  );
};

export default ListItem;