// SignupModal.js
import React, { useState } from 'react';
import { Modal, Form, Input, Button, Divider } from 'antd';
import { MailOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useStore } from '../../../../hooks/storeContext'; // Adjust the import path as needed

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 24px;
  }
`;

const StyledButton = styled(Button)`
  height: 52px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #414141;
  margin-bottom: 24px;
`;

const PasswordRequirements = styled.p`
  font-size: 12px;
  color: #414141;
  margin-top: 4px;
`;

const BackLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 16px;
  color: #1890ff;
  font-size: 14px;
`;

const StyledInput = styled(Input)`
  height: 48px;
  padding: 0 11px;
`;

const StyledPassword = styled(Input.Password)`
  height: 48px;
  padding: 0 11px;

  input {
    height: 48px;
    padding: 0 11px;
  }
`;

const SignupModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState('email');
  const [email, setEmail] = useState('');
  const { authStore } = useStore();

  const handleEmailSubmit = (values) => {
    setEmail(values.email);
    setStep('password');
  };

  const handlePasswordSubmit = async (values) => {
    try {
      await authStore.onClientSignUp({ email, password: values.password });
      onClose();
    } catch (error) {
      // Error is already handled in the store
      console.error('Signup failed:', error);
    }
  };

  const handleEmailLink = () => {
    console.log('Email Me a Link clicked');
    // Implement email link logic here
  };

  const handleBack = () => {
    if (step === 'password') {
      setStep('email');
    } else {
      onClose();
    }
  };

  const renderEmailStep = () => (
    <>
      <Title>Sign up</Title>
      <Subtitle>Enter your email to create an account.</Subtitle>
      <Form form={form} onFinish={handleEmailSubmit} layout="vertical">
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' }
          ]}
        >
          <Input placeholder="Email address" />
        </Form.Item>
        <Form.Item>
          <StyledButton type="primary" htmlType="submit" block>
            Continue
          </StyledButton>
        </Form.Item>
      </Form>
    </>
  );

  const renderPasswordStep = () => (
    <>
      <Title>Add a password</Title>
      <Subtitle>Set a password to access your account on all your devices.</Subtitle>
      <Form form={form} onFinish={handlePasswordSubmit} layout="vertical">
        <Form.Item
          name="password"
          label="Set a password"
          rules={[
            { required: true, message: 'Please input your password!' },
            { min: 8, message: 'Password must be at least 8 characters long' },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message: 'Password must meet the requirements below'
            }
          ]}
        >
          <Input.Password 
            placeholder="Enter password"
            iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <PasswordRequirements>
          Use 8 or more characters with a combination of uppercase, lowercase, a number, and a symbol.
        </PasswordRequirements>
        <Form.Item style={{ marginTop: '24px' }}>
          <StyledButton type="primary" htmlType="submit" block>
            Create Password
          </StyledButton>
        </Form.Item>
      </Form>
      <Divider plain>or</Divider>
      <StyledButton icon={<MailOutlined />} block onClick={handleEmailLink}>
        Email Me a Link
      </StyledButton>
    </>
  );

  return (
    <StyledModal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={500}
      centered
    >
      {step === 'email' ? renderEmailStep() : renderPasswordStep()}
      <BackLink onClick={handleBack}>{step === 'password' ? 'Back' : 'Cancel'}</BackLink>
    </StyledModal>
  );
};

export default SignupModal;