import React, { useState, useEffect, useRef } from 'react';
import { Menu, Button } from 'antd';
import { MenuOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Image } from 'antd';
import logo from '../../../../assets/images/logo.svg';
import { COLORS } from '../../../../constants/colors';

const MobileMenuWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const MenuButton = styled(Button)`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
`;

const SlideOutMenu = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-250px'};
  width: 250px;
  height: 100vh;
  background-color: white;
  transition: right 0.3s ease-in-out;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1002;
  display: flex;
  flex-direction: column;
`;

const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${COLORS.$blueShades92};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: ${COLORS.$black};
`;

const HeaderDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${COLORS.$blueShades92};
  margin: 0 16px;
`;

const CloseButton = styled(Button)`
  margin-left: auto;
  border: none;
  background: none;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
`;

const StyledMenu = styled(Menu)`
  border-right: none;
  .ant-menu-item {
    border-bottom: 1px solid ${COLORS.$blueShades96};
    &:last-child {
      border-bottom: none;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const DropdownMenu = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding-left: 16px;
  background-color: ${COLORS.$white};
`;

const MenuItemWithArrow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ArrowIcon = styled(DownOutlined)`
  transition: transform 0.3s ease;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMortgageOpen, setIsMortgageOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMortgageDropdown = () => {
    setIsMortgageOpen(!isMortgageOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MobileMenuWrapper>
      <MenuButton icon={<MenuOutlined />} onClick={toggleMenu} />
      <Overlay isOpen={isMenuOpen} />
      <SlideOutMenu isOpen={isMenuOpen} ref={menuRef}>
        <MobileMenuHeader>
          <LogoContainer>
            <Image preview={false} src={logo} alt="Logo" style={{width: '24px', marginRight: '8px'}} />
            <span>Invested</span>
          </LogoContainer>
          <HeaderDivider />
          <CloseButton icon={<CloseOutlined />} onClick={toggleMenu} />
        </MobileMenuHeader>
        <StyledMenu mode="vertical" style={{ flex: 1 }}>
          <Menu.Item key="/buy" onClick={closeMenu}>
            <Link to="/">Buy</Link>
          </Menu.Item>
          <Menu.Item key="/mortgage" onClick={toggleMortgageDropdown}>
            <MenuItemWithArrow style={{fontSize: '16px'}}>
              Mortgage
              <ArrowIcon isOpen={isMortgageOpen} />
            </MenuItemWithArrow>
          </Menu.Item>
          <DropdownMenu isOpen={isMortgageOpen}>
            <Menu.Item key="/tools/mortgage-calculator" onClick={closeMenu}>
              <Link to="/tools/mortgage-calculator">Mortgage calculator</Link>
            </Menu.Item>
            <Menu.Item key="/tools/affordability-calculator" onClick={closeMenu}>
              <Link to="/tools/affordability-calculator">Affordability Calculator</Link>
            </Menu.Item>
          </DropdownMenu>
        </StyledMenu>
      </SlideOutMenu>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
