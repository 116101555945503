import React from 'react';
import { Row, Col } from 'antd';
import MyProperties from './MyProperties/index.js';

import {
  ContentContainer,
  StyledCard,
} from './styled';

const AgentDashboard = () => {

  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: '0px' }}>
      <ContentContainer>
          <MyProperties />
      </ContentContainer>
    </div>
  );
};

export default AgentDashboard;