import React from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { observer } from 'mobx-react';

// Styles
import styles from './HomeLayout.module.scss';

// Components
import { Layout } from 'antd';
import SideNavigation from './SideNavigation';
import Header from './Header';
import { isDesktop } from 'react-device-detect';

const AgentLayout = observer(({ children }) => {
  return (
    <Layout
      hasSider
      className={classNames(styles.layout, { [styles.mobile]: isMobile })}
    >
      {isDesktop && <SideNavigation />}
      <Layout>
        <Header />
        {children}
      </Layout>
    </Layout>
  );
});

export default AgentLayout;