import { useState } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/storeContext';
import { useNavigate } from 'react-router-dom';
import { AGENT_ROUTE_PATH } from '../../../../constants/routePath';
import { BaseInput } from '../../../../components/Input';
import BaseTypography from '../../../../components/BaseTypography';
import { COLORS } from '../../../../constants/colors';
import {
  StyledFlex,
  StyledForm,
} from '../../styled';
import { BaseButton } from '../../../../components/Button';
import { isMobile } from 'react-device-detect';

const AgentLogin = observer(() => {
  const { authStore } = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onLogin = async (values) => {
    const isValid = await authStore.onAgentLogin(values);

    if (isValid) navigate(AGENT_ROUTE_PATH.DASHBOARD);
  };

  return (
    <StyledFlex vertical>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        type="title"
        color={COLORS.$black}
        fontWeight={700}
        level={3}
      >
        Welcome Back
      </BaseTypography>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        marginBottom="32px"
        color={COLORS.$blackOpacity50}
      >
        Welcome back! Please enter your details to login to your agent dashboard.
      </BaseTypography>
      <StyledForm form={form} onFinish={onLogin}>
        <Form.Item name="email">
          <BaseInput label="Email" placeholder="Email" />
        </Form.Item>
        <Form.Item name="password">
          <BaseInput.Password
            type="password"
            label="Password"
            placeholder="Enter password"
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />
        </Form.Item>
        <Form.Item>
          <BaseButton type="primary" htmlType="submit" block>
            Log in
          </BaseButton>
        </Form.Item>
      </StyledForm>
    </StyledFlex>
  );
});

export default AgentLogin;
