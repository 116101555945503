import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
`;

const TermContainer = styled.div`
  margin-bottom: 20px;
`;

const TermTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  
  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 1em;
    background-color: #007bff;
    margin-right: 10px;
  }
`;

const TermDefinition = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.5;
`;

const terms = [
  {
    title: "Bi-weekly Mortgage",
    definition: "A bi-weekly mortgage is a payment strategy where borrowers make half their monthly mortgage payment every two weeks, resulting in 26 half-payments or 13 full payments per year. This approach can help homeowners pay off their mortgage faster and reduce overall interest costs. For example, if your monthly payment is $1,000, you'd pay $500 every two weeks, totaling $13,000 annually instead of $12,000 with traditional monthly payments. This accelerated payment schedule can significantly shorten your loan term and save thousands in interest over the life of your mortgage."
  },
  {
    title: "Down Payment",
    definition: "A down payment is a crucial upfront payment made by homebuyers, representing a percentage of the property's purchase price. This initial investment demonstrates the buyer's commitment and affects loan terms. Larger down payments often lead to better interest rates, lower monthly payments, and potentially eliminate the need for private mortgage insurance (PMI). Common down payment amounts range from 3% to 20% of the home's value, depending on the loan type and lender requirements. First-time homebuyers should explore various down payment assistance programs available to help make homeownership more accessible."
  },
  {
    title: "Escrow",
    definition: "Escrow is a vital financial safeguard in real estate transactions, where a neutral third party holds and regulates payment of funds and documents. In mortgage contexts, escrow accounts often manage property taxes and insurance payments. Lenders may require borrowers to maintain an escrow account, ensuring timely payment of these expenses. This arrangement protects both the lender's investment and the homeowner's property. Understanding escrow is essential for homebuyers, as it impacts monthly mortgage payments and overall homeownership costs."
  },
  {
    title: "Extra Mortgage Payment",
    definition: "An extra mortgage payment is a strategic financial move where homeowners make additional payments beyond their regular monthly mortgage obligation. These extra payments directly reduce the principal balance, potentially saving thousands in interest and shortening the loan term. Homeowners can make extra payments annually, monthly, or sporadically. Before making extra payments, it's crucial to check if your mortgage has prepayment penalties. Implementing an extra payment strategy can be an effective way to build equity faster and achieve mortgage-free homeownership sooner."
  },
  {
    title: "Homeowners Insurance",
    definition: "Homeowners insurance is a critical component of protecting your home investment. This comprehensive policy typically covers damage to your house and personal property from events like fire, theft, or natural disasters. It also includes liability protection if someone is injured on your property. Most mortgage lenders require homeowners insurance as a condition of the loan. When choosing a policy, consider factors like replacement cost coverage, deductible amounts, and additional living expenses coverage. Regularly reviewing and updating your homeowners insurance ensures adequate protection as your home's value and your personal circumstances change over time."
  },
  {
    title: "Home Sale Proceeds",
    definition: "Home sale proceeds represent the net amount a seller receives after closing a real estate transaction. This figure is calculated by subtracting various costs from the sale price, including outstanding mortgage balance, real estate agent commissions (typically 5-6% of the sale price), transfer taxes, and other closing costs. Understanding potential proceeds is crucial for sellers planning their next financial moves, such as purchasing a new home or investing. Factors affecting proceeds include local market conditions, home improvements, and negotiation outcomes. Sellers should consult with real estate professionals to accurately estimate their potential proceeds and optimize their home's sale value."
  },
  {
    title: "Loan Amount",
    definition: "The loan amount in a mortgage refers to the principal sum borrowed from a lender to finance a home purchase. This figure is determined by subtracting the down payment from the home's purchase price. For example, if you're buying a $300,000 home with a 20% down payment ($60,000), your loan amount would be $240,000. The loan amount directly impacts your monthly mortgage payments, interest paid over the life of the loan, and the lender's risk assessment. A lower loan amount relative to the home's value (lower loan-to-value ratio) often results in more favorable loan terms and interest rates."
  },
  {
    title: "Loan-to-Value Ratio (LTV)",
    definition: "The Loan-to-Value (LTV) ratio is a critical metric used by lenders to assess mortgage risk. It's calculated by dividing the loan amount by the appraised value or purchase price of the property, whichever is lower. For instance, a $200,000 loan on a $250,000 home results in an 80% LTV. Lower LTV ratios (typically below 80%) are preferred by lenders and can lead to better interest rates and loan terms. Higher LTVs may require private mortgage insurance (PMI). Understanding and managing your LTV is crucial for securing favorable mortgage terms and building equity. As you pay down your mortgage or if your home's value increases, your LTV decreases, potentially allowing you to refinance to better terms or eliminate PMI."
  },
  {
    title: "Long-term Mortgage",
    definition: "A long-term mortgage, typically spanning 15 to 30 years, offers homebuyers extended repayment periods with lower monthly payments. These mortgages are popular for their affordability and stability, especially the 30-year fixed-rate option. While they come with higher overall interest costs due to the extended term, long-term mortgages provide budget flexibility and potential tax benefits. They're ideal for homeowners planning to stay in their property for many years or those prioritizing lower monthly payments. However, building equity is slower compared to shorter-term loans. When considering a long-term mortgage, evaluate factors like interest rates, your long-term financial goals, and potential savings from shorter terms."
  },
  {
    title: "Lump Sum Payment",
    definition: "A lump sum payment in mortgages refers to a one-time, substantial payment made towards the principal balance, separate from regular monthly payments. This strategy can significantly reduce the overall interest paid and shorten the loan term. Lump sum payments often come from windfalls like bonuses, inheritances, or tax refunds. Many mortgages allow annual lump sum payments of 10-20% of the original principal without penalties. Before making a lump sum payment, check your mortgage terms for any prepayment restrictions or penalties. This approach is an effective way to accelerate mortgage payoff and build equity faster, especially when combined with other strategies like bi-weekly payments or rounding up regular payments."
  },
  {
    title: "Monthly Mortgage Payment",
    definition: "The monthly mortgage payment is a comprehensive financial obligation for homeowners, typically comprising four key components: Principal, Interest, Taxes, and Insurance (often referred to as PITI). The principal portion reduces your loan balance, while interest is the cost of borrowing. Property taxes and homeowners insurance are often included in the payment and held in an escrow account. Additional components may include private mortgage insurance (PMI) for conventional loans with less than 20% down payment, or mortgage insurance premiums (MIP) for FHA loans. Understanding the breakdown of your monthly payment is crucial for budgeting and evaluating the true cost of homeownership over time."
  },
  {
    title: "Mortgage Payment Calculator",
    definition: "A mortgage payment calculator is an essential online tool for prospective homebuyers and current homeowners alike. It helps estimate monthly mortgage payments based on inputs like loan amount, interest rate, loan term, property taxes, and insurance costs. These calculators allow users to adjust variables and see how different scenarios affect their payments, aiding in budgeting and decision-making. Advanced calculators may include features for comparing different loan types, estimating the impact of extra payments, or calculating the break-even point for refinancing. Regular use of a mortgage calculator can help homeowners track their progress towards building equity and plan for future financial goals related to their home investment."
  },
  {
    title: "Mortgage Rates",
    definition: "Mortgage rates are the interest rates charged on home loans, significantly impacting the overall cost of homeownership. These rates can be fixed (remaining constant throughout the loan term) or adjustable (fluctuating based on market indices). Factors influencing mortgage rates include economic conditions, inflation, Federal Reserve policies, and individual borrower characteristics like credit score and down payment. Even small rate differences can result in thousands of dollars saved or spent over the life of a loan. Prospective borrowers should shop around and compare rates from multiple lenders, considering both the interest rate and Annual Percentage Rate (APR) when evaluating loan offers. Staying informed about market trends and maintaining a strong credit profile can help secure more favorable mortgage rates."
  },
  {
    title: "Mortgage Refinance",
    definition: "Mortgage refinancing involves replacing an existing home loan with a new one, often to secure better terms or tap into home equity. Common reasons for refinancing include lowering interest rates, shortening loan terms, switching from adjustable to fixed rates, or accessing cash for home improvements or debt consolidation. The refinancing process typically involves a new loan application, credit check, home appraisal, and closing costs. Homeowners should carefully evaluate the costs versus potential savings, considering factors like how long they plan to stay in the home and current market conditions. A general rule of thumb is to refinance if you can lower your rate by at least 0.75% and recoup closing costs within 36 months. However, individual circumstances may vary, making it essential to consult with financial advisors or mortgage professionals."
  },
  {
    title: "Mortgage Term",
    definition: "The mortgage term refers to the duration of your home loan, typically ranging from 15 to 30 years, though other options exist. This period determines how long you have to repay the borrowed amount. Shorter terms like 15 years often come with lower interest rates but higher monthly payments, allowing for faster equity building and less overall interest paid. Longer terms, such as 30 years, offer lower monthly payments but result in paying more interest over time. Choosing the right mortgage term depends on factors like financial goals, income stability, and long-term plans. Some borrowers opt for longer terms for affordability but make extra payments to shorten the effective loan duration. Understanding how different terms impact your financial situation is crucial when selecting a mortgage that aligns with your homeownership and financial objectives."
  },
  {
    title: "Pre-tax Amount",
    definition: "In mortgage and financial contexts, the pre-tax amount, also known as gross income, refers to your total earnings before income taxes and other deductions are applied. This figure is crucial in mortgage applications as lenders use it to calculate debt-to-income ratios and determine loan eligibility. Pre-tax income includes salary, wages, bonuses, investment income, and other sources of revenue before any deductions for taxes, retirement contributions, or other withholdings. Understanding your pre-tax income is essential for accurate financial planning, budgeting for a home purchase, and estimating your mortgage affordability. It's important to note that while lenders consider pre-tax income, your actual disposable income for mortgage payments will be your after-tax (net) income, which should be factored into your personal budgeting calculations."
  },
  {
    title: "Short-term Mortgage",
    definition: "A short-term mortgage typically has a repayment period of 15 years or less, offering distinct advantages for certain homebuyers. These mortgages usually come with lower interest rates compared to longer-term options, resulting in substantial interest savings over the life of the loan. Short-term mortgages allow for faster equity building and debt-free homeownership. However, they require higher monthly payments, which can strain budgets. They're ideal for borrowers with stable, higher incomes who prioritize long-term savings over short-term cash flow. Short-term mortgages also provide less protection against interest rate fluctuations, as they require more frequent refinancing. When considering a short-term mortgage, carefully evaluate your financial stability, long-term goals, and ability to manage higher monthly payments."
  }
];

const CommonTerms = () => (
  <Section>
    <Title>Common Terms</Title>
    {terms.map((term, index) => (
      <TermContainer key={index}>
        <TermTitle><span> </span>{term.title}</TermTitle>
        <TermDefinition>{term.definition}</TermDefinition>
      </TermContainer>
    ))}
  </Section>
);

export default CommonTerms;