import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../../constants/api';
import { useStore } from "../../../hooks/storeContext";

// Styles
import {
  ListingGridContainer,
  ImageContainer,
  ListingImage,
  ListingGridContent,
  PriceTypography,
  NeighborhoodTypography,
  AddressTypography,
  Break,
  ListingPropertiesContainer,
  ListingProperties,
  Value,
  Sqft
} from './Styled';

// Constants
import {
  FavoritedSvg,
  FavoritedFilledSvg, // Import the filled heart SVG
  BedroomSvg,
  SqFtSvg,
  BathroomSvg,
} from '../../../components/Icons';
import { ROUTE_PATH } from '../../../constants/routePath';
import listingExample from '../../../assets/images/listing-grid-example.png';

// Utils
import { formatNumberToDollars } from '../../../utils/numberFormat';

// Components
import { Flex } from 'antd';
import Icon from '@ant-design/icons';

const MarketSearchGridItem = ({ property }) => {
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(false);
  const { authStore } = useStore();

  const {
    current_price,
    bathrooms,
    bedrooms,
    address,
    city,
    state,
    subdivision,
    type,
    streetViewImage, // Add the streetViewImage property
  } = property;

  const handleClick = () => {
    navigate(`${ROUTE_PATH.DETAILS}/${property.id}`, {
      state: { property },
    });
  };

  const handleFavoriteClick = async (event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the image click handler
    const listingId = property.id; // Assuming property.id is the listing ID
    const userEmail = authStore.me.email;

    try {
      // Make the API call to add the property to the favorites
      const response = await axios.post(api.user.setFavorite, { 
        email: userEmail,
        listingId });

      if (response.status === 200) {
        console.log('Favorite added successfully:', response.data.favorites);
        
        const response = await axios.post(api.listing, {
          ...address,
        });

        setIsFavorited(!isFavorited); // Toggle the favorited state
      } else {
        console.error('Failed to add favorite:', response.data.msg);
      }
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  return (
    <ListingGridContainer onClick={handleClick}>
      <ListingImage
        src={streetViewImage || listingExample} // Use streetViewImage if available, otherwise fallback to listingExample
        alt="Property"
        preview={false}
      />
      <ImageContainer>
      </ImageContainer>
      <ListingGridContent>
        <Flex vertical>
          <div>
            <Flex justify="space-between" gap={4}>
              <div>
                <PriceTypography>
                  {formatNumberToDollars(current_price)}
                </PriceTypography>
                <NeighborhoodTypography>
                  {subdivision || type}
                </NeighborhoodTypography>
              </div>
              <div onClick={handleFavoriteClick}>
                <Icon component={isFavorited ? FavoritedFilledSvg : FavoritedSvg} />
              </div>
            </Flex>
          </div>

          <AddressTypography>
            {`${address} ${city}, ${state}`}
          </AddressTypography>

          <Break />

          <ListingPropertiesContainer gap={15}>
            <ListingProperties>
              <Icon component={BedroomSvg} />
              <Value>{bedrooms}</Value>
            </ListingProperties>
            <ListingProperties>
              <Icon component={BathroomSvg} />
              <Value>{bathrooms}</Value>
            </ListingProperties>
          </ListingPropertiesContainer>
        </Flex>
      </ListingGridContent>
    </ListingGridContainer>
  );
};

export default MarketSearchGridItem;
