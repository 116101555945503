import React, { useReducer, useState, useMemo, useEffect, useCallback } from 'react';
import {
  Map,
  MapControl,
  ControlPosition,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';

// Styles
import styles from './MarketSearchMap.module.scss';

// Reducer
import reducer from '../../../reducers/UndoRedoReducer';

// Hooks
import useDrawingManager from '../../../hooks/useDrawingManager';
import useMarkers from '../../../hooks/useMarkers';

// Components
import Markers from './Markers';
import UndoRedoControl from './UndoRedoControl';
import MarketSearchDrawer from './MarketSearchDrawer';
import { formatNumberToDollars } from '../../../utils/numberFormat';

const MarketSearchMap = ({
  drawerContainerRef,
  properties,
  setIsFiltersDrawerOpen,
  isMapDrawerOpen,
  handleClickMapDrawerClose,
  params,
}) => {
  const [bounds, setBounds] = useState({
    ne: {},
    sw: {},
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [center, setCenter] = useState({ lat: 32.779167, lng: -96.808891 }); // Default center
  const geometry = useMapsLibrary('geometry');
  const [state, dispatch] = useReducer(reducer, {
    past: [],
    now: [],
    future: [],
  });
  const drawingManager = useDrawingManager();
  const [mapRef, setMapRef] = useState(null);

  useEffect(() => {
    if (params.latitude && params.longitude) {
      setCenter({
        lat: parseFloat(params.latitude),
        lng: parseFloat(params.longitude)
      });
    }
  }, [params.latitude, params.longitude]);

  const polygonPath = state.now.length
    ? state.now[0].snapshot.path.map((coord) => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }))
    : [];

  const polygon = state.now.length ? state.now[0].geometry : false;
  const points = useMemo(
    () =>
      properties.map((property) => ({
        id: property.id,
        position: property.position,
        value: formatNumberToDollars(property.current_price),
        property,
      })),
    [properties]
  );

  const markerParams = useMemo(() => ({
    points,
    polygonPath,
    geometry,
    polygon,
    state,
    bounds,
  }), [points, polygonPath, geometry, polygon, state, bounds]);

  const { markers } = useMarkers(markerParams);

  const fitBoundsToMarkers = useCallback(() => {
    if (mapRef && markers.length) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.position.lat, marker.position.lng));
      });

      const center = bounds.getCenter();
      mapRef.setCenter(center);

      const padding = { top: 50, right: 50, bottom: 50, left: 50 };
      mapRef.fitBounds(bounds, padding);

      const maxZoom = 15;
      const currentZoom = mapRef.getZoom();
      if (currentZoom > maxZoom) {
        mapRef.setZoom(maxZoom);
      }
    }
  }, [mapRef, markers]);

  useEffect(() => {
    fitBoundsToMarkers();
  }, [fitBoundsToMarkers, markers]);

  const handleBoundsChanged = useCallback((props) => {
    const { map } = props;
    const neBounds = map.getBounds().getNorthEast();
    const swBounds = map.getBounds().getSouthWest();
    const ne = { lat: neBounds.lat(), lng: neBounds.lng() };
    const sw = { lat: swBounds.lat(), lng: swBounds.lng() };

    setBounds({ ne, sw });
  }, []);

  const handleMapLoad = useCallback((map) => {
    setMapRef(map);
    if (center.lat !== 32.779167 || center.lng !== -96.808891) {
      map.setCenter(center);
      map.setZoom(12);
    }
  }, [center]);

  const handleMarkerClick = useCallback((property) => {
    setSelectedProperty((prevSelected) => 
      prevSelected && prevSelected.id === property.id ? null : property
    );
  }, []);

  const handleMapClick = useCallback(() => {
    setSelectedProperty(null);
  }, []);

  return (
    <div className={styles.mapContainer}>
      <Map
        onBoundsChanged={handleBoundsChanged}
        onLoad={handleMapLoad}
        mapId="marketSearchMap"
        defaultCenter={{ lat: 32.779167, lng: -96.808891 }}
        defaultZoom={11}
        onClick={handleMapClick}
      >
        {Boolean(markers.length) &&
          markers.map((marker) => (
            <Markers
              key={marker.id}
              point={marker}
              onClick={() => handleMarkerClick(marker.property)}
              isSelected={selectedProperty && selectedProperty.id === marker.property.id}
            />
          ))}
        <MapControl position={ControlPosition.TOP_CENTER}>
          <UndoRedoControl
            drawingManager={drawingManager}
            state={state}
            dispatch={dispatch}
          />
        </MapControl>
      </Map>
      <MarketSearchDrawer
        handleClickClose={handleClickMapDrawerClose}
        isOpen={isMapDrawerOpen}
        container={drawerContainerRef.current}
        setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
        properties={properties}
        property={selectedProperty}
      />
    </div>
  );
};

export default MarketSearchMap;