import React from 'react';
import { Form, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../../constants/routePath';
import { observer } from 'mobx-react';
import BaseTypography from '../../../../components/BaseTypography';
import { COLORS } from '../../../../constants/colors';
import { BaseInput } from '../../../../components/Input';
import {
  StyledFlex,
  StyledLink,
  StyledRegister,
  StyledForgot,
  StyledForm,
} from '../../styled';
import { BaseButton } from '../../../../components/Button';
import { useStore } from '../../../../hooks/storeContext';
import { isMobile } from 'react-device-detect';

const Register = observer(() => {
  const { authStore } = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onRegister = async (values) => {
    try {
      const result = await authStore.onCreateUser(values);
      
      if (result.profile.isApproved) {
        navigate(ROUTE_PATH.ONBOARDING);
      } else {
        navigate(ROUTE_PATH.PENDING_APPROVAL);
      }
    } catch (error) {
      console.error('Registration error:', error);
      message.error('Registration failed. Please try again.');
    }
  };

  return (
    <StyledFlex vertical>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        type="title"
        color={COLORS.$black}
        level={3}
        fontWeight={700}
      >
        Welcome
      </BaseTypography>
      <BaseTypography
        align={isMobile ? 'center' : 'left'}
        marginBottom="32px"
        color={COLORS.$blackOpacity50}
      >
        Welcome! Please sign up to create an account.
      </BaseTypography>
      <StyledForm form={form} onFinish={onRegister}>
        <Form.Item name="fullName">
          <BaseInput label="Name" placeholder="Full name" />
        </Form.Item>
        <Form.Item name="email">
          <BaseInput label="Email" placeholder="hi@example.com" />
        </Form.Item>
        <Form.Item name="password">
          <BaseInput.Password
            type="password"
            label="Password"
            placeholder="Enter password"
          />
        </Form.Item>
        <StyledForgot>
          Must be at least 8 characters.
          <StyledLink to={ROUTE_PATH.FORGOT_PASSWORD}>
            Forgot password?
          </StyledLink>
        </StyledForgot>
        <Form.Item>
          <BaseButton type="primary" htmlType="submit" block>
            Sign up
          </BaseButton>
        </Form.Item>
        <StyledRegister>
          Already have an account? <Link to={ROUTE_PATH.LOGIN}>Login</Link>
        </StyledRegister>
      </StyledForm>
    </StyledFlex>
  );
});

export default Register;