import React from 'react';

// Styles
import styles from './Card.module.scss';

// Components
import BaseTypography from '../../../components/BaseTypography';

const Card = ({ children, title }) => {
  return (
    <div className={styles.card}>
      <BaseTypography className={styles.title} fontSize="24px" align="left">
        {title}
      </BaseTypography>
      {children}

      <hr className={styles.break} />
    </div>
  );
};

export default Card;
