import React from 'react';
import { Typography } from 'antd';
import { StyledFlex } from './styled.js';

const { Title, Paragraph } = Typography;

const PendingApproval = () => {
  return (
    <StyledFlex vertical>
      <Title style={{ paddingBottom: '20px' }} level={2}>Thank you for registering</Title>
      <Paragraph>
        Your account is currently pending approval. We will review and get back to you soon if you are approved to use the platform.
      </Paragraph>
    </StyledFlex>
  );
};

export default PendingApproval;