import React from 'react';
import { Flex, Form, Input, Switch, Button } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { BaseButton } from '../../../components/Button';
import { isMobile } from 'react-device-detect';

import './Input.scss';
import styles from './Account.module.scss';
import { BaseInput } from '../../../components/Input';

const Account = () => {
  const [form] = Form.useForm();
  const handleSubmit = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        console.log('Received values of form:', values);
      })
      .catch((errorInfo) => {
        console.error('Form validation failed:', errorInfo);
      });
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  return (
    <div className={styles.formWrapper}>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <h4 className={styles.titleForm}>My Account</h4>

        <div className={isMobile ? styles.innerMain : styles.inputsWrapper}>
          <FormItem>
            <div className={styles.label}>Email</div>
            <BaseInput
              suffix="Verify"
              className={styles.input}
              size="large"
              name="email"
              placeholder={'Email'}
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            />
          </FormItem>
          <FormItem>
            <div className={styles.label}>Password</div>
            <BaseInput.Password
              className={styles.input}
              size="large"
              name="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              placeholder={'Change Password'}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            />
          </FormItem>
        </div>
        <div className={styles.innerMain}>
          <Flex justify="space-between">
            <div className={styles.textWrapper}>
              <p>Enable 2-steps verification</p>
              <p className={styles.textGray}>
                Make your account extra secure. Along with your password, you’ll
                need to enter a code that we text to your phone each time you
                sign in.
              </p>
            </div>
            <Switch defaultChecked onChange={onChange} />
          </Flex>
        </div>

        <div className={styles.inner}>
          <Flex
            justify="space-between"
            align={isMobile ? 'start' : 'center'}
            gap={isMobile ? 16 : 8}
            vertical={isMobile}
          >
            <Flex gap={8} vertical>
              <p className={styles.deleteTitle}>Delete Account</p>
              <p>Delete your account and all the data</p>
            </Flex>
            <Button danger size="large">
              Delete account
            </Button>
          </Flex>
        </div>
        <Flex justify="end">
          <div className={styles.button}>
            <BaseButton type="submit">Save changes</BaseButton>
          </div>
        </Flex>
      </Form>
    </div>
  );
};

export default Account;
