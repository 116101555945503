import styled from 'styled-components';
import { Dropdown } from 'antd';
import { COLORS } from '../../constants/colors';

export const StyledDropdown = styled(Dropdown)`
  padding: 8px 26px 8px 12px;
  border-color: ${COLORS.$blueShades92};
  height: auto;

  @media screen and (max-width: 1024px) {
    padding: 6px 8px;
  }
`;
