import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const SectionContainer = styled.div`
  background-color: ${props => props.backgroundColor || '#fff'};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: ${props => props.imageOnLeft ? 'row-reverse' : 'row'};
  }
`;

const ContentCol = styled.div`
  flex: 1;
  order: 1;

  @media (min-width: 768px) {
    order: 2;
  }
`;

const ImageCol = styled.div`
  flex: 1;
  order: 2;

  @media (min-width: 768px) {
    order: 1;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const ContentWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media (min-width: 768px) {
    padding: 0 48px;
  }
`;

const LinkToMore = styled.div`
  max-width: 200px;
  height: 52px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1890ff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #40a9ff;
  }
`;

const ImageSection = ({ title, description, buttonText, imageSrc, backgroundColor, imageOnLeft, linkTo }) => (
  <SectionContainer backgroundColor={backgroundColor}>
    <StyledRow imageOnLeft={imageOnLeft}>
      <ContentCol>
        <ContentWrapper>
          <Title level={2}>{title}</Title>
          <Paragraph>{description}</Paragraph>
          {linkTo ? (
            <Link to={linkTo}>
              <LinkToMore style={{backgroundColor: '#005eb8'}}>{buttonText}</LinkToMore>
            </Link>
          ) : (
            <LinkToMore style={{backgroundColor: '#005eb8'}}J>{buttonText}</LinkToMore>
          )}
        </ContentWrapper>
      </ContentCol>
      <ImageCol>
        <StyledImage src={imageSrc} alt={title} />
      </ImageCol>
    </StyledRow>
  </SectionContainer>
);

export default ImageSection;