import React from 'react';

// Styles
import styles from './Spinner.module.scss';

// Components
import { Spin } from 'antd';

const Spinner = () => {
  return (
    <div className={styles.loading}>
      <Spin size="large" />
    </div>
  );
};

export default Spinner;
