import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, ConfigProvider, Table as AntTable, Modal } from 'antd';
import BaseTypography from '../../../components/BaseTypography';
import {
  Card,
  CardHeader,
  CardHeaderSection,
  CardTitle,
  CardValue,
  CustomTable,
  EmptyTable,
  CustomImage,
  EmptyTableHeader
} from './Styled';
import { ROUTE_PATH } from '../../../constants/routePath';
import listingExample from '../../../assets/images/listing-example.png';
import { api } from '../../../constants/api';
import { useStore } from '../../../hooks/storeContext';

const MyProperties = () => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const lenderEmail = authStore.me?.email;

  const handleImageClick = (property) => {
    navigate(`${ROUTE_PATH.DETAILS}/${property.id}`);
  };

  const MY_PROPERTIES_COLUMNS = [
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      width: 400,
      render: (property) => (
        <Row align="middle">
          <Col>
            <div onClick={() => handleImageClick(property)} style={{ cursor: 'pointer' }}>
              <CustomImage
                preview={false}
                src={property.image || listingExample}
              />
            </div>
          </Col>
          <Col flex="auto" style={{ marginLeft: '10px' }}>
            <div>
              <BaseTypography align="left" fontSize="16px">
                {property.address}
              </BaseTypography>
              <BaseTypography align="left" fontSize="14px">
                {property.city}, {property.state}
              </BaseTypography>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      width: 150,
      render: (property) => (
        <Button size="large" type="primary" onClick={() => showModal(property)}>
          View Terms
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchLendingTerms = async () => {
      if (!lenderEmail) {
        console.log('User is not authenticated yet.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(api.lending.getLendedHomesByLender(lenderEmail));
        const data = await response.json();
        setProperties(data);
      } catch (error) {
        console.error('Error fetching lending terms:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLendingTerms();
  }, [lenderEmail]);

  const handleClickDashboard = () => {
    navigate(ROUTE_PATH.DASHBOARD);
  };

  const showModal = (property) => {
    setSelectedProperty(property);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async () => {
    try {
      await fetch(api.lending.deleteLendingTerms, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: selectedProperty._id }),
      });

      setProperties(properties.filter((property) => property._id !== selectedProperty._id));
      setIsModalVisible(false);
      setIsConfirmVisible(false);
    } catch (error) {
      console.error('Error deleting property:', error);
    }
  };

  const handleConfirmDelete = () => {
    setIsConfirmVisible(true);
  };

  const handleCancelConfirm = () => {
    setIsConfirmVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const myProperties = properties.map((property) => {
    const dateCreated = new Date(property.createdAt).toLocaleDateString('en-us', {
      month: 'short',
      day: 'numeric',
    });
    const timeCreated = new Date(property.createdAt).toLocaleTimeString('en-us', {
      timeStyle: 'short',
    });
    return {
      date: `${dateCreated} ${timeCreated}`,
      property,
      details: property,
    };
  });

  const totalInvestment = properties.reduce((sum, property) => sum + property.lendingAmount, 0);

  return (
    <>
      <Card
        title={
          <CardHeader gutter={[16, 16]}>
            <CardHeaderSection xs={12} sm={12} md={8}>
              <CardTitle>Total Investment</CardTitle>
              <CardValue>${totalInvestment.toLocaleString()}</CardValue>
            </CardHeaderSection>
          </CardHeader>
        }
      >
        <ConfigProvider
          renderEmpty={() => (
            <EmptyTable>
              <EmptyTableHeader>Make your first investment</EmptyTableHeader>
              <Button style={{ cursor: 'pointer!important' }} type="primary" size="large" onClick={handleClickDashboard}>
                Search Properties
              </Button>
            </EmptyTable>
          )}
        >
          <CustomTable>
            <AntTable
              size="large"
              loading={isLoading}
              dataSource={myProperties}
              columns={MY_PROPERTIES_COLUMNS}
              pagination={{
                pageSize: 2,
                showSizeChanger: false,
                showQuickJumper: false,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                hideOnSinglePage: false,
              }}
            />
          </CustomTable>
        </ConfigProvider>
      </Card>

      <Modal
        title="Lending Terms"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="delete" type="danger" onClick={handleConfirmDelete}>
            Delete Terms
          </Button>,
        ]}
      >
        {selectedProperty && (
          <div style={{ marginBottom: '77px' }}>
            <p style={{ marginBottom: '10px' }}><strong>Address:</strong> {selectedProperty.address}</p>
            <p style={{ marginBottom: '10px' }}><strong>City:</strong> {selectedProperty.city}</p>
            <p style={{ marginBottom: '10px' }}><strong>State:</strong> {selectedProperty.state}</p>
            <p style={{ marginBottom: '10px' }}><strong>Lending Amount:</strong> ${selectedProperty.lendingAmount}</p>
            <p style={{ marginBottom: '10px' }}><strong>Down Payment:</strong> ${selectedProperty.downPayment}</p>
            <p style={{ marginBottom: '10px' }}><strong>Interest Rate:</strong> {selectedProperty.interestRate}%</p>
            <p style={{ marginBottom: '10px' }}><strong>Loan Length:</strong> {selectedProperty.loanLength} years</p>
            <p style={{ marginBottom: '10px' }}><strong>Payment Frequency:</strong> {selectedProperty.paymentFrequency}</p>
            <p style={{ marginBottom: '10px' }}><strong>Late Payment Penalties:</strong> ${selectedProperty.latePaymentPenalties}</p>
          </div>
        )}
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isConfirmVisible}
        onOk={handleDelete}
        onCancel={handleCancelConfirm}
      >
        <p>Are you sure you want to delete these terms?</p>
        <p>Buyers wont be able to view or purchase after you Delete.</p>
      </Modal>
    </>
  );
};

export default MyProperties;