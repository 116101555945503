// src/components/FeatureSection.js
import React from 'react';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faCalculator, faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import {
  SectionContainer,
  SectionItem,
  SectionTitle,
  SectionDescription,
  SectionHighlight,
  SectionSubheading,
  SectionWrapper,
  SectionContent,
  IconWrapper,
  StyledRouterLink,
  StyledLink,
} from './styled';

const FeatureSection = () => (
  <SectionContainer>
    <SectionWrapper>
      <SectionHighlight>Simplifying <span style={{ color: '#005eb8' }}>Home Ownership</span> Journey</SectionHighlight>
      <SectionSubheading>We streamline the home buying process, offering alternatives to traditional banking.</SectionSubheading>
      <SectionContent>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={8}>
            <SectionItem>
              <IconWrapper>
                <FontAwesomeIcon icon={faCoins} />
              </IconWrapper>
              <SectionContent>
                <SectionTitle>Discover Your Home Budget</SectionTitle>
                <SectionDescription>
                  Estimate your ideal price range and integrate it into your buyer profile for a tailored home search experience.
                </SectionDescription>
              </SectionContent>
              <StyledRouterLink to="/tools/affordability-calculator">
                Explore Our Budget Tool
              </StyledRouterLink>
            </SectionItem>
          </Col>
          <Col xs={24} md={8}>
            <SectionItem>
              <IconWrapper>
                <FontAwesomeIcon icon={faCalculator} />
              </IconWrapper>
              <SectionContent>
                <SectionTitle>Calculate Your Monthly Expenses</SectionTitle>
                <SectionDescription>
                  Get a comprehensive breakdown of your expected monthly payments and closing costs, tailored to your financial goals.
                </SectionDescription>
              </SectionContent>
              <StyledRouterLink to="/tools/mortgage-calculator">Use Our Payment Calculator</StyledRouterLink>
            </SectionItem>
          </Col>
          <Col xs={24} md={8}>
            <SectionItem>
              <IconWrapper>
                <FontAwesomeIcon icon={faPiggyBank} />
              </IconWrapper>
              <SectionContent>
                <SectionTitle>Explore Down Payment Options</SectionTitle>
                <SectionDescription>
                  Learn about low down payment opportunities, including 3.5% down options, and discover assistance programs to make homeownership more accessible.
                </SectionDescription>
              </SectionContent>
              <StyledLink href="#">Explore Down Payment Assistance</StyledLink>
            </SectionItem>
          </Col>
        </Row>
      </SectionContent>
    </SectionWrapper>
  </SectionContainer>
);

export default FeatureSection;