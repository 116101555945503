import React, { useState } from 'react';
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks/storeContext";
import BaseTypography from "../../../../components/BaseTypography";
import { COLORS } from "../../../../constants/colors";
import { ROUTE_PATH } from '../../../../constants/routePath';
import { useNavigate } from 'react-router-dom';
import {
    StyledContainer,
    StyledFlex,
    StyledInputGroup,
    StyledLabel,
    StyledRegister,
    StyledInputNumber,
    StyledSelect,
    StyledSkipButton
} from "./styled";
import { BaseButton } from "../../../../components/Button";
import { Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const INVESTMENT_GOALS = [
    { value: 'short_term_gains', label: 'Short-term gains' },
    { value: 'long_term_growth', label: 'Long-term growth' },
    { value: 'passive_income', label: 'Passive income' },
    { value: 'portfolio_diversification', label: 'Portfolio diversification' },
    { value: 'tax_benefits', label: 'Tax benefits' },
];

const Onboarding3 = observer(({ setStep }) => {
    const [financialInfo, setFinancialInfo] = useState({
        investmentGoals: [],
        availableCapital: null,
        preferredInvestmentAmount: null,
    });
    const { authStore } = useStore();
    const navigate = useNavigate();

    const handleChange = (name, value) => {
        setFinancialInfo(prev => ({ ...prev, [name]: value }));
    };

    const onSubmit = async () => {
        try {
            // Save financial information
            await authStore.saveFinancialInformation(financialInfo);

            setStep(4);

            // Navigate to Market Search with specified parameters
            // navigate({
            //     pathname: ROUTE_PATH.SEARCH,
            //     search: searchParams.toString(),
            // });

                        // const data = authStore.searchPropGPT('Find 3 bedroom, 2 bathroom homes in Dallas');
            // console.log(data);
    
            // If needed, navigate to another route after some condition or timeout
            // navigate(ROUTE_PATH.DASHBOARD); // Uncomment if you want to navigate to the dashboard after some condition
        } catch (error) {
            console.error('Error saving financial information:', error);
        }
    };    

    const onSkip = () => {
        navigate(ROUTE_PATH.DASHBOARD);
    };

    return (
        <StyledContainer>
            <StyledFlex vertical>
                <BaseTypography align="left" type="paragraph" color={COLORS.$greyScale500} level={0}>
                    Step 3/4
                </BaseTypography>
                <BaseTypography align="left" type="title" color={COLORS.$black} level={3} style={{ marginBottom: '20px' }}>
                    Financial Information
                </BaseTypography>

                <StyledInputGroup>
                    <StyledLabel>Investment Goals</StyledLabel>
                    <StyledSelect>
                        <Select
                            mode="multiple"
                            placeholder="Select your investment goals"
                            options={INVESTMENT_GOALS}
                            onChange={(value) => handleChange('investmentGoals', value)}
                        />
                    </StyledSelect>
                </StyledInputGroup>

                <StyledInputGroup>
                    <StyledLabel>
                        Preferred Investment Amount per Property
                        <Tooltip title="The amount you typically want to invest in a single property">
                            <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
                        </Tooltip>
                    </StyledLabel>
                    <StyledInputNumber
                        prefix="$"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => handleChange('preferredInvestmentAmount', value)}
                    />
                </StyledInputGroup>

                <StyledInputGroup>
                    <StyledLabel>
                        Available Capital for Investment
                        <Tooltip title="The total amount you're willing to invest across all properties">
                            <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
                        </Tooltip>
                    </StyledLabel>
                    <StyledInputNumber
                        prefix="$"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => handleChange('availableCapital', value)}
                    />
                </StyledInputGroup>

                <BaseButton type="primary" onClick={onSubmit} block style={{ marginTop: '20px' }}>
                    Next
                </BaseButton>
                <StyledRegister>
                    <StyledSkipButton onClick={onSkip}>
                        Skip For Now
                    </StyledSkipButton>
                </StyledRegister>
            </StyledFlex>
        </StyledContainer>
    );
});

export default Onboarding3;