import styled from "styled-components";
import { Row, Col, Card, Input, Slider, Select, Button, Divider } from "antd";
import { COLORS } from "../../constants/colors";

export const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
`;

export const ImageCard = styled(Card)`
  display: flex;
  align-items: center;
  .ant-card-body {
    display: flex;
    align-items: center;
  }
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
`;

export const MortgageCalculatorCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ProfitCalculatorCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const TermsCard = styled(Card)`
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const SectionTitle = styled.h2`
  margin: 0 0 10px;
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  height: 48px;
  border-radius: 8px;
  width: 100%;
  background: #005eb8;
  font-size: 16px;
  color: #fff;
  line-height: 150%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #005eb8;
  font-weight: 700;
`;

export const StyledRow = styled(Row)`
  margin-top: 20px;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 10px;
  height: 48px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 10px;
  height: 48px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalTitle = styled.h4`
  margin: 0;
  font-weight: bold;
`;

export const ModalValue = styled.p`
  margin: 0;
`;
