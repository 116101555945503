import React from 'react';
import {
  StyledRow,
  StyledHeader,
  StyledSquare,
  StyledCol,
  StyledWrapper,
  StyledImage,
  StyledContent,
} from './styled';
import { isDesktop } from 'react-device-detect';
import { Flex, Image } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import main from '../../assets/images/auth_main_croped.png';
import { observer } from 'mobx-react';
import BaseTypography from '../../components/BaseTypography';

const Index = observer(() => {
  return (
    <StyledRow>
      <StyledCol span={isDesktop ? 12 : 24}>
        <StyledHeader>
          <Image preview={false} src={logo} />
          Invested
        </StyledHeader>
        <Outlet />
      </StyledCol>
      {isDesktop ? (
        <StyledWrapper span={12}>
          {isDesktop ? <StyledSquare></StyledSquare> : null}
          <StyledContent>
            <StyledImage width="100%" preview={false} src={main} />
            <Flex gap={16} vertical>
              <Flex align="center" gap={8}>
                <BaseTypography $fontSize="14px" color="#000929">
                  Powered by
                </BaseTypography>
                <Image preview={false} src={logo} />
              </Flex>
              <BaseTypography align="left" $fontSize="14px" color="#000929">
                You agree to Invested {''}
                <Link to="/">Terms of Use & Privacy Policy.</Link>
              </BaseTypography>
            </Flex>
          </StyledContent>
        </StyledWrapper>
      ) : null}
    </StyledRow>
  );
});

export default Index;
