import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { isDesktop, isMobile } from 'react-device-detect';
import { useStore } from "../../../hooks/storeContext";
import Icon from '@ant-design/icons';
import DropdownComponent from '../../../components/DropdownComponent';
import HeaderMenu from './HeaderMenu';
import BaseTypography from '../../../components/BaseTypography';
import { Input, Avatar, Flex, Col } from 'antd';
import { createSearchParams } from 'react-router-dom';
import {
  Header,
  HeaderContainer,
  LinkStyle,
  HeaderProfile,
  HeaderLogo,
  Divider,
  SearchField,
  CustomCol,
} from './Styled';

// Icons
import {
  LogoSvg,
  SearchIconSvg,
} from '../../../components/Icons';

// Constants
import { ROUTE_PATH } from '../../../constants/routePath';

const HEADER_TITLES = {
  dashboard: 'Dashboard',
  search: 'Search',
  invested: 'Invested',
  map: 'Map',
  settings: 'Settings',
  favorites: 'Favorites',
  lend: 'Lend',
  calculators: 'Calculators',
  myProperties: 'My Properties',
};

const CustomHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authStore } = useStore();
  const [searchQuery, setSearchQuery] = useState('');
  const [isMenuVisible, setMenuVisible] = useState(false);

  const items = [
    {
      label: 'Profile',
      key: 'Profile',
    },
    {
      label: 'My Account',
      key: 'My Account',
    },
    {
      type: 'divider',
    },
    {
      label: 'Sign out',
      key: 'signOut',
      onClick: () => authStore.logout(),
    },
  ];

  const toggleMenu = () => {
    setMenuVisible((prevState) => !prevState);
  };

  const handleMenuClose = () => {
    setMenuVisible(false);
  };

  const handleMenuItemClick = () => {
    setMenuVisible(false);
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      const searchParams = createSearchParams({ streetName: searchQuery }).toString();
      const path = {
        pathname: ROUTE_PATH.SEARCH,
        search: searchParams,
      };
      navigate(path);
    }
  };

  const isDashboard = location.pathname === ROUTE_PATH.DASHBOARD;
  const isDetails = location.pathname.includes(ROUTE_PATH.DETAILS);
  const isSearch = location.pathname === ROUTE_PATH.SEARCH;
  const isSetting = location.pathname === ROUTE_PATH.SETTINGS;
  const isFavorites = location.pathname === ROUTE_PATH.FAVORITES;
  const isMap = location.pathname === ROUTE_PATH.MAP;
  const isLend = location.pathname === ROUTE_PATH.LEND;
  const isMyProperties = location.pathname === ROUTE_PATH.MY_PROPERTIES;
  const isMarketSearch = location.pathname === ROUTE_PATH.MARKET_SEARCH;
  const isCalculators = location.pathname === ROUTE_PATH.CALCULATORS;

  const handleClickDashboard = () => {
    navigate(ROUTE_PATH.DASHBOARD);
  };

  const handleClick = (data) => {
    navigate(ROUTE_PATH.SETTINGS, { state: { activeTab: data.key } });
  };

  const title = () => {
    switch (true) {
      case isSearch:
        return HEADER_TITLES.search;
      case isDetails:
        return HEADER_TITLES.invested;
      case isSetting:
        return HEADER_TITLES.settings;
      case isFavorites:
        return HEADER_TITLES.favorites;
      case isMap:
          return HEADER_TITLES.map;
      case isLend:
        return HEADER_TITLES.lend;
      case isCalculators:
        return HEADER_TITLES.calculators;
      case isMyProperties:
        return HEADER_TITLES.myProperties;
      default:
        return HEADER_TITLES.dashboard;
    }
  };

  return (
    <Header>
      <HeaderContainer justify="space-between" align="middle">
        {(isSetting) && isMobile && (
          <LinkStyle to={'/'}>
            <ArrowLeftOutlined /> Back to Dashboard
          </LinkStyle>
        )}
        { isMobile && (
          <>
            <Col>
              <Flex align="center">
                <Icon component={LogoSvg} />
                <HeaderLogo onClick={handleClickDashboard}>
                  {title()}
                </HeaderLogo>
              </Flex>
            </Col>
            <Col span={2}>
              <MenuOutlined onClick={toggleMenu} />
            </Col>
            {isMenuVisible && (
              <HeaderMenu onClose={handleMenuClose} onItemClick={handleMenuItemClick} />
            )}
          </>
        )}

        {isDesktop && (
          <Flex align="center" style={{ flex: 1 }}>
            <BaseTypography type={'text'} style={{ marginRight: '20px' }}>
              {title()}
            </BaseTypography>
            {!(isSearch || isDetails) && (
              <SearchField>
                <Input
                  size="large"
                  margin="auto!important"
                  placeholder="Search for properties..."
                  prefix={<Icon component={SearchIconSvg} />}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onPressEnter={handleSearch}
                />
              </SearchField>
            )}
          </Flex>
        )}

        {isDesktop && (
          <CustomCol span={4} offset={isSearch ? 17 : 0}>
            <Flex justify="flex-end">
              <HeaderProfile gap={20}>
                <Divider />
                <DropdownComponent items={items} onClick={handleClick}>
                  <Flex gap={8} align="center">
                    <Avatar
                      size={32}
                      style={{
                        backgroundColor: '#005EB8',
                      }}
                    >
                      {authStore.me?.fullName ? authStore.me.fullName.charAt(0).toUpperCase() : ' '}
                    </Avatar>
                    {isDesktop && (
                      <BaseTypography type={'text'}>
                        {authStore.me?.fullName
                          ? (() => {
                            const firstName = authStore.me.fullName.split(' ')[0];
                            const truncatedName = firstName.slice(0, 9);
                            return truncatedName.charAt(0).toUpperCase() + truncatedName.slice(1).toLowerCase();
                          })()
                          : 'Guest'}
                      </BaseTypography>
                    )}
                  </Flex>
                </DropdownComponent>
              </HeaderProfile>
            </Flex>
          </CustomCol>
        )}
      </HeaderContainer>
    </Header>
  );
};

export default CustomHeader;
