// MapSearch/styled.js
import styled from 'styled-components';
import { Button as AntButton, Flex as AntFlex } from 'antd';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const SearchContainer = styled.div`
  margin-bottom: 15px;
`;

export const SelectsWrapper = styled(AntFlex)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FilterButton = styled(AntButton)`
  height: 44px;
  min-width: 120px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  
  &:hover {
    border-color: #000;
    color: #000;
  }
`;

export const FiltersButton = styled(AntButton)`
  background-color: ${props => props.theme.colorPrimary};
  color: #ffffff;
  height: 40px;
`;

export const Header = styled(AntFlex)`
  @media (max-width: 1024px) {
    padding-bottom: 16px;
  }
`;

export const GrayNav = styled.span`
  @media (max-width: 1024px) {
    color: #6c727f;
  }
`;

export const BlackNav = styled.span`
  @media (max-width: 1024px) {
    color: ${props => props.theme.black};
    font-size: 14px;
  }
`;

export const NavIcon = styled.span`
  @media (max-width: 1024px) {
    margin: 0 4px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
