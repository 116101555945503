import React, { useState } from 'react';
import { Input, Button, Form, Space } from 'antd';
import {
  CalculatorContainer,
  CalculatorTitle,
  FormItem,
  ResultContainer,
  ResultItem
} from './Styled';
import { StyledButton } from '../../Lend/styled';

const DiscountedCashFlowCalculator = () => {
  const [form] = Form.useForm();
  const [cashFlows, setCashFlows] = useState([{ year: 1, amount: 0 }]);
  const [presentValue, setPresentValue] = useState(0);

  const onFinish = (values) => {
    const { discountRate } = values;

    // Calculate present value of each cash flow
    let totalPresentValue = 0;
    cashFlows.forEach(({ year, amount }) => {
      totalPresentValue += amount / Math.pow(1 + discountRate / 100, year);
    });

    setPresentValue(totalPresentValue);
  };

  const handleAddCashFlow = () => {
    setCashFlows([...cashFlows, { year: cashFlows.length + 1, amount: 0 }]);
  };

  const handleCashFlowChange = (index, value) => {
    const updatedCashFlows = [...cashFlows];
    updatedCashFlows[index].amount = parseFloat(value);
    setCashFlows(updatedCashFlows);
  };

  return (
    <CalculatorContainer>
      <CalculatorTitle>Discounted Cash Flow Calculator</CalculatorTitle>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label="Discount Rate (%)"
          name="discountRate"
          rules={[{ required: true, message: 'Please input the discount rate!' }]}
        >
          <Input suffix="%" type="number" placeholder="Discount Rate" />
        </FormItem>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          {cashFlows.map((cashFlow, index) => (
            <FormItem
              key={index}
              label={`Year ${cashFlow.year} Cash Flow`}
            >
              <Input
                prefix="$"
                type="number"
                placeholder="Cash Flow"
                value={cashFlow.amount}
                onChange={(e) => handleCashFlowChange(index, e.target.value)}
              />
            </FormItem>
          ))}
        </Space>
        <FormItem>
          <StyledButton type="dashed" onClick={handleAddCashFlow}>
            Add Cash Flow
          </StyledButton>
        </FormItem>
        <FormItem>
          <StyledButton type="primary" htmlType="submit">
            Calculate
          </StyledButton>
        </FormItem>
      </Form>
      <ResultContainer>
        <ResultItem>
          <strong>Present Value:</strong> ${presentValue.toFixed(2)}
        </ResultItem>
      </ResultContainer>
    </CalculatorContainer>
  );
};

export default DiscountedCashFlowCalculator;
