import { useContext, createContext } from 'react';
import store from '../_store';

export const StoreContext = createContext(store);

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error(
      'useCounterStoreContext must be used within a CounterStoreProvider'
    );
  }
  return context;
};
