import styled from 'styled-components';
import { Typography } from 'antd';

export const HeroContainer = styled.div`
  padding: 35px 24px;
  background-color: #f5f5f5;
  text-align: center;
  margin: 0 auto;
`;

export const MapInsightsText = styled(Typography.Text)`
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #0066cc;
  margin-bottom: 16px;
`;

export const StyledTitle = styled(Typography.Title)`
  &&&& {
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 48px;
    margin-top: 20px!important;
    
    span {
      color: #0066cc;
    }
  }
`;

export const MapImage = styled.img`
  width: 200%;
  max-width: 1000px;
  height: auto;
  border-radius: 16px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
`;