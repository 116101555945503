import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';
import { InputNumber } from 'antd';

export const ThemePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 100px;
    background-color: #f3f3f1;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    margin: 20px 0;
`;

export const TemplateSelector = styled.div`
    width: 100%;
    margin-bottom: 20px;
    text-align: center;

    .slick-prev,
    .slick-next {
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
    }

    .slick-prev {
        left: -35px;
    }

    .slick-next {
        right: -35px;
    }

    .slick-dots {
        bottom: -30px;
    }

    .slick-dots li button:before {
        color: #007bff;
    }
`;

export const TemplateImage = styled.div`
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 500px;
    width: 300px;

    img {
        max-width: 100%;
        max-height: 90%;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
`;

export const UseTemplateButton = styled.button`
    background-color: ${COLORS.$mainBlue};
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: ${COLORS.$mainBlueHover};
    }
`;

export const ColorPickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;

    label {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .color-picker-row {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

export const ColorPickerContainer = styled.div`
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
`;

export const ColorBox = styled.div`
    width: 50px;
    height: 50px;
    background-color: ${(props) => props.color};
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
`;

export const ColorPickerHexInputWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

export const ColorPickerHexInput = styled.input`
    width: 100px;  /* Match width of ColorBox */
    height: 50px; /* Match height of ColorBox */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0 8px;
    font-size: 14px;
    color: #333;
    text-align: left; /* Align text to the left inside the input */
    box-sizing: border-box; /* Ensure padding doesn't increase size */
`;

export const BackgroundPickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
`;

export const BackgroundCardGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
    width: 100%;
`;

export const BackgroundCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(25% - 10px);

    @media (max-width: 1024px) {
        width: calc(33.33% - 10px);
    }

    @media (max-width: 768px) {
        width: calc(50% - 10px);
    }
`;

export const BackgroundCard = styled.div`
    width: 100%;
    position: relative;
    border-radius: 15px;
    border: 1px solid #ddd;
    background-color: ${({ color, gradient }) => color || (gradient ? 'transparent' : '#f0f0f0')};
    background: ${({ gradient, gradientColor, gradientDirection }) =>
        gradient ? `linear-gradient(${gradientDirection === 'up' ? 'to bottom' : 'to top'}, ${gradientColor}33, ${gradientColor}66)` : undefined}; // Adjusted for lighter gradient
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        border: 2px solid black;
    }

    ${({ isSelected }) => isSelected && `
        border: 2px solid ${COLORS.$mainBlue};
    `}

    &:before {
        content: '';
        display: block;
        padding-bottom: 177.78%; // Aspect ratio 9:16
    }

    .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const BackgroundOption = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10px; /* Add space between the card and the label */

    .label {
        font-size: 14px;
        margin-top: 5px; /* Adjust the space between the icon and the label */
    }
`;

export const DirectionButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;

    label {
        display: flex;
        align-items: center;
        margin-right: 10px;

        input {
            margin-right: 5px;
        }
    }
`;

export const DirectionLabel = styled.span`
    font-weight: bold;
    margin-bottom: 5px;
`;

export const CardContainer = styled.div`
    background: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
`;

export const ThemePickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
`;

export const ThemeCardGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
    width: 100%;

    @media (max-width: 768px) {
        gap: 10px;
        justify-content: space-between;
    }
`;

export const CustomThemeCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(25% - 10px);
    margin-bottom: 40px;

    @media (max-width: 1024px) {
        width: calc(33.33% - 10px);
    }

    @media (max-width: 768px) {
        width: calc(50% - 10px);
    }
`;

export const CustomThemeCard = styled.div`
    width: 100%;
    border-radius: 15px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d3d3d3;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 0;
    padding-bottom: 177.78%; // Aspect ratio 9:16
    position: relative;

    .customize-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        font-weight: bold;
        color: #333;
        text-align: center;
    }
`;

export const ThemeCard = styled.div`
    width: 100%;
    border-radius: 15px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 0;
    padding-bottom: 177.78%; // Aspect ratio 9:16
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    ${({ isSelected }) => isSelected && `
        border: 2px solid ${COLORS.$mainBlue};
    `}
`;

export const ThemeOption = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    width: calc(25% - 10px);

    @media (max-width: 1024px) {
        width: calc(33.33% - 10px);
    }

    @media (max-width: 768px) {
        width: calc(50% - 10px);
    }
`;

export const ThemeImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const StyledContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};
  gap: ${props => props.gap || '10px'};
  align-items: ${props => props.alignItems || 'stretch'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
`;

export const StyledInputGroup = styled.div`
  margin-bottom: 20px;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: ${COLORS.$greyScale700};
  font-size: 14px;
`;

export const StyledRegister = styled.div`
  margin-top: 30px;
  text-align: center;
`;

export const StyledSkipButton = styled.button`
  background: none;
  border: none;
  color: ${COLORS.$primary};
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    color: ${COLORS.$primaryHover};
  }
`;

export const StyledMapContainer = styled.div`
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
`;

export const StyledSlider = styled.div`
  margin-bottom: 20px;
`;

export const StyledTooltip = styled.span`
  margin-left: 8px;
  color: ${COLORS.$greyScale500};
  cursor: help;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  height: 48px;

  .ant-input-number-input-wrap {
    height: 100%;
  }

  input {
    height: 100%;
    padding-left: 20px !important; // Make room for the $ sign
  }

  // Style for the $ prefix
  .ant-input-number-prefix {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    line-height: normal;
  }
`;

export const StyledSelect = styled.div`
  .ant-select {
    width: 100%;
  }
`;