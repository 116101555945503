import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const Section = styled.section`
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 8px;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
`;

const List = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
`;

const StyledCollapse = styled(Collapse)`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const FAQTitle = styled.h3`
  font-size: 32px;
  color: #333;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 25px 16px!important;
  }
  .ant-collapse-expand-icon {
    order: 2;
    margin-left: 12px;
  }
`;

const RotatingIcon = styled(CaretRightOutlined)`
  transition: transform 0.2s;
  transform: ${props => props.isActive ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

const Introduction = () => (
  <>
  </>
);

const FAQItem = ({ question, answer, isActive, onClick }) => (
  <StyledPanel 
    header={question} 
    key={question} 
    isActive={isActive} 
    onClick={onClick}
    expandIcon={({ isActive }) => <RotatingIcon isActive={isActive} />}
  >
    {answer.split('\n\n').map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph.includes('\n') ? (
          <List>
            {paragraph.split('\n').map((item, itemIndex) => (
              <ListItem key={itemIndex}>{item.replace(/^\d+\.\s/, '')}</ListItem>
            ))}
          </List>
        ) : (
          <Paragraph>{paragraph}</Paragraph>
        )}
      </React.Fragment>
    ))}
  </StyledPanel>
);

const faqData = [
  {
    question: "How can a mortgage calculator benefit homebuyers?",
    answer: "A mortgage calculator is an essential tool for prospective homebuyers, offering several key benefits:\n\n" +
      "1. Budget assessment: It helps you determine if a mortgage fits within your financial plan by breaking down home loans into monthly payments.\n" +
      "2. Affordability insights: The calculator assists in figuring out how much house you can comfortably afford based on your financial situation.\n" +
      "3. Scenario analysis: You can easily adjust variables like location and loan type to see how different factors impact your payments.\n" +
      "4. Financial planning: By plugging in various numbers and scenarios, you can visualize how your decisions affect the overall cost of homeownership.\n" +
      "5. Interest rate comparison: It allows you to compare how different interest rates impact your monthly payments and total loan cost.\n\n" +
      "Using a mortgage calculator early in your home buying journey can provide valuable insights, helping you make informed decisions about your future home purchase."
  },
  {
    question: "What is a mortgage and how does it work?",
    answer: "A mortgage is a crucial financial tool that enables many people to achieve homeownership. Here's a comprehensive explanation:\n\n" +
      "1. Definition: A mortgage is a loan specifically designed to help cover the cost of buying a home.\n" +
      "2. Function: It allows people to purchase properties they might not otherwise be able to afford upfront.\n" +
      "3. Lenders: Typically provided by large financial institutions such as banks or specialized mortgage lenders.\n" +
      "4. Repayment: Borrowers repay the loan in small increments (usually monthly) over an extended period, often 15 to 30 years.\n" +
      "5. Interest: Lenders charge interest on the borrowed amount as compensation for the loan.\n" +
      "6. Security: The property itself serves as collateral for the loan, meaning the lender can foreclose if payments are not made.\n" +
      "7. Ownership: While you're paying off the mortgage, you can live in and use the property as your own.\n\n" +
      "Understanding mortgages is essential for most homebuyers, as they play a pivotal role in making homeownership accessible to a broader range of people."
  },
  {
    question: "What components make up a typical mortgage payment?",
    answer: "A standard mortgage payment is often referred to by the acronym PITI, which stands for the four main components:\n\n" +
      "1. Principal: The original amount borrowed, which you're paying back over time.\n" +
      "2. Interest: The cost charged by the lender for borrowing the money, calculated as a percentage of the principal.\n" +
      "3. Taxes: Property taxes, often collected as part of your mortgage payment and held in escrow.\n" +
      "4. Insurance: Homeowners insurance, which protects your property from damage or theft.\n\n" +
      "Additional components may include:\n" +
      "5. Private Mortgage Insurance (PMI): Required if your down payment is less than 20% of the home's value.\n" +
      "6. HOA fees: If your property is part of a homeowners association.\n" +
      "7. Additional insurance: Such as flood or earthquake insurance in high-risk areas.\n\n" +
      "Understanding these components helps you budget effectively and comprehend the true cost of homeownership beyond just the purchase price."
  },
  {
    question: "What are the minimum down payment requirements for different types of mortgages?",
    answer: "Down payment requirements vary depending on the type of mortgage you're seeking. Here's a breakdown of common options:\n\n" +
      "1. Conventional loans: Typically require 20% down for the best rates, but some allow as little as 3% down.\n" +
      "2. FHA loans: Designed for first-time buyers, requiring as little as 3.5% down.\n" +
      "3. VA loans: Available to eligible veterans and service members, offering 0% down payment options.\n" +
      "4. USDA loans: For rural property purchases, also offering 0% down payment to qualified buyers.\n" +
      "5. Jumbo loans: For high-value properties, usually require 10-20% down.\n\n" +
      "Keep in mind:\n" +
      "- Lower down payments often mean higher monthly payments and added costs like PMI.\n" +
      "- A larger down payment can lead to better interest rates and loan terms.\n" +
      "- Some programs offer down payment assistance for eligible buyers.\n\n" +
      "Consider your financial situation and long-term goals when deciding on your down payment amount."
  },
  {
    question: "How do lenders determine your home affordability?",
    answer: "Lenders assess home affordability through a comprehensive review of your financial situation. Key factors include:\n\n" +
      "1. Income: Your total earnings from all sources, including salary, investments, and other income.\n" +
      "2. Debt-to-Income (DTI) Ratio: The percentage of your gross monthly income that goes towards debt payments.\n" +
      "3. Credit Score: A numerical representation of your credit history and financial responsibility.\n" +
      "4. Employment History: Stability and consistency in your career.\n" +
      "5. Assets: Savings, investments, and other financial resources you possess.\n" +
      "6. Down Payment: The amount you can put towards the home purchase upfront.\n\n" +
      "Lenders typically use the 28/36 rule as a guideline:\n" +
      "- Housing costs should not exceed 28% of your gross monthly income.\n" +
      "- Total debt payments (including housing) should not exceed 36% of your gross monthly income.\n\n" +
      "Understanding these factors can help you prepare for the mortgage application process and improve your chances of approval."
  },
  {
    question: "What are the main types of mortgages and their characteristics?",
    answer: "Understanding different mortgage types can help you choose the best option for your situation:\n\n" +
      "1. Fixed-Rate Mortgages:\n" +
      "   - Interest rate remains constant throughout the loan term\n" +
      "   - Predictable payments, good for long-term homeowners\n" +
      "   - Common terms: 15, 20, or 30 years\n\n" +
      "2. Adjustable-Rate Mortgages (ARMs):\n" +
      "   - Interest rate can change periodically\n" +
      "   - Often start with lower rates than fixed-rate mortgages\n" +
      "   - Good for short-term homeowners or those expecting income increases\n\n" +
      "3. FHA Loans:\n" +
      "   - Government-backed, easier to qualify for\n" +
      "   - Lower down payment requirements (as low as 3.5%)\n" +
      "   - Ideal for first-time homebuyers or those with lower credit scores\n\n" +
      "4. VA Loans:\n" +
      "   - For eligible veterans and service members\n" +
      "   - Can offer 0% down payment\n" +
      "   - Often have lower interest rates and no PMI requirement\n\n" +
      "5. USDA Loans:\n" +
      "   - For rural property purchases\n" +
      "   - Can offer 0% down payment to qualified buyers\n" +
      "   - Income limits apply\n\n" +
      "Consider your financial situation, long-term plans, and risk tolerance when choosing a mortgage type."
  },
  {
    question: "How does a 15-year mortgage compare to a 30-year mortgage?",
    answer: "When choosing between a 15-year and 30-year mortgage, consider these key differences:\n\n" +
      "1. Monthly Payments:\n" +
      "   - 15-year: Higher monthly payments\n" +
      "   - 30-year: Lower monthly payments\n\n" +
      "2. Interest Rates:\n" +
      "   - 15-year: Generally lower interest rates\n" +
      "   - 30-year: Slightly higher interest rates\n\n" +
      "3. Total Interest Paid:\n" +
      "   - 15-year: Significantly less interest paid over the life of the loan\n" +
      "   - 30-year: More interest paid due to the longer term\n\n" +
      "4. Equity Building:\n" +
      "   - 15-year: Faster equity accumulation\n" +
      "   - 30-year: Slower equity buildup\n\n" +
      "5. Financial Flexibility:\n" +
      "   - 15-year: Less flexibility in monthly budget\n" +
      "   - 30-year: More room in budget for other expenses or investments\n\n" +
      "6. Debt-Free Timeline:\n" +
      "   - 15-year: Become debt-free sooner\n" +
      "   - 30-year: Longer period of mortgage payments\n\n" +
      "Choose based on your financial goals, income stability, and comfort with different payment levels."
  },
  {
    question: "What strategies can help lower your mortgage payments?",
    answer: "Several strategies can help reduce your mortgage payments:\n\n" +
      "1. Shop for lower interest rates: Compare offers from multiple lenders to find the best rate.\n\n" +
      "2. Extend loan term: Opting for a 30-year mortgage instead of a 15-year can lower monthly payments.\n\n" +
      "3. Buy discount points: Upfront fee to lower your interest rate, potentially reducing long-term costs.\n\n" +
      "4. Increase down payment: A larger down payment reduces the loan amount and potentially eliminates PMI.\n\n" +
      "5. Avoid PMI: Reach 20% down payment to avoid Private Mortgage Insurance.\n\n" +
      "6. Choose a less expensive home: Buying below your maximum budget naturally lowers payments.\n\n" +
      "7. Improve credit score: A higher score can lead to better interest rates.\n\n" +
      "8. Refinance: If rates drop, refinancing could lower your payments.\n\n" +
      "9. Remove PMI: Once you reach 20% equity, request PMI removal.\n\n" +
      "10. Make biweekly payments: This can reduce total interest and shorten the loan term.\n\n" +
      "Consider your long-term financial goals when implementing these strategies."
  },
  {
    question: "How does mortgage pre-approval benefit homebuyers?",
    answer: "Mortgage pre-approval offers several advantages to homebuyers:\n\n" +
      "1. Clear budget: Provides a realistic understanding of how much home you can afford.\n\n" +
      "2. Stronger offers: Shows sellers you're a serious, qualified buyer, potentially giving you an edge in competitive markets.\n\n" +
      "3. Faster closing: Much of the paperwork is completed upfront, potentially speeding up the closing process.\n\n" +
      "4. Interest rate lock: Some lenders allow you to lock in an interest rate during pre-approval.\n\n" +
      "5. Negotiating power: Knowing your budget helps you negotiate more effectively.\n\n" +
      "6. Identify credit issues: Allows time to address any credit problems before making an offer.\n\n" +
      "7. Saves time: Focus your home search on properties within your approved price range.\n\n" +
      "8. Reduces stress: Eliminates uncertainty about financing during the home-buying process.\n\n" +
      "9. Better real estate agent relationships: Agents often prefer working with pre-approved clients.\n\n" +
      "10. Smoother transaction: Reduces the chance of financing falling through after an offer is accepted.\n\n" +
      "Pre-approval typically lasts 60-90 days. Remember, it's not a guarantee of a loan, but a conditional commitment based on your current financial situation."
  }
];

const FAQSection = () => {
  const [activeKey, setActiveKey] = React.useState(null);

  const onChange = (keys) => {
    setActiveKey(keys);
  };

  return (
    <Section>
      <Introduction />
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <StyledCollapse activeKey={activeKey} onChange={onChange}>
      {faqData.map((item) => (
        <FAQItem
          key={item.question}
          question={item.question}
          answer={item.answer}
          isActive={activeKey === item.question}
          onClick={() => setActiveKey(activeKey === item.question ? null : item.question)}
        />
        ))}
      </StyledCollapse>
    </Section>
  );
};

export default FAQSection;