import styled from 'styled-components';
import { Input, Checkbox, Button } from 'antd';

export const BackgroundImageContainer = styled.div`
  background-image: ${props => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); // Adjust the opacity as needed
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    margin-top: -190px;
  }
`;

export const Header = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

export const Subheader = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

export const CalculatorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FormSection = styled.div`
  width: ${props => props.showingResults ? '100%' : '50%'};
  background: white;
  padding: 30px;
  border-radius: 17px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition: width 0.2s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BuyingPowerContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BuyingPowerCard = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 17px;
  box-shadow: 0 0 10px rgba(0,0,0,0.125);
`;

export const AffordabilityHeader = styled.h3`
  color: ${props => {
    switch(props.type) {
      case 'affordable':
        return 'green';
      case 'stretch':
        return 'orange';
      case 'difficult':
        return 'red';
      default:
        return 'black';
    }
  }};
`;

export const AffordabilityRange = styled.p`
  margin: 10px 0;
`;

export const AffordabilityDescription = styled.small`
  display: block;
  margin-top: 5px;
`;

export const FormTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 20px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-size: 18px;
  margin-top: 20px;
`;

export const NextSteps = styled.div`
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  display: block;
  width: 270px;
  margin: 20px 0;
  background-color: transparent;
  color: black;
  border: 1.5px solid black;
  height: 48px;
  &:hover {
    background-color: #333;
    color: white;
  }
`;

export const NextStepsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const NextStepsContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NextStepsText = styled.div`
  flex: 1;
`;

export const NextStepsImage = styled.img`
  width: 400px;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const DesktopOnly = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  height: 48px;
`;

export const InputDescription = styled.small`
  margin-top: 5px;
`;