import React from 'react';

// Styles
import styles from './SelectComponent.module.scss';
// Components
import { Select } from 'antd';

const SelectComponent = ({
  options = [],
  label = '',
  placeholder = '',
  size = 'middle',
  variant = 'outlined',
  onChange,
  defaultValue,
  customStyles,
}) => {
  return (
    <>
      {Boolean(label) && <span className={styles.label}>{label}</span>}
      <Select
        className={styles.select}
        popupClassName={styles.select_list}
        options={options}
        placeholder={placeholder}
        size={size}
        variant={variant}
        onChange={onChange}
        defaultValue={defaultValue}
        style={customStyles}
      />
    </>
  );
};

export default SelectComponent;
