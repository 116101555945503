import { StyledInput } from './styled';
import { Label } from '../../../Label';
import { Fragment } from 'react';

export const Password = ({ label, ...props }) => {
  return (
    <Fragment>
      <Label text={label} />
      <StyledInput {...props} />
    </Fragment>
  );
};
