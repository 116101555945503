import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks/storeContext";
import { ROUTE_PATH } from '../../../../constants/routePath';
import useGetPropertySearch from '../../../../hooks/useGetPropertySearch';

// Components
import BaseTypography from "../../../../components/BaseTypography";
import { BaseButton } from "../../../../components/Button";
import { Flex, Row, Col, Spin } from 'antd';
import MarketSearchGridItem from '../../../MarketSearch/MarketSearchGridItem';

// Styles
import { StyledContainer } from "../../styled";
import { COLORS } from "../../../../constants/colors";


const onboarding4 = observer(() => {
    const [searchParams, setSearchParams] = useState(null);
    const { authStore } = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInvestorDetails = async () => {
            const details = await authStore.getInvestorDetails();

            setSearchParams({
                latitude: details.profile.investmentLocation.coordinates.lat,
                longitude: details.profile.investmentLocation.coordinates.lng,
                radius: details.profile.investmentLocation.radius,
                property_type: details.profile.propertyType,
                mls_listing_price_max: details.profile.preferredInvestmentAmount || null,
                size: 12,
            });
        };

        fetchInvestorDetails();
    }, [authStore]);

    const { properties, isLoading, hasError } = useGetPropertySearch(searchParams || {});

    const recommendations = useMemo(() => {
        // You can add additional filtering or sorting logic here if needed
        return properties.slice(0, 12); // Limit to 12 recommendations for example
    }, [properties]);

    const handleViewAllProperties = () => {
        if (searchParams) {
            const { latitude, longitude, radius, property_type, mls_listing_price_max, size = 20 } = searchParams; 
            navigate({
                pathname: ROUTE_PATH.SEARCH,
                search: `?view=Map&latitude=${latitude}&longitude=${longitude}&radius=${radius}&property_type=${property_type}${mls_listing_price_max ? `&mls_listing_price_max=${mls_listing_price_max}` : ''
                    }&size=${size}`,
            });
        }
    };

    return (
        <StyledContainer>
            <Flex vertical>
                <BaseTypography
                    align={"left"}
                    type="title"
                    color={COLORS.$black}
                    level={3}
                >
                    Recommended Properties
                </BaseTypography>
                <BaseTypography
                    align={"left"}
                    type="paragraph"
                    color={COLORS.$greyScale500}
                    level={0}
                >
                    Based on your investment preferences, we have selected these properties for you.
                </BaseTypography>

                {isLoading ? (
                    <Flex justify="center" align="center" style={{ height: '300px' }}>
                        <Spin size="large" />
                    </Flex>
                ) : hasError ? (
                    <BaseTypography
                        align={"center"}
                        type="paragraph"
                        color={COLORS.$red500}
                        level={0}
                    >
                        An error occurred while fetching recommendations. Please try again later.
                    </BaseTypography>
                ) : (
                    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                        {recommendations.map((property) => (
                            <Col xs={12} sm={12} md={8} lg={8} key={property.id}>
                                <MarketSearchGridItem property={property} />
                            </Col>
                        ))}
                    </Row>
                )}

                <Flex justify="center" style={{ marginTop: '30px' }}>
                    <BaseButton type="primary" onClick={handleViewAllProperties}>
                        View More
                    </BaseButton>
                </Flex>
            </Flex>
        </StyledContainer>
    );
});

export default onboarding4;